import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// import { useSession } from "../../hooks/useSession";
import { useSession } from "../../hooks/useSessionDev";
import { useRest } from "../../hooks/useRest";
import { imgUrl, setStatus } from "../../templates/consts";
import Tooltip from "../util/tooltip/Tooltip";
import { AppContext } from "../../context/AppContext";

interface Props {
    bid: string;
    zones: any[];
    floor: any;
}

const BldgFloor: React.FC<Props> = ({ bid, zones, floor }) => {

    // router fncs
    const nav = useNavigate();

    const { appData, setAppData } = useContext(AppContext);

    // custom hooks
    const [sessionData, , , , , , postObUpdate] = useSession();
    const [request] = useRest();

    // built-in hooks
    const ref = useRef<HTMLDivElement>(null);

    const [showAlerts, setShowAlerts] = useState<boolean>(false);

    const compileFloorAlerts = () => {
        if (floor.meas && floor.alerts) {
            const floorAlerts = Object.keys(floor.alerts).filter((prop: string) => prop !== 'overall').reduce((fAlerts: any, prop: string) => {
                fAlerts.bad += floor.alerts[prop].bad;
                fAlerts.crit += floor.alerts[prop].crit;
                return fAlerts;
            }, { bad: 0, crit: 0 });
            if (floorAlerts.crit || floorAlerts.bad) {
                return floorAlerts;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    // handles click off functionality
    useEffect(() => {
        document.addEventListener('click', clickOff);
        return () => {
            document.removeEventListener('click', clickOff);
        }
        compileFloorAlerts()
    }, []);
    const clickOff = (e: MouseEvent) => {
        if (ref.current && !ref?.current.contains(e.target as Node)) {
            setOb(false);
        }
    }

    // useState var sets
    const [ob, setOb] = useState<boolean>(false);
    const [floorName, setFloorName] = useState<string>(`Floor ${floor.index}`);
    const [hover, setHover] = useState<boolean>(false);

    const handleInit = () => {
        if (!floor.id) {
            // setOb(true);
            setAppData({
                ...appData,
                helper: {
                    type: 'blocker',
                    title: 'Disabled',
                    content: "This feature has been restricted for the duration of the Pilot Program"
                }
            });
        } else {
            nav(`/floor/${floor.id}`);
        }
    }

    const obNewFloor = async () => {
        const postFloor = { index: floor.index, name: floorName, bid };
        try {
            const postedFloor = await request('floor', 'post', 'floor', postFloor);
            await postObUpdate(postedFloor, 'floor');
            setOb(false);
            nav(`/floor/${postedFloor.id}`);
        } catch (obNewFloorErr) {
            console.error({ obNewFloorErr });
        }
    }

    const closeOb = (e: any) => {
        e.stopPropagation();
        setOb(false);
    }

    const handleKeyDown = (e: any) => {
        if (e.key == 'Enter' && floorName.length) obNewFloor();
    }

    return (
        <>
            <div className='bldg-floor'
                id={`floor-${floor.index}`}
                onClick={() => handleInit()}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                ref={ref}
                style={{ background: !floor.id ? '' : (floor.id && !floor.meas ? 'var(--breezi-blue-transparent)' : setStatus('all', floor.alerts ? floor.alerts.overall : false)) }}
            // data-tooltip-id='bldg-floor-tooltip'
            >
                {
                    !ob &&
                    <>
                        {
                            !floor.name && !hover &&
                            <h3>Floor {floor.index}</h3>
                        }
                        {
                            !floor.name && hover &&
                            <h3 style={{ color: 'var(--cancel)' }}>Activate Floor {floor.index}</h3>
                        }
                        {
                            floor.name &&
                            <h3>{floor.name}</h3>
                        }
                        {
                            floor.name && hover && compileFloorAlerts() &&
                            <div className='floor-alert-overlay'>
                                <h3 style={{ color: 'var(--cancel)' }}>{compileFloorAlerts().bad}</h3>
                                <img src={`${imgUrl}/alert.png`} />
                                <h3 style={{ color: 'var(--crit)' }}>{compileFloorAlerts().crit}</h3>
                            </div>
                        }
                    </>
                }
                {
                    ob &&
                    <div className='bldg-floor-overlay dark-overlay'>
                        <small>Floor Name: </small>
                        <input
                            value={floorName}
                            onChange={e => setFloorName(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)} />
                        {
                            floorName.length > 0 &&
                            <a className='btn prim-btn' style={{ fontSize: '.8em' }} onClick={() => obNewFloor()}>Activate Floor</a>
                        }
                        <a className='btn cancel-btn' style={{ fontSize: '.8em' }} onClick={e => closeOb(e)}>Cancel</a>
                    </div>
                }
            </div>


            {/* { TOOLTIP TYPES } */}

            {/* <Tooltip id='bldg-floor-tooltip' 
                    type='message' 
                    content='Click to activate floor and begin adding Measurement Zones and Datapoints'
                    anchor={ `floor-${floor.index}`} />  */}

            {/* <Tooltip 
            id='bldg-floor-tooltip'
            anchor={ `floor-${floor.index}`}
            type='placard'
            imgs={ ['vocPlacard'] } /> */}

            {/* <Tooltip 
            id='bldg-floor-tooltip'
            anchor={ `floor-${floor.index}`}
            type='info'
            imgs={ ['info'] }
            title='It looks like you want some information...'
            content='Four score and seven years ago, our forefathers brought forth on this continent a new nation. Conceived in liberty and dedicated to the proposition that all men are created equal. But now we are engaged in a great Civil War: testing whether this nation, or any nation so conceived and so dedicated can long endure.' /> */}

        </>


    )

}

export default BldgFloor;