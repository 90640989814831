import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRest } from '../../hooks/useRest';
// import { useSession } from '../../hooks/useSessionContext';
// import { useSession } from '../../hooks/useSessionContext';
import { useSession } from '../../hooks/useSessionDev';

import Card from '../card/Card';
import { AppContext } from '../../context/AppContext';

export default () => {

    const { id } = useParams();

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession()
    const [request] = useRest();

    const [dp, setDp] = useState<any>(null);
    const [zoneData, setZoneData] = useState<any>(null);
    const [dpIndex, setDpIndex] = useState<number | null>(null);

    const initializeDatapoint = async () => {
        const newDp = await getSessionEntity('datapoint', id ?? '');
        if (newDp) setSessionEntity('datapoint', newDp);
    }

    useEffect(() => {
        (async () => {
            await initializeDatapoint();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (sessionData.util && !dp) {
                setDp(sessionData.datapoint);
                const zone = await getSessionEntity('zone', sessionData.datapoint.zid);
                setDpIndex(zone.datapoints.map((dp: any) => dp.id).indexOf(sessionData.datapoint.id))
                setZoneData({ name: 'any' });
                setAppData({ ...appData, loading: { visible: false } });
            }
        })();
    }, [sessionData.util]);


    return (
        <main className='mob-dp-main'>
            <div className='card-frame'>
                {
                    dp && dpIndex != null &&
                    <Card datapoint={dp} dpIndex={dpIndex} zName={zoneData.name} zoneRefresh={() => { }} />
                }
            </div>
        </main>
    )
}