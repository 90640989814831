import { createContext, useContext, useEffect, useState } from "react";
import { useSession } from "./useSession";
import { useRest } from "./useRest";
import { getServers } from "dns";
import { useParams } from "react-router-dom";

interface FloorContextType {
    floorData:{[key:string]: any};
    setFloorData:(floorData:{[key:string]:any}) => void;
}

export const FloorContext = createContext<FloorContextType>({
    floorData: {},
    setFloorData: () => {}
});

export const useFloor = (refresh?:() => void):[
        number, 
        (view:number) => void, 
        () => void, any, 
        (ap:any) => void, 
        () => void, 
        () => number, any, 
        (dp:any) => void, 
        (zone:any) => void, 
        (dp:any) => void, 
        any,
        (apsn: any) => void,
        any,
        (newFloorData:any) => void
    ] => {

    const { id } = useParams();

    // Encapsulated session vars for this hook, to be consumed by all Floor Components
    const { floorData, setFloorData } = useContext(FloorContext);

    const [ sessionData, , getSessionEntity, , , , , , updateSessionEntity ] = useSession();
    const [ request ] = useRest();

    const [ utilView, setUtilView ] = useState<number>(0);

    const updateUtilView = (view:number) => {
        setUtilView(view);
    }

    useEffect(() => {
        setFloorData({ ...floorData, utilView });
    }, [utilView]);

    const initBank = async () => {
        if (!floorData.bank) {
            try {
                const bank = await request('bank', 'get', 'bank');
                setFloorData({ ...floorData, bank })
                // updateSessionData(bank, 'bank');
            } catch(getBankErr) {
                console.error({ getBankErr });
            }
        }
    }

    const setActiveBankAp = (ap:any) => {
        setFloorData({ ...floorData, activeBankAp: ap })
    }

    const clearApFromBank = () => {
        let bank = sessionData.bank;
        bank = bank.filter((ap:any) => ap.apsn != floorData.activeBankAp.apsn);
        // updateSessionDsetata(bank, 'bank');
        let floor = floorData;
        delete floorData.activeBankAp;
        setFloorData(floor);
        updateUtilView(0);
    }

    const getBankIndex = () => {
        return floorData.bank.indexOf(floorData.activeBankAp);
    }

    const setDragDp = (dragDp:any) => {
        setFloorData({ ...floorData, dragDp });
    }

    const rebaseAp = async (dest:any) => {
        // let floor = sessionData.floor
        let floor = await getSessionEntity('floor', id, false) as any;
        let dragDp = { ...floorData.dragDp };
        let origin = { ...floor.zones.find((z:any) => z.id == dragDp.zid) };
        const zids = floor.zones.map((z:any) => z.id);
        const origIndex = zids.indexOf(origin.id);
        const destIndex = zids.indexOf(dest.id);
        const dpIndex = origin.datapoints.map((dp:any) => dp.id).indexOf(dragDp.id);
        origin.datapoints = origin.datapoints.filter((d:any) => d.id != dragDp.id);
        let updatedDp = { ...dragDp, zid: dest.id };
        dest.datapoints.push(updatedDp);
        floor.zones[origIndex].datapoints = origin.datapoints;
        floor.zones[destIndex] = dest;
        delete floor.dragDp;
        floor.dragDest = dest.id;
        updateSessionEntity('floor', floor.id, floor, sessionData, true);
        // setFloorData(floor);
        // try {
        //     await request('rebase', 'put', 'rebase', {id: dragDp.id, origin: origin.id, dest: dest.id, originIndex: dpIndex})
        //     setFloorData(floor);
        // }
        // origin.datapoints.splice(dpIndex, 1);
        // dragDp.zid = dest.id;
        // dest.datapoints.push(dragDp);
        // floor.zones[origIndex] = origin;
        // floor.zones[destIndex] = dest;
        // delete floor.dragDp;
        // try {
        //     setFloorData(floor);
        //     // updateSessionData(floor, 'rebase');
        //     // await request('rebase', 'put', 'rebase', {id: dragDp.id, origin: origin.id, dest: dest.id, originIndex: dpIndex})
        //     updateSessionEntity('floor', id, floor, sessionData);
        // } catch(rebaseErr) {
        //     updateSessionEntity('floor', id, floor, sessionData);
        // }
    }

    const primeBelayedApsnAssignment = (dp:any) => {
        setFloorData({ ...floorData, belayedApsnDp: dp });
        updateUtilView(4);
    }

    const belayedApsnAssignment = async (apsn:any) => {
        try {
            const assigned = await request(`datapoint`, 'put', { ...floorData.belayedApsnDp, apsn, bankIndex: sessionData.bank.indexOf(apsn) });
            setFloorData({ ...floorData, belayedApsnDp: false });
            updateUtilView(0);
            // remove from bank...
        } catch(belayedApsnAssignmentErr) {
            console.error({ belayedApsnAssignmentErr });
        }
    }

    return [ 
        floorData.utilView, 
        updateUtilView, 
        initBank, 
        floorData.activeBankAp, 
        setActiveBankAp, 
        clearApFromBank, 
        getBankIndex,
        floorData.dragDp,
        setDragDp,
        rebaseAp,
        primeBelayedApsnAssignment,
        floorData.belayedApsnAssignment ?? false,
        belayedApsnAssignment,
        floorData,
        setFloorData
    ];

}
