import { useEffect, useState } from "react"
import codes, { ICountryCodeItem } from 'country-calling-code';

export const useSelector = (selectorContent:string):[any[], (searchCriteria:string, filterProps:string[]) => void, () => void] => {

    const setListByContent = () => {
        let retList;
        switch (selectorContent) {
            case 'countryCode':
                retList = codes;
                break;
        }
        return retList as ICountryCodeItem[];
    }

    useEffect(() => {
        (async () => {
            
            // const continents = await geonames.search({q: 'CONT'}).promise();
        })();
    }, []);

    const [ originalList, setOriginalList ] = useState<any[]>(setListByContent());
    const [ list, setList ] = useState<any[]>(setListByContent());
    const [ filterProps, setFilterProps ] = useState<string[]>([]);

    const filterList = (searchCriteria:string, filterProps:string[]) => {
        let newList = originalList.filter((entry:any) => {
            entry.countryCodes = entry.countryCodes[0];
            return filterProps.some((prop:string) => entry[prop].toLowerCase().includes(searchCriteria.toLowerCase()));
        })
        const usa = newList.find(c => c.isoCode2 == 'US');
        if (usa) {
            newList.splice(newList.indexOf(usa), 1);
            setList([ usa, ...newList ]);   
        } else {
            setList(newList);
        }
    }

    const resetList = () => {
        setList(originalList);
    }

    return [ list, filterList, resetList ]

} 