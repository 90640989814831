import React, { useEffect, useState } from 'react';
import { useForm } from '../../hooks/useFormContext';
import { imgUrl } from '../../templates/consts';
import { useSelector } from '../../hooks/useSelector';
import { setFlagsFromString } from 'v8';
interface Props {
    prop:string;
    input:any;
    decrement: () => void;
}

const capitalize = (str:string) => str[0].toUpperCase() + str.substring(1, str.length).toLowerCase();

const Prefill:React.FC<Props> = ({ prop, input, decrement }) => {

    const [ formData, updateFormData ] = useForm();
    const [ list ] = useSelector('countryCode');
    const [ countryName, setCountryName ] = useState<string>('');
    const [ hover, setHover ] = useState<boolean>(false);

    useEffect(() => {
        const country = list.find((listItem:any) => listItem.isoCode2 == formData.country);
        setCountryName(country.country);
    }, []);

    return (
        <label className='text-input' onMouseOver={ () => setHover(true) } onMouseLeave={ () => setHover(false) }>
            <div className='input-box' onClick={ () => decrement() }>
                <div className='form-prefill'>
                    { !hover &&
                        <>
                        {
                            prop == 'country' &&
                            <>
                                <img src={ `https://catamphetamine.github.io/country-flag-icons/3x2/${formData[prop]}.svg` } />
                                <h4>{ countryName }</h4>
                            </>
                        }
                        </>

                    }
                    {
                        hover && <h4 style={ {width: '100%', textAlign: 'center'}}>CHANGE</h4>
                    }
                </div>
            </div>
            { input.label }
        </label>
    )
}

export default Prefill;