import { useEffect, useState } from "react";
import { useEquipment } from "../../hooks/useEquipmentContext"
import Section from "./Section";
import MobileSecAp from "./MobileSecAp";
import MobDiff from "./MobileDiff";

interface Props {
    stageTog: number;
    setStageTog: (newStageTog: number) => void;
}

const MobHVAC: React.FC<Props> = ({ stageTog, setStageTog }) => {

    const [equip] = useEquipment();
    const [radialDp, setRadialDp] = useState<string>('');
    const [focusElem, setFocusElem] = useState<any>({});

    return (
        <div className='mob-hvac'>
            {
                equip && equip.sections?.length > 1 &&
                <>
                    <h3 style={{margin: 0, marginTop: '5px'}}>{equip.sections[stageTog].elems[0].type} Stage</h3>
                    <MobileSecAp sec={equip.sections[stageTog]} />
                    <Section
                        sec={equip.sections[stageTog]}
                        showStage={false}
                        radialDp={radialDp}
                        setRadialDp={setRadialDp}
                        bankState={''}
                        focusElem={focusElem}
                        setFocusElem={setFocusElem} />
                    <MobDiff stageTog={stageTog} sec={equip.sections[stageTog]} />
                </>
            }
        </div>
    )
}

export default MobHVAC;