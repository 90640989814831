import React, { useEffect, useState } from 'react';

import { useForm } from '../../hooks/useFormContext';
import { capitalize, imgUrl } from '../../templates/consts';
import '../../stylesheets/Selectors.css'

interface Props {
    list:any[];
    updateStep:() => void;
    label?: string;
    prop:string;
}

interface SharedType {
    prop:string;
    title:string;
}

const LtdSelector:React.FC<Props> = ({ list, updateStep, label, prop }) => {

    const [ formData, updateFormData ] = useForm();
    const [ selected, setSelected ] = useState<string>('');

    const selectType = (li:string|SharedType) => {
        li = typeof li == 'string' ? li : li.prop;
        updateFormData({[prop]: li});
        updateStep();
    }

    return (
        <>
        { label && label.length > 0 && <h3 className='selector-label'>{ label }</h3> }
            <div className='lg-selector' style={ {alignContent: 'space-around'} }>
            {
                list.map((li, lx) => (
                    <div className='ltd-sel-node' onClick={ () => selectType(li) }>
                        <div className='ltd-icon'>
                            {
                                !li.title ? <img src={ `${imgUrl}/${li}.png` } /> : <h2>{ li.alt ?? li.prop }</h2>
                            }
                        </div>
                        <h5>{ !li.title ? capitalize(li) : li.title }</h5>
                    </div>
                ))
            }
        </div>
        </>
    )
}

export default LtdSelector;