import { useEffect, useState } from "react";
import cToF from 'celsius-to-fahrenheit';

import { imgUrl, setStatus, threshMap } from "../../templates/consts";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useSession } from "../../hooks/useSessionDev";


interface Props {
    rdgRange: any;
    img: string;
    udSetpoints: (prop: string, rng: any) => void;
    currentVal?: number;
    rdgProps: any;
    config:any;
}

const Range: React.FC<Props> = ({ rdgRange, img, udSetpoints, currentVal, rdgProps, config }) => {

    const [sessionData] = useSession();

    const [defaultSp, setDefaultSp] = useState<any>(rdgRange);
    const [ranges, setRanges] = useState<any>();
    const [widths, setWidths] = useState<number[]>([]);

    useEffect(() => {
        updateRanges(rdgRange);
    }, []);

    useEffect(() => {
        if (ranges && ranges != defaultSp) {
            udSetpoints(rdgProps.prop, ranges);
        }
        assessWidths();
    }, [ranges]);

    const handleStyle = () => {
        return ranges.map((range: any, rIndex: number) => {
            return {
                backgroundColor: `var(--${rdgProps.tiers[rIndex]})`,
                width: '22.5px',
                height: '22.5px',
                bottom: '-4px',
                opacity: 1,
                borderRadius: '100%'
            }
        })
    }

    const assessWidths = () => {
        let widthArr = Object.keys(rdgRange).map(r => rdgRange[r]);
        widthArr = [...widthArr, rdgProps.min, rdgProps.max].sort((a, b) => a - b);
        let retArr: any = [];
        widthArr.forEach((r, rIndex) => {
            if (rIndex != widthArr.length - 1) {
                const wid = (widthArr[rIndex + 1] - widthArr[rIndex]) / (rdgProps.max - rdgProps.min) * 100;
                retArr.push(wid);
            }
        });
        setWidths(retArr);
    }

    const setRangeText = (tierProp: string, tierIndex: number) => {
        if (tierIndex == 0 && rdgProps.prop !== 'voc') return `${adjustPrefVal(ranges[0])}`;
        if (rdgProps.prop != 'voc') {
            return `${adjustPrefVal(tierIndex != rdgProps.tiers.length - 1 ? ranges[tierIndex] : rdgProps.max)}`;
        } else {
            return tierIndex === 2 ? 1 : (ranges[tierIndex]/1000).toFixed(3);   
        }
        // return `${tierIndex != rdgProps.tiers.length - 1 ? (ranges[tierIndex] / 1000).toFixed(3) : 1}`;
        // return !isNaN(ranges[tierIndex]) ? (ranges[tierIndex] / 1000).toFixed(3) : 1;
        // if (tierIndex == 0) return `${adjustPrefVal(rdgProps.min)} ~ ${adjustPrefVal(ranges[0])}${adjustPrefUnit()}`;
        // if (rdgProps.prop != 'voc') return `${adjustPrefVal(ranges[tierIndex - 1])} ~ ${adjustPrefVal(tierIndex != rdgProps.tiers.length - 1 ? ranges[tierIndex] : rdgProps.max)}${adjustPrefUnit()}`;
        // return `${(ranges[tierIndex - 1]/1000).toFixed(3)} ~ ${tierIndex != rdgProps.tiers.length - 1 ? (ranges[tierIndex]/1000).toFixed(3) : 1}${rdgProps.units}`
    }

    const updateRanges = (e: any) => {
        if (!Array.isArray(e)) e = Object.keys(e).map(prop => e[prop]);
        const rangeArr = e.sort((a: number, b: number) => a - b);
        setRanges(rangeArr);
    }

    const adjustPrefVal = (val: number | undefined) => {
        if (val || val?.toString() == '0') {
            if (rdgProps.prop == 'htp') return !sessionData.imperial ? val : Math.round(cToF(val));
            // if (rdgProps.prop == 'htp') return val;
            if (rdgProps.prop == 'dpr') return !sessionData.iwg ? val : (val * 0.0040147).toFixed(3);
            return val
        } else {
            return 'Calibrating';
        }
    }

    const adjustPrefUnit = () => {
        if (rdgProps.prop == 'htp') return `˚${!sessionData.imperial ? 'C' : 'F'}`;
        if (rdgProps.prop == 'dpr') return !sessionData.iwg ? 'Pa' : 'iwg';
        return rdgProps.units;
    }


    return (
        <>
            {
                ranges && ranges.length &&
                <div className='rdg-range'>
                        <header className='rdg-range-header'>
                        <img src={`${imgUrl}/${img === 'days' && config === 'coil' ? 'coilFlow' : img}.png`} />
                        <h5>{rdgProps.title.includes('Filter') && config === 'coil' ? 'Coil Condition' : rdgProps.title}</h5>
                        <h5
                            style={{ marginLeft: '10px', borderLeft: '1px solid var(--white)', paddingLeft: '10px' }}>
                            Current: {adjustPrefVal(currentVal)}{adjustPrefUnit()}
                        </h5>
                    </header>
                    <label className='sp-label'>
                        <div className='range-spectrum'>
                            {
                                rdgProps.tiers.map((tier: string, tIndex: number) => (
                                    <div
                                        className='range-tier'
                                        style={{
                                            background: `var(--${tier})`,
                                            width: `${widths[tIndex]}%`,
                                            border: '1px solid var(--white)'
                                        }}></div>
                                ))
                            }
                        </div>
                        <div className='slider-bg' style={{ zIndex: -5 }}></div>
                        <Slider
                            min={rdgProps.min}
                            max={rdgProps.max}
                            range
                            defaultValue={ranges}
                            onChange={e => updateRanges(e)}
                            railStyle={{ display: 'none' }}
                            trackStyle={{ display: 'none' }}
                            allowCross={false}
                            handleStyle={handleStyle()}
                        // handleStyle={ {
                        //     width: '20px', 
                        //     height: '20px', 
                        //     bottom: '-2px', 
                        //     background: 'var(--white)', 
                        //     opacity: 1,
                        //     borderRadius: '5px'
                        // } } 
                        />
                    </label>
                    <div className='rdg-range-text'>
                        {
                            rdgProps.tiers.map((tierProp: string, tierIndex: number) => (
                                <small
                                    style={{
                                        color: `var(--${rdgProps.tiers[tierIndex]})`
                                    }}>
                                    {setRangeText(tierProp, tierIndex)}
                                </small>
                            ))
                            // Object.keys(rdgRange.thresholds).map((threshProp, threshIndex) => (
                            //     <small
                            //         style={ {
                            //             color: `var(--${rdgRange.tiers[threshIndex]})`
                            //         } }>{ setRangeText(threshProp, threshIndex) }</small>
                            // ))
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Range;

