import { useEffect } from "react";
import { measSet, defaultAlertThresholds } from "../../templates/consts";
import ReadingGauge from "../reading/ReadingGauge";
import ReadingNode from "../reading/ReadingNode";
import { useSession } from "../../hooks/useSessionDev";


interface Props {
    dp: any;
    setPlacard?:false | ((newPlacard:string) => void);
}

const Env: React.FC<Props> = ({ dp, setPlacard }) => {

    const [sessionData] = useSession();

    const getMin = (rdg: string) => {
        const thresh = defaultAlertThresholds as any;
        const rdgArrs = thresh[rdg];
        return rdgArrs[0][0];
    }

    const getMax = (rdg: string) => {
        const thresh = defaultAlertThresholds as any;
        const rdgArrs = thresh[rdg];
        const finalArr = rdgArrs[rdgArrs.length - 1];
        return finalArr[finalArr.length - 1];
    }

    return (
        <div
            className='card-rdg-content env-rdg-content'
            style={{
                border: '2px solid gray',
                background: 'var(--dark-blue)',
                height: sessionData.zone?.equip ? '66.5%' : ''
            }}>
            {/* <div className='card-rdg-tab'>
                <small>Environmental Conditions</small>
            </div> */}
            <div className='card-rdg-rdgs' style={{ width: '95%' }}>
                <div className='card-rdg-row'>
                    {
                        ['htp', 'hhm'].map((prop: string) => (
                            <ReadingGauge
                                rdg={prop}
                                val={dp.meas[prop] ?? false}
                                min={getMin(prop)}
                                max={getMax(prop)}
                                alert={dp.alerts ? dp.alerts[prop] : false} />
                            // <ReadingNode
                            //     rdg={measSet.find((m: any) => m.prop == prop)}
                            //     val={dp.meas[prop] ?? false}
                            //     alert={dp.alerts[prop] ?? false}
                            //     width='half' />
                        ))
                    }
                </div>
                <div className='card-rdg-row sm-rdg-row'>
                    {
                        ['iaq', 'co2', 'voc'].map((prop: string) => (
                            <ReadingNode
                                rdg={prop}
                                val={dp.meas ? dp.meas[prop] : false}
                                alert={dp.alerts ? dp.alerts[prop] : false}
                                width='third'
                                setPlacard={setPlacard ?? false} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Env;