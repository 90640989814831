import { useEffect, useState } from "react";
import { imgUrl } from "../../templates/consts";

interface Props {
    activeIndex:number;
    setActiveIndex: (index: number) => void;
    isEquipDp?:boolean;
}

const nodes = [
    {
        elem: 'img',
        content: 'crp',
        route: 'crp'
    },
    {
        elem: 'h3',
        route: 'meas'
    },
    {
        elem: 'h3',
        route: 'alerts'
    }
];

const equipNodes = [
    {
        elem: 'h3',
        route: 'meas'
    },
    {
        elem: 'h3',
        route: 'alerts'
    }
]

const CardToggle:React.FC<Props> = ({ activeIndex, setActiveIndex, isEquipDp }) => {

    const [ active, setActive ] = useState<number>(activeIndex);

    useEffect(() => {
        if (!isEquipDp) {
            setTimeout(() => setActive(activeIndex == 3 ? 2 : activeIndex), 300);
        } else {
            setTimeout(() => setActive(activeIndex), 300)
        }
    }, [activeIndex]);

    // const initToggle = (route:string) => {
    //     setFlipped(!flipped);
    //     if (flipped) {
    //         // setTimeout(() => {
    //             // setFront(route);
    //             setActiveView(route);
    //             setActiveNode(route);
    //         // }, 1000);
    //     } else {
    //         // setTimeout(() => {
    //             // setBack(route);
    //             setActiveView(route);
    //             setActiveNode(route);
    //         // }, 1000);
    //     }
    // }

    return (
        <div className='card-toggle' style={{width: isEquipDp ? '35%' : '', marginLeft: isEquipDp ? '30%' : ''}}>
            <div className='tog-connector'></div>
            {
                !isEquipDp && nodes.map((node, nodeIndex) => (
                    <div 
                        className={ `card-toggle-node${active == nodeIndex ? ' active-toggle-node' : ''}` }
                        onClick={ () => setActiveIndex(nodeIndex == 2 ? 3 : nodeIndex) }>
                        {
                            node.elem == 'img'
                            ? <img src={ `${imgUrl}/${node.content}.png` } />
                            : <h3>{ nodeIndex }</h3>
                        }
                    </div>
                ))
            }
            {
                isEquipDp &&
                <>
                    <div 
                        className={ `card-toggle-node${(activeIndex === 1) ? ' active-toggle-node' : ''}` }
                        onClick={ () => setActiveIndex(1) }>
                        <h3>1</h3>
                    </div>
                    <div 
                        className={ `card-toggle-node${(activeIndex === 2) ? ' active-toggle-node' : ''}` }
                        onClick={ () => setActiveIndex(2) }>
                        <h3>2</h3>
                    </div>
                </>
            }
        </div>
    )
}

export default CardToggle;