import { useEffect, useState } from "react";
import moment from 'moment';

import { imgUrl, profiles, setStatus } from "../../templates/consts";
import CRP from "../chart/CRP";
import ObDatapoint from "./ObDatapoint";
import { useFloor } from "../../hooks/useFloorContext";
import { useNavigate } from "react-router-dom";
import '../../stylesheets/Card.css';
import { useSession } from "../../hooks/useSessionDev";
import { useRest } from "../../hooks/useRest";

interface Props {
    z: any;
    clearOb: () => void;
    refresh: () => void;
}

const FloorZone: React.FC<Props> = ({ z, clearOb, refresh }) => {

    const nav = useNavigate();

    const [, updateUtilView, , , , , , dragAp, setDragAp, rebaseAp, primeBelayedApsnAssignment, , , floorData, setFloorData] = useFloor(refresh);
    const [sessionData, , , getSessionEntity] = useSession();
    const [req] = useRest();

    // const [ dragAp, setDragAp ] = useState<any>({});
    const [zone, setZone] = useState<any>(z);
    const [showDps, setShowDps] = useState<boolean>(false);
    const [obDatapoint, setObDatapoint] = useState<boolean>(false);
    const [assignIndex, setAssignIndex] = useState<number>(-1);
    const [assignHover, setAssignHover] = useState<boolean>(false);
    const [hoverZone, setHoverZone] = useState<boolean>(false);
    const [equipOn, setEquipOn] = useState<boolean>(false);

    const initObDatapoint = () => {
        updateUtilView(4);
        setObDatapoint(true);
    }

    const handleDragAp = (e: React.DragEvent, dp: any) => {
        setDragAp(dp);
        setZone({ ...zone, dragAp: dp });
    }

    const handleDropAp = async (e: React.DragEvent) => {
        try {
            const rebase = dragAp;
            await rebaseAp({ ...zone });
        } catch (rebaseErr) {
            console.error({ rebaseErr });
        }
    }

    const toggleDps = (e: any) => {
        e.stopPropagation();
        setShowDps(!showDps);
    }

    const configImg = (config: string) => {
        const prof = profiles.find(p => p.prop == config);
        return prof?.img ?? 'datapoint';
        // return 'datapoint';
    }

    const closeAssignment = (e: any) => {
        e.stopPropagation();
        setAssignHover(false);
        setAssignIndex(-1);
    }

    const updateObTog = (tog: boolean) => {
        clearOb();
        setObDatapoint(tog);
    }

    const assessOnOff = async () => {
        if (!zone.servedBy) {
            return 'powerOff';
        }
        const floor = await getSessionEntity('floor', zone.fid);
        const bldg = await getSessionEntity('building', floor.bid);
        const zones = bldg.floors.reduce((allFloors: any[], floor: any) => allFloors.concat(floor.zones), []);
        const equip = zones.find((z: any) => z.id === zone.servedBy.id);
        if (equip.datapoints) {
            const dids = equip.datapoints.map((dp: any) => dp.did);
            const onOffs = await Promise.all(dids.map(async (did: number) => {
                return (await req('onOff/' + did, 'get')).rawDpr;
            }));
            setEquipOn(onOffs.some((oo:any) => typeof oo === 'number' && oo >= 10));
        }
        // if (!equip || !equip.meas || equip.meas.dpr < 20) {
        //     setEquipOn(false);
        // } else {
        //     setEquipOn(true);
        // }
        // if (sessionData.floors && sessionData.floors.length) {
        //     const zones = sessionData.building.floors.reduce((allFloors: any[], floor: any) => allFloors.concat(floor.zones), []);
        //     const equip = zones.find((z: any) => z.id === zone.servedBy.id);
        //     if (!equip || !equip.meas || equip.meas.dpr < 20) return 'powerOff';
        //     return 'power';
        // }
    }

    const assessServedByBorder = () => {
        if (window.innerHeight >= 400 && window.innerHeight <= 880) return 'rgba(0, 0, 0, 0)';
        return !zone.servedBy ? 'gray' : 'var(--equip)'
    }

    const lastUpdate = () => {
        const updates = zone.datapoints.map((dp: any) => dp.updatedTms);
        const max = Math.max(...updates);
        if (!max) return false;
        return (window.innerHeight >= 880 ? 'Updated: ' : '') + moment(max * 1000).format('MMM-DD @ h:mma');
    }

    useEffect(() => {
        if (assignIndex > -1) primeBelayedApsnAssignment(zone.datapoints[assignIndex]);
    }, [assignIndex]);

    useEffect(() => {
        assessOnOff();
    }, [])

    return (
        <div
            className='floor-zone'
            onDragOver={e => e.preventDefault()}
            onDrop={e => handleDropAp(e)}
            onMouseOver={() => setHoverZone(true)}
            onMouseLeave={() => setHoverZone(false)}>
            {
                !obDatapoint && zone &&
                <>
                    <header className='floor-zone-status' style={{ background: setStatus('all', zone.alerts.overall) }}></header>
                    <header className='floor-zone-header' onClick={() => nav('/zone/' + zone.id)}>
                        <div className='floor-zone-icon'>
                            <img src={`${imgUrl}/${zone.type}.png`} />
                        </div>
                        <div className='floor-zone-title'>
                            <h3>{zone.name}</h3>
                            {
                                !hoverZone
                                    ? <small style={{ color: 'var(--breezi-blue)', fontSize: '.85em' }}>{zone.datapoints.length ?? 0} AirPulse{zone.datapoints.length === 1 ? '' : 's'}</small>   
                                    : <small style={{ color: 'var(--breezi-blue)', fontSize: '.85em' }}>{lastUpdate()}</small>
                            }
                        </div>
                    </header>
                    <div className='floor-zone-body'>
                        {
                            !showDps &&
                            <CRP meas={zone.meas} ht={85} alert={zone.alerts.overall ?? false} />
                        }
                        {
                            showDps &&
                            <div className='floor-dps'>
                                {
                                    zone.datapoints?.map((dp: any, dpIndex: number) => (
                                        <div className='floor-dp-frame' draggable={false}>
                                            {
                                                dp.apsn != 'pending'
                                                    ? <div
                                                        className='floor-dp drag-dp unassigned-floor-dp'
                                                        draggable={false}
                                                        onDragStart={e => handleDragAp(e, dp)}
                                                        style={{
                                                            background: dp.alerts && dp.alerts.overall ? setStatus('all', dp.alerts.overall, true) : 'var(--breezi-blue-transparent)',
                                                            border: `2px solid ${dp.alerts && dp.alerts.overall ? setStatus('all', dp.alerts.overall) : 'var(--breezi-blue)'}`
                                                        }}>
                                                        <img draggable={false} src={`${imgUrl}/apIcon.png`} />
                                                    </div>
                                                    : <div
                                                        className='floor-dp drag-dp pending-floor-ap'
                                                        draggable={false}
                                                        onDragStart={e => handleDragAp(e, dp)}
                                                        style={{
                                                            background: 'gray',
                                                            border: '2px solid var(--white)',
                                                            cursor: 'pointer',
                                                            position: 'relative'
                                                        }}
                                                        onClick={() => setAssignIndex(dpIndex)}>
                                                        <img draggable={false} src={`${imgUrl}/pending.png`} />
                                                        {
                                                            assignIndex == dpIndex &&
                                                            <div
                                                                className='floor-dp-tooltip'
                                                                onClick={e => closeAssignment(e)}
                                                                onMouseOver={() => setAssignHover(true)}
                                                                onMouseLeave={() => setAssignHover(false)}>
                                                                {
                                                                    !assignHover && <small className='appear-text'>Select AP Serial Number from Bank</small>
                                                                }
                                                                {
                                                                    assignHover && <small>Cancel</small>
                                                                }
                                                            </div>
                                                        }
                                                    </div>

                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </>
            }
            {/* <div className='card-corner' onClick={e => toggleDps(e)} style={{ border: 'none', borderRadius: '0px' }}>
                <img src={`${imgUrl}/${showDps ? 'crp' : 'apIcon'}.png`} />
            </div> */}
            {
                zone && zone.servedBy &&
                <div className='card-served-by' style={{ position: 'absolute', bottom: '15px' }}>
                    <div
                        className='card-hvac-ref' style={{border: `2px solid ${!equipOn ? 'gray' : 'var(--equip)'}`}}>
                        <small>{zone.servedBy.name}</small>
                        <img src={`${imgUrl}/${equipOn ? 'power' : 'powerOff'}.png`} />
                    </div>
                </div>
            }
            {
                !zone || !zone.servedBy &&
                <div className='card-served-by' style={{ position: 'absolute', bottom: '15px' }}>
                    <div
                        className='card-hvac-ref'
                        style={{ width: '160px', border: '2px solid gray' }}>
                        <small>No HVAC Assigned</small>
                        <img src={`${imgUrl}/powerOff.png`} />
                    </div>
                </div>
            }
            {/* <div className='floor-served-by'>
                <div
                    className='card-hvac-ref'
                    style={{
                        border: `2px solid ${assessServedByBorder()}`,
                        width: 'auto',
                        padding: '3px'
                    }}>
                    <small style={{ marginTop: '2px' }}>{zone.servedBy ? zone.servedBy.name : 'No HVAC Assigned'}</small>
                    <img style={{ height: '80%', margin: '10px', marginBottom: '10px' }} src={`${imgUrl}/${assessOnOff()}.png`} />
                </div>
            </div> */}
            {
                obDatapoint &&
                <ObDatapoint zid={zone.id} togOb={updateObTog} fid={zone.fid} />
            }
        </div>
    )

}

export default FloorZone;