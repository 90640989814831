import { useEffect, useState } from "react";
import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl } from "../../templates/consts";
import moment from "moment";
import MaintenanceEventForm from "./MaintenanceEventForm";

interface Props {
    secIndex: number;
    elemIndex?:number;
    resetForm: () => void;
    maintInit:any;
}

const dets = {
    length: '',
    width: '',
    brand: '',
    model: ''
}

const titles = [
    'Length (in)',
    'Width (in)',
    'Filter Brand*',
    'Filter Model Number*'
];

const momentify = (tms:number) => {
    return moment(tms).format('MMM-DD @ h:mma');
}

const ElemForm: React.FC<Props> = ({ secIndex, elemIndex, resetForm, maintInit }) => {
    
    const [ equip, , , , , , , , , , updateElemDetails, , , initEquip ] = useEquipment();

    const [formElem, setFormElem] = useState<any>(null);
    const [details, setDetails] = useState<any>(dets);
    const [conf, setConf] = useState<boolean>(false);

    const updateDetails = (prop: string, val: string) => {
        setDetails({ ...details, [prop]: val });
    }

    const submitDetails = async () => {
        await updateElemDetails({ ...details, zid: equip.id }, secIndex, elemIndex ?? equip.sections[secIndex].elems.length - 1);
        initEquip(true);
        resetForm();
    }

    useEffect(() => {
        const sec = equip.sections[secIndex];
        const elem = sec.elems[elemIndex ?? sec.elems.length - 1];
        setFormElem(elem);
    }, [equip]);

    useEffect(() => {
        if (typeof elemIndex === 'number') {
            setConf(true);
        }
    }, []);

    const maintTerm = () => {
        if (formElem) {
            switch (formElem.type) {
                case 'Filter':
                    return 'Filter Change';
                case 'Fan':
                    return 'Belt Adjustment';
                case 'Coil':
                    return 'Coil Cleaning'
            }
        }
    }

    const cancel = () => {
        resetForm();
    }

    return (
        <>
            {
                formElem && maintInit === 'maint' &&
                <>
                    <header className='expand-header'>
                        <img src={`${imgUrl}/equip-${formElem.img}.png`} />
                        <div className='expand-title'>
                            {
                                !elemIndex && <h5>{formElem.type === 'Filter' ? 'New' : ''} {formElem.title}</h5>
                            }
                        <small>Last Maint: { momentify(Date.now()) }</small>
                        </div>
                    </header>
                    {
                        !conf &&
                        <>
                            <div className='expand-dims'>
                            </div>
                            {
                                ['brand', 'model'].map((detProp: string, detIndex: number) => (
                                    <label className='expand-input'>
                                        <input
                                            value={details[detProp]}
                                            onChange={e => updateDetails(detProp, e.target.value)} />
                                            { titles[detIndex + 2] }
                                    </label>
                                ))
                            }
                            {
                                <a
                                    className='elem-action elem-submit'
                                    onClick={ () => submitDetails() }>
                                    <div className='elem-action-head'>
                                        <img src={ `${imgUrl}/checked.png` } />
                                    </div>
                                    <h5>Submit</h5>
                                </a>
                            }
                        </>
                    }
                    {
                        conf &&
                        <>
                            <div className='conf-maint-msg' style={{ width: '70%' }}>
                            {
                                conf && formElem.type === 'Filter' &&
                                <h4
                                    style={{
                                        textAlign: 'left',
                                        color: 'gray',
                                            fontWeight: 'lighter',
                                        fontSize: '.9em'
                                    }}>
                                    You are registering a Filter Change.<br /><br />
                                    This action will permanently update the maintenance record and reset the Filter Life algorithm for this unit.  Select Continue below to confirm this action.  Please note that data for this unit will not report for 15-20 minutes while the algorithm resets.
                                    </h4>
                            }
                            {
                                conf && formElem.type !== 'Filter' &&
                                <h4
                                    style={{
                                        textAlign: 'left',
                                        color: 'gray',
                                            fontWeight: 'lighter',
                                        fontSize: '.9em'
                                    }}>
                                    You have chosen to record a new <span style={{ color: 'var(--breezi-blue)', fontWeight: 'bold' }}>{maintTerm()}</span> event for this element.
                                    Selecting <span style={{ color: 'var(--prim)', fontWeight: 'bold' }}>Yes</span> below will permanently update the Element's
                                    maintenance record Breezi's Database and
                                    any AirPulses affixed to this Element's Stage will not report until the update has 
                                    been registered with the Server. This typically takes 10-15 minutes. Would you
                                    like to continue?
                                </h4>
                            }
                        </div>
                        {
                            formElem.type !== 'Filter' &&
                            <a className='elem-action elem-submit'onClick={ () => submitDetails() }>
                                <div className='elem-action-head'>
                                    <img src={ `${imgUrl}/checked.png` } />
                                </div>
                                <h5>Yes</h5>
                            </a>
                            }
                            {
                                formElem.type === 'Filter' &&
                                <a className='elem-action elem-submit' onClick={ () => setConf(false) }>
                                    <div className='elem-action-head'>
                                        <img src={ `${imgUrl}/change.png` } />
                                    </div>
                                    <h5>Continue</h5>
                                </a>
                            }
                            <a className='elem-action elem-info' onClick={ () => cancel() }>
                                <div className='elem-action-head'>
                                    <img src={ `${imgUrl}/closeToo.png` } />
                                </div>
                                <h5>Cancel</h5>
                            </a>
                        </>
                    }
                </>
            }
            {
                formElem && maintInit === 'otherMaint' &&
                <MaintenanceEventForm sectionIndex={secIndex} elemIndex={elemIndex} closeForm={resetForm} />
            }
        </>
    )

}

export default ElemForm;