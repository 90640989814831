import { useState } from "react";
import { imgUrl } from "../../templates/consts";
import { sleeveStates } from "../../templates/equipmentComponentTemplate";
import { useEquipment } from "../../hooks/useEquipmentContext";
import Airflow from "./Airflow";

interface Props {
    bankState: string;
    setBankState: (newBankState: string) => void;
    showStages: boolean;
    setShowStages: (newShowStages: boolean) => void;
    sleeveView: string;
    setSleeveView: (newSleeveView: string) => void;
    handleRefresh: (e: any) => void;
    showZonesServed: boolean;
    setShowZonesServed:(newShowZonesServed:boolean) => void;
}

const Sleeve: React.FC<Props> = ({ 
    bankState, 
    setBankState, 
    showStages, 
    setShowStages, 
    sleeveView, 
    setSleeveView, 
    handleRefresh,
    showZonesServed,
    setShowZonesServed
 }) => {
    const [equip] = useEquipment();
    const [hoverSleeve, setHoverSleeve] = useState<string>('');
    
    return bankState == ''
        ? <div className="grid-tog tog-sleeve">
            {
                sleeveStates.map((btn: any, btnIndex: number) => (
                    <div
                        className={`sleeve-node${sleeveView == btn.val ? ' active-sleeve-node' : ''}`}
                        onClick={() => setSleeveView(btn.val)}
                        onMouseOver={() => setHoverSleeve(btn.val)}
                        onMouseLeave={() => setHoverSleeve('')}>
                        <img src={`${imgUrl}/${btn.img}.png`} />
                    </div>
                ))
            }
            <div className='sleeve-config'>
                <div
                    className='sleeve-node'
                    onClick={e => handleRefresh(e)}>
                    <img src={`${imgUrl}/reload.png`} />
                </div>
                <div
                    className='sleeve-node'
                    onClick={() => setShowStages(!showStages)}>
                    <img src={`${imgUrl}/${showStages ? 'hideStages' : 'showStages'}.png`} />
                </div>
                <div
                    className={`sleeve-node ${sleeveView == 'config' ? ' active-sleeve-node' : ''}`}
                    onClick={() => setBankState('config')}
                    onMouseOver={() => setHoverSleeve('config')}
                    onMouseLeave={() => setHoverSleeve('')}>
                    <img src={`${imgUrl}/hvacConfig.png`} />
                </div>
                <div
                    className='sleeve-node'
                    onClick={() => setShowZonesServed(!showZonesServed)}
                    style={{
                        border: showZonesServed ? '2px solid var(--prim)' : ''
                    }}>
                    <img src={`${imgUrl}/${!showZonesServed ? 'envZone' : 'closeToo'}.png`} />
                </div>
            </div>
            <Airflow bankState={bankState} />
        </div>
        : <div className='grid-tog tog-sleeve'>
            <div className='sleeve-config' style={{ width: '130px' }}>
                <div
                    className='sleeve-node'
                    onClick={() => setBankState(bankState == 'config' && equip.sections.length ? 'ap' : 'config')}
                    style={{ cursor: equip.id && !equip.sections.length ? 'not-allowed' : '', width: '55px' }}>
                    <img src={`${imgUrl}/${bankState == 'config' ? 'apIcon' : 'hvacConfig'}.png`} />
                    <h3>+</h3>
                </div>
                <div
                    className={`sleeve-node ${sleeveView == 'config' ? ' active-sleeve-node' : ''}`}
                    onClick={() => setBankState('')}
                    onMouseOver={() => setHoverSleeve('config')}
                    onMouseLeave={() => setHoverSleeve('')}>
                    <img src={`${imgUrl}/checked.png`} />
                </div>
            </div>
            <Airflow bankState={bankState} />
        </div>
}

export default Sleeve;