import { useParams } from "react-router-dom";
import { useSession } from '../../hooks/useSessionDev';
import { useContext, useEffect, useRef, useState } from 'react';
import cToF from 'celsius-to-fahrenheit';

import Rooftop from '../building/BldgRooftop';
import BldgFloor from "../building/BldgFloor";
import Setpoints from '../setpoints/Setpoints';

import { useRest } from "../../hooks/useRest";

import '../../stylesheets/Building.css';
import { capitalize, imgUrl, restUrl } from "../../templates/consts";
import ModifyEntity from "../util/modifyEntity/ModifyEntity";
import BuildingHeader from "../building/BuildingHeader";
import { AppContext } from "../../context/AppContext";

const Building = () => {

    const { id } = useParams();
    const bodyRef = useRef(null);

    const { appData, setAppData } = useContext(AppContext);

    // const [ sessionData, setSessionEntity, , updateUtilData, , entityUpdate ] = useSession();
    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession()
    const [request] = useRest();

    const [bldg, setBldg] = useState<any>(null);
    const [floors, setFloors] = useState<any>({});
    const [weather, setWeather] = useState<any>({});
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [showSettings, setShowSettings] = useState<boolean>(false)
    const [showModify, setShowModify] = useState<boolean>(false)
    const [showSp, setShowSp] = useState<boolean>(false);
    const [bodyHeight, setBodyHeight] = useState<number>(0);

    const exitSp = () => {
        setShowSp(false);
        return;
    }

    const setpointRefresh = async (newSp: any) => {
        let sp = bldg.setpoints ?? {};
        const setpoints = { ...sp, ...newSp };
        let newBldg = { ...bldg, setpoints };
        setShowSp(false);
    }

    const constructFloors = () => {
        let above = Array.from(Array(parseInt(bldg.above)), (floor: any, index: number) => ({ index: index + 1 })).reverse() || [];
        let below = Array.from(Array(parseInt(bldg.below)), (floor: any, index: number) => ({ index: 0 - (index + 1) })) || [];
        above = above.map(aboveFloor => {
            const foundFloor = bldg.floors.find((bldgFloor: any) => bldgFloor.index == aboveFloor.index);
            return foundFloor ?? aboveFloor;
        });
        below = below.map(belowFloor => {
            const foundFloor = bldg.floors.find((bldgFloor: any) => bldgFloor.index == belowFloor.index);
            return foundFloor ?? belowFloor;
        });
        setFloors({ above, below });
    }

    const getSetWeatherData = async () => {
        try {
            const queryString = `?country=${bldg.country}&zipCode=${bldg.zipCode}`;
            const weatherRes = await request(`locale/${queryString}`, 'get');
            setWeather(weatherRes);
            setAppData({ ...appData, loading: { visible: false } });
        } catch (getSetWeatherErr) {
            // TODO: INIT ERR COMPONENT
        }
    }

    const togSettings = () => {
        if (showSettings) {
            setShowSettings(false)
            setShowSp(false)
            setShowModify(false)
            setBodyHeight(0);
        } else {
            if (bodyRef.current) {
                const refElem = bodyRef.current as HTMLElement;
                const refRect = refElem.getBoundingClientRect();
                setBodyHeight(refRect.height);
            }
        }
    }

    const togSetpoints = () => {
        if (!showSp) {
            setShowSettings(false)
            setShowModify(false)
            setShowSp(true)
        } else {
            setShowSettings(false);
            setShowModify(false);
            setShowSp(false);
        }
    }

    const togModify = () => {
        setShowSettings(false)
        setShowSp(false)
        setShowModify(true)
    }

    useEffect(() => {
        (async () => {
            // setAppData({ ...appData, loading: {visible: true} })
            const newBldg = await getSessionEntity('building', id ?? '');
            if (newBldg) setSessionEntity('building', newBldg);
        })();
    }, []);

    useEffect(() => {
        if (sessionData.building) {
            setBldg(sessionData.building);
            if (localStorage.getItem('breeziProDeeplink')) localStorage.clearItem('breeziProDeeplink');
        }
    }, [sessionData.util]);

    useEffect(() => {
        (async () => {
            if (bldg && bldg.id) {
                constructFloors();
                await getSetWeatherData();
                setAppData({ ...appData, loading: { visible: false } })
            }
        })()
    }, [bldg]);

    useEffect(() => {
        if (!showSettings) {
            setShowModify(false)
            setShowSp(false)
        }
    }, [showSettings]);

    useEffect(() => {
        if (bodyHeight) {
            setShowSettings(true);
        }
    }, [bodyHeight]);

    return (
        <main className='bldg-main'>
            {
                bldg &&
                <div className={screenWidth >= 851 ? 'panel bldg-container' : 'mobile-bldg-container'}>
                    <BuildingHeader
                        bldg={bldg}
                        weather={weather}
                        togSettings={togSettings}
                        showSettings={showSettings}
                        togSp={togSetpoints}
                        showSp={showSp} />
                    {
                        !showSp && !showSettings && !showModify &&
                        <div className='bldg-body' ref={bodyRef}>
                            <div className='bldg-floors'>
                                <Rooftop bid={bldg.id} floor={bldg.floors.find((floor: any) => floor.type && floor.type == 'rooftop') ?? false} />
                                {
                                    floors.above &&
                                    floors.above.map((floor: any) => (
                                        <BldgFloor bid={bldg.id} zones={[]} floor={floor} />
                                    ))
                                }
                                <div className='ground-level'>
                                    <small>Ground Level</small>
                                </div>
                                {
                                    floors.below &&
                                    floors.below.map((floor: any) => (
                                        <BldgFloor bid={bldg.id} zones={[]} floor={floor} />
                                    ))
                                }
                            </div>
                        </div>
                    }
                    {
                        showSettings &&
                        <div className='bldg-settings-panel' style={{ height: `${bodyHeight}px` }}>
                            <ModifyEntity ent={bldg} entType={'building'} modifyableProps={[]} dest={'organization'} floor={true} />
                        </div>
                    }
                </div>
            }
        </main>
    )

}

export default Building;