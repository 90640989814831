import { useContext, useEffect, useState } from "react";
import { imgUrl } from "../../templates/consts";
import { useSession } from "../../hooks/useSessionDev";
import { AppContext } from "../../context/AppContext";

interface Props {
    zone: any;
    showBank: boolean;
    setDragAp: (newAp: any) => void;
    handleDragOver: (e: any) => void;
    handleCancel: (e: any) => void;
    hier?: any;
}

const ZoneHeader: React.FC<Props> = ({ zone, showBank, setDragAp, handleDragOver, handleCancel, hier }) => {

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession();

    const initBlocker = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Disabled',
                content: "This feature has been restricted for the duration of the Pilot Program"
            }
        });
    }

    return (
        <>
            <header
                className='panel-header'
                style={{
                    minHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : '',
                    maxHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : ''
                }}>
                <div
                    className='header-icon'
                    style={{
                        minHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : '',
                        maxHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : ''
                    }}>
                    <img
                        style={{
                            minHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '30px' : '',
                            maxHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '30px' : ''
                        }}
                        src={`${imgUrl}/envZone.png`} />
                </div>
                {
                    hier && window.innerWidth > 767 &&
                    <div className='zone-hier'>
                        {
                            hier.map((hString: string, hIndex: number) => (
                                <h4>
                                    <span style={{ color: 'var(--breezi-blue)', fontWeight: 'bold' }}></span>
                                    {' ' + hString} <span style={{ color: 'var(--breezi-blue)', fontWeight: 'bold' }}>{hIndex !== 2 && '||'}</span></h4>
                            ))
                        }
                    </div>
                }
            </header>
            {
                showBank &&
                <div
                    className='equip-bank equip-ap-bank'
                    style={{ animation: 'none', borderBottom: '3px solid var(--breezi-blue)', overflowY: 'scroll', width: '100%' }}>
                    {
                        sessionData.apBank && sessionData.apBank.length > 0 && sessionData.apBank.map((ap: any, apIndex: number) => (
                            <div
                                className='equip-ap'
                                // draggable
                                // onDragStart={() => setDragAp(ap)}
                                // onDragOver={e => handleDragOver(e)}>
                                draggable={false}
                                onClick={() => initBlocker()}
                                onDragStart={() => initBlocker()}>
                                <img src={`${imgUrl}/envAp.png`} />
                                <small>{ap.apsn}</small>
                            </div>
                        ))
                        }
                        {
                            (!sessionData.apBank || !sessionData.apBank.length) &&
                            <h4 style={{width: '100%'}}>Your Unassigned AirPulse Bank is Empty</h4>
                        }
                </div >
            }
        </>
    )

}

export default ZoneHeader; 