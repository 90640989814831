import { useNavigate } from "react-router-dom"
import { useRest } from "./useRest"
// import { useSession } from "./useSessionContext"
// import { useSession } from "./useSession"
import { useSession } from "./useSessionDev";
import { capitalize, listProps } from "../templates/consts"
import { AppContext } from "../context/AppContext"
import { useContext, useEffect } from "react"

export const useModifyEntity = (entType: string, entId: string): [
    (dest:string) => void
] => {

    const nav = useNavigate()

    const { appData, setAppData } = useContext(AppContext)
    const [ sessionData, , , , , , , postDeleteUpdate ] = useSession()
    const [ request ] = useRest()

    const deleteEntity = async (dest: string) => {
        let destProp = dest == 'equipment' ? 'zone' : dest;
        const parentId = typeof sessionData[destProp] == 'string' ? sessionData[destProp] : sessionData[destProp].id;
        const destRoute = `/${dest}/${parentId}`
        try {
            const body = { id: entId, entType, parentId };
            try {
                await request('modifyEntity', 'delete', '', body)
                postDeleteUpdate(entType, entId);
                nav(destRoute)
            } catch(deleteFromServerErr) {
                console.error({ deleteFromServerErr });
                postDeleteUpdate(entType, entId);
                nav(destRoute)
            }
        } catch (deleteEntityError) {
            const err = deleteEntityError as any
            setAppData({
                type: 'error',
                title: `Delete ${capitalize(entType)} Error`,
                content: err.message
            })
            nav(destRoute);
        }
    }

    return [ deleteEntity ]

}