import cToF from 'celsius-to-fahrenheit';

import { useSession } from "../../hooks/useSessionDev";
import { imgUrl } from "../../templates/consts";
import Tooltip from '../util/tooltip/Tooltip';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';

interface Props {
    bldg: any;
    weather: any;
    togSettings: () => void;
    showSettings: boolean;
    togSp: () => void;
    showSp: boolean;
}

const BuildingHeader: React.FC<Props> = ({ bldg, weather, togSettings, showSettings, togSp, showSp }) => {

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData] = useSession();

    const [showWeather, setShowWeather] = useState<boolean>(false);

    const blockSettings = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Disabled',
                content: "This feature has been restricted for the duration of the Pilot Program"
            }
        });
    }

    useEffect(() => {
        setTimeout(() => setShowWeather(true), 1500)
    }, []);

    return (
        <header className='panel-header' style={{ justifyContent: 'space-between' }}>
            {
                weather &&
                <div className='header-icon bldg-weather' style={{ width: '90px' }}>
                    {
                        showWeather &&
                        <>
                            <img style={{ height: '60px', marginTop: '-10px', marginBottom: '-10px' }} src={`https://openweathermap.org/img/wn/${weather.icon}@2x.png`} />
                            <small style={{ marginBottom: '3px' }}>{sessionData.imperial ? parseInt(cToF(parseInt(weather.temp))) : Math.round(parseInt(weather.temp))}˚{sessionData.imperial ? 'F' : 'C'} | {weather.hum}%</small>
                        </>
                    }
                </div>
            }
            <div className='bldg-header-title'>
                <div className='bldg-header-loc' data-tooltip-id={`bldg-${bldg.id}-tooltip`}>
                    <h4 style={{ color: 'var(--breezi-blue)' }}>{bldg.locale.city}, {bldg.locale.state ?? ''} <span style={{ fontSize: '1.3em' }}>||</span> </h4>
                    <img src={`https://catamphetamine.github.io/country-flag-icons/3x2/${bldg.country}.svg`} />
                </div>
            </div>
            <div className='bldg-settings' style={{ justifyContent: 'center' }}>
                {
                    !showSp &&
                    <div
                        className='bldg-sp-tog'
                        // onClick={() => togSettings()}>
                        onClick={() => blockSettings()}>
                        <img style={{ width: '25px' }} src={`${imgUrl}/${showSettings ? 'closeToo' : 'gearsWhite'}.png`} />
                    </div>
                }
            </div>
        </header>
    )

}

export default BuildingHeader; 