import React from 'react';

import StepNode from './StepNode';

import { Step } from '../../tsDeclarations/form';

import '../../stylesheets/StepProgress.css';
import { imgUrl } from '../../templates/consts';

interface Props {
    currentStep:number;
    template:Step[];
    setCurrentStep:(step:number) => void;
}

const Steps:React.FC<Props> = ({ currentStep, template, setCurrentStep }) => {
    return (
        <div className='form-steps'>
            {
                template.map((step, stepIndex) => (
                    <>
                        {
                            stepIndex > 0 &&
                            <div className='step-sep'></div>
                        }
                        <StepNode img={ `${imgUrl}/${step.nodeIcon}.png`} label={ step.title } currentStep={ currentStep } stepIndex={ stepIndex } setCurrentStep={ setCurrentStep } />
                    </>
                ))
            }
        </div>
    )
}

export default Steps;