import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import { AppContext } from '../../context/AppContext';

import Form from '../util/Form';

import signupTmp from '../../templates/formTemplates/signup.json';
import loginTmp from '../../templates/formTemplates/login.json';
import secondarySignupTmp from '../../templates/formTemplates/secondarySignupTemplate.json';

import '../../stylesheets/Landing.css';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useRest } from '../../hooks/useRest';
// import { useSession } from '../../hooks/useSessionContext';
import { useSession } from '../../hooks/useSessionDev';
import { BreeziProVersionNumber, imgUrl } from '../../templates/consts';
import InstallButton from '../util/InstallButton';
import ForgotPassword from '../form/ForgotPassword';
import forgotTmp from '../../templates/formTemplates/forgotPw.json';
import LoginForm from '../form/SignupForm';

type ViewType = null | 'login' | 'signup' | 'secondarySignup';

export default () => {

    const nav = useNavigate();
    const query = window.location.search.replace('?', '').split('=')[1] ?? false;
    const loc = useLocation();

    // const [ sessionData, setSessionEntity, clearSession, , getInitialDataset, entityUpdate ] = useSession();
    const [sessionData, setInitialSession, , getSessionEntity, setSessionEntity, , , , , , , clearSession] = useSession();
    const [, , , , getTkn, logout] = useAuth();
    const [showCookie, setShowCookie] = useState<boolean>(true);
    const { appData, setAppData } = useContext(AppContext);
    // const [ request ] = useRest();

    const [view, setView] = useState<ViewType>(null);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [showForgot, setShowForgot] = useState<boolean>(false);

    const login = async () => {
        if (!sessionData.email) {
            const deeplink = localStorage.getItem('breeziProDeeplink') ?? false;
            const tkn = await getTkn();
            if (deeplink) {
                await setInitialSession()
            } else {
                nav('/organization');
            }
        }
    }

    useEffect(() => {
        setAppData({ ...appData, settings: false, helper: false });
        const cookieSetting = localStorage.getItem('breeziProCookieToggle') ?? false;
        const deeplink = localStorage.getItem('breeziProDeeplink') ?? false;
        if (deeplink) setView('login');
        if (cookieSetting) setShowCookie(false);
        // setSessionData({});
        if (query && query == 'secondary') {
            // logout(false);
            // setView('secondarySignup');
        } else {
            if (loc.pathname === '/secondarySignup') {
                logout(false);
                setView('secondarySignup');
            } else {
                // setAppData({ helper: {type: 'tutorial'} });
                setAppData({ ...appData, settings: false });
                // setSessionData({});
                // clearSession();
                clearSession();
                login();
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            let deeplink = localStorage.getItem('breeziProDeeplink') ?? false;
            if (sessionData.email && deeplink && (deeplink.includes('/zone') || deeplink.includes('/equipment'))) {
                const queryString = deeplink.split('?')[1] ?? false;
                if (queryString?.includes('&')) {
                    let queryParams = queryString.split('&');
                    localStorage.removeItem('breeziProDeeplink');
                    await queryParams.forEach(async (qp: string) => {
                        const [prop, id] = qp.split('=');
                        const ent = await getSessionEntity(prop, id);
                        setSessionEntity(prop, ent);
                    });
                    nav(deeplink.split('?')[0]);
                }
            } else {
                localStorage.removeItem('breeziProDeeplink');
            }
        })();
    }, [sessionData]);

    const closeLandingForm = (attemptLogin: boolean) => {
        try {
            if (showForgot && attemptLogin) {
                setAppData({
                    ...appData,
                    helper: {
                        type: 'tutorial',
                        title: 'Password Reset Successfully',
                        content: 'Your password has been updated. Please make a note of the change and save it somewhere secure. You may use it immediately to login.'
                    }
                });
            }
            setView(null);
            setShowForgot(false);
            login();
            // implicitLogin();
        } catch (loginErr) {
            console.error({ loginErr });
        }
    }

    const removeCookieSetting = () => {
        localStorage.setItem('breeziProCookieToggle', 'true');
        setShowCookie(false);
    }
    
    const togForgot = () => {
        setView(null);
        setShowForgot(true);
    }

    useEffect(() => {
        // const checkAndUpdate = async () => {
        //     try {
        //     const currentVersion = BreeziProVersionNumber;
    
        //     const cachedVersion = localStorage.getItem('breeziProVersionNumber');
    
        //     if (!cachedVersion || cachedVersion !== currentVersion) {
        //         document.cookie.split(';').forEach(function (c) {
        //         document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        //         });
    
        //         await caches.keys().then((cacheNames) => {
        //         return Promise.all(
        //             cacheNames.map((cacheName) => {
        //             return caches.delete(cacheName);
        //             })
        //         );
        //         });
    
        //         localStorage.setItem('breeziProVersionNumber', currentVersion);
    
        //         window.location.reload();
        //     }
        //     } catch (error) {
        //     console.error('Error checking for updates:', error);
        //     }
        // };
        // checkAndUpdate();
    })

    return (
        <main className='landing-main' style={{ zIndex: 10, margin: 0, padding: 0 }}>
            <main className='overlay dark-overlay landing-overlay' style={{margin: 0, padding: 0}}>
                {
                    !view &&
                    <>
                        {/* {
                            screenWidth >= 850 &&
                            <div className='col landing-sm-col lang-col' style={{ justifyContent: 'flex-end' }}>
                                <h3 onClick={() => navLang()}>Language<br />Preferences</h3>
                            </div>
                        } */}
                        <div className='col landing-lg-col' style={{ justifyContent: 'center' }}>
                            <section className='col title-col'>
                                <img className='landing-logo' style={{ width: '500px', maxWidth: '90vw' }} src='https://breezi-pro-assets.s3-eu-west-1.amazonaws.com/pro-box.png' />
                                <h3 style={{ marginTop: '8px', paddingTop: '5px', borderTop: '2px solid var(--white)', fontWeight: 'lighter', width: '500px', maxWidth: '90vw', fontSize: '1.325em' }}>Predictive Maintenance for Commercial HVAC</h3>
                                <div className='btns' style={{ border: 'none' }}>
                                    <a className='btn prim-btn' onClick={() => setView('login')}>Login</a>
                                    {/* <a className='btn sec-btn' onClick={() => setView('signup')}>Signup</a> */}
                                    <a className='btn tri-btn'>More Info</a>
                                </div>
                            </section>
                        </div>
                    </>
                }
                {/* {
                    view == 'signup' &&
                    <Form tmp={signupTmp} closeForm={closeLandingForm} />
                } */}
                {
                    view == 'login' &&
                    // <Form tmp={loginTmp} closeForm={closeLandingForm} initForgot={togForgot} />
                    <LoginForm closeForm={closeLandingForm} initForgot={togForgot} />
                }
                {
                    view == 'secondarySignup' &&
                    <Form tmp={secondarySignupTmp} closeForm={closeLandingForm} />
                }
                {
                    showForgot &&
                    <Form tmp={forgotTmp} closeForm={closeLandingForm} />
                }
                {/* {
                    !showForgot && view === 'login' &&
                    <div className='forgot-pw-init' onClick={() => togForgot()}>
                        <p style={{color: 'gray', cursor: 'pointer'}}>Forgot Password</p>
                    </div>
                } */}
                {/* <InstallButton /> */}
            </main>
            {/* {
                showCookie &&
                <div className='cookie-setting' onClick={() => removeCookieSetting()}>
                    <img src={`${imgUrl}/cookieWhite.png`} />
                    <p>
                        We use cookies to improve your experience on our site and to show you relevant issues related to the buildings
                        and equipment you monitor with BreeziPro. Please close this window to accept our use of cookies. To learn more,
                        please read our Privacy Policy. You can click this message to remove it.
                    </p>
                </div>
            } */}
        </main>
    )
}