import { useEffect, useState } from "react";
import moment from 'moment';

import { imgUrl, setStatus } from "../../templates/consts";
import '../../stylesheets/Card.css';
import { useSession } from "../../hooks/useSessionDev";

interface Props {
    datapoint: any;
    panel: any;
}

type alert = { bad: number, crit: number };

const measSet = [
    {
        prop: 'days',
        img: 'filterOfficial',
        title: 'Days Remaining',
        units: 'days'
    },
    {
        prop: 'htp',
        img: 'temp',
        title: 'Temperature',
        units: '˚C'
    },
    {
        prop: 'hhm',
        img: 'hhm',
        title: 'Humidity',
        units: '%'
    },
    {
        prop: 'iaq',
        img: 'iaq',
        title: 'Air Quality',
        units: ''
    },
    {
        prop: 'co2',
        img: 'co2',
        title: 'CO2',
        units: 'ppm'
    },
    {
        prop: 'voc',
        img: 'nuevoVOC',
        title: 'tVOC',
        units: 'ppm'
    }
];

const UtilPanel: React.FC<Props> = ({ datapoint, panel }) => {

    const [sessionData, , , getSessionEntity] = useSession();
    const [maintDate, setMaintDate] = useState<string[]>(["No HVAC Unit is Assigned", "to this AirPulse's Zone"]);
    const [maintNodes, setMaintNodes] = useState<any[]>([]);

    const alertList = () => {
        if (!datapoint.alerts) {
            // setAlertList([]);
            return []
        } else {
            const alertProps = Object.keys(datapoint.alerts).filter(a => !['dpr', 'bat'].includes(a));
            const critList = alertProps.reduce((critAlerts: any[], alertProp: string) => {
                const meas = measSet.find((ms: any) => ms.prop == alertProp);
                if (datapoint.alerts[alertProp].crit) critAlerts.push({
                    prop: alertProp,
                    tier: 'crit',
                    img: meas?.img,
                    title: meas?.title
                });
                return critAlerts;
            }, []);
            const warnList = alertProps.reduce((warnAlerts: any[], alertProp: string) => {
                const meas = measSet.find((ms: any) => ms.prop == alertProp);
                if (datapoint.alerts[alertProp].bad
                    && !critList.find(cAlert => cAlert.prop == alertProp)) warnAlerts.push({
                        prop: alertProp,
                        tier: 'warn',
                        img: meas?.img,
                        title: meas?.title
                    });
                return warnAlerts;
            }, []);
            // setAlertList(critList.concat(warnList));
            return critList.concat(warnList);
        }
    }

    const setDaysAlertColor = (days: number | boolean) => {
        if (!days && days.toString() !== '0') return 'var(--breezi-blue)';
        if (typeof days === 'number') {
            if (days <= 10) {
                return 'var(--crit)'
            } else if (days <= 25) {
                return 'var(--cancel)';
            } else {
                return 'var(--prim)';
            }
        }
    }

    const createMaintNodes = async () => {
        const zone = await getSessionEntity('zone', datapoint.zid);
        if (zone.servedBy) {
            const equip = await getSessionEntity('zone', zone.servedBy.id);
            const dps = equip.datapoints;
            if (dps.length) {
                const udNodes = dps.reduce((dpNodes: any[], dp: any) => {
                    const img = dp.config === 'fan' ? 'maint' : (dp.config === 'coil' ? 'coilFlow' : 'days');
                    const now = Math.floor(Date.now() / 1000);
                    const daysRem = dp.meas.days ?? 0;
                    let latestMaint = moment((now + (86400 * daysRem)) * 1000).format('MMM-DD');
                    const daysColor = setDaysAlertColor(dp.meas?.days ?? false);
                    // let latestMaint = dp.maintenanceHistory.find((hist: any) => !hist.toTimestamp).fromTimestamp;
                    // latestMaint = moment(latestMaint * 1000).format('MMM-DD h:mma');
                    const equipName = equip.name;
                    const type = dp.config === 'fan' ? 'Belt Adjustment' : (dp.config === 'coil' ? 'Coil Cleaning' : 'Filter Change')
                    const initials = (sessionData.given_name && sessionData.family_name) ? `${sessionData.given_name[0]}${sessionData.family_name[0]}` : 'BP';
                    dpNodes.push({ img, type, equipName, latestMaint, initials, daysColor });
                    return dpNodes;
                }, []);
                setMaintNodes(udNodes)
            }
        } else {
            setMaintNodes([]);
        }
    }

    const assessMaintDate = async () => {
        const zone = await getSessionEntity('zone', datapoint.zid);
        if (zone.servedBy) {
            const equip = await getSessionEntity('zone', zone.servedBy.id);
            if (equip.datapoints && equip.datapoints.length) {
                const dps = equip.datapoints;
                let hasMaint = dps.filter((dp: any) => dp.maintenanceHistory);
                hasMaint = hasMaint.map((hm: any) => {
                    const latest = hm.maintenanceHistory.find((hist: any) => !hist.toTimestamp).fromTimestamp;
                    return latest ?? false;
                }).filter((hm: any) => hm !== false);
                if (hasMaint.length) {
                    const latestLatest = Math.max(...hasMaint);
                    setMaintDate([equip.name, `Last Maintenance: ${moment(latestLatest * 1000).format('MMM-DD')}`]);
                }
            }
        }
        return [];
    }

    useEffect(() => {
        // assessMaintDate();
        createMaintNodes();
    }, []);

    return (
        <div
            className='dp-panel dp-third dp-util-panel'
            style={{ border: `2px solid ${panel.color}` }}>
            <header className='dp-util-header' style={{ background: panel.color }}>
                <h5>{panel.title}</h5>
            </header>
            <div className='dp-util-body' style={{ flexDirection: 'column', overflowX: 'hidden', overflowY: 'scroll', height: '100%', justifyContent: 'flex-start' }}>
                {
                    ['equipment', 'maintenance'].includes(panel.prop) &&
                    <div className='dp-maint'>
                            {
                                maintNodes.length > 0 && maintNodes.map((maint: any, maintIndex: number) => (
                                    <div className='dp-card-alert' style={{border: '2px solid var(--white)', maxWidth: '400px', height: '50px'}}>
                                        <div
                                            className='dp-alert-icon'
                                            style={{background: 'var(--dark-blue)', borderRight: '2px solid var(--white)', height: '100%'}}>
                                            <img style={{height: '62.5%'}} src={`${imgUrl}/${maint.img}.png`} />
                                        </div>
                                        <div className='maint-node-content'>
                                            <div className='maint-node-half' style={{borderRight: '2px solid var(--white)'}}>
                                                <h4 className='maint-node-equip-title'>{maint.equipName}</h4>
                                                {/* <small style={{color: maint.daysColor}}>{maint.latestMaint}</small> */}
                                                <small style={{color: 'gray'}}>Learning</small>
                                            </div>
                                            <div className='maint-node-half'>
                                                <small style={{color: 'gray'}}>{maint.type}</small>
                                                <small>PM Based</small>
                                            </div>
                                            <div className='maint-initials'>
                                                <h2>{maint.initials}</h2>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* <img src={`${imgUrl}/maint.png`} />
                            <small style={{color: maintDate[0].includes('Assigned') ? 'gray' : 'var(--breezi-blue)'}}>{maintDate[0]}<br />{maintDate[1]}</small> */}
                    </div>
                }
                {
                    datapoint?.alerts && panel.prop == 'alerts' && alertList().length > 0 &&
                    alertList().filter((a: any) => ['htp', 'hhm', 'iaq', 'co2', 'voc'].includes(a.prop)).map((alert: any) => (
                        <>
                            <div
                                className='dp-card-alert'
                                style={{
                                    background: `var(--${alert?.tier == 'crit' ? 'crit' : 'cancel'}-transparent)`,
                                    border: `2px solid var(--${alert?.tier == 'crit' ? 'crit' : 'cancel'})`,
                                    width: '60%'
                                }}>
                                <div
                                    className='dp-alert-icon'
                                    style={{ background: `var(--${alert?.tier == 'crit' ? 'crit' : 'cancel'})` }}>
                                    <img src={`${imgUrl}/${alert.img}.png`} />
                                </div>
                                <h5>{alert.title == 'Air Quality' ? 'IAQ' : alert.title} {alert.tier == 'warn' ? 'Warning' : 'Critical'}</h5>
                            </div>
                        </>
                    ))
                }
                {
                    panel.prop == 'alerts' && (!datapoint?.alerts || alertList().length === 0) &&
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div
                        className='dp-card-alert'
                        style={{
                            background: 'var(--prim-transparent)',
                            border: '2px solid var(--prim)',
                            width: '60%'
                        }}>
                            <div
                                className='dp-alert-icon'
                                style={{ background: 'var(--prim)' }}>
                                <img src={`${imgUrl}/checked.png`} />
                            </div>
                            <h5>No Alerts Present</h5>
                        </div>
                    </div>
                }
                {
                    datapoint.meas && !alertList &&
                    <div
                        className='dp-card-alert'
                        style={{
                            background: 'var(--prim-transparent)',
                            border: '2px solid var(--prim)',
                            width: '60%'
                        }}>
                        <div
                            className="dp-alert-icon"
                            style={{ background: 'var(--prim)' }}>
                            <img src={`${imgUrl}/checked.png`} />
                        </div>
                        <h5>No Active Alerts</h5>
                    </div>
                }
            </div>
        </div>
    )
}

export default UtilPanel;