import { useEffect, useState } from "react";
import cToF from 'celsius-to-fahrenheit';

import { useOrganization } from "../../hooks/useOrganization";
import { defaultAlertThresholds, imgUrl, measSet, setStatus } from "../../templates/consts";
import ReadingNode from "../reading/ReadingNode";
import ReadingGauge from "../reading/ReadingGauge";
import { useSession } from "../../hooks/useSessionDev";
import { useNavigate } from "react-router-dom";
import EquipRdgSet from "./EquipRdgSet";
import { BooleanLiteral } from "typescript";

interface Props {
    bldg: any;
    initFauxReport:(e:any, newFauxReport:boolean) => void;
}

const rdgTemplates = {
    filter: {
        title: 'Filter Condition',
        img: 'filterOfficial',
        vals: [
            {
                prop: 'htp',
                img: 'temp'
            },
            {
                prop: 'hhm',
                img: 'hhm'
            }
        ]
    },
    coil: {
        title: 'Coil Conditions',
        img: 'coilFlow',
        vals: [
            {
                prop: 'htp',
                img: 'temp'
            },
            {
                prop: 'hhm',
                img: 'hhm'
            }
        ]
    }
}

const OrgCard: React.FC<Props> = ({ bldg, initFauxReport }) => {

    const nav = useNavigate();

    const [sessionData] = useSession();
    const [, , , trifurcate] = useOrganization();
    const [envMeas, setEnvMeas] = useState<any>(null);
    const [filterMeas, setFilterMeas] = useState<any>(null);
    const [fanMeas, setFanMeas] = useState<any>(null);
    const [coilMeas, setCoilMeas] = useState<any>(null);
    const [staged, setStaged] = useState<boolean>(false);

    const getMin = (rdg: string) => {
        const defaultThresh = defaultAlertThresholds as any;
        const thresh = defaultThresh[rdg];
        return thresh[0][0];
    }

    const getMax = (rdg: string) => {
        const defaultThresh = defaultAlertThresholds as any;
        const thresh = defaultThresh[rdg];
        const maxThresh = thresh[thresh.length - 1];
        return maxThresh[1];
    }

    const parseVal = (rdg: string) => {
        if (!bldg.meas || !bldg.meas[rdg]) return '-';
        let val = bldg.meas[rdg];
        return rdg === 'htp' && sessionData.imperial
            ? Math.round(cToF(bldg.meas.htp))
            : (rdg === 'dpr' && sessionData.iwg ? (bldg.meas.dpr * .0040147).toFixed(3) : bldg.meas[rdg]);
    }

    const parseUnits = (rdg: string) => {
        return rdg === 'htp' && sessionData.imperial
            ? '˚F'
            : (rdg === 'dpr' && sessionData.iwg ? 'iwg' : measSet.find((m: any) => m.prop === rdg)?.units)
    }

    const avgMeasType = (isEnv: boolean, group: any, isCoil?:boolean) => {
        if (!group) return false;
        const datapoints = group.reduce((dps: any[], groupItem: any) => dps.concat(groupItem.datapoints), []);
        let props = ['htp', 'hhm'];
        if (!isEnv) {
            props.push('days');
        } else {
            props = props.concat(['iaq', 'co2', 'voc']);
        }
        const propObj = props.reduce((pObj: any, prop: string) => {
            const haveRdg = datapoints.filter((dp: any) => dp.meas && dp.meas.hasOwnProperty(prop));
            if (haveRdg.length) {
                const val = haveRdg.reduce((avg: number, dp: any, dpIndex: number) => {
                    if (dp.meas[prop] === false) return '-';
                    if (prop === 'voc') dp.meas[prop] = parseFloat(dp.meas[prop]);
                    avg += dp.meas[prop];
                    if (dpIndex === haveRdg.length - 1) {
                        const rawAvg = avg / haveRdg.length;
                        avg = prop === 'voc' ? parseFloat(rawAvg.toFixed(3)) : Math.round(rawAvg);
                    }
                    return avg;

                }, 0);
                const alerts = haveRdg.reduce((alerts: any, dp: any) => {
                    if (dp.alerts && dp.alerts[prop]) {
                        alerts.bad += dp.alerts[prop].bad;
                        alerts.crit += dp.alerts[prop].crit;
                    }
                    return alerts;
                }, { bad: 0, crit: 0 });

                // TODO: reset this code to work with days after algo is recalibrated!
                pObj[prop] = { val, alerts };
                // if (prop !== 'days') {
                //     pObj[prop] = { val, alerts };
                // } else {
                //     pObj.days = {val: '-', alerts: {bad: 0, crit: 0}}
                // }
                
            }
            pObj.dpCount = haveRdg.length;
            pObj.unitCount = haveRdg.reduce((units:any[], dp: any) => {
                if (!units.includes(dp.zid)) units.push(dp.zid);
                return units;
            }, []).length;
            return pObj;
        }, {});
        return propObj ?? false;
    }

    const getEquipPath = () => {
        const tri = trifurcate(bldg);
        const dps = [tri.coil, tri.filter, tri.fan].reduce((allDps: any[], set: any) => {
            return allDps.concat(set.reduce((setDps: any[], subset: any) => {
                return setDps.concat(subset.datapoints);
            }, []));
        }, []);
        const equipCount = dps.reduce((units: any[], dp: any) => {
            if (!units.includes(dp.zid)) units.push(dp.zid);
            return units;
        }, []).length;
        return `${equipCount} HVAC Units | ${dps.length} AirPulses`
    }

    const getEnvPath = () => {
        const tri = trifurcate(bldg);
        const env = tri.env;
        const dpCount = env.reduce((allDps: any[], zone: any) => {
            return allDps.concat(zone.datapoints);
        }, []).length;
        return `${env.length} Zones | ${dpCount} AirPulses`
    }

    const navMonthlyReport = (e: any) => {
        e.stopPropagation();
        nav(`/monthlyReport/${bldg.id}`);
    }

    useEffect(() => {
        setStaged(false);
        const sep = trifurcate(bldg);
        if (sep) {
            const coilAvg = avgMeasType(false, sep.coil ?? null, true);
            const filterAvg = avgMeasType(false, sep.filter ?? null);
            const fanAvg = avgMeasType(false, sep.fan ?? null);
            const envAvg = avgMeasType(true, sep.env ?? null);
            setCoilMeas(coilAvg);
            setFilterMeas(filterAvg);
            setFanMeas(fanAvg);
            setEnvMeas(envAvg);
        }
        setStaged(true);
    }, []);

    return (
        <section className='card bldg-card org-card'
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                border: window.innerWidth <= 767 ? '1px solid var(--breezi-blue)' : '',
                height: window.innerWidth <= 767 ? '250px' : ''
            }}
            onClick={() => nav(`/building/${bldg.id}`)}>
            <header className='card-header'>
                <div className='bldg-header-img'>
                    <img src={`${imgUrl}/${bldg.type}.png`} />
                </div>
                <div className='bldg-header-title' style={{ flexDirection: 'column', justifyContent: 'center' }}>
                    <h3>{bldg.name}</h3>
                    <small>{bldg.floors?.length ?? 0} of {parseInt(bldg.above) + parseInt(bldg.below)} Floors Monitored</small>
                    {/* <small>{bldg.floors?.length ?? 0} Floor</small> */}
                </div>
                <div className='bldg-card-loc'>
                    <img
                        id={`${bldg.id}-country-flag`}
                        data-tooltip-id={`bldg-locale-tooltip-${bldg.id}`}
                        src={`https://catamphetamine.github.io/country-flag-icons/3x2/${bldg.country}.svg`} />
                    {/* <small>{ bldg.locale?.zipCode }</small> */}
                </div>
            </header>
            <div className='org-card-body' style={{ marginTop: window.innerWidth ? '.5%' : '' }}>
                {
                    staged &&
                    <div className='org-card-half org-card-equip'>
                        <h4>Equipment <span style={{fontSize: '.8em', color: 'gray', marginLeft: '5px', marginTop: '1px'}}>| {getEquipPath()}</span></h4>
                        {/* <small className='org-path'>{getEquipPath()}</small> */}
                        <EquipRdgSet
                            maintProp="filter"
                            meas={bldg.meas ?? false}
                            alerts={bldg.alerts ?? false}
                            stageMeas={filterMeas} />
                        <EquipRdgSet
                            maintProp="coil"
                            meas={bldg.meas ?? false}
                            alerts={bldg.alerts ?? false}
                            stageMeas={coilMeas} />
                    </div>
                }
                <div className='org-card-sep'></div>
                <div className='org-card-half org-card-env'>
                    <h4>Environmental <span style={{fontSize: '.8em', color: 'gray', marginLeft: '5px', marginTop: '1px'}}>| {getEnvPath()}</span></h4>
                    {/* <small className='org-path'>{getEnvPath()}</small> */}
                    {
                        envMeas && window.innerWidth > 767 && ['htp', 'hhm', 'iaq', 'co2', 'voc'].map((prop: string) => (
                            <div
                                className='org-card-rdg'
                                style={{ border: `2px solid ${setStatus(prop, envMeas[prop] && envMeas[prop].alerts ? envMeas[prop].alerts : false)}` }}>
                                <div
                                    className='org-card-rdg-head'
                                    style={{
                                        background: setStatus(prop, envMeas[prop] && envMeas[prop].alerts ? envMeas[prop].alerts : false, true),
                                        borderRight: `2px solid ${setStatus(prop, envMeas[prop] && envMeas[prop].alerts ? envMeas[prop].alerts : false)}`
                                    }}>
                                    <img src={`${imgUrl}/${prop}.png`} />
                                </div>
                                <h3 style={{display: 'flex', justifyContent: 'center', 'alignItems': 'flex-start'}}>{parseVal(prop)}<span style={{fontSize: '.6em'}}>{parseUnits(prop)}</span></h3>
                            </div>
                        ))
                    }
                    {
                        envMeas && window.innerWidth <= 767 && ['htp', 'hhm', 'iaq', 'co2', 'voc'].map((prop: string) => (
                            <div
                                className='org-env-rdg'
                                style={{
                                    // background: setStatus(prop, bldg.alerts ? bldg.alerts[prop] : false, true),
                                    background: setStatus(prop, envMeas[prop].alerts ?? false, true),
                                    border: `2px solid ${setStatus(prop, envMeas[prop].alerts ?? false)}`,
                                    borderRadius: ['htp', 'hhm'].includes(prop) ? '100%' : ''
                                }}>
                                <img src={`${imgUrl}/${prop === 'htp' ? 'temp' : (prop === 'voc' ? 'nuevoVOC' : prop)}.png`} />
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='monthly-icon' onClick={e => navMonthlyReport(e)}>
                <img src={`${imgUrl}/MonthlyReport.png`} />
            </div>
        </section>
    )
}

export default OrgCard; 