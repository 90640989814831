import { createContext, useContext, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormTemplate, Input } from '../tsDeclarations/form';

import { AppContext } from '../context/AppContext';
import { useAuth } from './useAuth';
import { validateInputs } from '../util/inputValidator';
import { capitalize } from '../templates/consts';

interface FormContextType {
  formData: {[key:string]:any}
  setFormData:(formData:{[key:string]:any}) => void;
}

export const FormContext = createContext<FormContextType>({
  formData: {},
  setFormData: () => {}
});

export const useForm = ():[any, (updateProps:any, remove?:boolean) => void, () => void, (route:string) => any, (inputs:Input[]) => any, any, (props:string[], res:any) => void ] => {
  const nav = useNavigate();
  const [ signup, confirmCode, resendCode, authenticateUser, , , changePw, forgotPw, confirmPw ] = useAuth();
  const { formData, setFormData } = useContext(FormContext);
  const { appData, setAppData } = useContext(AppContext);
  const [ invalidProps, setInvalidProps ] = useState<any>({});

  const updateFormData = (updateProps:any, remove?:boolean) => {
    if (!remove) {
      setFormData({ ...formData, ...updateProps });
    } else {
      let removedFormData = formData;
      Object.keys(updateProps).forEach(udp => delete removedFormData[udp]);
      setFormData(removedFormData);
    }
  }

  const clearInputs = () => {
    setFormData({});
  }

  const authRequest = async (route:string) => {
    return new Promise(async (resolve, reject) => {
      let toReturn:any = null;
      try {
        setAppData({ ...appData, loading: {visible: true, msg: 'Attempting to sign up SuperUser...'} })
        switch (route) {
          case 'signup':
            toReturn = await signup(formData);
            updateFormData({cognitoUser: toReturn});
            break;
          case 'confirmCode':
            await confirmCode(formData);
            await authenticateUser(formData);
            break;
          case 'resendCode':
            await resendCode(formData);
            break;
          case 'authenticate':
            await authenticateUser(formData);
            break;
          case 'changePw':
            await changePw(formData.oldPassword, formData.password);
            break;
          case 'forgotPw':
            const frg = await forgotPw(formData);
            break;
          case 'confirmPw':
            const { password, confCode, email } = formData;
            await confirmPw(email, confCode, password);
            break;
          case 'submitForgotPw':
            const data = {
              email: formData.email,
              verificationCode: formData.confCode,
              newPassword: formData.password
            }
            await forgotPw(data);
            break;
        }
        if (!['confirmCode', 'forgotPw'].includes(route)) {
          setTimeout(() => {
            const newAppData = { loading: false, helper: {} };
            if (route === 'changePw') {
              newAppData.helper = {
                type: 'tutorial',
                title: 'Password Successfully Changed',
                content: "You have successfully changed the password to this BreeziPro Account. Please make a note of this change and keep it in your records for future logins."
              }
            } else {
              newAppData.helper = false;
            }
            setAppData(newAppData)
            resolve(toReturn);
          }, 5000);
        } else {
          resolve(toReturn);
        }
      } catch(authErr) {
        const err = authErr as { [i: string]: any };
        const authMsg = err.message;
        const helper = {title: `${route != 'authenticate' ? capitalize(route) : 'Authentication'} Error`, content: authMsg, type: 'error'};
        setAppData({ loading: {visible: false}, helper });
      }
    });
  }

  const validateStep = (inputs:Input[]) => {
    const valids = validateInputs(formData, inputs);
    setInvalidProps(valids);
    return valids;
  }

  const addRestResProps = (props:string[], res:any) => {
    if (props[0] == 'locale') {
      setFormData({ ...formData, locale: res });
    }
  }

  return [ formData, updateFormData, clearInputs, authRequest, validateStep, invalidProps, addRestResProps ];
}