import React, { useEffect, useState } from 'react';

interface Props {
    img:string;
    label:string;
    currentStep:number;
    stepIndex:number;
    setCurrentStep:(step:number) => void;
}

const StepNode:React.FC<Props> = ({ img, label, currentStep, stepIndex, setCurrentStep }) => {

    const [ stepClass, setStepClass ] = useState<string|null>(null);
    const [ hover, setHover ] = useState<boolean>(false);

    const updateStepClass = (step:number) => {
        if (step == stepIndex) {
            setStepClass(' active-step');
        } else if (step > stepIndex) {
            setStepClass(' past-step');
        } else {
            setStepClass('');
        }
    }

    useEffect(() => {
        updateStepClass(currentStep);
    }, [currentStep]);

    const stepChange = () => {
        if (currentStep > stepIndex) setCurrentStep(stepIndex);
    }

    return (
            <div 
                className={ `step-node${stepClass}`}
                // style={ {animation: hover ? 'grow-square .5s forwards' : ''} }
                // onMouseOver={ () => setHover(true) }
                // onMouseLeave={ () => setHover(false) }
                onClick={ () => stepChange() }>
                    {
                        currentStep == stepIndex &&
                        <img src={ img } />
                    }
                    {/* {
                        !hover &&
                        <img src={ img } />
                    }
                    {
                        hover &&
                        <small>{ label }</small>
                    } */}
            </div>
    )
}

export default StepNode;
