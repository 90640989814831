import cToF from 'celsius-to-fahrenheit';

import { imgUrl } from '../../templates/consts';
import '../../stylesheets/Reading.css';
import { useSession } from '../../hooks/useSessionDev';
import { useEffect, useState } from 'react';
import Tooltip from '../util/tooltip/Tooltip';

interface Props {
    rdg: any;
    val: string | boolean;
    alert?: any;
    width?: string;
    setPlacard?:false | ((newPlacard:string) => void);
}

const ReadingNode: React.FC<Props> = ({ rdg, val, alert, width, setPlacard }) => {
    const [sessionData] = useSession();
    const [style, setStyle] = useState<any>({});
    const [dots, setDots] = useState<any[]>(Array(4).fill('inactive'));
    const [ttStyle, setTTStyle] = useState<any>(null);
    const [clientPos, setClientPos] = useState<any>(null);
    const assessStatus = () => {
        if (!alert) return 'inactive';
        if (alert.crit) {
            return 'crit';
        } else if (alert.bad) {
            return 'cancel';
        } else {
            return 'prim';
        }
    }

    const initStyle = () => {
        if (width) {
            if (width == 'half') {
                setStyle({ minWidth: '115px', width: '48%', height: '130px', marginLeft: '1.5%', marginRight: '1.5%' });
            } else if (width == 'third') {
                setStyle({ minWidth: '70px', width: '30%', height: '130px', marginLeft: '1.5%', marginRight: '1.5%' });
            } else if (width == 'half-half') {
                setStyle(
                    { minWidth: '130px', width: '47%', height: '58px', marginLeft: '1.5%', marginRight: '1.5%', borderRadius: '5px', marginBottom: '20px' }
                )
            } else if (width == 'half-third') {
                setStyle(
                    { minWidth: '70px', width: '30%', height: '58px', marginLeft: '1.5%', marginRight: '1.5%', borderRadius: '5px' }
                )
            }
        }
    }

    const initDots = () => {
        if (val && alert) {
            if (!alert.bad && !alert.crit) setDots(Array(3).fill('prim'));
            if (alert.bad) setDots(Array(2).fill('cancel').concat('inactive'))
            if (alert.crit) setDots(['crit'].concat(Array(2).fill('inactive')));
        }
    }

    const assessImg = (prop: string) => {
        if (prop == 'voc') return 'nuevoVOC';
        if (prop == 'htp') return 'temp';
        return prop;
    }

    const handleMouseOver = (e: any, rdg: string) => {
        if (setPlacard && rdg !== 'voc') setPlacard(rdg);
    }

    const handleMouseLeave = () => {
        if (setPlacard) setPlacard('');
    }

    useEffect(() => {
        initStyle();
        initDots();
    }, []);

    return (
        <>
            {
                rdg && width != 'half-third' &&
                <>
                    {
                        ['htp', 'hhm'].includes(rdg) &&
                        <div className={`reading-node ${assessStatus()}-reading-node`}>
                            <div className='reading-icon'>
                                <img src={`${imgUrl}/${assessImg(rdg)}.png`} />
                            </div>
                            <h1>{val ?? '-'}</h1>
                            <small>{rdg.units}</small>
                        </div>
                    }
                    {
                        !['htp', 'hhm'].includes(rdg) &&
                        <div
                            className={`mini-reading-node ${assessStatus()}-reading-node`}
                            onMouseOver={(e) => handleMouseOver(e, rdg)}
                            onMouseLeave={() => handleMouseLeave()}
                            id={`${rdg}-rdg`}
                            data-tooltip-id={['iaq', 'co2'].includes(rdg) ? 'placard-tooltip' : ''}>
                            <div className='reading-icon mini-reading-icon'>
                                <img src={`${imgUrl}/${assessImg(rdg)}.png`} />
                            </div>
                            <div className='mini-reading-val'>
                                <h1>{!val || val.toString() === 'false' ? '-' : val}</h1>
                                <small>{rdg.units}</small>
                            </div>
                        </div>
                    }
                </>
            }
            {
                width == 'half-third' &&
                <div className={`mini-reading-node ${assessStatus()}-reading-node diff-mini-node`}>
                    <div className='reading-icon mini-reading-icon'>
                        <img src={`${imgUrl}/${assessImg(rdg.prop)}.png`} />
                    </div>
                    <div className='mini-rdg-text'>
                        <small>{!val || val.toString() === 'false' ? '-' : val}</small>
                        <small>{rdg.units}</small>
                    </div>
                </div>
            }
            {/* {
                placard.length > 0 &&
                <div className='faux-tt' style={ttStyle} onMouseOver={() => resetTT()}>
                    <img style={{width: '100%'}} src={`${imgUrl}/${placard.toUpperCase()}Placard.png`} />
                </div>
            } */}
        </>
    )
}

export default ReadingNode;