export const sleeveStates = [
    {
        img: 'layerCards',
        val: 'cards'
    },
    {
        img: 'equip-trend-icon',
        val: 'trends'
    },
    {
        img: 'equip-elems-icon',
        val: 'hvac'
    }
]

export const equipBank = [
    {
        title: 'Panel Filter',
        prop: 'panel',
        img: 'filter',
        class: 'full-col',
        width: 1,
        type: 'Filter'
    },
    {
        title: 'Bag Filter',
        prop: 'bag',
        img: 'bag-filter',
        class: 'full-col two-col',
        width: 2,
        type: 'Filter'
    },
    {
        title: 'Cooling Coil',
        prop: 'cooling',
        img: 'cooling-coil',
        class: 'full-col',
        width: 1,
        type: 'Coil'
    },
    {
        title: 'Heating Coil',
        prop: 'heating',
        img: 'heating-coil',
        class: 'full-col',
        width: 1,
        type: 'Coil'
    },
    {
        title: 'Chilled Water Coil',
        prop: 'chilled',
        img: 'chilled-water-coil',
        class: 'full-col',
        width: 1,
        type: 'Coil'
    },
    {
        title: 'Belt-driven Fan',
        prop: 'beltDriven',
        img: 'belt-driven-fan',
        class: 'shrink-square',
        width: 3,
        type: 'Fan'
    },
    {
        title: 'Direct-drive Fan',
        prop: 'directDrive',
        img: 'direct-drive-fan',
        class: 'shrink-square',
        width: 3,
        type: 'Fan'
    },
    {
        title: 'ECM Fan',
        prop: 'ecm',
        img: 'fan',
        class: 'shrink-square',
        width: 3,
        type: 'Fan'
    }
];

export const actions = {
    coil: [
        {
            img: 'clean',
            title: 'Cleaned Coil',
            fnc: 'maint'
        },
        {
            img: 'maintenance',
            title: 'Other Maintenance',
            fnc: 'otherMaint'
        }
    ],
    filter: [
        {
            img: 'change',
            title: 'Changed Filter',
            fnc: 'maint'
        },
        {
            img: 'maintenance',
            title: 'Other Maintenance',
            fnc: 'otherMaint'
        }
    ],
    fan: [
        {
            img: 'belt',
            title: 'Changed/Adjusted Belt',
            fnc: 'maint'
        },
        {
            img: 'maintenance',
            title: 'Other Maintenance',
            fnc: 'otherMaint'
        }
    ]
}

export const info = {
    coil: [
        {
            img: 'info',
            title: 'View/Update Info'
        },
        {
            img: 'basura',
            title: 'Delete Coil',
            fnc: 'del'
        }
    ],
    filter: [
        {
            img: 'info',
            title: 'View/Update Info'
        },
        {
            img: 'basura',
            title: 'Delete Filter',
            fnc: 'del'
        }
    ],
    fan: [
        {
            img: 'info',
            title: 'View/Update Info'
        },
        {
            img: 'basura',
            title: 'Delete Fan',
            fnc: 'del'
        }
    ]
}