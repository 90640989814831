interface Props {
    events: any[];
}

const MonthlyMaintDetail: React.FC<Props> = ({ events }) => {
    return (
        <div className='monthly-maint-detail'>

        </div>
    )
}

export default MonthlyMaintDetail;