import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { useSession } from "../../hooks/useSession"
import { useSession } from "../../hooks/useSessionDev"
import { imgUrl } from "../../templates/consts";
import { useAuth } from "../../hooks/useAuth";
import { AppContext } from "../../context/AppContext";

const fncColors = ['breezi-recessive', 'tri', 'crit'];

export default () => {

    const location = useLocation()

    const [, , , , , logout] = useAuth();
    // const [sessionData, setInitialSession, getSessionEntity, updateUtil] = useSession();
    const [sessionData] = useSession();
    const { appData, setAppData } = useContext(AppContext);

    const [hoverNode, setHoverNode] = useState<number>(-1);
    const [util, setUtil] = useState<any>(null);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [countImg, setCountImg] = useState<string>('bldgType');
    const [equip, setEquip] = useState<boolean>(false);


    // TODO: REMOVE THIS!
    const [windowDims, setWindowDims] = useState<any>({width: window.innerWidth, height: window.innerHeight});
    const [screenDims, setScreenDims] = useState<any>({width: window.screen.width, height: window.screen.height});
    useEffect(() => {
        const handleResize = () => {
            let width = window.innerWidth;
            let height = window.innerHeight;
            setWindowDims({ width, height });
            width = window.screen.width;
            height = window.screen.height;
            setScreenDims({ width, height });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth, window.innerHeight]);


    const assessChildImg = (countStr: string) => {
        const str = countStr.toLowerCase();
        if (str.includes('building')) {
            return 'bldgType';
        } else if (str.includes('floor')) {
            return 'tilesWhite';
        } else if (str.includes('zone')) {
            return 'envZone';
        } else if (str.includes('datapoint')) {
            return 'datapoint';
        } else {
            return 'bldgType'
        }
    }

    const genEntImg = () => {
        if (equip) {
            return 'equip';
        } else {
            if (sessionData.util.tier == 'building') {
                return sessionData.building.type;
            } else {
                return util.img;
            }
        }
    }

    useEffect(() => {
        if (sessionData.util) {
            setUtil(sessionData.util);
        }
    }, [sessionData.util]);

    return (
        <>
            {
                util && sessionData.organization &&
                <div className='app-header'>
                    {/* {
                        windowDims && screenDims &&
                        <>
                            <h3 style={{ position: 'absolute', left: '20%', padding: '2px' }}>Window: {windowDims.width} / {windowDims.height}</h3>
                            <h3 style={{ position: 'absolute', left: '20%', padding: '2px', top: '30px', color: 'var(--breezi-blue)', borderTop: '2px solid var(--breezi-blue)' }}>Screen: {screenDims.width} / {screenDims.height}</h3>
                        </>
                    } */}
                        
                    <div className='header-logo'>
                        <img src={`${imgUrl}/pro-box.png`} />
                            <small style={{ paddingTop: '3px' }}>{sessionData.given_name} {sessionData.family_name} <span style={{ color: 'var(--breezi-blue)' }}>||</span> {sessionData.jobTitle ?? 'Super User '}</small>
                            {/* <small>Pilot Tester <span style={{ color: 'var(--breezi-blue)' }}>||</span> Super User</small> */}
                    </div>
                    <header className='util-header' style={{height: window.innerWidth > 767 ? '' : '62.5px', marginTop: window.innerWidth <= 767 ? '-10px' : ''}}>
                        <div className='header-third ent-third'>
                            {
                                (!util || !util.title) &&
                                <img src={`${imgUrl}/enterprise.png`} />
                            }
                            {
                                util && util.title &&
                                <>
                                    <img src={`${imgUrl}/${genEntImg()}.png`} />
                                        <small style={{
                                            fontSize: window.innerHeight <= 767 ? '1.75em' : '',
                                            marginLeft: window.innerHeight <= 767 ? '20px' : '',
                                            width: window.innerHeight <= 767 ? '110px' : ''
                                        }}>{util.title}</small>
                                </>
                            }
                        </div>
                        <div className='header-third header-count'>
                            {
                                util.tier != 'datapoint' &&
                                <>
                                    {
                                        screenWidth >= 851 &&
                                        <>
                                            <h1>{util.count}</h1>
                                            <h2>{util.countStr} Monitored</h2>
                                        </>
                                    }
                                    {
                                        screenWidth < 767 &&
                                        <img className='mobile-count' style={{ width: '35px', height: 'auto', marginLeft: '25px' }} src={`${imgUrl}/${genEntImg()}.png`} />
                                    }
                                </>
                            }
                        </div>
                        <div className='header-third header-alerts'>
                            <h2 style={{ color: 'var(--cancel)' }}>{util.warn}</h2>
                            <img src={`${imgUrl}/alert.png`} />
                            <h2 style={{ color: 'var(--crit)' }}>{util.crit}</h2>
                        </div>
                        {
                            screenWidth < 851 &&
                            <div
                                className='mob-settings-tab'
                                onClick={() => setAppData({ ...appData, settings: true })}>
                                <img src={`${imgUrl}/dots.png`} />
                            </div>
                        }
                    </header>
                    <div className='header-fnc'>
                        <div className='fnc-node'
                            onMouseOver={() => setHoverNode(1)}
                            onMouseLeave={() => setHoverNode(-1)}
                            onClick={() => setAppData({ ...appData, settings: true })}
                            style={{ background: hoverNode == 1 ? `var(--${fncColors[1]})` : '' }}>
                            <img src={`${imgUrl}/dots.png`} />
                        </div>
                        <div className='fnc-node'
                            onMouseOver={() => setHoverNode(2)}
                            onMouseLeave={() => setHoverNode(-1)}
                            onClick={() => logout()}
                            style={{ background: hoverNode == 2 ? `var(--${fncColors[2]})` : '' }}>
                            <img src={`${imgUrl}/logoutWhite.png`} />
                        </div>
                    </div>
                </div>
            }
        </>
    )

}

{/* <div className='fnc-node'
                    onMouseOver={ () => setHoverNode(0)}
                    onMouseLeave={ () => setHoverNode(-1)}
                    style={ {background: hoverNode == 0 ? `var(--${fncColors[0]})` : ''}}>
                    <h2>{ sessionData.given_name[0] }{ sessionData.family_name[0] }</h2>
                </div> */}