import { useEffect, useState } from "react";
import cToF from 'celsius-to-fahrenheit';

import { imgUrl } from "../../templates/consts";
import PdfHiLo from "./PdfHiLo";
import { useSession } from "../../hooks/useSessionDev";

import tempImg from '../../assets/images/temp.png';

interface Props {
    equip: any;
    sessionEquip:any;
}

const PdfEquip: React.FC<Props> = ({ equip, sessionEquip }) => {

    const [sessionData] = useSession();

    const [supplyDp, setSupplyDp] = useState<any>(null);
    const [returnDp, setReturnDp] = useState<any>(null);
    const [supplyDays, setSupplyDays] = useState<number | null>(null);
    const [returnDays, setReturnDays] = useState<number | null>(null);
    
    const convertDelta = (val: number, sys: string) => {
        const frac = sys === 'c' ? 5/9 : 9/5;
        return Math.round(val * frac); 
    }

    const calcDeltaTemp = (val:number) => {
        return !sessionData.imperial
            ? val
            : convertDelta(val, 'i')
    }

    useEffect(() => {
        if (sessionEquip.datapoints?.length) {
            const supply = sessionEquip.datapoints.find((dp: any) => dp.config === 'coil');
            const ret = sessionEquip.datapoints.find((dp: any) => dp.id != supply.id);
            setSupplyDays(supply.meas.days ?? null);
            setReturnDays(ret.meas.days ?? null);
            const supplyId = supply.id;
            const returnId = ret.id;
            setSupplyDp(equip.datapoints.find((edp: any) => edp.dpid === supplyId));
            setReturnDp(equip.datapoints.find((edp:any) => edp.dpid === returnId));
        }
    }, [sessionEquip]);
    
    return (
        <section className='pdf-zone-container pdf-equip'>
            {/* <header>
                <div className='pdf-icon'>
                    <img src={`${imgUrl}/hvac.png`} />
                </div>
                <div className='pdf-title'>
                    <h3>{sessionEquip.name}</h3>
                    <h5>{sessionEquip.datapoints.length} AirPulse{sessionEquip.datapoints.length === 1 ? '' : 's'}</h5>
                </div>
            </header> */}
            <header>
                <h2>Equipment Status</h2>
            </header>
            <div className='pdf-zone-body'>
                {
                    returnDp &&
                    <PdfHiLo dp={returnDp} supply={false} days={returnDays} tms={equip.datapoints[0].tms} />
                }
                <div className='monthly-equip-sep'></div>
                {
                    supplyDp &&
                    <PdfHiLo dp={supplyDp} supply={true} days={supplyDays} tms={equip.datapoints[0].tms} />
                }
                <div className='pdf-delta'>
                    <div className='pdf-delta-head'>
                        <h2>∆</h2>
                        <img src={tempImg} />
                    </div>
                    <div className='pdf-delta-range'>
                        <div className='pdf-delta-node pdf-delta-zero'>
                            <h4>0</h4>
                        </div>
                        <div className='pdf-delta-node pdf-delta-avg'>
                            <h4>{calcDeltaTemp(equip.meas.workingHoursAvg.deltaTemp.avg)}</h4>
                            <small>AVG</small>
                        </div>
                        <div className='pdf-delta-sep'></div>
                        <div className='pdf-delta-node pdf-delta-hi'>
                            <h4>{calcDeltaTemp(equip.meas.workingHoursAvg.deltaTemp.hi)}</h4>
                            <small>HIGH</small>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PdfEquip;