import { imgUrl } from "../../../templates/consts";

interface Props {
    img:string;
}

const Placard:React.FC<Props> = ({ img }) => {
  return (
    <div className='tooltip-placard'>
      {
        img && img.length && img !== 'Placard' &&
        <img style={{height: '200px'}} src={ `${imgUrl}/${img}.png` } />
      }
    </div>
  )
}

export default Placard
