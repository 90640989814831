import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// import { useSession } from '../../hooks/useSessionContext';
// import { useSession } from '../../hooks/useSession';
import { useSession } from '../../hooks/useSessionDev';
import { useRest } from '../../hooks/useRest';
import { setStatus } from '../../templates/consts';
import { AppContext } from '../../context/AppContext';

interface Props {
    bid: string;
    floor: any;
}

const Rooftop: React.FC<Props> = ({ bid, floor }) => {

    // router fncs
    const nav = useNavigate();

    // external context
    const { appData, setAppData } = useContext(AppContext);

    // custom hooks
    const [sessionData, , , , , , postObUpdate] = useSession();
    const [request] = useRest();

    const [hover, setHover] = useState<boolean>(false);

    const navOrObRoof = async () => {
        let roof = floor;
        if (!floor) {
            // const newRoofItem = {
            //     index: false,
            //     type: 'rooftop',
            //     name: 'Rooftop',
            //     bid
            // }
            // try {
            //     const postedRoof = await request('floor', 'post', 'floor', newRoofItem);
            //     postedRoof.zones = []
            //     await postObUpdate(postedRoof, 'floor');
            //     roof = postedRoof;
            // } catch (postRoofErr) {
            //     console.error({ postRoofErr })
            // }
            setAppData({
                ...appData,
                helper: {
                    type: 'blocker',
                    title: 'Disabled',
                    content: "This feature has been restricted for the duration of the Pilot Program"
                }
            });
        } else {
            nav(`/floor/${floor.id}`)
        }
    }

    return (
        <div
            // className={`bldg-floor rooftop-bldg-floor${floor ? ' active-rooftop' : ''}`}
            className={`bldg-floor ${floor ? ' active-rooftop' : ''}`}
            style={{ background: floor && floor.zones?.length > 1 ? setStatus('all', floor.alerts ? floor.alerts.overall : false) : '' }}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => navOrObRoof()}
        >
            <h3>{hover && !floor && 'Add'} Rooftop</h3>
        </div>
    )

}

export default Rooftop; 