import moment from 'moment';

import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl } from "../../templates/consts";
import { actions, info } from "../../templates/equipmentComponentTemplate";
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { useSession } from '../../hooks/useSessionDev';
import MaintenanceEventForm from './MaintenanceEventForm';

interface Props {
    sec: any;
    secIndex: number;
    elem: any;
    elemIndex: number;
    updateConfigElem: () => void;
    removeElem: (sIndex: number, eIndex: number) => void;
    dp: any;
    initMaint: (newMaint: any) => void;
}

const momentify = (tms: number) => {
    return moment(tms).format('MMM-DD @ h:mma');
}

// const ElemInspector: React.FC<Props> = ({  }) => {
const ElemInspector: React.FC<Props> = ({ sec, secIndex, elem, elemIndex, updateConfigElem, removeElem, dp, initMaint }) => {

    const { appData, setAppData } = useContext(AppContext);

    const [equip, , , , , , , , , , , removeEquipDp] = useEquipment();
    const [, , , , , , , postDeleteUpdate, , updateApBank] = useSession();

    const [viewElem, setViewElem] = useState<any>(null);
    const [delConf, setDelConf] = useState<boolean>(false);
    const [delText, setDelText] = useState<string>('');
    const [otherMaint, setOtherMaint] = useState<boolean>(false);

    const assessMaint = () => {
        const dps = equip.datapoints.filter((dp: any) => dp.sectionIndex == sec.index);
        const diffDp = dps.find((dp: any) => dp.apsn === sec.diffSensor) ?? false;
        let maintTms: any;
        if (diffDp && diffDp.maintenanceHistory) {
            const diffDp = dps.find((dp: any) => dp.apsn === sec.diffSensor);
            if (diffDp && diffDp.maintenanceHistory) {
                const activeHistory = diffDp.maintenanceHistory.find((hist: any) => !hist.toTimestamp);
                if (activeHistory) maintTms = activeHistory.fromTimestamp;
            }
        } else {
            const latestSec = equip.sections[secIndex];
            const latestElem = latestSec.elems[elemIndex];
            maintTms = latestElem.maintTms;
        }
        return momentify(parseInt(maintTms) * 1000)
        // return !latestElem.maintTms ? momentify(Date.now()) : momentify(parseInt(latestSec.maintTms) * 1000);
    }

    const getElemActions = () => {
        const acts = actions as any;
        return acts[elem.type.toLowerCase()];
    }

    const getElemInfoSet = () => {
        const infos = info as any;
        return infos[elem.type.toLowerCase()];
    }

    const handleElemClick = async (e: any, fnc: string | false) => {
        if (fnc === 'maint') {
            initMaint('maint');
        } else if (fnc === 'otherMaint') {
            initMaint('otherMaint');
        } else {
            setAppData({
                ...appData,
                helper: {
                    type: 'blocker',
                    title: 'Disabled',
                    content: "This feature has been restricted for the duration of the Pilot Program"
                }
            });
        }
        // if (fnc == 'del') {
        //     if (sec.elems.length > 1) {
        //         setDelConf(true);
        //     } else {
        //         setAppData({
        //             ...appData,
        //             helper: {
        //                 type: 'error',
        //                 title: `${elem.type} can not be deleted`,
        //                 content: `An HVAC stage must contain at least one Element.`
        //             }
        //         })
        //     }
        //     // updateConfigElem(e);
        // } else if (fnc == 'maint') {
        //     initMaint(true);
        // }
    }

    const closeInspector = () => {
        const configElem = { secIndex: -1, elemIndex: -1 };
        updateConfigElem();
    }

    const confirmDel = async () => {
        if (delText.toLowerCase() == 'delete') {
            if (!dp) {
                await removeElem(secIndex, elemIndex);
                updateConfigElem();
            } else {
                removeEquipDp(dp)
                updateConfigElem();
                initMaint(false);
            }
        }
    }

    useEffect(() => {
        const sec = equip.sections[secIndex]
        if (!elem && !dp) {
            setViewElem(sec.elems[sec.elems.length - 1]);
        }
    }, [elem]);

    return (elem || dp) &&
        <>
            <img
                className='expand-close'
                onClick={e => updateConfigElem()}
                src={`${imgUrl}/closeToo.png`}
                style={{ width: 'auto' }} />
            {
                !dp &&
                <header className='expand-header'>
                    <img src={`${imgUrl}/equip-${elem.img}.png`} />
                    <div className='expand-title'>
                        <h5>{elem.title}</h5>
                        {/* {
                        sec && sec.elems?.length > 0 && <small>Stage {sIndex + 1} of {equip.sections.length} <span style={ {color: 'var(--breezi-blue)'} }>|</span> Element {eIndex + 1} of {sec.elems.length}</small>   
                    } */}
                    </div>
                </header>
            }
            {
                dp &&
                <header className='expand-header'>
                    <img src={`${imgUrl}/apIcon.png`} />
                    <div className='expand-title'>
                        <h5>{dp.apsn}</h5>
                        {/* {
                        sec && sec.elems?.length > 0 && <small>Stage {sIndex + 1} of {equip.sections.length} <span style={ {color: 'var(--breezi-blue)'} }>|</span> Element {eIndex + 1} of {sec.elems.length}</small>   
                    } */}
                    </div>
                </header>
            }
            {
                !delConf && !dp &&
                <>
                    <h5 className='expand-sub action-sub'>Actions</h5>
                    {
                        getElemActions().map((act: any, actIndex: number) => (
                            <div
                                className={`elem-action${act.inactive ? ' inactive-action' : ''}`}
                                onClick={e => handleElemClick(e, act.fnc ?? false)}>
                                <div className='elem-action-head'>
                                    <img src={`${imgUrl}/${act.img}.png`} />
                                </div>
                                <h5>{act.title}</h5>
                            </div>
                        ))
                    }
                    <h5 className='expand-sub info-sub'>Details</h5>
                    {
                        getElemInfoSet().map((info: any, infoIndex: number) => (
                            <div
                                className='elem-action elem-info'
                                onClick={e => handleElemClick(e, info.fnc ?? false)}>
                                <div className='elem-action-head'>
                                    <img src={`${imgUrl}/${info.img}.png`} />
                                </div>
                                <h5>{info.title}</h5>
                            </div>
                        ))
                    }
                    <footer>
                        <h6>Last Maintenance: {assessMaint()}</h6>
                    </footer>
                </>
            }
            {
                delConf &&
                <>
                    <label className='expand-input'>
                        <input value={delText} onChange={e => setDelText(e.target.value)} />
                    </label>
                    <h5 style={{ width: '80%' }}>Type 'delete' to confirm deletion of this Element. This can not be undone.</h5>
                    <a
                        className={`elem-action ${delText.toLowerCase() !== 'delete' ? 'inactive' : 'del'}-action`}
                        onClick={() => confirmDel()}>
                        <div className='elem-action-head'>
                            <img src={`${imgUrl}/basura.png`} />
                        </div>
                        <h5>Confirm Delete</h5>
                    </a>
                </>
            }
            {
                dp &&
                <>
                    <label className='expand-input'>
                        <input value={delText} onChange={e => setDelText(e.target.value)} />
                    </label>
                    <h5 style={{ width: '80%' }}>Type 'delete' to confirm deletion of this Datapoint. This can not be undone.</h5>
                    <a
                        className={`elem-action ${delText.toLowerCase() !== 'delete' ? 'inactive' : 'del'}-action`}
                        onClick={() => confirmDel()}>
                        <div className='elem-action-head'>
                            <img src={`${imgUrl}/basura.png`} />
                        </div>
                        <h5>Confirm Delete</h5>
                    </a>
                </>
        }
        {
            otherMaint &&
            <MaintenanceEventForm sectionIndex={secIndex} elemIndex={elemIndex} closeForm={() => setOtherMaint(false)} />
        }
        </>
}

export default ElemInspector;