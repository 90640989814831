import { useEffect, useState } from "react";
import cToF from 'celsius-to-fahrenheit';

import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl, measSet } from "../../templates/consts";
import { useSession } from "../../hooks/useSessionDev";
import { useNavigate } from "react-router-dom";

interface Props {
    sec: any;
}

const MobileSecAp: React.FC<Props> = ({ sec }) => {

    const nav = useNavigate();

    const [equip] = useEquipment();
    const [sessionData] = useSession();

    const [dps, setDps] = useState<any>(null);

    const parseAlertClass = (dp: any, rdg: any) => {
        if (!dp.alerts || !dp.meas[rdg]) return '';
        let tier = 'good';
        ['bad', 'crit'].forEach(tr => {
            if (dp.alerts[rdg][tr]) tier = tr;
        });
        return ` ${tier}-equip-rdg`;
    }

    const setVal = (dp: any, rdg: any) => {
        if (!dp.meas || dp.meas[rdg] === '-') return '-'
        let val = dp.meas[rdg];
        if (typeof val !== 'number') val = parseInt(val);
        return rdg == 'htp' && sessionData.imperial ? parseInt(cToF(val)) : val
    }

    const setUnits = (rdg: string) => {
        const rdgObj = measSet.find((m: any) => m.prop === rdg);
        if (!rdgObj) return '';
        return rdgObj.units;
    }

    const handleDpNav = (dir: string) => {
        if (dps[dir]) {
            nav(`/datapoint/${dps[dir].id}`)
        }
    }

    useEffect(() => {
        const allDps = equip.datapoints;
        if (allDps.length) {
            let secDps = allDps.filter((dp: any) => dp.sectionIndex === equip.sections.indexOf(sec));
            secDps = {
                upstream: secDps.find((dp: any) => dp.loc === 'up') ?? false,
                downstream: secDps.find((dp: any) => dp.loc === 'down') ?? false,
            }
            setDps(secDps);
        }
    }, []);

    return (
        <div className='mob-sec-aps'>
            <div className='mob-ap-node' onClick={() => handleDpNav('upstream')}>
                {
                    dps && dps.upstream &&
                    ['htp', 'hhm'].map((rdg: string) => (
                        <div className={`mob-ap-equip-rdg${parseAlertClass(dps.upstream, rdg)}`}>
                            <div className='ap-equip-head'>
                                <img src={`${imgUrl}/${rdg === 'htp' ? 'temp' : rdg}.png`} />
                            </div>
                            <h6 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {setVal(dps.upstream, rdg)}
                                <span style={{ fontSize: '.75em' }}>
                                    {rdg == 'htp' && sessionData.imperial ? '˚F' : setUnits(rdg)}
                                </span>
                            </h6>
                        </div>
                    ))
                }
                {
                    !dps || !dps.upstream &&
                    <div className='mob-empty-ap' style={{opacity: 0}}>
                        <h5>No AirPulse Installed</h5>
                    </div>
                }
            </div>
            <div className='mob-airflow'>
                <img
                    style={{ transform: equip.airflowDir === 'ltr' ? '' : '180deg' }}
                    src={`${imgUrl}/airflow.png`} />
                <h4>Airflow</h4>
            </div>
            <div className='mob-sec-downstream'>
                <div className='mob-ap-node' onClick={() => handleDpNav('downstream')}>
                    {
                        dps && dps.downstream &&
                        ['htp', 'hhm'].map((rdg: string) => (
                            <div className={`mob-ap-equip-rdg${parseAlertClass(dps.downstream, rdg)}`}>
                                <div className='ap-equip-head'>
                                    <img src={`${imgUrl}/${rdg === 'htp' ? 'temp' : rdg}.png`} />
                                </div>
                                <h6 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {setVal(dps.downstream, rdg)}
                                    <span style={{ fontSize: '.75em' }}>
                                        {rdg == 'htp' && sessionData.imperial ? '˚F' : setUnits(rdg)}
                                    </span>
                                </h6>
                            </div>
                        ))
                    }
                    {
                        !dps || !dps.downstream &&
                        <div className='mob-empty-ap' style={{opacity: 0}}>
                            <h5>No AirPulse Installed</h5>
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}

export default MobileSecAp;