import { useEffect, useState } from "react";
import cToF from 'celsius-to-fahrenheit';

import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl } from "../../templates/consts";
import { useSession } from "../../hooks/useSessionDev";
import Radial from "../util/RadialMenu";

interface Props {
    sec: any;
    left: boolean;
    ghost?: string;
    radialDp: string;
    setRadialDp: (newRadialDp: string) => void;
    bankState: string;
    initDelDp:(dp:any) => void;
}

const SectionAP: React.FC<Props> = ({ sec, left, ghost, radialDp, setRadialDp, bankState, initDelDp }) => {

    const [ sessionData ] = useSession();
    const [equip, , , , , , setAPDiff] = useEquipment();

    const [dp, setDp] = useState<any>(null);
    const [meas, setMeas] = useState<any>(null);
    const [showRadial, setShowRadial] = useState<boolean>(false);

    const assignDpIfExists = () => {
        if (equip.datapoints?.length) {
            let secDps = equip.datapoints.filter((dp: any) => dp.sectionIndex == sec.index);
            const hasPos = secDps.find((sdp: any) => left ? sdp.loc == 'up' : sdp.loc == 'down');
            if (hasPos) setDp(hasPos ?? null);
        }
    }

    const parseAlertClass = (rdg:any) => {
        if (!dp.alerts || !dp.meas[rdg]) return '';
        let tier = 'good';
        ['bad', 'crit'].forEach(tr => {
            if (dp.alerts[rdg][tr]) tier = tr;
        });
        return ` ${tier}-equip-rdg`;
    }

    const alertColor = () => {
        let color = 'breezi-blue';
        if (dp.meas && dp.alerts) {
            if (Object.keys(dp.alerts).some((alert:string) => dp.alerts[alert].crit)) {
                color = 'crit';
            } else if (Object.keys(dp.alerts).some((alert:string) => dp.alerts[alert].bad)) {
                color = 'cancel'
            } else {
                color = 'prim';
            }
        }
        return `var(--${color})`;
    }

    const hoverGhost = (pos?: string) => {
        return !pos ? ghost : (left && ghost == 'left') || (!left && ghost == 'right')
    }

    const setVal = (rdg: any) => {
        if (rdg.val == '-') return '-';
        if (rdg && rdg.val) {
            if (typeof rdg.val !== 'number') rdg.val = parseInt(rdg.val);
            return rdg.rdg == 'htp' && sessionData.imperial ? parseInt(cToF(rdg.val)) : rdg.val
        }
    }
    
    useEffect(() => {
        setDp(null);
        assignDpIfExists();
    }, [equip]);

    useEffect(() => {
        if (dp && !meas) {
            setMeas([{ rdg: 'htp', val: dp.meas?.htp ?? '-', units: '˚C', img: 'temp' }, { rdg: 'hhm', val: dp.meas?.hhm ?? '-', units: '%', img: 'hum' }]);
        }
    }, [dp]);

    return (
        <div 
        className='hvac-ap' 
            style={{
                width: dp?.id || hoverGhost() || bankState != '' ? '' : '20px',
                margin: dp?.id || hoverGhost() || bankState != '' ? '' : '0px',
                padding: dp?.id || hoverGhost() || bankState != '' ? '' : '0px'
            }}>
            {
                dp && meas &&
                <>
                    {
                        meas.map((rdg:any, val:any) => (
                            <div 
                                className={ `ap-equip-rdg${parseAlertClass(rdg.rdg)}` } 
                                onClick={() => setRadialDp(dp.apsn)}>
                                {/* style={{cursor: 'default'}}> */}
                                <div className='ap-equip-head'>
                                    <img src={ `${imgUrl}/${rdg.img ?? rdg.rdg}.png` } />
                                </div>
                                {
                                   rdg.val &&
                                    <h6 style={ {display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        {setVal(rdg)}
                                        <span style={ {fontSize: '.75em'} }>
                                            {rdg.rdg == 'htp' && sessionData.imperial ? '˚F' : rdg.units}
                                        </span>    
                                    </h6>
                                }
                                {
                                    !rdg.val &&
                                    <h6>-</h6>
                                }
                            </div>
                        ))
                    }
                    {
                        radialDp == dp.apsn &&
                        <Radial dp={ dp } bg={ alertColor() } closeRadial={ () => setRadialDp('')} initDpDelete={initDelDp} />
                    }
                </>
            }
            {
                hoverGhost('pos') &&
                <>
                    <div className='radial-ap' style={{ position: 'relative' }}>
                        <img src={`${imgUrl}/apIcon.png`} style={{ background: 'var(--breezi-blue)' }} />
                    </div>
                    <small style={{ marginTop: '10px', color: 'var(--breezi-blue)' }}>{ equip.holdAP.apsn }</small>
                    <small style={ {color: 'var(--prim)', fontSize: '.7em'} }>Add Here</small>
                </>
            }
            {
                dp && equip.holdDiffAP && equip.holdDiffAP == dp.apsn && !equip.holdAP && !sec.diffSensor &&
                <div className='hvac-diff-sel'>
                    <h5><span style={{ color: 'var(--prim)' }}>Differential<br /></span> Sensor?</h5>
                    <div className='hvac-diff-opts'>
                            <div
                                className='hvac-opt' style={{ background: 'var(--crit-transparent)' }}
                                onClick={ () => setAPDiff(false) }>
                            <h6>NO</h6>
                        </div>
                        <div
                            className='hvac-opt' style={{ background: 'var(--prim-transparent)' }}
                            onClick={ () => setAPDiff(true, sec) }>
                        <h6>YES</h6>
                    </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SectionAP;