import { useEffect } from "react";
import { imgUrl } from "../../templates/consts";
import { intervals } from "./EquipTrend";

interface Props {
    interval: number;
    setInterval: (newInterval: number) => void;
    breadcrumbs: any[];
    handleTrendNav: (dir: string) => void;
    tmsRange:any[];
}
 
const toggleNodes = [
    {
        img: 'calendar',
        title: 'Daily'
    },
    {
        img: 'hour',
        title: 'Hourly'
    },
    {
        img: 'realtime',
        title: 'Realtime'
    }
];

const TrendControls: React.FC<Props> = ({ interval, setInterval, breadcrumbs, handleTrendNav, tmsRange }) => {

    const navInterval = (index: number) => {
        if (index < intervals.indexOf(interval)) {
            setInterval(intervals[index]);
        }
    }

    const validNav = (dir: string) => {
        const minDate = Math.floor(new Date('2023-10-24').getTime() / 1000);
        const intIndex = intervals.indexOf(interval);
        const now = Math.floor(Date.now()/1000);
        let crumb = parseInt(tmsRange[intIndex])
        if (interval === 86400) {
            if (crumb - (86400 * 10) >= minDate && dir === 'back') return 'active-equip-trend-tog';
            if (parseInt(tmsRange[tmsRange.length - 1]) + (86400 * 10) <= now && dir === 'forward') return 'active-equip-trend-tog';
            return '';
        } else {
            if (dir == 'back') {
                if (isNaN(crumb)) return 'active-equip-trend-tog';
                // const minAdjusted = 
                if (crumb - (interval * 60) >= minDate) {
                    return 'active-equip-trend-tog';
                } else {
                    return '';
                }
            } else {
                const latest = Math.round(new Date().getTime() / 1000) - 86400;
                if (crumb < latest) {
                    return 'active-equip-trend-tog';
                } else {
                    return '';
                }
            }
        }
    }

    const handleNav = (dir: string) => {
        if (validNav(dir).length) {
            handleTrendNav(dir);
        }
    }

    return (
        <div className='equip-trend-controls'>
            <div className='equip-trend-toggles'>
                {
                    toggleNodes.map((tog: any, togIndex: number) => (
                        <div
                            className={`equip-trend-tog${intervals.indexOf(interval) >= togIndex ? ' active-equip-trend-tog' : ''}`}
                            onClick={() => navInterval(togIndex)}>
                            <img src={`${imgUrl}/${tog.img}.png`} />
                        </div>
                    ))
                }
            </div>
            <div className='equip-trend-toggles' style={{ justifyContent: 'center' }}>
                {
                    validNav('back') 
                    ?
                    <div
                        className={`equip-trend-tog equip-trend-dir active-equip-trend-tog`}
                        onClick={() => handleNav('back')}>
                        <h2 style={{marginRight: '5px'}}> {'<'} </h2>
                    </div>
                    :
                    <div className={`equip-trend-tog equip-trend-dir`}>
                        <h2 style={{marginRight: '5px'}}> {'<'} </h2>
                    </div>
                }
                {
                    validNav('forward')
                    ?
                    <div
                        className={`equip-trend-tog equip-trend-dir active-equip-trend-tog`}
                        onClick={() => handleNav('forward')}>
                        <h2 style={{marginLeft: '5px'}}> {'>'} </h2>
                    </div>
                    :
                    <div className={`equip-trend-tog equip-trend-dir`}>
                        <h2 style={{marginLeft: '5px'}}> {'>'} </h2>
                    </div>
                }
            </div>
        </div>
    )
}

export default TrendControls;