import { createContext } from "react";

export interface AppContextType {
    appData: {[key:string]:any}
    setAppData:(appData:{[key:string]:any}) => void;
}

export const AppContext = createContext<AppContextType>({
    appData: {},
    setAppData: () => {}
})