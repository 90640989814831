import { useEffect, useState } from "react";
import cToF from 'celsius-to-fahrenheit';

import { useEquipment } from "../../hooks/useEquipmentContext";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl, measSet, setStatus } from "../../templates/consts";

interface Props {
    stageTog: number;
    sec: any;
}

const MobDiff: React.FC<Props> = ({ stageTog, sec }) => {

    const [equip] = useEquipment();
    const [sessionData] = useSession();

    const [diffDp, setDiffDp] = useState<any>(null);

    const parseAlertClass = (dp: any, rdg: any) => {
        if (!dp.alerts || !dp.meas[rdg]) return '';
        let tier = 'good';
        ['bad', 'crit'].forEach(tr => {
            if (dp.alerts[rdg][tr]) tier = tr;
        });
        return ` ${tier}-equip-rdg`;
    }

    const setVal = (dp: any, rdg: any) => {
        if (!dp.meas || dp.meas[rdg] === '-') return '-'
        let val = dp.meas[rdg];
        if (typeof val !== 'number') val = parseInt(val);
        // return rdg == 'htp' && sessionData.imperial ? parseInt(cToF(val)) : val
        return rdg === 'dpr' && sessionData.iwg ? (parseFloat((val * 0.0040147).toString())).toFixed(3) : val;
    }

    const setUnits = (rdg: string) => {
        if (rdg === 'days') return 'days'
        return sessionData.iwg ? 'iwg' : 'Pa'
    }

    useEffect(() => {
        const allDps = equip.datapoints;
        if (allDps.length) {
            let secDps = allDps.filter((dp: any) => dp.sectionIndex === equip.sections.indexOf(sec));
            if (secDps && secDps.length && sec.diffSensor) {
                const differentialDp = secDps.find((dp: any) => dp.apsn === sec.diffSensor);
                if (differentialDp) setDiffDp(differentialDp);
            }
        }
    }, []);

    return (
        <div className='mob-sec-aps' style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
            {
                !diffDp &&
                <div className='mob-diff-empty' style={{opacity: 0}}>
                    <h5>No Installed AirPulse is taking differential measurements</h5>
                </div>
            }
            {
                diffDp &&
                <>
                    <div
                        className='mob-diff-span'
                        style={{
                            background: setStatus('dpr', diffDp.alerts ? diffDp.alerts.dpr : false)
                        }}>
                    </div>
                    <div className='mob-diff-rdgs'>
                    {
                        ['days', 'dpr'].map((rdg: string) => (
                            <div className='mob-ap-equip-rdg' style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                                <div className='ap-equip-head'>
                                    {/* <img src={`${imgUrl}/${rdg === 'htp' ? 'temp' : rdg}.png`} /> */}
                                    <img src={`${imgUrl}/${diffDp.config === 'coil' && rdg === 'days' ? 'coilFlow' : rdg}.png`} />
                                </div>
                                <h6 style={{fontSize: '.75em', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, marginLeft: '5px'}}>Learning</h6>
                            </div>
                            // <div className={`mob-ap-equip-rdg${parseAlertClass(diffDp, rdg)} mob-diff-rdg`}>
                            //     <div className='ap-equip-head'>
                            //         {/* <img src={`${imgUrl}/${rdg === 'htp' ? 'temp' : rdg}.png`} /> */}
                            //         <img src={`${imgUrl}/${diffDp.config === 'coil' && rdg === 'days' ? 'coilFlow' : rdg}.png`} />
                            //     </div>
                            //     <h6 style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            //         {setVal(diffDp, rdg)}
                            //         <span style={{ fontSize: '.75em' }}>
                            //             {rdg == 'htp' && sessionData.imperial ? '˚F' : setUnits(rdg)}
                            //         </span>
                            //     </h6>
                            // </div>
                        ))
                    }
                    </div>
                </>
            }
        </div>
    )
}

export default MobDiff;