import { useState } from "react";
import moment from 'moment';

import { intervals } from "./Trend";
import { imgUrl } from "../../templates/consts";

interface Props {
    interval: number;
    setInterval: (newInterval: number) => void;
    breadcrumbs: any[];
    handleTrendNav: (dir: string) => void;
    tmsRange:any[];
}

const toggleNodes = [
    {
        img: 'calendar',
        title: 'Daily'
    },
    {
        img: 'hour',
        title: 'Hourly'
    },
    {
        img: 'realtime',
        title: 'Realtime'
    }
]

const TrendControls: React.FC<Props> = ({ interval, setInterval, breadcrumbs, handleTrendNav, tmsRange }) => {
    const [hoverIndex, setHoverIndex] = useState<number>(-1);
    const navInterval = (index: number) => {
        if (index < intervals.indexOf(interval)) {
            setInterval(intervals[index]);
        }
    }
    const validNav = (dir: string) => {
        const minDate = Math.floor(new Date('2023-10-07').getTime() / 1000);
        const intIndex = intervals.indexOf(interval);
        const now = Math.floor(Date.now()/1000);
        let crumb = parseInt(tmsRange[intIndex])
        if (interval === 86400) {
            if (crumb - (86400 * 10) >= minDate && dir === 'back') return ' active-trend-nav-node';
            if (parseInt(tmsRange[tmsRange.length - 1]) + 86400 <= now && dir === 'forward') return ' active-trend-nav-node';
            return '';
        }
        else {
            if (dir == 'back') {
                if (breadcrumbs[intervals.indexOf(interval)] - interval * 60 >= minDate) {
                    return ' active-trend-nav-node';
                } else {
                    return '';
                }
            } else {
                const latest = Math.round(new Date().getTime() / 1000) - 86400;
                if (breadcrumbs[intervals.indexOf(interval)] < latest) {
                    return ' active-trend-nav-node';
                } else {
                    return '';
                }
            }
        }
    }
    const handleNav = (dir: string) => {
        if (validNav(dir).length) {
            handleTrendNav(dir);
        }
    }
    return (
        <div className='dp-trend-controls'>
            <div className='dp-trend-tiers'>
                {
                    toggleNodes.map((tog: any, togIndex: number) => (
                        <div
                            className={`dp-trend-tog${intervals.indexOf(interval) >= togIndex ? ' active-trend-tog' : ''}`}
                            onMouseOver={() => setHoverIndex(togIndex)}
                            onMouseLeave={() => setHoverIndex(-1)}
                            onClick={() => navInterval(togIndex)}
                            style={{ background: intervals.indexOf(interval) === togIndex ? 'var(--prim-transparent)' : '' }}>
                            <img src={`${imgUrl}/${tog.img}.png`} />
                            {
                                hoverIndex === togIndex || intervals.indexOf(interval) == togIndex &&
                                <h5>{tog.title}</h5>
                            }
                        </div>
                    ))
                }
            </div>
            <div className='dp-trend-nav'>
                <div
                    className={`trend-nav-node${validNav('back')}`}
                    onClick={() => handleNav('back')}>
                    <h2>{'<'}</h2>
                </div>
                <div
                    className={`trend-nav-node${validNav('forward')}`}
                    onClick={() => handleNav('forward')}>
                    <h2>{'>'}</h2>
                </div>
            </div>
        </div>
    )
}

export default TrendControls;