import { useEffect, useRef, useState } from "react";
import moment from 'moment';

import { imgUrl } from "../../templates/consts";
import { useEquipment } from "../../hooks/useEquipmentContext";
import ExpandMenu from "./ElementExpandMenu";
import { actions, info } from "../../templates/equipmentComponentTemplate";
import ElemInspector from "./ElemInspector";

interface Props {
    elem: any;
    elemIndex: number;
    secIndex: number;
    bankState: string;
    setFocusElem: (newFocus: { secIndex: number, elemIndex: number }) => void;
}

const momentify = (tms: number) => {
    return moment(tms).format('MMM-DD @ h:mma');
}

const EquipElem: React.FC<Props> = ({ elem, elemIndex, secIndex, bankState, setFocusElem }) => {

    const ref = useRef(null);

    const [equip, , , , , , , updateConfigElem, , removeElem] = useEquipment();
    const [rect, setRect] = useState<any>(null);
    const [sec, setSec] = useState<any>(null);

    const initRect = () => {
        if (ref.current) {
            let current = ref.current as HTMLElement;
            setRect(current.getBoundingClientRect());
        }
    }

    useEffect(() => {
        if (!sec) {
            const sections = equip.sections;
            setSec(sections[secIndex]);
        }
    }, [equip]);

    useEffect(() => {
        initRect();
    }, [])

    return (
        <div
            ref={ref}
            className='hvac-elem'
            style={{ opacity: equip.dragElem && equip.dragElem[0] == secIndex && equip.dragElem[1] == elemIndex ? 0 : '' }}
            draggable={false}>
            <img
                src={`${imgUrl}/equip-${elem.img}-min${elem.img.includes('coil') ? '-2' : ''}.png`}
                onClick={e => setFocusElem({ secIndex: secIndex, elemIndex: elemIndex })}
                draggable={false} />
        </div>
    )
}

export default EquipElem;