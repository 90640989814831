import { isValidNumber } from 'libphonenumber-js';

const transformProperNoun = (improper: string) => {
    let str = improper.toLowerCase();
    let strArr: string[] = str.split('');
    const indexArr = strArr.map((char: string, charIndex: number) => [char, charIndex]).filter((charSet: any[]) => charSet[0] == ' ');
    indexArr.forEach((charSet: any[]) => {
        if (charSet[1] != strArr.length - 1) strArr[charSet[1] + 1] = strArr[charSet[1] + 1].toUpperCase();
    });
    strArr[0] = strArr[0].toUpperCase();
    const retStr = strArr.join('');
    return retStr;
}

const validateNumber = (num: string) => {
    const regex = /[^0-9]/g;
    if (regex.test(num)) throw 'Must include only integers'
}

const validateEmail = (email: string) => {
    // const adminEmails = [];
    // if (adminEmails.includes(email)) {
    //     return email;
    // }
    // const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|biz)$/;
    // if (regex.test(email)) throw 'Only business emails are valid'
}

export const validatePhoneNumber = (phoneNo: string) => {
    // const validNumber = isValidNumber(phoneNo);
    // if (!validNumber) throw ('Not a valid phone number')
}


export const parseInputContent = (input: any, value: string, override?:boolean) => {
    try {
        if (value.length) {
            if (override) return value;
            if (input.textType == 'properNoun') value = transformProperNoun(value);
            if (input.textType == 'number') validateNumber(value);
            if (input.textType == 'email') validateEmail(value);
            // if (input.prop == 'phone_number') validatePhoneNumber('+' + value);
        }
        return value;
    } catch (parseInputContentErr) {
        console.error({ parseInputContentErr })
        throw parseInputContentErr;
    }
}