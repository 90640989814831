import { useEffect, useRef, useState } from "react";
import { useEquipment } from "../../hooks/useEquipmentContext";
import EquipElem from "./EquipElem";
import EquipFan from "./EquipFan";
import SectionAP from "./SectionAP";
import EquipDiff from "./EquipDiff";
import ElemInspector from "./ElemInspector";
import { imgUrl } from "../../templates/consts";
import ElemForm from "./ElemForm";

interface Props {
    sec: any;
    showStage: boolean;
    radialDp: string;
    setRadialDp: (newRadialDp: string) => void;
    bankState: string;
    delOverlay?: number;
    focusElem: { secIndex: number, elemIndex: number }
    setFocusElem: (newFocus: { secIndex: number, elemIndex: number }) => void;
}

const Section: React.FC<Props> = ({ sec, showStage, radialDp, setRadialDp, bankState, delOverlay, focusElem, setFocusElem }) => {

    const ref = useRef<HTMLDivElement>(null);

    const [equip, , , addElem, , addAP, , updateConfigElem, , removeElem] = useEquipment();

    const [dragHover, setDragHover] = useState<boolean>(false);
    const [apGhost, setApGhost] = useState<string>('');
    const [newFilter, setNewFilter] = useState<boolean>(false);
    const [delDp, setDelDp] = useState<any>(null);
    const [maintInit, setMaintInit] = useState<any>(false);

    const handleSectionDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragHover(false);
        if (equip.holdElem && assessValidDrop()) {
            const elem = equip.holdElem;
            const stageType = sec.elems[0].type;
            if (stageType == equip.holdElem.type) {
                addElem(sec.index);
                // setFocusElem({ secIndex: equip.sections.map((s: any) => s.id).indexOf(sec.id), elemIndex: sec.elems.length });
                if (elem.type == 'Filter') setNewFilter(true);
            }
        }
        if (equip.holdAP) {
            addAP(sec.index, apGhost == 'left' ? 'up' : 'down');
            setApGhost('');
        }
    }

    const handleSectionDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (equip.holdElem) {
            const stageType = sec.elems[0].type;
            if (stageType == equip.holdElem.type && assessValidDrop()) setDragHover(true);
        } else if (equip.holdAP) {
            const refRect = ref.current?.getBoundingClientRect();
            if (refRect) {
                const half = refRect.x + refRect.width / 2;
                const clientX = e.clientX;
                const left = clientX < half;
                const secDps = equip.datapoints.filter((dp: any) => dp.sectionIndex == sec.index) ?? [];
                if (left && !secDps.find((dp: any) => dp.loc == 'up')) {
                    setApGhost('left');
                }
                if (!left && !secDps.find((dp: any) => dp.loc == 'down')) {
                    setApGhost('right');
                }
            }
        } else if (equip.dragElem) {

        }
    }

    const assessValidDrop = () => {
        if (equip.holdElem) {
            const stageType = sec.elems[0].type;
            if (stageType == 'Fan') {
                return !(sec.elems.length >= 1);
            } else if (sec.elems.length > 2) {
                return false;
            } else if (stageType != equip.holdElem.type) {
                return false;
            }
            return true;
        }
    }

    const handleSectionDragLeave = (e: any) => {
        setDragHover(false);
        setApGhost('');
    }

    const initDelDp = (dp: any) => {
        setDelDp(dp);
    }

    const closeInspector = () => {
        setFocusElem({ secIndex: -1, elemIndex: -1 });
        setDelDp(null);
    }

    const resetForm = () => {
        setNewFilter(false);
        setFocusElem({ secIndex: -1, elemIndex: -1 });
        setMaintInit(false);
    }

    return (
        <div
            className='hvac-section'
            ref={ref}
            style={{
                border: showStage ? '' : '2px solid rgba(0,0,0,0)',
                background: dragHover ? 'rgba(111, 111, 111, 0.7)' : '',
                height: bankState != '' ? '95%' : '',
                overflow: delDp || newFilter || focusElem.secIndex === equip.sections.indexOf(sec) ? 'hidden' : ''
                // marginBottom: bankState != '' ? '3%' : ''
            }}
            onDrop={e => handleSectionDrop(e)}
            onDragOver={e => handleSectionDragOver(e)}
            onDragLeave={e => handleSectionDragLeave(e)}>
            {showStage && <h4 className='sec-title'>{sec.elems[0].type} Stage</h4>}
            <div className='hvac-sec-main'>
                {
                    window.innerWidth > 767 &&
                    <SectionAP
                        sec={sec}
                        left={true}
                        ghost={apGhost}
                        radialDp={radialDp}
                        setRadialDp={setRadialDp}
                        bankState={bankState}
                        initDelDp={initDelDp} />
                }
                <div className='hvac-elems'>
                    {
                        sec.elems[0].type != 'Fan'
                            ? sec.elems?.length && sec.elems.map((elem: any, elemIndex: number) => (
                                <EquipElem
                                    elem={elem}
                                    elemIndex={elemIndex}
                                    secIndex={equip.sections.indexOf(sec)}
                                    bankState={bankState}
                                    setFocusElem={setFocusElem} />
                            ))
                            : <EquipFan
                                bankState={bankState}
                                secIndex={equip.sections.indexOf(sec)}
                                setFocusElem={setFocusElem} />
                    }
                </div>
                {
                    window.innerWidth > 767 &&
                    <SectionAP
                        sec={sec}
                        left={false}
                        ghost={apGhost}
                        radialDp={radialDp}
                        setRadialDp={setRadialDp}
                        bankState={bankState}
                        initDelDp={initDelDp} />
                }
            </div>
            {
                sec.diffSensor && window.innerWidth > 767 &&
                <div className='hvac-footer' style={{ width: bankState == '' ? 'auto' : '' }}>
                    <EquipDiff sec={sec} bankState={bankState} />
                </div>
            }
            {
                (focusElem.secIndex == equip.sections.indexOf(sec) || delDp) && !maintInit &&
                <div className='section-blur expand-blur'
                    style={
                        window.innerWidth > 767
                            ? {
                                width: '100%',
                                height: '100%'
                            }
                            : {
                                position: 'fixed',
                                width: '100vw',
                                height: 'calc(100vh - 150px)',
                                top: '80px'
                            }
                    }>
                    <ElemInspector
                        sec={sec}
                        secIndex={focusElem.secIndex}
                        elem={sec.elems[focusElem.elemIndex]}
                        elemIndex={focusElem.elemIndex}
                        updateConfigElem={() => closeInspector()}
                        removeElem={removeElem}
                        dp={delDp || false}
                        initMaint={setMaintInit} />
                </div>
            }
            {
                (newFilter || (focusElem.secIndex == equip.sections.indexOf(sec) && maintInit)) &&
                <div
                    className='section-blur expand-blur'
                    style={
                        window.innerWidth > 767
                            ? {
                                width: '100%',
                                height: '100%'
                            }
                            : {
                                position: 'fixed',
                                width: '100vw',
                                height: 'calc(100vh - 150px)',
                                top: '80px'
                            }
                    }>
                    <ElemForm
                        secIndex={equip.sections.indexOf(sec)}
                        resetForm={() => resetForm()}
                        elemIndex={focusElem.elemIndex ?? false}
                        maintInit={maintInit} />
                </div>
            }
        </div >
    )

}

export default Section;