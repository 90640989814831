import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import { useNavigate } from "react-router-dom";

interface Props {
    equip:any;
}

const ServedZones: React.FC<Props> = ({ equip }) => {

    const nav = useNavigate();
    
    const [sessionData, , , getSessionEntity] = useSession();

    const [servedZones, setServedZones] = useState<any>(null);

    const initZones = async () => {
        const floor = await getSessionEntity('floor', equip.fid);
        const bldg = await getSessionEntity('building', floor.bid);
        const zones = bldg.floors.reduce((allZones: any[], floor: any) => {
            if (floor.zones?.length) {
                allZones = allZones.concat(floor.zones.filter((z:any) => z.servedBy && z.servedBy.id === equip.id));
            }
            return allZones;
        }, []);
        if (zones.length) setServedZones(zones);
    }

    const handleZoneNav = (z:any) => {
        nav('/floor');
        setTimeout(() => nav(`/zone/${z.id}`), 500);
    }

    useEffect(() => {
        initZones();
    }, [])

    return (
        <div className='equip-zones-served'>
        {/* 
            {
                !servedZones || servedZones.length &&
                <div className='served-zone no-served-zone'>
                    <h4 style={{color: 'gray'}}>No Zones Assigned</h4>
                </div>  
            } */}
            <div className='served-zone title-served-zone'>
                <h4>Zones Served</h4>
            </div>
            {
                servedZones && servedZones.length &&
                servedZones.map((z: any) => (
                    <div className='served-zone' onClick={() => handleZoneNav(z)}>
                        <h4>{z.name}</h4>
                    </div>
                ))
            }
        </div>
    )

}

export default ServedZones;