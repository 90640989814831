import React, { useState } from 'react';
import { useForm } from '../../hooks/useFormContext';

import '../../stylesheets/Drawer.css';

interface Props {
    stepCount:number| undefined;
    cur:number;
    setStep:(newStep:number) => void;
    submitFinal: () => void;
}
const Drawer:React.FC<Props> = ({ stepCount, cur, setStep, submitFinal }) => {
    const [ formData, updateFormData ] = useForm();

    return (
        <div className='drawer-panel' style={ {border: stepCount?.toString() && (cur + 1) == stepCount ? '2px solid var(--prim)' : '', borderTop: 'none'} }>
            <div className='btns'>
                {
                    stepCount?.toString() && (cur + 1) < stepCount &&
                    <a 
                        className='btn sec-btn'
                        onClick={ () => setStep(cur + 1) }>Next</a>
                }
                {
                    stepCount?.toString() && (cur + 1) == stepCount &&
                    <a className='btn prim-btn' onClick={ () => submitFinal() }>Submit</a>
                }
            </div>
        </div>
    )
};

export default Drawer;