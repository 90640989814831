import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl } from "../../templates/consts";
import cToF from 'celsius-to-fahrenheit';
import { defaultAlertThresholds } from "../../templates/consts";

interface Props {
    equip: any;
    screenHeight: number;
    activeZone: any;
    setActiveZone: (newActiveZone: any) => void;
    month: any;
}

const MonthlyEquip: React.FC<Props> = ({ equip, screenHeight, activeZone, setActiveZone, month }) => {

    const [sessionData, , , getSessionEntity] = useSession();
    const [sessionZone, setSessionZone] = useState<any>(null);
    const [supplyAp, setSupplyAp] = useState<any>(null);
    const [returnAp, setReturnAp] = useState<any>(null);
    const [workingHours, setWorkingHours] = useState<boolean>(true);
    const [active, setActive] = useState<boolean>(false);

    const findAvgDpBySection = (sZone:any, elemType: string) => {
        const sec = sZone.sections.find((sec: any) => sec.elems[0].type === elemType) || false;
        if (sec) {
            const sessionDp = sZone.datapoints.find((dp: any) => dp.apsn === sec.diffSensor);
            const monthDp = equip.datapoints.find((edp: any) => edp.dpid === sessionDp.id);
            const zid = sZone.id;
            return monthDp;
        } 
        return false;
    }

    const calcTemp = (rawTemp: string | number|undefined) => {
        if (rawTemp) {
            if (typeof rawTemp === 'string') rawTemp = parseInt(rawTemp);
            return sessionData.imperial ? parseInt(cToF(rawTemp)) : parseHum(rawTemp);
        } else {
            return '-';
        }
    }

    const parseHum = (hum: number) => {
        return hum.toString().split('.')[0];   
    }

    const genStyle = (val: any, rdg: string, dpid: string) => {
        if (val) {
            let style:any = {h4:{}, div:{}};
            const hi = parseInt(val.hi);
            const lo = parseInt(val.lo);
            const avg = parseInt(val.avg);
            const diff = hi - lo;
            const offsetLo = avg - lo;
            const alertColor = getAvgAlert(rdg, dpid);
            style.h4.border = `2px solid var(--${alertColor})`;
            style.h4.borderTop = 'none';
            let percOffset = Math.round(offsetLo / diff * 100) - 7;
            if (percOffset > 50) percOffset = 50;
            if (percOffset < 22) percOffset = 22;
            style.div.bottom = `${percOffset}%`
            style.header = {
                background: `var(--${alertColor}-transparent)`,
                border: `2px solid var(--${alertColor})`,
                borderBottom: 'none'
            };
            if (screenHeight >= 400 && screenHeight <= 880) {
                style.header.borderBottom = `2px solid var(--${alertColor})`;
                style.header.borderRadius = '3px';
                style.header.padding = '2px';
                style.header.height = '14px'
            }
            // const perc = offset / diff * 100;
            return style;
        } else {
            return {};
        }
    }

    const assessDapStyle = (days: string | false) => {
        let div = { border: '2px solid var(--breezi-blue)' };
        let icon = {background: 'var(--breezi-blue)', borderRight: '2px solid var(--breezi-blue)'}
        if (days) {
            let color;
            const d = parseInt(days);
            if (d >= 25) {
                color = 'prim'
            } else if (d >= 10) {
                color = 'cancel';
            } else {
                color = 'crit';
            }
            div = {border: `2px solid var(--${color})`};
            icon = { background: `var(--${color}-transparent)`, borderRight: `2px solid var(--${color})` }
        }
        return { div, icon };
    }

    const getAvgAlert = (rdg: string, dpid:string) => {
        const alertThresholds = defaultAlertThresholds as any;
        const thresh = alertThresholds[rdg];
        const ap = [supplyAp, returnAp].find((airPulse:any) => airPulse.dpid === dpid);
        const val = parseInt(ap.meas.workingHoursAvg[rdg].avg)
        const foundThresh = thresh.find((t: [number, number]) => val >= t[0] && val <= t[1]);
        const threshIndex = thresh.indexOf(foundThresh);
        if ([0, 4].includes(threshIndex)) return 'crit';
        if ([1, 3].includes(threshIndex)) return 'cancel';
        if (threshIndex === 2) return 'prim';
        return 'breezi-blue';
    }

    const convertDelta = (val: number, sys: string) => {
        const frac = sys === 'c' ? 5/9 : 9/5;
        return Math.round(val * frac);
    }

    const calcDeltaTemp = (val:number) => {
        return !sessionData.imperial
            ? val
            : convertDelta(val, 'i')
    }

    const positionDeltaNode = (temp:number) => {
        let perc = Math.round(temp / 18 * 100);
        if (perc > 85) perc = 85;
        return `${perc > 100 ? 100 : perc}%`;
    }

    useEffect(() => {
        (async () => {
            const sZone = await getSessionEntity('zone', equip.zid);
            setSessionZone(sZone);
            setSupplyAp(findAvgDpBySection(sZone, 'Coil'));
            setReturnAp(findAvgDpBySection(sZone, 'Filter'));
        })();
    }, []);

    useEffect(() => {
        setActive(activeZone?.zid === equip.zid);
    }, [activeZone]);

    return sessionZone && supplyAp && returnAp && (
        <div
            className='monthly-zone monthly-equip'
            style={{ position: 'relative'}}
            onClick={() => setActiveZone(equip)}>
            <header className='floor-zone-header'>
                <div className='floor-zone-icon'>
                    <img src={ `${imgUrl}/equip.png` } />
                </div>
                <div className='floor-zone-title' style={{marginLeft: window.innerHeight >= 400 && window.innerHeight <= 700 && window.innerWidth >= 1100 ? '10px' : ''}}>
                    <h3>{sessionZone.name}</h3>
                    <h5>{equip.datapoints.length ?? 0} Datapoint{!equip.datapoints || equip.datapoints.length !== 1 ? 's' : ''}</h5>
                </div>
            </header>
            <div className='monthly-equip-body'>
                <div className='monthly-equip-half'>
                    <h4>Return Air</h4>
                    <div
                        className='equip-avg-dap' style={assessDapStyle(returnAp.meas.dapAvg.lifeExpectancy.avg ?? false).div}>
                        <img style={assessDapStyle(returnAp.meas.dapAvg.lifeExpectancy.avg ?? false).icon} src={`${imgUrl}/filterOfficial.png`} />
                        <h5>{returnAp.meas.dapAvg.lifeExpectancy.avg} days</h5>
                    </div>
                    <div className='equip-avgs'>
                        <div className='equip-hi-lo-avg'>
                            <div className='equip-hi-lo equip-hi'>
                                <h5>{calcTemp(returnAp.meas?.workingHoursAvg?.htp?.hi) ?? '-'}˚</h5>
                            </div>
                            <div
                                className='equip-avg'
                                style={genStyle(returnAp.meas.workingHoursAvg.htp, 'htp', returnAp.dpid).div}>
                                <header className='equip-avg-header' style={genStyle(returnAp.meas.workingHoursAvg.htp, 'htp', returnAp.dpid).header}>
                                    <img src={`${imgUrl}/temp.png`} />
                                    {
                                        (screenHeight < 400 || screenHeight > 880) &&
                                        <h6>AVG</h6>
                                    }
                                    {
                                        (screenHeight >= 400 && screenHeight <= 880) && returnAp.meas?.workingHoursAvg.htp?.avg &&
                                        <h5>{calcTemp(returnAp.meas.workingHoursAvg.htp.avg)}˚</h5>
                                    }
                                </header>
                                {
                                    (screenHeight < 400 || screenHeight > 880) && returnAp.meas?.workingHoursAvg.htp?.avg &&
                                    <h5
                                        style={genStyle(returnAp.meas.workingHoursAvg.htp, 'htp', returnAp.dpid).h4}>
                                        {calcTemp(returnAp.meas.workingHoursAvg.htp.avg)}˚
                                    </h5>
                                }
                            </div>
                            <div className='equip-hi-lo equip-lo'>
                                {
                                    returnAp.meas?.workingHoursAvg.htp?.lo && <h5>{calcTemp(returnAp.meas.workingHoursAvg.htp.lo)}˚</h5>
                                }
                            </div>
                        </div>
                        <div className='equip-hi-lo-avg'>
                            <div className='equip-hi-lo equip-hi'>
                                {
                                    returnAp.meas?.workingHoursAvg.htp?.hi && <h5>{parseHum(returnAp.meas.workingHoursAvg.hhm.hi)}%</h5>
                                }
                            </div>
                            <div
                                className='equip-avg'
                                style={genStyle(returnAp.meas.workingHoursAvg.hhm, 'hhm', returnAp.dpid).div}>
                                <header className='equip-avg-header' style={genStyle(returnAp.meas.workingHoursAvg.hhm, 'hhm', returnAp.dpid).header}>
                                    <img src={`${imgUrl}/hhm.png`} />
                                    {
                                        (screenHeight < 400 || screenHeight > 880) &&
                                        <h6>AVG</h6>
                                    }
                                    {
                                        (screenHeight >= 400 && screenHeight <= 880) && returnAp?.meas?.workingHoursAvg?.hhm?.avg &&
                                        <h5>{parseHum(returnAp.meas.workingHoursAvg.hhm.avg)}%</h5>
                                    }
                                </header>
                                {
                                    (screenHeight < 400 || screenHeight > 880) && returnAp?.meas?.workingHoursAvg?.hhm?.avg &&
                                    <h5
                                        style={genStyle(returnAp.meas.workingHoursAvg.hhm, 'hhm', returnAp.dpid).h4}>
                                        {parseHum(returnAp.meas.workingHoursAvg.hhm.avg)}%
                                    </h5>
                                }
                            </div>
                            <div className='equip-hi-lo equip-lo'>
                                {
                                    returnAp?.meas?.workingHoursAvg?.hhm?.lo && <h5>{parseHum(returnAp.meas.workingHoursAvg.hhm.lo)}%</h5>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='monthly-equip-sep'></div>
                <div className='monthly-equip-half'>
                    <h4>Supply Air</h4>
                    <div
                        className='equip-avg-dap' style={
                            month.includes('February') || month.includes('March') || month.includes('April')
                                ? {border: `2px solid var(--prim)`}
                                : assessDapStyle(supplyAp.meas.dapAvg.lifeExpectancy.avg ?? false).div}>
                        <img style={
                            month.includes('February') || month.includes('March') || month.includes('April')
                            ? { background: `var(--prim-transparent)`, borderRight: `2px solid var(--prim)` }
                            : assessDapStyle(supplyAp.meas.dapAvg.lifeExpectancy.avg ?? false).icon
                        } src={`${imgUrl}/coilFlow.png`} />
                        <h5 style={{
                            fontSize: month.includes('February') || month.includes('March') || month.includes('April') ? '.75em' : '',
                            marginRight: month.includes('February') || month.includes('March') || month.includes('April') ? '3px' : ''
                        }}>{month.includes('February') || month.includes('March') || month.includes('April') ? '>270' : supplyAp.meas.dapAvg.lifeExpectancy.avg} days</h5>
                    </div>
                    <div className='equip-avgs'>
                        <div className='equip-hi-lo-avg'>
                            <div className='equip-hi-lo equip-hi'>
                                {
                                    returnAp?.meas?.workingHoursAvg?.hhm?.hi &&
                                    <h5>{calcTemp(supplyAp.meas.workingHoursAvg.htp.hi)}˚</h5>
                                }
                            </div>
                            <div
                                className='equip-avg'
                                style={genStyle(supplyAp.meas.workingHoursAvg.htp, 'htp', supplyAp.dpid).div}>
                                <header className='equip-avg-header' style={genStyle(supplyAp.meas.workingHoursAvg.htp, 'htp', supplyAp.dpid).header}>
                                    <img src={`${imgUrl}/temp.png`} />
                                    {
                                        (screenHeight < 400 || screenHeight > 880) &&
                                        <h6>AVG</h6>
                                    }
                                    {
                                        (screenHeight >= 400 && screenHeight <= 880) && supplyAp?.meas?.workingHoursAvg?.htp?.avg &&
                                        <h5>{calcTemp(supplyAp.meas.workingHoursAvg.htp.avg)}˚</h5>
                                    }
                                </header>
                                {
                                    (screenHeight < 400 || screenHeight > 880) && supplyAp?.meas?.workingHoursAvg?.htp?.avg &&
                                    <h5
                                        style={genStyle(supplyAp.meas.workingHoursAvg.htp, 'htp', supplyAp.dpid).h4}>
                                        {calcTemp(supplyAp.meas.workingHoursAvg.htp.avg)}˚
                                    </h5>
                                }
                            </div>
                            <div className='equip-hi-lo equip-lo'>
                                {
                                    supplyAp?.meas?.workingHoursAvg?.htp?.lo && <h5>{calcTemp(supplyAp.meas.workingHoursAvg.htp.lo)}˚</h5>
                                }
                            </div>
                        </div>
                        {/* <div className='hi-lo-stretch'>
                            <div className='stretcher stretcher-hi' style={{top: '15px'}}>
                                <h5>HIGH</h5>
                            </div>
                            <div className='stretcher stretcher-lo'>
                                <h5>LOW</h5>
                            </div>
                        </div> */}
                        <div className='equip-hi-lo-avg'>
                            <div className='equip-hi-lo equip-hi'>
                                {
                                    supplyAp?.meas?.workingHoursAvg?.hhm?.hi && <h5>{parseHum(supplyAp.meas.workingHoursAvg.hhm.hi)}%</h5>
                                }
                            </div>
                            <div
                                className='equip-avg'
                                style={genStyle(supplyAp.meas.workingHoursAvg.hhm, 'hhm', supplyAp.dpid).div}>
                                <header className='equip-avg-header' style={genStyle(supplyAp.meas.workingHoursAvg.hhm, 'hhm', supplyAp.dpid).header}>
                                    <img src={`${imgUrl}/hhm.png`} />
                                    {
                                        (screenHeight < 400 || screenHeight > 880) &&
                                        <h6>AVG</h6>
                                    }
                                    {
                                        (screenHeight >= 400 && screenHeight <= 880) && supplyAp?.meas?.workingHoursAvg?.hhm?.avg &&
                                        <h5>{parseHum(supplyAp.meas.workingHoursAvg.hhm.avg)}</h5>
                                    }
                                </header>
                                {
                                    (screenHeight < 400 || screenHeight > 880) && supplyAp?.meas?.workingHoursAvg?.hhm?.avg &&
                                    <h5
                                        style={genStyle(supplyAp.meas.workingHoursAvg.hhm, 'hhm', supplyAp.dpid).h4}>
                                        {parseHum(supplyAp.meas.workingHoursAvg.hhm.avg)}%
                                    </h5>
                                }
                            </div>
                            <div className='equip-hi-lo equip-lo'>
                                {
                                    supplyAp?.meas?.workingHoursAvg?.hhm?.lo &&
                                    <h5>{parseHum(supplyAp.meas.workingHoursAvg.hhm.lo)}%</h5>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='equip-delta'>
                <div className='equip-delta-icon'>
                    <h1>∆</h1>
                    <img src={`${imgUrl}/temp.png`} />
                </div>
                <div className='equip-delta-span'>
                    <div className='equip-delta-bar'>
                        <div 
                            className='equip-delta-node avg-node'
                            style={{left: positionDeltaNode(equip.datapoints[0]?.meas.workingHoursAvg?.deltaTemp?.avg)}}>
                            <h5>
                                {calcDeltaTemp(equip.datapoints[0]?.meas.workingHoursAvg?.deltaTemp?.avg)}˚
                                <br />
                                <span>AVG</span>
                            </h5>
                        </div>
                        <div 
                            className='equip-delta-node max-node'
                            style={{left: positionDeltaNode(parseFloat(equip.datapoints[0]?.meas.workingHoursAvg?.deltaTemp?.hi))}}>
                            <h5>
                                {calcDeltaTemp(parseFloat(equip.datapoints[0]?.meas.workingHoursAvg?.deltaTemp?.hi))}˚
                                <br />
                                <span>HI</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MonthlyEquip;