import { useEffect, useState } from "react";
import { imgUrl } from "../../templates/consts";
import { useEquipment } from "../../hooks/useEquipmentContext";

interface Props {
    secIndex: number;
    bankState: string;
    setFocusElem: (newFocus: { secIndex: number, elemIndex: number }) => void;
}

const EquipFan: React.FC<Props> = ({ secIndex, bankState, setFocusElem }) => {

    const [equip] = useEquipment();
    const [clusters, setClusters] = useState<any[]>([]);

    useEffect(() => {
        setClusters([]);
        const sec = equip.sections[secIndex];
        const elems = sec.elems;
        let cElems = [...elems];
        let newClusters = [];
        while (cElems.length) {
            if (cElems.length <= 4) {
                newClusters.push(cElems);
                cElems = [];
            } else {
                newClusters.push(cElems.splice(0, 4))
            }
        }
        setClusters(newClusters);
    }, [equip]);

    const elemIndex = (clusterIndex: number, fanIndex: number) => {
        if (clusterIndex == 0) return fanIndex;
        return (clusterIndex * 4) + fanIndex;
    }

    return (
        <>
            {
                equip.sections[secIndex]?.elems && equip.sections[secIndex].elems.length == 1 &&
                <div className='hvac-elem hvac-fan'>
                    <div className='hvac-elem hvac-fan-elem lg-fan-elem'>
                        <img
                            src={`${imgUrl}/equip-${equip.sections[secIndex].elems[0].img}.png`}
                            style={{ width: 'auto', maxWidth: window.innerWidth > 767 ? '240px' : '280px' }}
                            onClick={() => setFocusElem({ secIndex, elemIndex: 0 })} />
                    </div>
                </div>
            }
            {
                equip.sections[secIndex].elems.length > 1 &&
                <div className='hvac-fan-clusters' style={{ width: clusters.length < 3 ? '120px' : '' }}>
                    {
                        clusters.map((fanCluster, fanClusterIndex) => (
                            <div className='hvac-fan-cluster'>
                                {
                                    fanCluster.map((fan: any, fanIndex: number) => (
                                        <div className='hvac-cluster-fan'>
                                            <img
                                                src={`${imgUrl}/equip-${equip.sections[secIndex].elems[fanIndex].img}.png`}
                                                onClick={e => setFocusElem({ secIndex: secIndex, elemIndex: elemIndex(fanClusterIndex, fanIndex) })}
                                                style={{ cursor: 'pointer' }} />
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </>
    )
}

export default EquipFan;