import moment from "moment";
import { getCurrentMonthStart } from "./MonthlyTrend";
import { useEffect, useRef, useState } from "react";

interface Props {
    displayDate: string;
    setDisplayDate: (newDisplayDate: string) => void;
    showMonths:boolean;
    setShowMonths: (newShowMonth: boolean) => void;
    updateMonth:(tms:number) => void;
}

const mons = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const momentMonth = (utc:number) => moment(utc * 1000).format('MMMM, YYYY');

const getMonthList = (current: any, months: any[]): any => {
    const currentArr = current.split(', ');
    // const currentArr = momentMonth(current).split(', ');
    const currentMonth = currentArr[0];
    const currentYear = parseInt(currentArr[1]);
    const mIndex = mons.indexOf(currentMonth);
    let newMonth;
    let newYear = currentYear;
    if (currentMonth === 'January') {
        newYear -= 1;
        newMonth = 'December';
    } else {
        newMonth = mons[mIndex - 1];
    }
    const newMonthStr = `${newMonth}, ${newYear}`;
    months.push(newMonthStr);
    return newMonthStr === 'November, 2023' ? months : getMonthList(newMonthStr, months);
}

const MonthDropdown: React.FC<Props> = ({ displayDate, setDisplayDate, showMonths, setShowMonths, updateMonth }) => {

    const ref = useRef(null);

    const [mList, setMList] = useState<any[]>([]);

    const handleClickOutside = (e: any) => {
        const cur = ref.current as any;
        if (cur && !cur.contains(e.target)) {
            setShowMonths(false);
        }
    };

    const setNewMonth = (m: any) => {
        const splitMonth = m.split(',');
        splitMonth[0] += ' 2';
        m = splitMonth.join(',');
        updateMonth(Math.floor(new Date(m).getTime()/1000))
    }

    useEffect(() => {
        const start = getCurrentMonthStart();
        // const start = 1701734400;
        const monthList = getMonthList(
            momentMonth(start),
            [momentMonth(start)]
        );
        setMList(monthList);
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div ref={ref} className='month-dd' onClick={() => setShowMonths(false)}>
            {
                mList?.length > 0 &&
                mList.map((m: any) => (
                    <div className='report-month' onClick={() => setNewMonth(m)}>
                        <h4 style={{color: 'var(--white)'}}>{ m }</h4>
                    </div>
                ))
            }
        </div>
    )
}

export default MonthDropdown;