import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useFloor } from "../../hooks/useFloorContext";
import { imgUrl, profiles } from "../../templates/consts";
import { useRest } from "../../hooks/useRest";
import { useSession, SessionContext } from '../../hooks/useSession';

const configs = profiles;

const review = [
    {
        prop: 'apsn',
        title: 'AP Serial Number',
    },
    {
        prop: 'name',
        title: 'Datapoint Name',
        alt: 'Dynamic'
    },
    {
        prop: 'config',
        title: 'Configuration Profile',
        alt: 'configs'
    }
]

interface Props {
    zid:string;
    togOb: (tog: boolean) => void;
    fid:string;
}

const ObDatapoint:React.FC<Props> = ({ zid, togOb, fid }) => {

    const { id } = useParams();

    const [ sessionData, , getSessionEntity, , , , postObUpdate ] = useSession();
    const [ , setUtilView, , activeBankAp, , clearApFromBank, getBankIndex ] = useFloor();
    const [ request ] = useRest();

    const [ currentStep, setCurrentStep ] = useState<number>(0);
    const [ obap, setObap ] = useState<any>(null);
    const [ apName, setApName ] = useState<string>('');

    const setApsn = (apsn:any) => setObap({ apsn: apsn.apsn, did: apsn.did });

    useEffect(() => {
        if (activeBankAp && activeBankAp.apsn) setApsn(activeBankAp);
    }, [activeBankAp]);

    useEffect(() => {
        setUtilView(4);
    }, []);

    const submitObAp = async () => {
        let ap = obap;
        ap.zid = zid;
        ap.bankIndex = getBankIndex()
        try {
            const dp = await request('datapoint', 'post', 'floorAp', ap);
            togOb(false);
            const floor = await getSessionEntity('floor', fid, false) as any;
            const zone = await getSessionEntity('zone', zid, false) as any;
            const zIndex = floor.zones.indexOf(zone);
            zone.datapoints.push(dp);
            floor.zones[zIndex] = zone;
            await postObUpdate(floor, 'floor');
            clearApFromBank();
            setUtilView(0);
        } catch(submitObApErr) {
            console.error({ submitObApErr })
        }
    }

    const handleKeyDown = (e:any) => {
        const name = apName.length ? apName : '+|dynamic|+';
        setObap({ ...obap, name });
    }

    return (
        <div className='floor-ob-datapoint'>
            {
                (!obap || !obap.apsn) &&
                <div className="ob-apsn">
                    <small>If you are installing the AirPulse immediately, select its serial number from the bank on the left</small>
                    <h2>OR</h2>
                    <div className='btns'>
                        <a className='btn sec-btn' onClick={ () => setApsn({apsn: 'pending', did: -1}) }>Skip for Now</a>
                    </div>
                </div>
            }
            {
                obap && obap.apsn && !obap.name &&
                <div className='ob-apsn'>
                    <small>Add a custom name for this Datapoint</small>
                    <label className='ob-dp-input' style={ {width: '100%', padding: '0px'} }>
                        <input 
                            style={ {marginRight: '10px'} } 
                            value={ apName } 
                            onChange={ e => setApName(e.target.value) }
                            onKeyDown={ e => handleKeyDown(e) } />
                        {
                            apName.length > 0 &&
                            <a className='check-btn' onClick={ () => setObap({ ...obap, name: apName }) }>
                                <img src={ `${imgUrl}/checked.png` } />
                            </a>
                        }
                    </label>
                    <h2>OR</h2>
                    <div className='btns' style={ {flexDirection: 'column'} }>
                    <small style={ {marginTop: '-20px', marginBottom: '10px'} }>Use a Dynamic Name, which will use the name of any Zone in which the AP is installed (e.g. Hallway B)</small>
                        <a className='btn sec-btn' onClick={ () => setObap({ ...obap, name: '+|dynamic|+' }) }>Dynamic</a>
                    </div>
                </div>
            }
            {
                obap && obap.name && !obap.config &&
                <div className='ob-apsn selector-ob-apsn'>
                    <h4>Choose a Configuration Profile for this Datapoint</h4>
                    {
                        configs.map((config, configIndex) => (
                            <div className='ob-apsn-config' onClick={ () => setObap({ ...obap, config: config.prop }) }>
                                <img src={ `${imgUrl}/${config.img}.png` } />
                                <small>{ config.title }</small>
                            </div>
                        ))
                    }
                </div>
            }
            {
                obap && obap.config &&
                <div className='ob-apsn apsn-review'>
                    <h3>Review and Complete</h3>
                    {
                        review.map((rItem, rItemIndex) => (
                            <div className='ob-ap-review'>
                                <h4>{ rItem.title }</h4>
                                {
                                    !rItem.alt && <small>{ obap[rItem.prop] }</small>
                                }
                                {
                                    rItem.alt == 'Dynamic' && <small>{ obap.name != '+|dynamic|+' ? obap.name : 'Dynamic' }</small>
                                }
                                {
                                    rItem.alt == 'configs' && <small>{ configs.find(c => c.prop == obap[rItem.prop])?.title }</small>
                                }
                            </div>
                        ))
                    }
                    <a className='btn prim-btn' onClick={ () => submitObAp() }>
                        Submit
                    </a>
                </div>
            }
        </div>
    )

}

export default ObDatapoint;