import { createContext, useContext, useEffect } from "react";
import { useSession } from "./useSessionDev";
import { useParams } from "react-router-dom";
import { useRest } from "./useRest";
import { AppContext } from "../context/AppContext";

interface EquipContextType {
  equip: {[key:string]:any};
  setEquip:(equipData:{[key:string]:any}) => void;
}

export const EquipContext = createContext<EquipContextType>({
  equip: {},
  setEquip: () => {}
});

export const useEquipment = (): [
    any,
    (ob: boolean, elem: any) => void,
    (pos: number) => void,
    (secIndex: number) => void,
    (ob: boolean, ap: any) => void,
    (secIndex: number, loc: string) => void,
    (isDiff: boolean, sec?: any) => void,
    (e:any, secIndex: number | string, elemIndex: number) => void,
    (secIndex: number, elemIndex: number) => void,
    (secIndex: number, elemIndex: number) => void,
    (details: any, secIndex: number, elemIndex: number) => void,
    (dp: any) => void,
    (dir: string) => void,
    (refresh:boolean) => void
] => {

    const { id } = useParams();
    
    const { equip, setEquip } = useContext(EquipContext);
    const { appData, setAppData } = useContext(AppContext);

    const [sessionData, , , getSessionEntity, setSessionEntity, , postObUpdate, , , updateApBank] = useSession();
    const [request] = useRest();
    
    const initErr = (title: string, err: any) => {
        console.error({ err });
        err = err as any;
        setAppData({
            ...appData,
            helper: {
                type: 'error',
                title,
                content: err.message
            }
        });
    }

    const setHoldElem = (ob: boolean, elem: any) => {
        elem.maintTms = Math.floor(Date.now()/1000);
        const comb = { ...elem, ob };
        setEquip({ ...equip, holdElem: comb });
    }

    const addSection = async (pos:number) => {
        let newEquip = { ...equip };
        let sections = newEquip.sections;
        let toAdd = equip.holdElem;
        let secIndex = -1;
        const sec = { elems: [toAdd], index: !newEquip.sections.length ? 0 : pos }
        try {
            const posted = await request(`zone/${equip.id}?entity=section`, 'put', '', sec);
            if (!newEquip.sections.length) {
                sections.push(posted);
            } else {
                const lowestIndex = sections.sort((a: any, b: any) => a.index - b.index)[0].index;
                sections[pos < lowestIndex ? 'unshift' : 'push'](posted);
            }
            delete newEquip.holdElem;
            setEquip({ ...newEquip, sections, configElem: {secIndex: sections.indexOf(posted), elemIndex: 0} });
            setSessionEntity('zone', newEquip);
        } catch (addSectionErr) {
            initErr('There was an error adding the new Stage', addSectionErr);
        }
    }

    const addElem = async (secIndex:number) => {
        let newEquip = { ...equip };
        let sections = { ...newEquip }.sections;
        let sec = sections.find((s: any) => s.index == secIndex);
        const hardIndex = sections.indexOf(sec);
        sec.elems.push({ ...newEquip.holdElem });
        try {
            const posted = await request(`zone/${equip.id}?entity=section`, 'put', '', sec);
            sections[hardIndex] = posted;
            newEquip.configElem = newEquip.holdElem;
            delete newEquip.holdElem;
            newEquip.sections = sections;
            setSessionEntity('zone', newEquip);
            newEquip.configElem = { secIndex: hardIndex, elemIndex: posted.elems.length - 1 };
            setEquip(newEquip);
        } catch (addElemErr) {
            initErr('There was an error adding the new Element', addElemErr);
        }
    }

    const setHoldAP = (ob: boolean, ap: any) => {
        const comb = { ...ap, ob };
        setEquip({ ...equip, holdAP: comb });
    }

    const addAP = async (secIndex: number, loc: string) => {
        const bankIndex = sessionData.apBank.map((ap: any) => ap.apsn).indexOf(equip.holdAP.apsn);
        const sec = equip.sections.find((s: any) => s.index == secIndex);
        const hardIndex = equip.sections.indexOf(sec);
        let newDp = {
            ...equip.holdAP,
            zid: equip.id,
            assignedTms: Math.round(Date.now() / 1000),
            loc,
            sectionIndex: secIndex,
            id: (Math.round(Math.random() * 1000) + 1000).toString(),
            bankIndex,
            config: equip.sections[hardIndex].elems[0].type.toLowerCase()
        };
        try {
            const posted = await request(`datapoint`, 'post', '', newDp);
            const newEquip = { ...equip };
            delete newEquip.holdAP;
            newEquip.datapoints.push(posted);
            setEquip({ ...newEquip, holdDiffAP: posted.apsn });
            updateApBank(bankIndex);
            setAppData({ ...appData, loading: { visible: false } });
        } catch (addApErr) {
            initErr('There was an error adding the new Datapoint', addApErr);
        }
    }

    const setAPDiff = async (isDiff:boolean, sec?:any) => {
        let newEquip = { ...equip };
        if (!isDiff) {
            delete newEquip.holdDiffAP;
            setEquip(newEquip);
        } else {
            const hardIndex = newEquip.sections.indexOf(sec);
            sec.diffSensor = equip.holdDiffAP;
            delete newEquip.holdDiffAP;
            newEquip.sections[hardIndex] = sec;
            sec = await request(`zone/${equip.id}?entity=section`, 'put', 'bank', sec);
            const dp = equip.datapoints.find((d: any) => d.apsn === sec.diffSensor);
            const dpIndex = equip.datapoints.map((d: any) => d.apsn).indexOf(dp.apsn);
            if (!dp.maintenanceHistory) dp.maintenanceHistory = sec.dpMaintenanceHistory;
            newEquip.datapoints[dpIndex] = dp;
            setEquip(newEquip);
        }
        setSessionEntity('zone', newEquip);
    }

    const updateConfigElem = (e: any, secIndex: number | string, elemIndex: number) => {
        e.preventDefault();
        e.stopPropagation();
        const configElem = secIndex == 'clear' ? null : { secIndex, elemIndex };
        setEquip({ ...equip, configElem: configElem });
    }

    const setDragElem = (secIndex: number, elemIndex: number) => {
        const newEquip = { ...equip };
        setEquip({ ...equip, dragElem: [secIndex, elemIndex] });
        // let sections = [...newEquip.sections];
        // let sec = sections[secIndex];
        // sections[secIndex].elems.splice(elemIndex, 1);
        // sections[secIndex] = sec;
        // newEquip.sections = sections;
        // setEquip(newEquip);
    }

    const removeElem = async (secIndex: number, elemIndex: number) => {
        const toUpdate = { ...equip.sections[secIndex] };
        let newEquip = { ...equip };
        delete newEquip.configElem;
        toUpdate.elems.splice(elemIndex, 1);
        try {
            if (toUpdate.elems.length) {
                const updated = await request(`zone/${equip.id}?entity=section`, 'put', 'bank', toUpdate);
                newEquip.sections[secIndex] = updated;
                setEquip(newEquip);
                setSessionEntity('zone', newEquip);
            } else {
                const secDps = equip.datapoints.filter((dp: any) => dp.sectionIndex == toUpdate.index);
                if (!secDps.length) {
                    await request(`zone/${equip.id}?entity=${toUpdate.id}`, 'delete');
                    setEquip(newEquip);
                    setSessionEntity('zone', newEquip);
                } else {
                    const plrSng = (plr:string, sng:string) => secDps.length > 1 ? plr : sng;
                    throw {
                        message: `${plrSng('There are AirPulses', 'There is an AirPulse')} assigned to this HVAC Stage that must be removed before the final Element and Stage can be deleted. Please remove the Datapoint${plrSng('s', '')} associated with ${plrSng('these', 'this')} AirPulse${plrSng('s', '')}`
                    }
                }
            }
        } catch (removeElemErr) {
            console.error({ removeElemErr });
            const err = removeElemErr as any;
            setAppData({
                ...appData,
                loading: {visible: false},
                helper: {
                    type: 'error',
                    title: 'Delete Element Error',
                    content: err.message
                }
            })
        }
    }

    const updateElemDetails = async (details: any, secIndex: number, elemIndex: number) => {
        details.elemIndex = elemIndex;
        try {
            const updatedSec = await request(`element/${equip.sections[secIndex].id}`, 'put', '', details);
            let newEquip = { ...equip };
            newEquip.sections[secIndex] = updatedSec;
            setSessionEntity('zone', newEquip);
            setEquip(sessionData.zone);
        } catch (updateElemDetailsErr) {
            console.error({ updateElemDetailsErr });
            const err = updateElemDetailsErr as any;
            setAppData({
                ...appData,
                helper: {
                    type: 'error',
                    title: 'Maintenance Update Error',
                    content: err.message
                }
            })
        }
        setAppData({ ...appData, loading: {visible: false} })
    }

    const removeEquipDp = async (dp:any) => {
        try {
            const body = { id: dp.id, entType: 'datapoint', parentId: equip.id };
            await request('modifyEntity', 'delete', '', body);
        } catch (delEntityErr) {
            console.error({ delEntityErr });
        }
        const sections = equip.sections;
        const sec = sections.find((s: any) => s.index == dp.sectionIndex);
        const hardIndex = sections.indexOf(sec);
        if (sec.diffSensor && sec.diffSensor == dp.apsn) {
            delete sec.diffSensor;
            sections[hardIndex] = sec;
        }
        try {
            await request(`zone/${equip.id}?entity=section`, 'put', 'bank', sec);
        } catch (removeDiffSensorErr) {
            console.error({ removeDiffSensorErr });
        }
        updateApBank({ apsn: dp.apsn, did: dp.did });
        let newEquip = { ...equip };
        const dpIndex = equip.datapoints.map((edp: any) => edp.apsn).indexOf(dp.apsn);
        newEquip.datapoints.splice(dpIndex, 1);
        newEquip.sections = sections;
        setEquip(newEquip);
        setSessionEntity('zone', newEquip);
        setAppData({ ...appData, loading: {visible: false} });
    }

    const updateEquipAirflow = async (dir: string) => {
        try {
            await request(`/zone/${equip.id}?airflowDir=${dir}`, 'put');
            const newEquip = { ...equip, airflowDir: dir };
            setEquip(newEquip);
            if (localStorage.getItem('breeziProDeeplink')) localStorage.clearItem('breeziProDeeplink');
            setSessionEntity('zone', newEquip)
            setAppData({ ...appData, loading: { visible: false } })
        } catch (updateEquipAirflowErr) {
            console.error({ updateEquipAirflowErr });
            const err = updateEquipAirflowErr as any;
            setAppData({
                ...appData,
                loading: { visible: false },
                helper: {
                    type: 'error',
                    title: 'Error updating Airflow Direction',
                    content: err.message
                }
            })
        }
    }
    
    const initEquip = async (refresh:boolean) => {
        setEquip({});
        const newEquip = await getSessionEntity('zone', id ?? '', refresh);
        if (newEquip) setSessionEntity('zone', newEquip);
        return
    }

    useEffect(() => {
        (async () => {
            initEquip(false);
            // if (!equip.id) {
            //     const newEquip = await getSessionEntity('zone', id ?? '');
            //     // FIGURE OUT AP BANK LOGIC?
            //     setSessionEntity('zone', newEquip);
            // }
        })();
    }, []);

    useEffect(() => {
        if (sessionData.zone) {
            const defaultEquip = {
                holdSec: null,
                holdElem: null,
                holdAP: null,
                holdDp: null,
                holdDiffAP: sessionData.zone.holdDiffAP ?? null,
                configElem: null,
                dragElem: null
            };
            setEquip({ ...sessionData.zone, ...defaultEquip });
            setAppData({ ...appData, loading: { visible: false } });
        }
    }, [sessionData.zone]);

    return [
        equip, 
        setHoldElem, 
        addSection, 
        addElem, 
        setHoldAP, 
        addAP, 
        setAPDiff, 
        updateConfigElem, 
        setDragElem,
        removeElem,
        updateElemDetails,
        removeEquipDp,
        updateEquipAirflow,
        initEquip
    ];

}