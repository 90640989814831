import { useEffect, useState } from "react";
import { imgUrl } from "../../templates/consts";
import { useSession } from "../../hooks/useSessionDev";
import moment from 'moment';
import { useParams } from "react-router-dom";

interface Props {
    events: any;
    screenHeight:number;
}

// REMOVE THIS FOR PROD
const hcDays = {
    'e6f48e04-e601-412e-bbc5-310c1b52671e': 137,
    '1dd5868b-0263-46e1-af67-fb8779bb8847': 3,
    'be67e21c-ec35-461a-bc65-75cca6f73142': 99,
    'b9a26531-9433-4174-97f3-f71b8b06b36a': 153
}

const MonthlyMaint: React.FC<Props> = ({ events, screenHeight }) => {

    const { id } = useParams();

    const [equips, setEquips] = useState<any>(null);
    const [requiredMaint, setRequiredMaint] = useState<any>(null);

    const [sessionData, , , getSessionEntity] = useSession();

    const assessImg = (evt: any) => {
        const body = evt.body.toLowerCase();
        if (body.includes('fan')) {
            return 'hvac';
        } else if (body.includes('coil')) {
            return 'coilFlow';
        } else if (body.includes('filter')) {
            return 'filterOfficial';
        }
        return 'maint';
    }

    const setHvacName = (evt: any) => {
        const evtEquip = equips.find((eq: any) => eq.id === evt.zid);
        return evtEquip.name || '';
    }
    const setHvacDate = (evt: any) => {
        return (moment(evt.tms * 1000).format('MMM-DD YYYY'))
    }

    const compileRequiredMaints = () => {
        if (equips?.length) {
            const dps = equips.reduce((allDps: any[], eq: any) => {
                return allDps.concat(eq.datapoints);
            }, []);
            const rMaint:any = []
            dps.forEach((dp: any) => {
                if (dp.maintenanceHistory) {
                    // const days = dp.meas?.days ? dp.meas.days : 0;
                    const hardCodedDays = hcDays as any;
                    const days = hardCodedDays[dp.id];
                    const zoneName = equips.find((eq: any) => eq.id === dp.zid).name;
                    const config = dp.config;
                    const dpid = dp.id;
                    rMaint.push({ days, zoneName, config, dpid })
                }
            });
            setRequiredMaint(rMaint);
        }
    }

    const setConfigImg = (config: string) => {
        switch (config) {
            case 'filter': return 'filterOfficial';
            case 'fan': return 'hvac';
            case 'coil': return 'coilFlow';
            default: return 'maint';
        }
    }

    const assessMaintDate = (days: number) => {
        return moment((Math.floor(Date.now()/1000) + (days * 86400)) * 1000).format('MMM-DD YYYY')
    }

    const assessDaysColor = (days: number) => {
        let color = 'breezi-blue';
        if (days >= 25) {
            color = 'prim';
        } else if (days >= 11) {
            color = 'cancel';
        } else {
            color = 'crit';
        }
        return `var(--${color})`;
    }

    const switchEventType = (config: string) => {
        if (config === 'filter') return { body: 'Filter Change', sub: '(PdM-based)' }
        return { body: config === 'coil' ? 'Coil Cleaning' : 'Fan Belt Adjustment', sub: '(PdM-Diagnosis)' }
    }

    useEffect(() => {
        (async () => {
            const bid = id as string;
            const bldg = await getSessionEntity('building', bid);
            const equipZones = bldg.floors.reduce((equipments: any[], floor: any) => {
                const floorEquip = floor.zones.filter((z: any) => z.equip);
                if (floorEquip.length) equipments = equipments.concat(floorEquip);
                return equipments;
            }, []);
            setEquips(equipZones);
        })();
    }, []);

    useEffect(() => {
        compileRequiredMaints();
    }, [equips]);


    return equips && equips.length > 0 && (
        <div className='monthly-maint'>
            <div className='monthly-maint-panel maint-performed'>
                <h3>Maintenance Performed</h3>
                {
                    events.map((evt: any) => (
                        <div className='monthly-evt'>
                            <img src={`${imgUrl}/${assessImg(evt)}.png`} />
                            <div className='monthly-evt-title' style={{width: screenHeight < 400 || screenHeight > 880 ? '' : '100%'}}>
                                <h4>{setHvacName(evt)}</h4>
                                <small>{setHvacDate(evt)}</small>
                            </div>
                            {
                                (screenHeight < 400 || screenHeight > 880) &&
                                <>
                                    <div className='monthly-evt-sep'></div>
                                    <div className='monthly-evt-title'>
                                        <h5>{evt.title}</h5>
                                    </div>
                                    <div className='monthly-evt-initials'>
                                        <h3>CR</h3>
                                    </div>
                                </>
                            }
                        </div>
                    ))
                }
            </div>
            <div className='monthly-maint-sep'></div>
            <div className='monthly-maint-panel maint-required'>
                <h3>Maintenance Required</h3>
                {
                    requiredMaint && requiredMaint.length &&
                    requiredMaint.map((rm: any, rmIndex: number) => (
                        <div className='monthly-evt'>
                            <img src={`${imgUrl}/${setConfigImg(rm.config)}.png`} />
                            <div className='monthly-evt-title' style={{width: screenHeight < 400 || screenHeight > 880 ? '40%' : '100%'}}>
                                <h4>{rm.zoneName}</h4>
                                <small style={{color: assessDaysColor(rm.days)}}>{assessMaintDate(rm.days)}</small>
                            </div>
                            {
                                (screenHeight < 400 || screenHeight > 880) &&
                                <>
                                    <div className='monthly-evt-sep'></div>
                                    <div className='monthly-evt-title'>
                                        <h5>{switchEventType(rm.config).body}</h5>
                                        <small style={{fontSize: '.7em'}}>{switchEventType(rm.config).sub}</small>
                                    </div>
                                    <div className='evt-assign'>
                                        <h6 style={{fontSize: '.55em'}}>ASSIGN</h6>
                                    </div>
                                </>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default MonthlyMaint;