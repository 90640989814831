import { useEffect, useRef, useState } from "react";
import moment from 'moment';

import { useEquipment } from "../../hooks/useEquipmentContext";
import Section from "./Section";
import Sleeve from "./Sleeve";
import { imgUrl } from "../../templates/consts";
import Card from "../card/Card";
import EquipTrend from "./EquipTrend";
import ServedZones from "./ServedZones";
import { useSession } from "../../hooks/useSessionDev";
import { useNavigate } from "react-router-dom";

interface Props {
    bankState: string;
    setBankState: (newBankState: string) => void;
}

const HVAC: React.FC<Props> = ({ bankState, setBankState }) => {

    const ref = useRef<HTMLDivElement>(null);
    const nav = useNavigate();

    const [equip, , addSection, , , , , , setDragElem, , , , , initEquip] = useEquipment();
    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession();

    const [showStages, setShowStages] = useState<boolean>(true);
    const [ghost, setGhost] = useState<string>('');
    const [radialDp, setRadialDp] = useState<string>('');
    const [sleeveView, setSleeveView] = useState<string>('hvac');
    const [cursorState, setCursorState] = useState<string>('');
    const [delOverlay, setDelOverlay] = useState<number>(-1);
    const [focusElem, setFocusElem] = useState<{ secIndex: number, elemIndex: number }>({ secIndex: -1, elemIndex: -1 });
    const [showZonesServed, setShowZonesServed] = useState<boolean>(false);
    const [neighbors, setNeighbors] = useState<any>(null);
    const [neighborHover, setNeighborHover] = useState<number>(-1);

    const [dragMsg, setDragMsg] = useState<string | null>(null);

    const handleEquipDrag = (e: any) => {
        e.preventDefault();
        if (!equip.dragElem || equip.dragElem[0] == -1) {
            if (ref?.current && equip.sections.length < 3 && !equip.holdAP) {
                const pRef = ref.current;
                if (pRef.childNodes) {
                    const primChild = pRef.childNodes[1] as HTMLElement;
                    const firstChild = primChild.childNodes[0] as HTMLElement;
                    if (firstChild) {
                        const firstX = firstChild.getBoundingClientRect().right;
                        const clientX = e.clientX;
                        setGhost(clientX <= firstX ? 'left' : 'right');
                    }
                }
            }
        }
    }

    const handleEquipDrop = (e: any) => {
        if (!equip.dragElem) {
            if (equip.sections.length < 3 && !equip.holdAP) {
                let pos = 0;
                if (equip.sections.length) {
                    if (equip.sections.length && ghost == 'left') {
                        const lowestIndex = equip.sections.sort((a: any, b: any) => a.index - b.index)[0].index;
                        pos = lowestIndex - 1;
                    } else {
                        const highestIndex = equip.sections.sort((a: any, b: any) => b.index - a.index)[0].index;
                        pos = highestIndex + 1;
                    }
                }
                addSection(pos);
                // let sec = equip.sections.length;
                // if (!equip.sections.length || ghost == 'left') sec = 0;
            }
            setGhost('');
        } else {
            // setDragElem(-1, -1);
        }
    }

    const isDiff = (dp: any) => {
        const sections = equip.sections;
        let diff = false;
        if (sections.length) {
            const diffApsns = sections.map((s: any) => s.diffSensor ?? false);
            if (diffApsns.includes(dp.apsn)) diff = true;
        }
        return diff;
    }

    const setLastUpdated = () => {
        const dps = equip.datapoints;
        if (!dps.some((dp: any) => dp.meas)) return '';
        const sorted = dps.filter((dp: any) => dp.updatedTms).sort((a: any, b: any) => a.updatedTms - b.updatedTms);
        const recentDp = sorted[sorted.length - 1];
        return recentDp?.updatedTms ? moment(recentDp.updatedTms * 1000).format('MMM DD @ h:mma') : ''
    }

    const handleRefresh = (e: any) => {
        e.stopPropagation();
        initEquip(true);
        // initializeZone(true);
    }

    const assessNeighbors = async () => {
        if (!neighbors) {
            const floor = await getSessionEntity('floor', equip.fid);
            if (floor) {
                const equips = floor.zones.filter((z: any) => z.equip && z.id !== equip.id);
                if (equips.length) setNeighbors(equips);
            }
        }
    }

    const getNeighbor = (dir: string) => {
        if (neighbors.length === 1) return neighbors[0];
        // #TODO: Add provisions for more than 2 equips on floor
    }

    const navNeighbor = async () => {
        nav('/navigator');
        const newEquip = await getSessionEntity('zone', neighbors[0].id);
        if (newEquip) setSessionEntity('zone', newEquip);
        setTimeout(() => nav(`/equipment/${neighbors[0].id}`), 500)
    }

    const filterCoilOrder = () => {
        return equip.datapoints.reduce((ordered: any[], dp: any) => {
            const sec = equip.sections[dp.sectionIndex];
            if (!sec || !sec.elems || !sec.elems.length) {
                ordered.push(dp);
            } else {
                if (sec.elems[0].type === 'Filter') {
                    ordered.unshift(dp);
                } else {
                    ordered.push(dp);
                }
            }
            return ordered;
        }, []);
    }

    useEffect(() => {
        if (bankState != '') {
            setShowStages(true);
            setSleeveView('hvac');
        }
        setFocusElem({ secIndex: -1, elemIndex: -1 });
    }, [bankState]);

    useEffect(() => {
        assessNeighbors();
        if (!equip.dragElem || equip.dragElem[0] == -1) setDelOverlay(-1)
    }, [equip]);

    useEffect(() => {
        setRadialDp('');
    }, [sleeveView]);

    useEffect(() => {
        assessNeighbors();
    }, []);

    return (
        <div
            ref={ref}
            className='hvac-content'
            style={{
                height: bankState == '' ? '85%' : '',
                cursor: cursorState
            }}>
            <Sleeve
                bankState={bankState}
                setBankState={setBankState}
                showStages={showStages}
                setShowStages={setShowStages}
                sleeveView={sleeveView}
                setSleeveView={setSleeveView}
                handleRefresh={e => handleRefresh(e)}
                showZonesServed={showZonesServed}
                setShowZonesServed={setShowZonesServed} />
            {
                showZonesServed &&
                <ServedZones equip={equip} />
            }
            {
                equip.id && sleeveView == 'hvac' &&
                <div
                    className='hvac-sections'
                    onDragOver={e => handleEquipDrag(e)}
                    onDragLeave={e => setGhost('')}
                    onDrop={e => handleEquipDrop(e)}>
                    {
                        neighbors &&
                            <div
                                className='nav-equip-neighbor'
                                onMouseOver={() => setNeighborHover(0)}
                                onMouseLeave={() => setNeighborHover(-1)}
                                onClick={() => navNeighbor()}>
                            {
                                (window.innerHeight >= 880 || neighborHover === -1) &&
                                <div className='nav-equip-node'>
                                    <h1>{'<'}</h1>
                                </div>   
                            }
                            {
                                neighborHover === 0 && window.innerHeight > 880 &&
                                <div className='nav-equip-graphic' style={{right: '40px'}}>
                                    <img src={`${imgUrl}/equip.png`} />
                                    <small>{getNeighbor('left').name}</small>
                                </div>
                            }
                                    {
                                neighborHover === 0 && window.innerHeight <= 880 &&
                                <div className='mob-equip-graphic'>
                                    <img src={`${imgUrl}/equip.png`} />
                                    <small>{getNeighbor('left').name}</small>
                                </div>
                            }
                        </div>
                    }
                    {
                        ghost == 'left' &&
                        <div className='hvac-ghost'>
                            <img src={`${imgUrl}/section.png`} />
                            <h4>Add Stage</h4>
                            <h1>+</h1>
                        </div>
                    }
                    {
                        equip.sections?.length > 0 &&
                        equip.sections.sort((a: any, b: any) => a.index - b.index).map((sec: any, secIndex: number) => (
                            <>
                                {
                                    sec.elems?.length > 0 &&
                                    <Section
                                        sec={sec}
                                        showStage={showStages}
                                        radialDp={radialDp}
                                        setRadialDp={setRadialDp}
                                        bankState={bankState}
                                        delOverlay={delOverlay}
                                        focusElem={focusElem}
                                        setFocusElem={setFocusElem} />
                                }
                            </>
                        ))
                    }
                    {
                        ghost == 'right' &&
                        <div className='hvac-ghost'>
                            <img src={`${imgUrl}/section.png`} />
                            <h4>Add Stage</h4>
                            <h1>+</h1>
                        </div>
                    }
                    {
                        ghost == '' && !equip.sections.length &&
                        <h4
                            style={{
                                marginBottom: '10%',
                                fontWeight: 'lighter',
                                color: 'gray',
                                border: '1px dashed gray',
                                padding: '1%'
                            }}>
                            Drag an Element from the Bank <br />
                            above to begin configuration
                        </h4>
                    }
                    {
                    neighbors &&
                        <div
                            className='nav-equip-neighbor'
                            onMouseOver={() => setNeighborHover(1)}
                            onMouseLeave={() => setNeighborHover(-1)}
                            onClick={() => navNeighbor()}>
                        {
                            (window.innerHeight >= 880 || neighborHover === -1) &&
                            <div className='nav-equip-node'>
                                <h1>{'>'}</h1>
                            </div>   
                        }
                        {
                            neighborHover === 1 && window.innerHeight > 880 &&
                            <div className='nav-equip-graphic' style={{left: '40px'}}>
                                <img src={`${imgUrl}/equip.png`} />
                                <small>{getNeighbor('right').name}</small>
                            </div>
                        }
                        {
                            neighborHover === 1 && window.innerHeight <= 880 &&
                            <div className='mob-equip-graphic r-equip-graphic'>
                                <img src={`${imgUrl}/equip.png`} />
                                <small>{getNeighbor('right').name}</small>
                            </div>
                        }
                    </div>
                }
                </div>
            }
            {
                equip && equip.id && equip.datapoints && sleeveView == 'cards' &&
                <div className='hvac-sections hvac-cards'>
                    {
                        equip.datapoints &&
                        equip.datapoints.length > 0 &&
                        filterCoilOrder().map((dp: any, dpIndex: number) => (
                            <Card datapoint={dp} dpIndex={dpIndex} zName={equip.name} zoneRefresh={() => handleRefresh} diff={isDiff(dp)} />
                        ))
                    }
                </div>
            }
            {
                equip.id && sleeveView == 'trends' &&
                <EquipTrend equip={equip} />
            }
            {
                bankState === '' && equip.datapoints?.length > 1 &&
                <small style={{ position: 'absolute', bottom: '5px' }}>Last Updated: {setLastUpdated()}</small>
            }
        </div>
    )
};

export default HVAC;