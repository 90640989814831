import { useEffect, useState } from "react";

import '../../stylesheets/Card.css';
import Load from "./Load";
import Meas from "./Meas";
import CRP from "./CRP";
import Equip from "./Equip";
import Alerts from "./Alerts";
import CardToggle from "./Toggle";
import Settings from "./Settings";
import DatapointSetpoints from "./DatapointSetpoints";
// import { useSession } from "../../hooks/useSessionContext";
// import { useSession } from "../../hooks/useSession";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl } from "../../templates/consts";

interface Props {
    datapoint: any;
    dpIndex: number;
    zName: string;
    zoneRefresh: () => void;
    diff?: boolean;
}

type ViewsType = {
    [prop: string]: any;
}

const Card: React.FC<Props> = ({ datapoint, dpIndex, zName, zoneRefresh, diff }) => {

    // const [ , , , , , entityUpdate, , , postSetpointUpdate ] = useSession();
    const [sessionData] = useSession();

    const [flipped, setFlipped] = useState<boolean>(false);
    const [lastIndex, setLastIndex] = useState<number>(-1);
    const [activeIndex, setActiveIndex] = useState<number>(1)
    const [animDir, setAnimDir] = useState<string>('');
    const [showCard, setShowCard] = useState<boolean>(false);
    const [placard, setPlacard] = useState<string>('');

    useEffect(() => {
        if (activeIndex != -1) {
            setAnimDir(animDir == 'left' ? 'right' : 'left');
            setLastIndex(activeIndex);
            let prop = Object.keys(views)[activeIndex];
            if (datapoint?.config !== 'env' && activeIndex === 2) {
                prop = 'alerts';
            }
            if (flipped) {
                setFront(views[prop]);
            } else {
                setBack(views[prop]);
            }
            setTimeout(() => setFlipped(!flipped), 300);
        }
    }, [activeIndex]);

    const setpointRefresh = async (newSp: any) => {
        await zoneRefresh();
        setActiveIndex(datapoint?.config !== 'env' ? 0 : 1);
    }

    const views: ViewsType = {
        crp: <CRP datapoint={datapoint} dpIndex={dpIndex} setActiveIndex={setActiveIndex} zoneRefresh={zoneRefresh} />,
        meas: <Meas datapoint={datapoint} zName={zName} dpIndex={dpIndex} setActiveIndex={setActiveIndex} diff={diff ?? false} zoneRefresh={zoneRefresh} setPlacard={setPlacard} />,
        equip: <Equip datapoint={datapoint} dpIndex={dpIndex} setActiveIndex={setActiveIndex} zoneRefresh={zoneRefresh} />,
        alerts: <Alerts datapoint={datapoint} dpIndex={dpIndex} setActiveIndex={setActiveIndex} zoneRefresh={zoneRefresh} />,
        load: <Load datapoint={datapoint} />,
        settings: <Settings datapoint={datapoint} zName={zName} dpIndex={dpIndex} setActiveIndex={setActiveIndex} lastIndex={lastIndex} zoneRefresh={zoneRefresh} />,
        setpoints: <DatapointSetpoints datapoint={datapoint} flipBack={setActiveIndex} lastIndex={lastIndex} currentVals={datapoint?.meas ?? false} initResetAlerts={setpointRefresh} config={datapoint.config} />
    }

    useEffect(() => {
        if (datapoint.zid === sessionData.zone.id) setShowCard(true);
    }, [sessionData]);


    const [front, setFront] = useState(views.load);
    const [back, setBack] = useState(views.load);

    return (
        <section className='card-frame'>
            {
                showCard &&
                <div
                    className='card'
                    style={{
                        animation: `flip-${animDir} 1s forwards`,
                        border: window.innerWidth > 767 ? '2px solid gray' : 'none',
                        overflow: 'visible'
                    }}>
                    {
                        !flipped
                            ? <div className='card-face'>
                                {front}
                                {
                                    lastIndex != -1 && ![5, 6].includes(activeIndex) &&
                                    <CardToggle activeIndex={activeIndex} setActiveIndex={setActiveIndex} isEquipDp={datapoint?.config !== 'env'} />
                                }
                            </div>
                            : <div className='card-face card-back'>
                                {back}
                                {
                                    lastIndex != -1 && ![5, 6].includes(activeIndex) &&
                                    <CardToggle activeIndex={activeIndex} setActiveIndex={setActiveIndex} isEquipDp={datapoint?.config !== 'env'} />
                                }
                            </div>
                    }
                </div>
            }
            {
                placard.length > 0 &&
                <div className='placard-drawer'>
                    <img src={`${imgUrl}/${placard.toUpperCase()}Placard.png`} />
                </div>
            }
        </section>
    )
}

export default Card;