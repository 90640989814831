import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl, setStatus } from "../../templates/consts";

interface Props {
    dp: any;
    dpIndex: number
    formatName:(dp:any, dpIndex:number) => string;
}

const MiniCard: React.FC<Props> = ({ dp, dpIndex, formatName }) => {

    const [sessionData] = useSession();
    
    const [statusColor, setStatusColor] = useState<string>('var(--breezi-blue)')

    useEffect(() => {
        setStatusColor(setStatus('any', dp.alerts ? dp.alerts.overall : false, true));
    }, [sessionData.zone]);

    return (
        <div className='zone-mini-card'>
            <header className='mini-status' style={ {background: setStatus('all', dp.alerts ? dp.alerts.overall : false)} }></header>
            <div className='zone-mini-header'>
                <h5>{formatName(dp, dpIndex)}</h5>
            </div>
            <div className='zone-mini-row'>
                {
                    ['htp', 'hhm'].map((rdg: string, rdgIndex: number) => (
                        <>
                            <div className='zone-mini-rdg temphum-mini-rdg' style={ {background: setStatus(rdg, dp.alerts ? dp.alerts[rdg] : false, true)} }>
                                <img src={ `${imgUrl}/${rdg == 'htp' ? 'temp' : rdg}.png` } />
                            </div>
                            {/* {
                                rdgIndex == 0 &&
                                <div style={ {width: '8px', height: '8px', background: 'var(--breezi-blue)'} }></div>
                            } */}
                        </>
                    ))
                }
            </div>
            <div className='zone-mini-row'>
                {
                    ['iaq', 'co2', 'voc'].map((rdg: string, rdgIndex: number) => (
                        <div className='zone-mini-rdg' style={ {background: setStatus(rdg, dp.alerts ? dp.alerts[rdg] : false, true)} }>
                            <img src={ `${imgUrl}/${rdg}.png` } />
                        </div>
                    ))
                }
            </div>
            <div style={ {height: '2px', width: '60px', background: 'var(--breezi-blue)'} }></div>
        </div>
    )

    // return (
    //     <div className='zone-mini-card'>
    //         <div className='mini-dp' style={ {background: statusColor} }>
    //             <img src={`${imgUrl}/apIcon.png`} />
    //         </div>
    //         <h5 style={{ color: statusColor }}>
    //             {/* { sessionData.zone.name }<br /> */}
    //             {formatName()}
    //         </h5>
    //         <small>{dp.apsn}</small>
    //     </div>
    // )
}

export default MiniCard;