import { useState, useEffect, useContext } from 'react';
import {
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute,
    CookieStorage,
    CognitoUserPool,
    CognitoUserSession,
    CognitoIdToken
} from 'amazon-cognito-identity-js';
import { Password } from 'aws-sdk/clients/directoryservice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSession } from './useSessionDev';
import { AppContext } from '../context/AppContext';

const domain = '.breezi.guru';
// const domain = '.breezi-commerical-testbed.s3-website-us-east-1.amazonaws.com';
// const domain = 'localhost';
// const domain = '192.168.1.7';
// const domain = '192.168.86.29';

const poolData = {
    UserPoolId: 'us-east-1_xtNC2Z3aJ',
    ClientId: '6jav16j2lvufoa9jfe559bh37p',
    Storage: new CookieStorage({ domain, secure: false })
}

const validatePassword = (pw: string): boolean => {
    return true;
}

const userPool = new CognitoUserPool(poolData);

export const useAuth = (): [
    (data: any) => Promise<CognitoUser>,
    (data: any) => Promise<null>,
    (data: any) => Promise<null>,
    (data: any) => void,
    () => Promise<string>,
    (goHome?: boolean) => Promise<null>,
    (oldPw: string, newPw: string) => Promise<any>,
    (data: any) => Promise<any>,
    (email: string, code: string, newPw: string) => Promise<any>,
    (data:any) => Promise<any>
] => {

    const loc = useLocation();
    const { id } = useParams();
    const nav = useNavigate();

    const { appData, setAppData } = useContext(AppContext);

    const registerUser = (payload: any): Promise<CognitoUser> => {
        let { email, password, given_name, family_name, phone_number, countryCodes, country } = payload;
        phone_number = `+${countryCodes}${phone_number}`;
        const attributeList = [
            new CognitoUserAttribute({ Name: 'given_name', Value: given_name }),
            new CognitoUserAttribute({ Name: 'family_name', Value: family_name }),
            new CognitoUserAttribute({ Name: 'phone_number', Value: phone_number }),
            new CognitoUserAttribute({ Name: 'custom:country', Value: country })
        ];
        return new Promise((resolve, reject) => {
            userPool.signUp(email, password, attributeList, [], (signupErr, data) => {
                console.error({ signupErr });
                if (signupErr) reject(signupErr);
                resolve(new CognitoUser({
                    Username: email,
                    Pool: userPool,
                    Storage: new CookieStorage({ domain, secure: false })
                }));
            });
        });
    }

    const confirmCode = (data: any): Promise<null> => {
        const { confCode, cognitoUser } = data;
        return new Promise((resolve, reject) => {
            cognitoUser?.confirmRegistration(confCode, true, (codeErr: any, res: null) => {
                if (codeErr) reject(codeErr);
                resolve(null);
            });
        });
    }

    const resendCode = (data: any): Promise<null> => {
        return new Promise((resolve, reject) => {
            const { cognitoUser } = data;
            cognitoUser.resendConfirmationCode((resendCodeErr: any, res: any) => {
                if (resendCodeErr) reject(resendCodeErr);
                resolve(null);
            });
        });
    }

    const forgotPw = (data: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            const { email } = data;
            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
                Storage: new CookieStorage({ domain, secure: false })
            });
            cognitoUser.forgotPassword({
                onSuccess: data => {
                    setAppData({
                        ...appData,
                        helper: false
                    });
                    resolve(null);
                },
                onFailure: forgotPasswordErr => {
                    console.error({ forgotPasswordErr })
                    const err = forgotPasswordErr as any;
                    setAppData({
                        ...appData,
                        helper: {
                            type: 'error',
                            title: 'Error Resetting Password',
                            content: err.message
                        },
                        loading: {visible: false}
                    })
                    reject();
                }
            });
        });
    }

    const confirmPw = (email:string, code: string, newPw: string) => {
        return new Promise((resolve, reject) => {
            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
                Storage: new CookieStorage({ domain, secure: false })
            });
            cognitoUser.confirmPassword(code, newPw, {
                onSuccess: () => {
                    setAppData({
                        ...appData,
                        helper: {
                            type: 'tutorial',
                            title: 'Password Reset Successfully',
                            content: 'Your password has been updated. Please make a note of the change and save it somewhere secure. You may use it immediately to login.'
                        }
                    })
                    resolve(null);
                },
                onFailure: (confirmPwErr: any) => {
                    const err = confirmPwErr as any;
                    setAppData({
                        ...appData,
                        helper: {
                            type: 'error',
                            title: 'Error Resetting Password',
                            content: err.message
                        },
                        loading: {visible: false}
                    })
                    reject();
                }
            })
        });
    }

    const login = (data: any): Promise<null> => {
        const { email, password } = data;
        let newPassword = data.newPassword ?? false;
        const cognitoUser = data.cognitoUser ?? new CognitoUser({
            Username: email,
            Pool: userPool,
            Storage: new CookieStorage({ domain, secure: false })
        })
        return new Promise(async (resolve, reject) => {
            const authData = {
                Username: email,
                Password: password
            };
            const authDetails = new AuthenticationDetails(authData);
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: (res: any) => {
                    console.error(`Logging in as user ${email}`);
                    resolve(null);
                },
                onFailure: (authenticationErr: any) => {
                    console.error({ authenticationErr });
                    reject(authenticationErr);
                },
                newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
                    if (!newPassword) reject('Password Update Required');
                    cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                        onSuccess: async (suc:any) => {
                            resolve(await login({ email, password: newPassword }));
                        },
                        onFailure: (pwChallengeErr: any) => {
                            reject(pwChallengeErr);
                        }
                    })
                }
            });
        });
    }

    const authenticateUser = (data: any): Promise<null> => {
        const { email, password } = data;
        const cognitoUser = data.cognitoUser ?? new CognitoUser({
            Username: email,
            Pool: userPool,
            Storage: new CookieStorage({ domain, secure: false })
        })
        return new Promise((resolve, reject) => {
            const authData = {
                Username: email,
                Password: password
            };
            const authDetails = new AuthenticationDetails(authData);
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: (authenticateRes: any) => {
                    resolve(null);
                },
                onFailure: (authenticateErr: any) => {
                    console.error({ authenticateErr })
                    reject(authenticateErr);
                }
            });
        })
    }

    const getActiveUserAndSession = (): Promise<{ cognitoUser: CognitoUser, cognitoSession: CognitoUserSession }> => {
        return new Promise((resolve, reject) => {
            try {
                const cognitoUser = userPool.getCurrentUser();
                if (!cognitoUser) throw 'No active session'
                cognitoUser?.getSession((getSessionErr: any, cognitoSession: any) => {
                    if (getSessionErr) reject(getSessionErr);
                    resolve({ cognitoSession, cognitoUser })
                });
            } catch (userAndSessionErr) {
                if (!['/', '/secondarySignup', '/organization'].includes(loc.pathname)) localStorage.setItem('breeziProDeeplink', loc.pathname + loc.search);
                setAppData({ ...appData, loading: {visible: false} });
                nav('/');
                reject()
            }
        });
    }

    const getTkn = (): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            const { cognitoSession } = await getActiveUserAndSession();
            try {
                resolve(cognitoSession.getIdToken().getJwtToken());   
            } catch (getTknErr) {
                console.error({ getTknErr })
                reject(getTknErr);
            }
        });
    }

    const getUserAtts = () => {
        return new Promise(async (resolve, reject) => {
            const { cognitoUser } = await getActiveUserAndSession();
            cognitoUser.getUserAttributes((getAttsErr, atts) => {
                if (getAttsErr) reject(getAttsErr);
                resolve(atts);
            });
        });
    }

    const logout = (goHome = true): Promise<null> => {
        return new Promise(async (resolve, reject) => {
            const { cognitoUser } = await getActiveUserAndSession();
            cognitoUser.signOut();
            const cookieSetting = localStorage.getItem('breeziProCookieToggle') ?? false;
            localStorage.clear();
            if (cookieSetting) localStorage.setItem('breeziProCookieToggle', cookieSetting);
            const cookieNames = ['cognito-id-token', 'cognito-access-token', 'cognito-refresh-token', 'your-other-cookies'];
            cookieNames.forEach((cookieName) => {
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            });
            if (goHome) nav('/');
            resolve(null);
        });
    }

    const changePw = (oldPw: string, newPw: string) => {
        return new Promise(async (resolve, reject) => {
            const { cognitoUser } = await getActiveUserAndSession();
            cognitoUser.changePassword(oldPw, newPw, function (err, result) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    reject();
                }
                resolve('Succesfully changed password');
            });
        })
    }

    return [registerUser, confirmCode, resendCode, authenticateUser, getTkn, logout, changePw, forgotPw, confirmPw, login];

}