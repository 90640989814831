import { useNavigate } from "react-router-dom";
import cToF from 'celsius-to-fahrenheit';

import { useSession } from "./useSessionDev";
import { measSet } from "../templates/consts";

const rdgTemplates = {
    filter: {
        title: 'Filter Condition',
        img: 'filterOfficial',
        vals: [
            {
                prop: 'htp',
                img: 'temp'
            },
            {
                prop: 'hhm',
                img: 'hhm'
            }
        ]
    },
    coil: {
        title: 'Coil Conditions',
        img: 'coilFlow',
        vals: [
            {
                prop: 'htp',
                img: 'temp'
            },
            {
                prop: 'hhm',
                img: 'hhm'
            }
        ]
    }
}

export const useOrganization = (): [
    (bldg: any, rdg: string) => any,
    (rdg: string) => any,
    any,
    (bldg:any) => any
] => {

    const nav = useNavigate();

    const [ sessionData ] = useSession();

    const parseVal = (meas:any, rdg: string) => {
        if (!meas) return '-';
        let val = meas[rdg];
        return rdg === 'htp' && sessionData.imperial
            ? Math.round(cToF(meas))
            : (rdg === 'dpr' && sessionData.iwg ? (meas * .0040147).toFixed(3) : meas);
    }

    const parseUnits = (rdg: string) => {
        return rdg === 'htp' && sessionData.imperial
            ? '˚F'
            : (rdg === 'dpr' && sessionData.iwg ? 'iwg' : measSet.find((m: any) => m.prop === rdg)?.units)
    }

    const trifurcate = (bldg: any) => {
        const zones = bldg.floors.reduce((zs: {env:any[], equip:any[]}, floor: any) => {
            if (floor && floor.zones?.length) {
                const floorEquip = floor.zones.filter((fz: any) => fz.equip);
                zs.equip = zs.equip.concat(floorEquip);
                zs.env = zs.env.concat(floor.zones.filter((fz:any) => !floorEquip.includes(fz)));
            }
            return zs;
        }, { env: [], equip: [] });
        if (zones?.equip?.length) {
            const bifurcatedEquip = zones.equip.reduce((equipByType: any, equip: any, equipIndex: number) => {
                if (equip.sections?.length) {
                    equip.sections.forEach((sec: any) => {
                        const secType = sec?.elems[0].type?.toLowerCase();
                        const secDps = equip.datapoints?.filter((dp: any) => (dp.sectionIndex || dp.sectionIndex === 0) && dp.sectionIndex == parseInt(sec.index));
                        equipByType[secType].push({ ...sec, datapoints: secDps });
                    })
                }
                return equipByType;
            }, { coil: [], fan: [], filter: [] });
            bifurcatedEquip.env = zones.env;
            return bifurcatedEquip;
        }
        return false;
    }

    return [parseVal, parseUnits, rdgTemplates, trifurcate];

}