import serialImage from '../../assets/images/serialNo.png';
import manufacturerImg from '../../assets/images/manufacturer.png';
import modelNoImg from '../../assets/images/modelNo.png';
import capacityImg from '../../assets/images/capacity.png';
import filterImg from '../../assets/images/filterOfficial.png';
import coilImg from '../../assets/images/coilFlow.png';

interface Props {
    sessionZone:any;
}


const PdfNameplate: React.FC<Props> = ({ sessionZone }) => {

    const cap = (str: any) => str.toUpperCase();
    
    const orientation = (str: any) => 'horiz' ? 'Horizontal' : 'Vertical';
    
    const addDays = (days:number) => `${days} day${days === 1 ? '' : 's'}`;

    const npProps = [
        {
            prop: 'manufacturer',
            title: 'Manufacturer',
            img: manufacturerImg
        },
        {
            prop: 'modelNo',
            title: 'Model Number',
            img: modelNoImg
        },
        {
            prop: 'serialNo',
            title: 'Serial Number',
            img: serialImage
        },
        {
            prop: 'capacity',
            title: 'Capacity',
            img: capacityImg
        },
        {
            prop: 'filterCycle',
            title: 'Filter Change Cycle',
            img: filterImg,
            fnc: addDays
        },
        {
            prop: 'coilCycle',
            title: 'Coil Cleaning Cycle',
            img: coilImg,
            fnc: addDays
        }
    ];

    return (
        <div className='pdf-zone-container pdf-nameplate'>
            <header>
                <h2>Unit Details</h2>
            </header>
            <div className='pdf-zone-body pdf-nameplate-body'>
                {
                    npProps.map((p: any) => (
                        <div className='pdf-np-node'>
                            <div className='pdf-np-head'>
                                <img src={p.img} />
                            </div>
                            <h5 className='pdf-np-title'>
                                {p.title}
                            </h5>
                            {
                                !['filterCycle', 'coilCycle', 'capacity'].includes(p.prop) &&
                                <h5>
                                    {
                                        sessionZone[p.prop] && p.fnc ? p.fnc(sessionZone[p.prop]) : sessionZone[p.prop]
                                    }
                                    {
                                        !sessionZone[p.prop] && 'Not Specified'
                                    }
                                </h5>
                            }
                            {
                                ['filterCycle', 'coilCycle'].includes(p.prop) &&
                                <h5>Condition-based</h5>
                            }
                            {
                                p.prop === 'capacity' &&
                                <h5> 5-ton / 17.2KW</h5>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )

}

export default PdfNameplate;