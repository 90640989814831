import { useContext, useEffect, useState } from "react";

import { EquipContext } from "../../hooks/useEquipmentContext";

import '../../stylesheets/Equipment.css';
import EquipmentBank from "../equipment/EquipmentBank";
import HVAC from "../equipment/HVAC";
import { imgUrl } from "../../templates/consts";
import EquipmentHeader from "../equipment/EquipmentHeader";
import { useSession } from "../../hooks/useSessionDev";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

export default () => {
    
    const { id } = useParams();

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData, , , getSessionEntity] = useSession();

    const [equip, setEquip] = useState<any>({});
    const [bankState, setBankState] = useState<string>('');
    const [hier, setHier] = useState<any>(null);

    const constructHier = async () => {
        const floor = sessionData.floor ?? await getSessionEntity('floor', equip.fid);
        const bldg = sessionData.building ?? await getSessionEntity('building', floor.bid);
        setHier([bldg.name, floor.name, equip.name]);
    }

    useEffect(() => {
        setAppData({ ...appData, helper: false });
    }, []);

    useEffect(() => {
        if (sessionData.zone && sessionData.zone === id && !equip.id) {
            setBankState(sessionData.zone.sections.length ? '' : 'config')
        }
    }, [sessionData.zone]);

    useEffect(() => {
        if (equip && equip.id) constructHier();
    }, [equip]);
    // const [showDiffOverlay, setShowDiffOverlay] = useState<boolean>(true);

    return (
        <EquipContext.Provider value={{ equip, setEquip }}>
            <main className='organization-main'>
                <div className='equip-panel'>
                    <EquipmentHeader hier={hier} bankState={bankState} />
                    {
                        bankState != '' &&
                        <EquipmentBank bankState={bankState} />
                    }
                    <HVAC bankState={bankState} setBankState={setBankState} />
                </div>
            </main>
        </EquipContext.Provider>
    )
}