import { useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl } from "../../templates/consts";
import cToF from 'celsius-to-fahrenheit';

import temp from '../../assets/images/temp.png';
import hhm from '../../assets/images/hhm.png';
import iaq from '../../assets/images/iaq.png';
import co2 from '../../assets/images/co2.png';
import voc from '../../assets/images/nuevoVOC.png';

interface Props {
    zone: any;
    sessionZone:any;
}

const imgs:any = {
    temp,
    hhm,
    iaq,
    co2,
    voc
}

const PdfEnviro: React.FC<Props> = ({ zone, sessionZone }) => {

    const [sessionData] = useSession();

    const envRdgs = [
        {
            rdg: 'htp',
            img: 'temp',
            units: `˚${sessionData.imperial ? 'F' : 'C'}`,
            min: 0,
            max: 100
        },
        {
            rdg: 'hhm',
            img: 'hhm',
            units: '%',
            min: 0,
            max: 100
        }
    ]

    const iaqRdgs = [
        {
            rdg: 'iaq',
            img: 'iaq',
            units: '',
            min: 0,
            max: 300
        },
        {
            rdg: 'co2',
            img: 'co2',
            units: 'ppm',
            min: 0,
            max: 2500
        },
        {
            rdg: 'voc',
            img: 'voc',
            units: 'ppm',
            min: 0,
            max: 1.0
        }
    ];

    const parseVal = (rdg: string, val: string | number) => {
        if (typeof val === 'string') val = rdg === 'voc' ? parseFloat(val) : parseInt(val);
        if (rdg === 'htp') return sessionData.imperial ? Math.round(cToF(val)) : val;
        if (rdg !== 'voc') val = Math.round(val);
        return val;
    }

    const calcRunWidth = (rdg: any, val: any, tier: string) => {
        if (typeof val === 'string') val = rdg.rdg === 'voc' ? parseFloat(val) : parseInt(val);
        if (tier === 'hi') return '99%';
        let matchHi = zone.meas.workingHoursAvg[rdg.rdg].hi;
        matchHi = rdg.rdg === 'voc' ? parseFloat(matchHi) : parseInt(matchHi);
        let perc = Math.round(val / matchHi * 100);
        if (perc > 80) perc = 80;
        if (tier === 'lo' && perc > 40) perc = 50 - (perc/3);
        // if (perc < 25) return '55px';
        // if (perc >= 25 && perc <= 40) perc = 50;
        return `calc(${perc}% + ${perc >= 60 ? '0' : '50'}px)`;
    }

    return (
        <div className='pdf-zone-container pdf-enviro-container'>
            <header>
                <h2>Zone Conditions</h2>
            </header>
            <div className='pdf-zone-body pdf-enviro-body' style={{ justifyContent: 'center', marginTop: '10px' }}>
                <div className='pdf-equip-half pdf-enviro-half'>
                    <h3>Indoor Climate</h3>
                    <div className='pdf-enviro-ranges' style={{justifyContent: 'flex-start'}}>
                        {
                            envRdgs.map((rdg: any) => (
                                <div className='pdf-enviro-range pdf-enviro-rdg'>
                                    <div className='pdf-enviro-icon'>
                                        <img src={imgs[rdg.img]} />
                                        {/* <small style={{color: 'white'}}></small> */}
                                    </div>
                                    <div className='pdf-enviro-runs'>
                                        <div className='pdf-enviro-run run-hi' style={{ width: calcRunWidth(rdg, zone.meas.workingHoursAvg[rdg.rdg].hi, 'hi') }}>
                                            <div className='run-head'>
                                                <h2>{parseVal(rdg.rdg, zone.meas.workingHoursAvg[rdg.rdg].hi)}{rdg.units}</h2>
                                                <small>HIGH</small>
                                            </div>
                                        </div>
                                        <div className='pdf-enviro-run run-avg' style={{ width: calcRunWidth(rdg, zone.meas.workingHoursAvg[rdg.rdg].avg, 'avg') }}>
                                            <div className='run-head'>
                                                <h2>{parseVal(rdg.rdg, zone.meas.workingHoursAvg[rdg.rdg].avg)}{rdg.units}</h2>
                                                <small>AVG</small>
                                            </div>
                                        </div>
                                        <div className='pdf-enviro-run run-lo' style={{ width: calcRunWidth(rdg, zone.meas.workingHoursAvg[rdg.rdg].lo, 'lo') }}>
                                            <div className='run-head'>
                                                <h2>{parseVal(rdg.rdg, zone.meas.workingHoursAvg[rdg.rdg].lo)}{rdg.units}</h2>
                                                <small>LOW</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='monthly-equip-sep'></div>
                <div className='pdf-equip-half pdf-enviro-half'>
                    <h3>Indoor Air Quality</h3>
                    <div className='pdf-enviro-ranges'>
                        {
                            iaqRdgs.map((rdg: any) => (
                                <div className='pdf-enviro-range pdf-enviro-rdg'>
                                    <div className='pdf-enviro-icon'>
                                        <img style={{height: rdg.rdg === 'iaq' ? '45px' : ''}} src={imgs[rdg.img]} />
                                        <small style={{color: 'white'}}>{rdg.rdg !== 'iaq' ?  rdg.units : 'Index'}</small>
                                    </div>
                                    <div className='pdf-enviro-runs'>
                                        <div className='pdf-enviro-run run-hi' style={{ width: calcRunWidth(rdg, zone.meas.workingHoursAvg[rdg.rdg].hi, 'hi') }}>
                                            <div className='run-head'>
                                                <h2>{parseVal(rdg.rdg, zone.meas.workingHoursAvg[rdg.rdg].hi)}</h2>
                                                <small>HIGH</small>
                                            </div>
                                        </div>
                                        <div className='pdf-enviro-run run-avg' style={{ width: calcRunWidth(rdg, zone.meas.workingHoursAvg[rdg.rdg].avg, 'avg') }}>
                                            <div className='run-head'>
                                                <h2>{parseVal(rdg.rdg, zone.meas.workingHoursAvg[rdg.rdg].avg)}</h2>
                                                <small>AVG</small>
                                            </div>
                                        </div>
                                        <div className='pdf-enviro-run run-lo' style={{ width: calcRunWidth(rdg, zone.meas.workingHoursAvg[rdg.rdg].lo, 'lo') }}>
                                            <div className='run-head'>
                                                <h2>{parseVal(rdg.rdg, zone.meas.workingHoursAvg[rdg.rdg].lo)}</h2>
                                                <small>LOW</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PdfEnviro;