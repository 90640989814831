import { useEffect, useState } from "react";
import { useSession } from "../../../hooks/useSessionDev"
import { entProps, imgUrl } from "../../../templates/consts";

export default () => {

    const [ sessionData ] = useSession();

    const findCount = (entType:string) => {
        if (sessionData.organization && sessionData.organization.buildings) {
            const bldgs = sessionData.organization.buildings;
            if (entType == 'building') return bldgs.length;
            if (entType == 'floor') return bldgs.reduce((totalFloors:number, bldg:any) => totalFloors += bldg.floors.length ?? 0, 0);
            if (['zone', 'equip'].includes(entType)) {
                return bldgs.reduce((totalEnts:number, bldg:any) => {
                    bldg.floors.forEach((floor:any) => {
                        if (floor && floor.zones) {
                            totalEnts += floor.zones.filter((z:any) => entType == 'equip' ? z.equip : !z.equip).length ?? 0;
                        }
                    });
                    return totalEnts;
                }, 0);
            }
            if (entType == 'datapoint') {
                return bldgs.reduce((totalEnts:number, bldg:any) => {
                    if (bldg.floors) {
                        bldg.floors.forEach((floor:any) => {
                            if (floor.zones) {
                                floor.zones.forEach((z:any) => totalEnts += z.datapoints.length ?? 0)
                            }
                        })
                    }
                    return totalEnts
                }, 0);
            }
        }
    }
    
    return (
        <>
            {
                sessionData.organization &&
                <div className='s-section'>
                <div className='s-section-head'>
                    <img src={`${imgUrl}/companyBlue.png`} />
                    <h5>Summary</h5>
                </div>
                <div className='s-section-content'>
                    <h5>{ findCount('building') }<span style={{ color: 'var(--breezi-blue)' }}>-</span> Building{findCount('building') === 1 ? '' : 's'} Monitored</h5>
                    <h5>{ findCount('floor') }<span style={{ color: 'var(--breezi-blue)' }}>-</span> Floor{findCount('floor') === 1 ? '' : 's'} Monitored</h5>
                    <h5>{ findCount('zone') }<span style={{ color: 'var(--breezi-blue)' }}>-</span> Enviro Zone{findCount('zone') === 1 ? '' : 's'} Monitored</h5>
                    <h5>{ findCount('equip') }<span style={{ color: 'var(--breezi-blue)' }}>-</span> Air Handler{findCount('equip') === 1 ? '' : 's'} Monitored</h5>
                    <h5>{ findCount('datapoint') }<span style={ { color: 'var(--breezi-blue)'} }>-</span> Datapoint{findCount('datapoint') === 1 ? '' : 's'} Monitored</h5>
                </div>
            </div>
            }
        </>
    );

}