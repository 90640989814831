import { createRef, useContext, useEffect, useRef, useState } from "react";
// import { imgUrl } from "../../templates/consts";
import PdfZone from "./PdfZone";
import { useSession } from "../../hooks/useSessionDev";

// import jsPDF from "jspdf";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import moment from "moment";
import html2canvas from "html2canvas";

import exportImg from '../../assets/images/export.png';
import { AppContext } from "../../context/AppContext";

const imgUrl = '/assets/images';

interface Props {
    report: any;
    bldg: any;
    zoneDps: any;
    dt: number;
    displayDate: number;
    closePdf:() => void;
}

const MonthlyPdf: React.FC<Props> = ({ report, bldg, zoneDps, dt, displayDate, closePdf }) => {

    const [sessionData, , , getSessionEntity] = useSession();
    const pdfRef = useRef(null);

    const { appData, setAppData } = useContext(AppContext);

    const [equips, setEquips] = useState<any[]>([]);
    const [enviros, setEnviros] = useState<any[]>([]);
    const [chartsLoaded, setChartsLoaded] = useState<boolean[]>([]);

    const handleExport = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: `BreeziPro_Monthly_Report_${moment(dt * 1000).format('MMMM_YYYY')}.pdf`
    });

    useEffect(() => {
        (async () => {
            const sessionZones = await Promise.all(report.data.zones.map(async (z: any) => await getSessionEntity('zone', z.zid)));
            const sEquips = sessionZones.filter((z: any) => z.equip);
            const sEnviros = sessionZones.filter((z: any) => !z.equip);
            const equipIds = sEquips.map((iz: any) => iz.id);
            const enviroIds = sEnviros.map((iz: any) => iz.id);
            setEquips(report.data.zones.filter((rz: any) => equipIds.includes(rz.zid)));
            setEnviros(report.data.zones.filter((rz: any) => enviroIds.includes(rz.zid)));
            setAppData({ ...appData, loading: { visible: true } });
            // setTimeout(async () => {
            //     await handleExport();
            //     setAppData({ ...appData, loading: { visible: false } });
            //     closePdf();
            // }, 3000);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (chartsLoaded.length) {
                setTimeout(async () => {
                    await handleExport();
                    setAppData({ ...appData, loading: { visible: false } });
                    closePdf();
                }, 3000);
            }
        })();
    }, [chartsLoaded]);

    return (
        <main className='report-main report-pdf-main'>
            <div className='report-content report-pdf-content'>
                <div className='conf-pdf-export'>
                    <a className='conf-pdf-export-btn' onClick={e => handleExport(e)}>
                        <img src={exportImg} />
                        {/* <h4>Confirm Export</h4> */}
                    </a>
                </div>
                <div id='export-content' ref={pdfRef}>
                {
                    equips.length > 0 &&
                    equips.map((equip: any, eIndex:number) => (
                        <PdfZone zone={equip} report={report} dt={dt} bldg={bldg} zoneDps={zoneDps} displayDate={displayDate} eIndex={eIndex} chartsLoaded={chartsLoaded} setChartsLoaded={setChartsLoaded} />
                    ))
                }
                {
                    enviros.length > 0 && <PdfZone zone={enviros} report={report} dt={dt} bldg={bldg} zoneDps={zoneDps} displayDate={displayDate} />
                }
                </div>
            </div>
        </main>
    )
}

export default MonthlyPdf;