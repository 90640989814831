import { useContext, useState } from 'react';

import { useRest } from '../../hooks/useRest';
import { capitalize, imgUrl, profiles } from "../../templates/consts";
import '../../stylesheets/Card.css';
import { AppContext } from '../../context/AppContext';
import ModifyEntity from '../util/modifyEntity/ModifyEntity';

interface Props {
    datapoint:any;
    dpIndex:number;
    zName:string;
    setActiveIndex:(index:number) => void;
    lastIndex:number;
    zoneRefresh:() => void;
}

const Settings:React.FC<Props> = ({ datapoint, dpIndex, zName, setActiveIndex, lastIndex, zoneRefresh }) => {

    const [ request ] = useRest();
    const { appData, setAppData } = useContext(AppContext);

    const [ showDelConf, setShowDelConf ] = useState<boolean>(false);
    const [ delText, setDelText ] = useState<string>('');

    const settingsBtns = [
        {
            title: 'Update Datapoint Info',
            class: 'sec',
            navTo: 7
        },
        {
            title: 'Alert Setpoints',
            class: 'tri',
            navTo: 6
        }
    ];

    const prof = profiles.find(p => p.prop == datapoint.config);

    const formatName = () => {
        const name = datapoint.name;
        if (name != '+|dynamic|+') return name;
        return `${prof?.title} ${dpIndex + 1}`;
    }

    const handleSettingsBtnClick = (btn: any) => {
        if (btn.navTo == 7) {
            setShowDelConf(true);
        } else {
            setActiveIndex(6);
        }
        // if (index == 2) {
        //     // setActiveIndex(6);
        // } else if (index == 1) {
        //     // setShowDelConf(true);
        // } else {
        //     console.log('You clicked an inactive button. Good job.')
        // }
    }

    const closeDel = () => {
        setDelText('');
        setShowDelConf(false);
    }

    const initDeleteDatapoint = async () => {
        try {
            await request('datapoint/' + datapoint.id, 'delete');
        } catch (initDeleteDatapointErr) {
            const err = initDeleteDatapointErr as { [i: string]: any };
            const errMsg = err.message;
            const helper = {title: `Delete Datapoint Error`, content: errMsg, type: 'error'};
            setAppData({ loading: {visible: false}, helper });
        }
        setShowDelConf(false);
        setDelText('');
    }

    const modifyProps = [
        {
            prop: 'name',
            title: 'Name',
            type: 'string',
            alias: ['+|dynamic|+', 'Dynamic']
        },
        {
            prop: 'config',
            title: 'Profile',
            type: 'sel',
            options: ['env', 'iaq', 'all']
        }
    ]

    return (
        <div className='card-content'>
            <header className='card-header'>
                <div className='card-icon' style={ {borderRight: '1px solid var(--white)'} }>
                    <img src={ `${imgUrl}/gearsWhite.png` } />
                </div>
                <div className='dp-card-title settings-dp-card-title' style={ {width: '78%'} }>
                    <h4>Datapoint Utilities</h4>
                    <small style={ {color: 'var(--cancel)'} }>{ formatName() }</small>
                </div>
                <div className='close-card' onClick={ () => setActiveIndex(lastIndex) }>
                    <img src={ `${imgUrl}/cancel.png` } />
                </div>
            </header>
            <div className='dp-card-body settings-card-body'>
                {
                    settingsBtns.map((btn:any, btnIndex:number) => (
                        <a
                            className={`settings-card-btn ${btn.class}-btn`}
                            style={{ cursor: btn.class == 'inactive' ? 'not-allowed' : 'pointer' }}
                            onClick={() => handleSettingsBtnClick(btn)}>
                            { btn.title }
                        </a>
                    ))
                }
            </div>
            {
                showDelConf &&
                <ModifyEntity entType='datapoint' ent={ datapoint } modifyableProps={ modifyProps } dest='zone' refresh={ zoneRefresh } closeModify={ () => setShowDelConf(false) } />
            }
        </div>
    )

}

export default Settings;