import { useEffect, useState } from "react";
import { useEquipment } from "../../hooks/useEquipmentContext"
import { imgUrl } from "../../templates/consts";
import { useSession } from "../../hooks/useSessionDev";
import { useRest } from "../../hooks/useRest";

interface Props {
    bankState: string;
}

const Airflow: React.FC<Props> = ({ bankState }) => {

    const [equip, , , , , , , , , , , , updateEquipAirflow] = useEquipment();
    const [sessionData] = useSession();
    const [req] = useRest();

    const [selAirflow, setSelAirflow] = useState<boolean>(false);
    const [hoverIndex, setHoverIndex] = useState<number>(-1);
    const [dir, setDir] = useState<string>('');
    const [unitOn, setUnitOn] = useState<boolean>(false);

    const initAirflow = () => {
        // setDir('');
        // updateEquipAirflow('');
        // setSelAirflow(true);
    }

    const updateOnOff = () => {
        // setUnitOn(equip.meas.dpr && equip.meas.dpr >= 10); 
        const dpOnOff = equip.datapoints.some((dp: any) => dp.meas && dp.meas.dpr >= 10);
        
        setUnitOn(dpOnOff);
    }

    useEffect(() => {
        (async () => {
            if (equip && equip.meas) {
                const dids = equip.datapoints.map((dp: any) => dp.did);
                const onOffs = await Promise.all(dids.map(async (did: number) => {
                    return (await req('onOff/' + did, 'get')).rawDpr;
                }));
                const newOnOff = onOffs.some((onOff: any) => typeof onOff === 'number' && onOff >= 10);
                setUnitOn(newOnOff)
                // const newOnOff = equip.datapoints.some((dp: any) => dp.meas && dp.meas.dpr >= 10);
                // if (newOnOff !== unitOn) {
                //     setUnitOn(unitOn);
                // }
            }
        })();
        // updateOnOff();
    }, [sessionData]);

    useEffect(() => {
        if (equip.airflowDir) {
            setDir(equip.airflowDir);
            setSelAirflow(false);
        } else {
            setDir('ltr');
        }
    }, [equip.airflowDir]);


    return bankState !== ''
        ? <>
            {
                !dir && !selAirflow &&
                <div className='airflow' onClick={() => initAirflow()}>
                    <h4>Select Airflow</h4>
                </div>
            }
            {
                selAirflow &&
                <div className='airflow split-airflow'>
                    <div
                        className='airflow-half airflow-left'
                        onMouseOver={() => setHoverIndex(0)}
                        onMouseLeave={() => setHoverIndex(-1)}
                        onClick={() => updateEquipAirflow('rtl')}>
                        {
                            hoverIndex !== 0 &&
                            <img
                                src={`${imgUrl}/airflowIcon.png`}
                                style={{
                                    transform: 'rotateY(180deg)'
                                }} />
                        }
                        {
                            hoverIndex == 0 &&
                            <h4>R to L</h4>
                        }
                    </div>
                    <div
                        className='airflow-half airflow-right'
                        onMouseOver={() => setHoverIndex(1)}
                        onMouseLeave={() => setHoverIndex(-1)}
                        onClick={() => updateEquipAirflow('ltr')}>
                        {
                            hoverIndex !== 1 &&
                            <img
                                src={`${imgUrl}/airflowIcon.png`} />
                        }
                        {
                            hoverIndex == 1 &&
                            <h4>L to R</h4>
                        }
                    </div>
                </div>
            }
            {
                dir && !selAirflow &&
                <div className='airflow active-airflow' onClick={() => initAirflow()}>
                    {
                        equip.airflowDir == 'rtl' &&
                        <div className='airflow-half airflow-left'>
                            <img style={{ transform: 'rotateY(180deg)' }} src={`${imgUrl}/airflowIcon.png`} />
                        </div>
                    }
                    <h4>Airflow</h4>
                    {
                        equip.airflowDir == 'ltr' &&
                        <div className='airflow-half airflow-right'>
                            <img src={`${imgUrl}/airflowIcon.png`} />
                        </div>
                    }
                </div>
            }
        </>
        : <>
            {
                dir &&
                <div className='hvac-airflow'>
                    {
                        unitOn &&
                            <img
                                className='hvacAirflow'
                                src={`${imgUrl}/power.png`} />
                    }
                    {
                        !unitOn &&
                        <img
                            className='hvacAirflow'
                            src={`${imgUrl}/hvacPowerOff.png`} />
                    }
                    {
                        unitOn &&
                        <img
                            className='hvacAirflow'
                            src={`${imgUrl}/hvacAirflow.png`}
                            style={{ transform: equip.airflowDir === 'rtl' ? 'rotate(180deg)' : '', marginTop: '13px' }} />
                    }
                    {
                        !unitOn &&
                        <img
                            className='hvacAirflow'
                            src={`${imgUrl}/hvacAirflowOff copy.png`}
                            style={{ transform: equip.airflowDir === 'rtl' ? 'rotate(180deg)' : '', marginTop: '13px' }} />
                    }
                </div>
            }
        </>

    // return !equip.airflowDir
    //     ? <div className='airflow'>
    //         <h4>Select Airflow</h4>
    //     </div>
    //     : <h1>ASSIGNED</h1>
}

export default Airflow;