import { useContext, useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl } from "../../templates/consts";
import UtilPanel from "./UtilPanel";
import { AppContext } from "../../context/AppContext";
import Snapshot from "../equipment/EquipSnapshot";
import { avgAlert } from "../../util/alertAvg";
import { useNavigate } from "react-router-dom";
import { useRest } from "../../hooks/useRest";
// import FloorEquip from "../floor/FloorEquip";

interface Props {
    datapoint: any;
}

const panels = [
    // {
    //     prop: 'equipment',
    //     color: 'var(--breezi-blue)',
    //     title: 'Equipment'
    // },
    {
        prop: 'alerts',
        color: 'var(--cancel)',
        title: 'Alerts'
    },
    {
        prop: 'maintenance',
        color: 'green',
        title: 'Maintenance'
    }
]

const BottomRow: React.FC<Props> = ({ datapoint }) => {

    const nav = useNavigate();

    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession();
    const [req] = useRest();

    const [equip, setEquip] = useState<any>(null);
    const [onOff, setOnOff] = useState<boolean>(false);

    const assessOnOff = async () => {
        // return !equip || !equip.meas || equip.meas.dpr < 20 ? 'powerOff' : 'power';
        if (equip && equip.datapoints) {
            const dids = equip.datapoints.map((dp: any) => dp.did);
            const onOffs = await Promise.all(dids.map(async (did:number) => {
                return (await req('onOff/' + did, 'get')).rawDpr;
            }));
            setOnOff(onOffs.some((oo:any) => typeof oo === 'number' && oo >= 10));
        }
    }

    const navEquip = () => {
        if (equip && equip.id) {
            setSessionEntity('zone', equip);
            nav(`/equipment/${equip.id}`);
        }
    }

    useEffect(() => {
        (async () => {
            if (sessionData.zone?.servedBy) {
                let newEquip = await getSessionEntity('zone', sessionData.zone.servedBy.id);
                setEquip(newEquip);
                newEquip = avgAlert(newEquip, 'datapoints');
            }
        })();
    }, []);

    return (
        <div className='datapoint-row'>
            <div
                className='dp-panel dp-third dp-util-panel'
                style={{ border: '2px solid var(--breezi-blue', cursor: 'pointer' }}
                onClick={() => navEquip()}>
                <header className='dp-util-header' style={{ background: 'var(--breezi-blue)' }}>
                    <h5>Equipment</h5>
                </header>
                {
                    equip &&
                    <>
                        <div className='dp-equip-info'>
                            <h3 style={{ color: onOff ? 'var(--equip)' : 'lightgray' }}>{equip.name}</h3>
                            <small>{equip.equipType.toUpperCase()}<span style={{ color: 'var(--white)' }}> | </span>{equip.datapoints.length ?? 0} Datapoint{equip.datapoints?.length == 1 ? '' : 's'}</small>
                            <img src={`${imgUrl}/${onOff ? 'power' : 'powerOff'}.png`} />
                        </div>
                        <div className='dp-equip-info' style={{ height: '80%', flexDirection: 'row', borderRight: 'none' }}>
                            {/* <Snapshot equip={equip} onlyGraphic={true} /> */}
                            <Snapshot equip={ equip } graphicOnly={ true } />
                        </div>
                    </>
                }
                {
                    !equip &&
                    <div className='equip-unassigned'>
                        <img src={`${imgUrl}/equip.png`} />
                        <h5>No HVAC Equipment Assigned to Parent Zone</h5>
                    </div>
                }
            </div>
            {
                panels.map((panel, panelIndex) => (
                    <UtilPanel datapoint={datapoint} panel={panel} />
                ))
            }
        </div>
    )
}

export default BottomRow;