import { createContext, useContext, useEffect } from "react";

import { assignAlerts, avgAlert } from '../util/alertAvg';
import { capitalize, defaultAlertThresholds, entProps, listProps, profiles, updateChildItem, findInHierarchy, compileSessionIds, BreeziProVersionNumber } from "../templates/consts";
import { useRest } from "./useRest";
import { AppContext } from "../context/AppContext";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "./useAuth";

interface SessionContextType {
    sessionData: {[key:string]: any};
    setSessionData: (formData:{[key:string]: any}) => void;
}

export const SessionContext = createContext<SessionContextType>({
    sessionData: {},
    setSessionData: () => {}
});

const getNow = () => Math.floor(Date.now() / 1000);

const getChildProp = (tier:string|undefined) => {
    let prop;
    let title;
    let img;
    switch (tier) {
        case 'organization': 
            prop = 'buildings';
            title = 'Building';
            img = 'enterprise';
            break;
        case 'building':
            prop = 'floors';
            title = 'Floor';
            img = 'bldgType';
            break;
        case 'floor':
            prop = 'zones';
            title = 'Zone';
            img = 'tilesWhite'
            break;
        case 'zone':
            prop = 'datapoints';
            title = 'AirPulse';
            img = 'envZone'
            break;
        case 'equip':
            prop = 'datapoints';
            title = 'AirPulse';
            img = 'equip';
            break;
        case 'datapoint':
            prop = '';
            title = '';
            img = 'datapoint';
    }
    return { prop, title, img } as { prop:string, title:string, img:string };
}

const utilAlerts = (alerts: any, tier: string) => {
    if (!alerts) return 0;
    return Object.keys(alerts).reduce((tierTotal, rdg) => {
        if (rdg != 'overall') tierTotal += alerts[rdg][tier];
        return tierTotal;
    }, 0);
}

export const useSession = ():[
    any,
    () => void,
    (unit:string, active:boolean, onEvent:boolean) => void,
    (entType:string, entId:string, refresh?:boolean) => any,
    (entType:string, ent:any) => void,
    (entType: string, entId:string, forceReturn?:boolean) => any,
    (newChild:any, entType:string) => void,
    (entType: string, entId: string) => void,
    (entType: string, ent: any, session?: any) => void,
    (bankIndex: any) => void,
    (user: string) => void,
    () => void,
    (newNotifUsers:any) => void
] => {

    const { id } = useParams();
    const location = useLocation();

    const { appData, setAppData } = useContext(AppContext);
    const { sessionData, setSessionData } = useContext(SessionContext);

    const [request] = useRest();
    const [ , , , , , logout] = useAuth();

    const updateSession = (newSession:any) => {
        setSessionData(newSession);
        localStorage.setItem('breeziProSession', JSON.stringify(newSession));
    }

    const initErr = (title:string, content:string) => {
        setAppData({ ...appData, helper: { type: 'error', title, content }});
    }

    const updateUtil = (tier: string, data: any) => {
        const formatName = (name:string) => {
            const prof = profiles.find(p => p.prop == data.config);
            if (name != '+|dynamic|+') return name;
            // return `${prof?.title}`;
            return data.apsn;
        }
        if (!data.meas) {
            data = avgAlert(data.organization ? data.organization : data, listProps[entProps.indexOf(tier == 'equip' ? 'zone' : tier)]);
        }
        let { prop, title, img } = getChildProp(tier);
        const util = {
            title: tier != 'datapoint' ? data.name : formatName(data.name),
            count: data[prop]?.length ?? '', 
            countStr: `${title}${data[prop]?.length == 1 ? '' : 's'}` ?? '',
            img: data.hasOwnProperty('sections') ? 'equip' : img,
            warn: utilAlerts(data.alerts, 'bad'),
            crit: utilAlerts(data.alerts, 'crit'),
            tier,
            updateTms: getNow()
        }
        return util;
    }

    const getLocalBundle = (entType: string, entId: string = '', forceReturn:boolean=false) => {
        let loc: any = localStorage.getItem('breeziProSession');
        if (!loc) {
            return false;
        } else {
            loc = JSON.parse(loc);
            if (forceReturn) return loc;
            if (entType == 'init' && loc) {
                if (loc.restTms && loc.restTms >= getNow() - 900 && loc.organization.id) return loc;
                // if (loc.restTms && loc.restTms >= getNow() - 5 && loc.organization.id) {
                //     return loc;
                // }
            } else {
                try {
                    let sessionEnt = findInHierarchy(loc, entType, entId, 'organization', compileSessionIds(loc));
                    if (sessionEnt && ((sessionEnt && sessionEnt.restTms >= getNow() - 900) || loc.restTms >= getNow() - 900)) return sessionEnt;    
                    // if (sessionEnt && ((sessionEnt && sessionEnt.restTms >= getNow() - 5) || loc.restTms >= getNow() - 5)) {
                    //     return sessionEnt;    
                    // }
                } catch (findInHierarchyErr) {
                    console.error({ findInHierarchyErr });
                    return false;
                }
                
            }
            return false;
        }
    }
    
    const getRestBundle = async (entType: string, entId: string = '') => {
        try {
            const res = await request(`${entType}/${entId}`, 'get');
            res.restTms = getNow();
            localStorage.setItem(`breeziPro${entType}RestTms`, (Math.floor(Date.now()/1000)).toString());
            return res;
        } catch (getRestBundleErr) {
            const err = getRestBundleErr as any;
            throw getRestBundleErr;
        }
    }

    const getSpLineage:any = async (entType:string, ent:any, lineage:any[], targetLen:number) => {
        const idProps = ['bid', 'fid', 'zid'];
        if (targetLen == 0) return ent.setpoints ?? defaultAlertThresholds;
        if (lineage.length == targetLen) {
            let revLineage = [ ...lineage ].reverse();
            if (revLineage.some((tier:any) => tier.setpoints)) {
                const spIndex = revLineage.find((hierEnt:any) => hierEnt && hierEnt.setpoints);
                if (spIndex == -1) return ent.setpoints ?? defaultAlertThresholds;
                if (spIndex || spIndex == 0) return revLineage[spIndex].setpoints;
            } else {
                return defaultAlertThresholds;
            }
        }
        const typeIndex = entProps.indexOf(entType);
        const parentProp = idProps.find((prop:string) => ent && ent.hasOwnProperty(prop));
        const parentType = entProps[typeIndex - 1];
        if (parentProp) {
            const parentId = ent[parentProp];
            const session = await getLocalBundle(parentProp, parentId, true);
            const parent = session[parentType];
            lineage.push(parent?.setpoints ?? false);
            return await getSpLineage(parentType, parent, lineage, targetLen);
        }
    }

    const getSessionEntity = async (entType: string, entId: string, refresh?: boolean) => {
        try {
            let bundle = getLocalBundle(entType, entId);
            if (!bundle || refresh) {
                bundle = await getRestBundle(entType == 'init' ? 'organization' : entType, entId);
            }
            if (entType == 'init') {
                if (!bundle || !bundle.organization) return bundle;
                bundle.apBank = await request('bank', 'get');
                bundle.organization = avgAlert(bundle.organization, 'buildings');
            } else {
                // const sp:any = await getSpLineage(entType, bundle, [], entProps.indexOf(entType) - 1);
                if (entType == 'datapoint') {
                    if (bundle.meas?.dat) {
                        let newMeas = { ...bundle.meas };
                        Object.keys(bundle.meas.dat).forEach((prop: string) => newMeas[prop] = prop == 'voc' ? parseFloat(bundle.meas.dat[prop][0]) : parseInt(bundle.meas.dat[prop][0]));
                        delete newMeas.dat;
                        bundle.meas = newMeas
                    }
                }
                const sp = defaultAlertThresholds;
                bundle = entType == 'datapoint' ? assignAlerts(bundle) : avgAlert(bundle, listProps[entProps.indexOf(entType) + 1], sp);
            }
            return bundle;
        } catch(getSessionEntityErr) {
            console.error({ getSessionEntityErr });
            const err = getSessionEntityErr as any;
            // initErr('Logged into Multiple Accounts', "BreeziPro has detected a contemporaneous login from a different account. Only one BreeziPro account may be logged in per device. You are now being logged out and returned to the Landing Page.")
            // logout();
        }
    }

    const purgeChildren = (entType:string, session:any) => {
        let purgeHier = [ ...entProps ].splice(entProps.indexOf(entType) + 1, entProps.length);
        purgeHier.forEach((prop:string) => {
            if (session[prop]) delete session[prop];
        })
        return session;
    }

    const updateParentEnts:any = (entType: string, ent: any, session?:any) => {
        if (!session) session = { ...sessionData }
        if (entType == 'organization') {
            setSessionData(session)
            return
        }
        const parentProp:string = entProps[entProps.indexOf(entType) - 1]
        const parent = session[parentProp]
        const childIndex = parent[entType + 's'].map((c: any) => c.id).indexOf(ent.id)
        parent[entType + 's'][childIndex] = ent
        session[parentProp] = parent
        return updateParentEnts(parentProp, parent, session)
    }

    const setSessionEntity = (entType: string, ent: any) => {
        let session = getLocalBundle('organization', '', true);
        session.organization = avgAlert(session.organization, 'buildings');
        if (entType !== 'datapoint') {
            session[entType] = avgAlert(ent, listProps[entProps.indexOf(entType)]);
        } else {
            const dp = ent;
            ent = assignAlerts(dp, session.zone.setpoints ?? false);
            session.datapoint = dp;
        }
        session.util = updateUtil(entType, ent);
        if (entType != 'datapoint') session = purgeChildren(entType, session);
        session = updateChildItem(entType, ent, session);
        updateSession(session);
    }

    const setInitialSession = async () => {
        let init = await getSessionEntity('init', '');
        if (init.organization) {
            const util = updateUtil('organization', init.organization);
            init = purgeChildren('organization', init);
            init.util = util;
        } else {
            init.util = true;
            init.organization = {}
        }
        updateSession(init);
    }

    const setUnitPref = async (unit:string, toggled:boolean, onEvent:boolean) => {
        try {
            // const entType = location.pathname.split('/')[1];
            // const updatedEnt = await getSessionEntity(entType, entId ?? '');
            // const util = updateUtil(entType, updatedEnt);
            await request(`units?${unit}=${toggled}`, 'put')
            let newSession = { ...sessionData, [unit]: toggled };
            updateSession(newSession);
            setAppData({ ...appData, loading: {visible: false} });
        } catch(setUnitPrefErr) {
            const err = setUnitPrefErr as any;
            initErr('There was an error updating your Unit Preferences', err.message);
        }
    }

    const postObUpdate = (newChild:any, entType:string) => {
        newChild.restTms = getNow();
        newChild.meas = false;
        newChild.alerts = false;
        if (entType == 'organization') {
            updateSession({ ...sessionData, organization: newChild });
            updateUtil('organization', newChild);
            setAppData({ ...appData, loading: {visible: false} });
        } else {
            const session = getLocalBundle('organization', '', true);
            const parentProp = entProps[entProps.indexOf(entType) - 1];
            const parent = session[parentProp];
            parent[listProps[entProps.indexOf(parentProp)]].push(newChild);
            setSessionEntity(parentProp, parent);
            setAppData({ ...appData, loading: {visible: false} });
        }
    }

    const postDeleteUpdate = async (entType: string, entId: string) => {
        const parentType = entProps[entProps.indexOf(entType) - 1];
        const pidProp = entType == 'building' ? 'parent' : parentType[0] + 'id';
        const session = { ...sessionData };
        if (entType != 'datapoint') {
            let parent = await getSessionEntity(parentType, sessionData[entType][pidProp]);
            const childProp = entType + 's';
            const childIndex = parent[childProp].map((child:any) => child.id).indexOf(entId);
            parent[childProp].splice(childIndex, 1);
            setSessionEntity(parentType, parent);
            setAppData({ ...appData, loading: {visible: false} })
        } else {
            let zone = { ...sessionData.zone };
            const dpIndex = zone.datapoints.map((dp: any) => dp.id).indexOf(entId);
            const dp = zone.datapoints[dpIndex];
            const { apsn, did } = dp;
            updateApBank({apsn, did});
            zone.datapoints.splice(dpIndex, 1);
            setSessionEntity('zone', zone);
            setAppData({ ...appData, loading: {visible: false} });
        }
    }

    const updateApBank = (bankIndex: any) => {
        let apBank = [...sessionData.apBank];
        if (!bankIndex.apsn) {
            apBank.splice(bankIndex, 1);
        } else {
            apBank.push(bankIndex);
        }
        updateSession({ ...sessionData, apBank });
    }

    const addTeamMember = (user:string) => {
        let newSession = { ...sessionData };
        newSession.team = newSession.team ?? [];
        newSession.team.push(user);
        updateSession(newSession);
    }

    const clearSession = () => {
        setSessionData({});
    }

    const setNotifUsers = (newNotifUsers:any) => {
        updateSession({ ...sessionData, organization: { ...sessionData.organization, notifUsers: newNotifUsers }});
    }

    return [
        sessionData, 
        setInitialSession, 
        setUnitPref, 
        getSessionEntity, 
        setSessionEntity,
        getLocalBundle,
        postObUpdate,
        postDeleteUpdate,
        updateParentEnts,
        updateApBank,
        addTeamMember,
        clearSession,
        setNotifUsers
    ];

}