import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// import { useSession } from '../../hooks/useSessionContext';
// import { useSession } from '../../hooks/useSession';
import { useSession } from '../../hooks/useSessionDev';
import { capitalize, defaultAlertThresholds, imgUrl, measSet, profiles } from '../../templates/consts';
import '../../stylesheets/Organization.css';
import '../../stylesheets/Zone.css';
import '../../stylesheets/Equip.css';
import '../../stylesheets/Card.css';

import ZoneSettings from '../zone/ZoneSettings';
import BubbleChart from '../chart/Bubbles';
import Card from '../card/Card';
import { AppContext } from '../../context/AppContext';
import ZoneHeader from '../zone/ZoneHeader';
import MiniCard from '../zone/MiniCard';
import { useRest } from '../../hooks/useRest';
import Tooltip from '../util/tooltip/Tooltip';
import AssignEquip from '../zone/AssignEquip';
import Setpoints from '../setpoints/Setpoints';
import ModifyEntity from '../util/modifyEntity/ModifyEntity';
import ZoneSleeve from '../zone/ZoneSleeve';

const anchors = [
    {
        id: 'assign-equip-tog',
        content: 'Assign HVAC Equipment'
    }
]


export default () => {

    const { id } = useParams();
    const nav = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const navEquip = queryParams.get('assignEquip');


    const { appData, setAppData } = useContext(AppContext)

    const [sessionData, , , getSessionEntity, setSessionEntity, , postObUpdate, , , updateApBank] = useSession();
    const [request] = useRest();
    // const [ sessionData, , , updateUtilData, , entityUpdate, postObUpdate ] = useSession();

    const [zone, setZone] = useState<any>(null);
    const [showBank, setShowBank] = useState<boolean>(false);
    const [dragAp, setDragAp] = useState<any>(null);
    const [obAp, setObAp] = useState<any>(null);
    const [newDpName, setNewDpName] = useState<string>('');
    const [showAssignHVAC, setShowAssignHVAC] = useState<boolean>(false);
    const [showSp, setShowSp] = useState<boolean>(false);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [showSleeve, setShowSleeve] = useState<boolean>(false);
    const [anchorId, setAnchorId] = useState<string>('');
    const [ttContent, setTtContent] = useState<string>('');
    const [hier, setHier] = useState<any>(null);

    const handleDragOver = (e: any) => e.preventDefault();
    const handleCancel = (e: any) => {
        e.preventDefault();
        setDragAp(null);
    }

    const postSetpointReset = async () => {
        initializeZone();
    }

    const handleEquipClick = () => {
        nav(`/equipment/${zone.servedBy.id}`);
    }

    const initializeZone = async (refresh: boolean = false) => {
        setZone(null);
        const newZone = await getSessionEntity('zone', id ?? '', refresh);
        if (newZone) setSessionEntity('zone', newZone);
    }

    const initOb = (e: any) => {
        const bankIndex = sessionData.apBank.map((ap: any) => ap.apsn).indexOf(dragAp.apsn);
        setObAp({ ...dragAp, bankIndex });
        updateApBank(bankIndex);
        // setBank(bank.filter(b => b.apsn != dragAp.apsn));
        setDragAp(null);
    }

    const formatDpName = (dp: any, dpIndex: number) => {
        const prof = profiles.find(p => p.prop == dp.config);
        if (dp.config == 'env') {
            const name = dp.name;
            if (name != '+|dynamic|+') return name;
            return `${prof?.title} ${dpIndex + 1}`;
        } else {
            const likeConfig = sessionData.zone.datapoints.filter((dp: any) => dp.config == dp.config);
            return `${capitalize(dp.config)} ${likeConfig.indexOf(dp) + 1}`;
        }
    }

    const submitNewDp = async () => {
        let subDp = {
            ...obAp,
            name: newDpName.length ? newDpName : '+|dynamic|+',
            config: 'env',
            zid: zone.id
        };
        try {
            const newDp = await request('datapoint', 'post', '', subDp);
            postObUpdate(newDp, 'datapoint');
            setDragAp(null);
            setObAp(null);
            setAppData({ ...appData, loading: { visible: false } });
        } catch (submitNewDpErr) {
            console.error({ submitNewDpErr });
            const err = submitNewDpErr as any;
            setAppData({
                ...appData,
                loading: { visible: false },
                helper: {
                    type: 'error',
                    title: 'Add Datapoint Error',
                    content: err.message
                }
            })
        }
    }

    const hideSp = () => {
        setShowSp(false);
    }

    const handleRefresh = (e: any) => {
        e.stopPropagation();
        initializeZone(true);
    }

    const constructHier = async () => {
        const floor = sessionData.floor ?? await getSessionEntity('floor', zone.fid);
        const bldg = sessionData.building ?? await getSessionEntity('building', floor.bid);
        setHier([bldg.name, floor.name, zone.name]);
    }

    useEffect(() => {
        if (zone && zone.id) constructHier();
    }, [zone]);

    useEffect(() => {
        (async () => {
            await initializeZone();
            setAppData({ ...appData, loading: { visible: false } });
        })();
    }, []);

    useEffect(() => {
        if (sessionData.zone) {
            setZone(sessionData.zone);
            if (localStorage.getItem('breeziProDeeplink')) localStorage.clearItem('breeziProDeeplink');
            setShowBank(!sessionData.zone.datapoints || !sessionData.zone.datapoints.length);
            setShowSleeve(!sessionData.zone.datapoints || !sessionData.zone.datapoints.length);
            if (navEquip) {
                nav(`/zone/${id}`);
            }
            setAppData({ ...appData, loading: { visible: false } })
        }
    }, [sessionData.util]);

    return (
        <main className='organization-main'>
            <div
                className='panel zone-panel'>
                {
                    hier && <ZoneHeader zone={zone} showBank={showBank} setDragAp={setDragAp} handleDragOver={handleDragOver} handleCancel={handleCancel} hier={hier} />
                }
                {
                    zone &&
                    <div className='hvac-body' onDragOver={e => handleDragOver(e)} onDrop={e => initOb(e)}>
                        <div
                            className='equip-content zone-content'
                            style={{ height: showBank ? '62.5%' : '84%' }}>
                            {/* <ZoneSleeve
                                zone={zone}
                                showBank={showBank}
                                setShowBank={setShowBank}
                                showAssignHVAC={showAssignHVAC}
                                setShowAssignHVAC={setShowAssignHVAC}
                                handleRefresh={handleRefresh}
                                setShowSp={setShowSp}
                                setShowSettings={setShowSettings}
                                showSleeve={showSleeve}
                                setShowSleeve={setShowSleeve}
                                setAnchor={setAnchorId}
                                setTtContent={setTtContent} /> */}
                            <div className='zone-cards'>
                                {
                                    (!zone.datapoints || !zone.datapoints.length) &&
                                    <h4
                                        style={{
                                            marginBottom: '10%',
                                            width: '250px',
                                            fontWeight: 'lighter',
                                            color: 'gray',
                                            border: '1px dashed gray',
                                            padding: '1%',
                                            alignSelf: 'center',
                                            position: 'absolute',
                                            left: 'calc(50% - 125px)'
                                        }}>
                                        Drag an Element from the Bank <br />
                                        above to begin configuration
                                    </h4>
                                }
                                {
                                    !showBank && zone.datapoints.every((dp:any) => dp.zid === id) &&
                                    zone.datapoints.map((dp: any, dpIndex: number) => (
                                        <Card datapoint={dp} dpIndex={dpIndex} zName={zone.name} zoneRefresh={() => initializeZone(true)} />
                                    ))
                                }
                                {
                                    showBank &&
                                    <>
                                        {
                                            zone.datapoints.map((dp: any, dpIndex: number) => (
                                                <MiniCard dp={dp} dpIndex={dpIndex} formatName={formatDpName} />
                                            ))
                                        }
                                        {
                                            dragAp &&
                                            <div className='zone-mini-card ghost-mini-card'>
                                                <img src={`${imgUrl}/apIcon.png`} />
                                                <small>
                                                    Add <span style={{ color: 'var(--breezi-blue)' }}>{dragAp.apsn}</span>
                                                    <br />to this Zone
                                                </small>
                                            </div>
                                        }
                                        {
                                            obAp &&
                                            <div className='zone-mini-card ob-mini-card'>
                                                <header className='mini-status' style={{ background: 'var(--breezi-blue)' }}></header>
                                                <div className='zone-mini-header'>
                                                    <h5 style={{ color: 'var(--prim)' }}>{obAp.apsn} <span style={{ color: 'gray', fontSize: '.7em' }}>| New Datapoint</span></h5>
                                                </div>
                                                <input value={newDpName} onChange={e => setNewDpName(e.target.value)} />
                                                <label>Custom Name</label>
                                                <h2 style={{ opacity: newDpName.length ? 0 : 1 }}>OR</h2>
                                                {
                                                    !newDpName.length
                                                        ? <a className='btn sec-btn' onClick={() => submitNewDp()}>Dynamic</a>
                                                        : <a className='btn prim-btn' onClick={() => submitNewDp()}>Submit</a>
                                                }
                                            </div>

                                        }
                                    </>
                                }
                            </div>
                        </div>
                        {
                            showSp &&
                            <div className='overlay dark-overlay zone-sp-overlay' style={{ backdropFilter: 'blur(6px)' }}>
                                <div className='card-frame'>
                                    <div className='card-face'>
                                        <div className='card-content'>
                                            <Setpoints
                                                entType='zone'
                                                entId={zone.id}
                                                defaultSp={zone.setpoints ?? false}
                                                initCancel={hideSp}
                                                currentVals={zone.meas ?? '-'}
                                                initResetAlerts={initializeZone} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    showAssignHVAC &&
                    <AssignEquip zone={zone} zoneRefresh={() => { }} closeSettings={() => setShowAssignHVAC(false)} />
                }
                {
                    showSettings &&
                    <ModifyEntity
                        entType='zone'
                        ent={zone}
                        dest='floor'
                        modifyableProps={[]} />
                }
            </div>
            {/* <Tooltip
                id='assign-equip-tooltip'
                anchor={anchorId}
                type='message'
                content={ttContent} /> */}
        </main>
    )

}