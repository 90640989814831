import { useContext, useState } from "react"
import axios from "axios";

import { useAuth } from './useAuth';

import { restUrl } from "../templates/consts";
import { AppContext } from "../context/AppContext";

interface RestParams {
    url:string;
    method:string;
    headers: {Authorization:string, 'Content-Type':'application/json'},
    data?:string|false;
}

export const useRest = ():[(route:string, method:string, tier?:string, data?:any) => Promise<any>] => {

    const [ , , , authenticateUser, getTkn, logout ] = useAuth();
    // const [ , setSessionEntity ] = useSession();
    const { appData, setAppData } = useContext(AppContext);

    const [ idTkn, setIdTkn ] = useState<string|null>(null);

    const request = (route: string, method: string, tier?: string, data?: any): Promise<any> => {
        // console.log(`Making REST request for ${route.toUpperCase()} DATA`)
        return new Promise(async (resolve, reject) => {
            // if (tier != 'rebase') setAppData({ ...appData, loading: {visible: true, msg: 'Attempting to sign up SuperUser...'} })
            try {
                const tkn = await getTkn() ?? false;
                let params:RestParams = {
                    url: `${restUrl}/${route}`,
                    method,
                    headers: {
                        Authorization: tkn,
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify(data) || false
                }
                const res = (await axios(params)).data;
                res.restTms = Math.floor(Date.now()/1000);
                resolve(res);
            } catch (restRequestErr) {
                const err = restRequestErr as any;
                if (err?.statusCode == 401) {
                    logout();
                    alert('Your session has expired or this machine has been used to log into a different BreeziPro account. You are being logged out.')
                }
                console.error({ restRequestErr })
                reject(restRequestErr);
            }
        })
    }

    return [ request ];

} 