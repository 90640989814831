import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import cToF from 'celsius-to-fahrenheit';

import { useEffect, useState } from "react";

import '../../stylesheets/Reading.css';
import { imgUrl, measSet } from '../../templates/consts';
import { useSession } from '../../hooks/useSessionDev';

interface Props {
    rdg: string;
    val: number;
    max: number;
    min: number;
    alert: any;
    diff?: boolean;
}

const ReadingGauge: React.FC<Props> = ({ rdg, val, max, min, alert, diff }) => {

    const [sessionData] = useSession();

    const [perc, setPerc] = useState<number>(val / max * 100);

    const assessAlertColor = () => {
        if (rdg && alert) {
            if (alert.crit) {
                return 'var(--crit)';
            } else if (alert.bad) {
                return 'var(--cancel)';
            } else {
                return 'var(--prim)';
            }
        }
    }

    useEffect(() => {
        setPerc(val / max * 100);
    }, [val]);

    const setUnits = () => {
        let m = measSet.find((m: any) => m.prop == rdg);
        if (m) {
            return rdg === 'htp' && sessionData.imperial ? '˚F' : m.units;
        } else {
            return '-';
        }
    }

    const setVal = (r: string, v: any) => {
        if (typeof v !== 'number') v = parseInt(v);
        return r == 'htp' && sessionData.imperial ? cToF(v) : v
    }

    const chartOptions = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            // width: '100%',
            // height: '100%'
        },
        title: false,
        subtitle: false,
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        xAxis: {
            reversed: true
        },
        plotOptions: {
            pie: {
                shadow: false,
                center: ["50%", "50%"],
                background: 'rgba(0, 0, 0, 0)',
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        enabled: false
                    }
                },
                size: window.innerWidth < 880 ? '150%' : (window.innerHeight >= 400 && window.innerHeight <= 880 ? '170%' : "140%"),
                innerSize: "90%",
                borderColor: null,
                borderWidth: 2,
                animation: false
            }
        },
        series: [
            {
                innerSize: `${perc}%`,
                data: [
                    {
                        name: '',
                        y: 100 - perc,
                        color: 'gray',
                        innerSize: '90%'
                    },
                    {
                        innerSize: `90%`,
                        name: '',
                        y: perc,
                        color: assessAlertColor()
                    }
                ]
            }
        ]
    }
    return (
        <div className={`gauge${diff ? ' diff-gauge' : ''}`}>
            <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { height: '100%', width: '100%', overflow: 'visible' } }}
                options={chartOptions} />
            <div className='gauge-readout'>
                <img className='gauge-icon' src={`${imgUrl}/${rdg == 'htp' ? 'temp' : rdg}.png`} />
                {
                    val &&
                    <h1 style={{
                        color: !val || !alert ? 'gray' : assessAlertColor(),
                        fontSize: diff ? '1.5em' : '2.2em',
                        fontWeight: 'lighter',
                        margin: 0,
                        marginBottom: '20px'
                    }}>{Math.round(
                        setVal(rdg, val)
                    )}{setUnits()}</h1>
                }
                {
                    !val &&
                    <h5 style={{ color: 'gray', marginTop: '-20%' }}>{!diff ? 'Calibrating' : '-'}</h5>
                }
            </div>
            <div className='no-touchy'></div>
        </div>
    )

}

export default ReadingGauge;