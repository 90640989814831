import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import cToF from 'celsius-to-fahrenheit';

import { useEffect, useState } from 'react';

// import { useSession } from '../../hooks/useSessionContext';
import { useSession } from '../../hooks/useSessionDev';
import { imgUrl, measSet } from '../../templates/consts';

import '../../stylesheets/Chart.css';

HighchartsMore(Highcharts);

interface Ranges {
  [key: string]: number[];
}

const rdgRanges: Ranges = {
  htp: [-5, 110],
  hhm: [0, 100],
  iaq: [0, 1000],
  co2: [0, 5000],
  voc: [0, .734],
  // dpr: [0, 300]
}
// const titles = ['Temp', 'Hum', 'IAQ', 'eCO2', 'tVOC'];
// const props = ['htp', 'hhm', 'iaq', 'co2', 'voc'];
// const imgs = ['temp', 'hum', '']

interface Props {
  meas: any;
  ht?: number;
  alert: string | false;
  datapoint?:any;
}

const switchAlertColor = (tier: string | false) => {
  if (!tier) {
    return { border: 'var(--breezi-blue)', bg: 'var(--breezi-blue-transparent)' };
  }
  if (tier == 'good') tier = 'prim';
  if (tier == 'bad') tier = 'cancel';
  return { border: `var(--${tier})`, bg: `var(--${tier}-transparent)` };
}

const CRP: React.FC<Props> = ({ meas, ht, alert, datapoint }) => {

  const [sessionData] = useSession();

  const [options, setOptions] = useState<any>({});

  let envMeas = [...measSet];
  envMeas = envMeas.splice(1, measSet.length);

  const measPercent = (rdg: keyof Ranges, val: number) => {
    const range = rdgRanges[rdg];
    if (typeof val != 'string' && range) {
      let sum = range[1] - range[0];
      // sum += parseInt(sum.toString()) / 3;
      const diff = range[1] - val;
      // return Math.round(100 - ((diff / sum) * 100));
      return (diff/sum) * 100;
    } else if (rdg === 'voc') {
      const numVal = parseFloat(val.toString());
      // return 100 - (numVal * 200)
      return numVal * 100;
    } else {
      return 100;
    }
  }

  const assessRdgAlert = (img: string) => {
    let prop = img;
    if (img === 'temp') prop = 'htp';
    if (img === 'nuevoVOC') prop = 'voc';
    if (!datapoint || !datapoint.alerts || !datapoint.alerts[prop]) {
      return 'gray';
    }
    const propAlert = datapoint.alerts[prop];
    if (propAlert.crit) {
      return 'var(--crit)';
    } else if (propAlert.bad) {
      return 'var(--cancel)';
    } else {
      return 'var(--prim-transparent)'
    }
  }

  const setChartHeight = () => {
    if (window.innerWidth > 400 && window.innerHeight <= 700 && window.innerWidth >= 1100) {
      return '60%';
    } else {
      return window.innerHeight >= 1200 ? 220 : (ht ? `${ht == 100 ? 80 : ht - 5}%` : '65%');
    }
  }

  const optionConfig = {
    title: {
      text: typeof ht == 'number' && [85, 100].includes(ht) ? '' : `Condition Risk Profile`,
      style: {
        fontFamily: 'Comfortaa',
        fontSize: '.7em',
        color: 'var(--prim)'
      },
      verticalAlign: 'bottom',
      y: -15
    },
    pane: {
      size: setChartHeight()
    },
    chart: {
      polar: true,
      type: 'area',
      backgroundColor: 'transparent',
      height: window.innerHeight >= 1200 ? 330 : 300,
      width: window.innerHeight >= 1200 ? 300 : ht == 85 ? 220 : (ht == 100 ? 300 : ''),
      animation: false
    },
    xAxis: {
      categories: envMeas.map(m => m.title),
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        formatter(): string {
          const imgs = envMeas.map(m => m.img);
          const index = arguments[0].pos;
          const img = imgs[index];
          return `<div style="display:flex;justify-content:center;align-items:center;margin-bottom:${img === 'temp' ? '8px' : ''}">
                    <img src="${imgUrl}/${img}.png" style="width:20px;" />
                  </div>`
        },
        useHTML: true
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      tickInterval: 4
    },
    plotOptions: {
      area: {
        fillColor: 'blue'
      },
      series: {
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      useHTML: true,
      formatter(this: Highcharts.TooltipFormatterContextObject): any {
        const index = this.point.index;
        const rdg = envMeas[index];
        let val = meas ? meas[rdg.prop] : '-';
        if (val != '-' && rdg.prop === 'htp' && sessionData.imperial) {
          val = Math.round(cToF(val));
        }
        const units = sessionData.imperial && rdg.prop == 'htp' ? '˚F' : rdg.units;
        const tooltipContent = `
            <div>
                <small>${rdg.title}: ${val}${units}</small>
            </div>
        `;

        return tooltipContent;
      }
    },
    series: [
      {
        data: envMeas.map(m => m.prop).map(rdg => measPercent(rdg, meas && meas[rdg] ? meas[rdg] : 'no data')),
        pointPlacement: 'on',
        lineColor: switchAlertColor(alert).border,
        fillColor: switchAlertColor(alert).bg
      }
    ]
  }

  const setMarginBottom = () => {
    if (window.innerHeight >= 400 && window.innerHeight <= 700 && window.innerWidth >= 1100) {
      return ht !== 100 ? '50px' : ''
    } else {
      return window.innerHeight >= 400 && window.innerHeight <= 880 ? '25px' : '';
    }
  }
  const setMarginLeft = () => {
    if (window.innerHeight >= 400 && window.innerHeight <= 700 && window.innerWidth >= 1100) {
      return '';
    } else {
      return ht == 100 ? '10%' : '';
    }
  }

  useEffect(() => {
    setOptions(optionConfig);
  }, [sessionData]);

  return (
    <div className='crp' style={{
      width: typeof ht == 'number' && [85, 100].includes(ht) ? '100%' : '',
      border: typeof ht == 'number' && [85, 100].includes(ht) ? 'none' : '',
      margin: ht == 85 ? '0px' : '',
      marginLeft: setMarginLeft(),
      overflow: 'visible'
    }}>
      <div className='crp-anim' style={{ alignItems: ht ? 'flex-end' : 'center', marginBottom: setMarginBottom() }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options} />
      </div>
    </div>
  )

}

export default CRP;