import { useOrganization } from "../../hooks/useOrganization";
import { imgUrl, setStatus } from "../../templates/consts"

interface Props {
    maintProp: string;
    meas: any;
    alerts: any;
    stageMeas:any;
}

const EquipRdgSet: React.FC<Props> = ({ maintProp, meas, alerts, stageMeas }) => {

    const [parseVal, parseUnits, rdgTemplates] = useOrganization();

    const status = (alerts: any) => {
        if (!alerts || !alerts.hasOwnProperty('bad') || !alerts.hasOwnProperty('crit')) return 'gray';
        if (!alerts.bad && !alerts.crit) {
            return 'var(--prim)';
        } else if (alerts.crit) {
            return 'var(--crit)';
        } else {
            return 'var(--cancel)';
        }
    }

    const setVal = (val: any) => {
        return maintProp === 'coil' ? '>270' : val;
    }

    return (
        <div
            className='org-card-rdg-set'
            style={{
                // border: `2px solid ${setStatus('days', alerts.days ?? false, true)}`,
                border: `2px solid ${status(stageMeas && stageMeas.days ? stageMeas.days.alerts : false)}`,
                paddingRight: window.innerWidth <= 767 && maintProp === 'coil' ? 0 : '',
                paddingLeft: window.innerWidth <= 767 && maintProp === 'coil' ? '5px' : ''
            }}>
            {
                (window.innerWidth <= 767 && maintProp === 'filter') && 
                <div className='supply-ret' style={{
                    background: setStatus('days', stageMeas.days.alerts ?? false)
                }}>
                    <h5>Return</h5>
                </div>
            }
            {
                window.innerWidth > 767 &&
                <>
                    <div className='supply-ret' style={{ background: status(stageMeas && stageMeas.days ? stageMeas.days.alerts : false) }}>
                        <h5>{maintProp === 'filter' ? 'Return' : 'Supply'}</h5>
                    </div>
                    <div
                        className='org-card-maint'
                        style={{
                            // background: setStatus('days', bldg.alerts.days ?? false, true), 
                            border: `2px solid ${status(stageMeas && stageMeas.days ? stageMeas.days.alerts : false)}`
                        }}>
                        <img src={`${imgUrl}/${rdgTemplates[maintProp].img}.png`} />
                        <small
                            style={{
                                marginTop: '6px',
                                fontSize: '.75em',
                                // color: status(stageMeas && stageMeas.days ? stageMeas.days.alerts : false)
                                color: stageMeas.days && stageMeas.days.val !== '-' ? status(stageMeas && stageMeas.days ? stageMeas.days.alerts : false) : 'gray'
                            }}>
                            {stageMeas.days && stageMeas.days.val !== '-' ? setVal(stageMeas.days.val) : 'Learning'} {stageMeas.days && stageMeas.days.val !== '-' ? 'days' : ''}
                            </small>
                    </div>
                </>
            }
            {
                window.innerWidth <= 767 && maintProp === 'coil' &&
                <div
                    className='org-card-maint'
                    style={{
                        // background: setStatus('days', stageMeas && stageMeas.days ? stageMeas.days.alerts : false, true),
                        background: 'none',
                        // border: `2px solid ${setStatus('days', stageMeas && stageMeas.days ? stageMeas.days.alerts : false)}`
                        border: '2px solid gray'
                    }}>
                    <img src={`${imgUrl}/${rdgTemplates[maintProp].img}.png`} />
                    {/* <small
                        style={{
                            marginTop: '6px',
                            color: setStatus('days', bldg.alerts ? bldg.alerts.days : false)
                        }}>{bldg.meas ? bldg.meas.days : '-'} days</small> */}
                </div>
            }
            {
                rdgTemplates[maintProp].vals.map((val: any) => (
                    <div
                        className='org-card-tmp-hum'
                        style={{
                            // border: `2px solid ${setStatus(val.prop, alerts[val.prop] ?? false)}`,
                            border: `2px solid ${status(stageMeas && stageMeas[val.prop] ? stageMeas[val.prop].alerts : false)}`,
                            background: window.innerWidth <= 767 ? status(stageMeas && stageMeas[val.prop] ? stageMeas[val.prop].alerts : false) : ''
                        }}>
                        {
                            window.innerWidth > 767 &&
                            <div className='org-card-tmp-hum-head'>
                                <img src={`${imgUrl}/${val.img}.png`} />
                            </div>
                        }
                        {
                            window.innerWidth <= 767 && <img style={{ height: '60%', marginTop: '20%' }} src={`${imgUrl}/${val.img}.png`} />
                        }
                        {
                            window.innerWidth > 767 &&
                            <h3 style={{ color: status(stageMeas && stageMeas[val.prop] ? stageMeas[val.prop].alerts : false), display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>{parseVal(stageMeas && stageMeas[val.prop] ? stageMeas[val.prop].val : false, val.prop)}<span style={{ fontSize: '.75em' }}>{parseUnits(val.prop)}</span></h3>
                        }
                    </div>
                ))
            }
            {
                window.innerWidth <= 767 && maintProp === 'filter' &&
                <div
                    className='org-card-maint'
                    style={{
                        // background: setStatus('days', alerts.days ?? false, true),
                        background: 'none',
                        // border: `2px solid ${setStatus('days', alerts.days ?? false)}`
                        border: '2px solid gray'
                    }}>
                    <img src={`${imgUrl}/${rdgTemplates[maintProp].img}.png`} />
                    {/* <small
                        style={{
                            marginTop: '6px',
                            color: setStatus('days', bldg.alerts ? bldg.alerts.days : false)
                        }}>{bldg.meas ? bldg.meas.days : '-'} days</small> */}
                </div>
            }
            {
                window.innerWidth <= 767 && maintProp === 'coil' &&
                <div
                    className='supply-ret'
                    style={{
                        background: setStatus('days', alerts.days ?? false),
                        paddingRight: 0,
                        paddingLeft: '5px',
                    }}>
                    <h5 style={{ transform: 'rotate(90deg)' }}>Supply</h5>
                </div>
            }
        </div>
    )
}

export default EquipRdgSet;