import { imgUrl } from "../../templates/consts";
import CardHeader from "./CardHeader";

interface Props {
    datapoint: any;
    dpIndex: number;
    setActiveIndex: (index: number) => void;
    zoneRefresh: () => void;
}

const Equip: React.FC<Props> = ({ datapoint, dpIndex, setActiveIndex, zoneRefresh }) => {
    return (
        <div className='card-content'>
            <CardHeader datapoint={datapoint} dpIndex={dpIndex} subtitle={'Equipment'} setActiveIndex={setActiveIndex} zoneRefresh={zoneRefresh} />
            <div className='dp-card-body'>
                <div className='dp-card-box double-dp-box'>
                    <img src={`${imgUrl}/comingSoon.png`} />
                </div>
            </div>
        </div>
    )
}

export default Equip;