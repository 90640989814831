import { useAuth } from "./useAuth"

export const useLogin = (): [
    (formData:any) => any
] => {

    const [, , , , , , , , , login] = useAuth();

    const authenticate = async (formData: any) => {
        const loginResult = await login(formData);
    }

    return [authenticate];

}