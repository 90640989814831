import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './AppV2';
import reportWebVitals from './reportWebVitals';
import 'react-tooltip/dist/react-tooltip.css';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register(`/service-worker.js?${new Date().getTime()}`)
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }