import { useContext, useState } from "react";
import { imgUrl } from "../../templates/consts";

import '../../stylesheets/Radial.css';
import { AppContext } from "../../context/AppContext";

interface Props {
    dp: any;
    bg: string;
    closeRadial: (newRadial: boolean) => void;
    initDpDelete: (dp: any) => void;
}

const Radial: React.FC<Props> = ({ dp, bg, closeRadial, initDpDelete }) => {

    const { appData, setAppData } = useContext(AppContext);

    const [btns, setBtns] = useState<any[]>([
        {
            dir: 'N',
            img: 'edit',
            text: 'Edit'
        },
        {
            dir: 'S',
            img: 'setpoints',
            text: 'Setpoints'
        },
        {
            dir: 'E',
            img: 'basura',
            text: 'Delete'
        },
        {
            dir: 'W',
            img: 'gearsWhite',
            text: 'Configure'

        }
    ]);
    const [showClose, setShowClose] = useState<boolean>(false);

    const initClose = () => {
        setTimeout(() => {
            setShowClose(true);
        }, 1000);
    }

    const initBlocker = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Disabled',
                content: "This feature has been restricted for the duration of the Pilot Program"
            }
        });
    }

    const hoverClick = (index: number) => {
        initBlocker();
        // if (index == 2) {
        //     initDpDelete(dp);
        // }
    }

    const [hoverBtn, setHoverBtn] = useState<number>(-1);
    return (
        <>
            <div className='section-blur'></div>
            <div className='radial'>
                {
                    btns.map((radBtn: any, radBtnIndex: number) => (
                        <div className="radial-node">
                            <div
                                className={`radial-btn ${radBtn.dir}-radial`}
                                style={{
                                    // border: `2px solid ${bg}`,
                                    // animation: `radial-slide-${radBtn.dir} ${.25 * radBtnIndex}s forwards`,
                                    zIndex: radBtnIndex
                                }}
                                onMouseOver={() => setHoverBtn(radBtnIndex)}
                                onMouseLeave={() => setHoverBtn(-1)}
                                onClick={() => hoverClick(radBtnIndex)}>
                                {
                                    hoverBtn == radBtnIndex && ['N', 'S'].includes(radBtn.dir) &&
                                    <small>{radBtn.text}</small>
                                }
                                <img src={`${imgUrl}/${radBtn.img}.png`} />
                                {
                                    hoverBtn == radBtnIndex && ['E', 'W'].includes(radBtn.dir) &&
                                    <small>{radBtn.text}</small>
                                }
                            </div>
                        </div>
                    ))
                }
                <div
                    className='radial-ap'
                    onMouseOver={() => setShowClose(true)}
                    onMouseLeave={() => setShowClose(false)}
                    onClick={() => closeRadial(false)}
                    style={{ zIndex: 5 }}>
                    <img
                        src={`${imgUrl}/${showClose ? 'closeToo' : 'apIcon'}.png`}
                        style={{ background: 'var(--breezi-blue)' }} />
                </div>
                <h6 style={{ color: 'var(--equip)' }}>{dp.apsn}</h6>
            </div>
        </>
    )
}

export default Radial;