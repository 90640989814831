import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cToF from 'celsius-to-fahrenheit';

import { useForm } from '../../hooks/useFormContext';
import { useSession } from '../../hooks/useSessionDev';
import { imgUrl } from '../../templates/consts';

interface Props {
    initProceed:() => void;
}

const LocaleValidator:React.FC<Props> = ({ initProceed }) => {

    const [ sessionData ] = useSession();
    const [ formData, updateFormData ] = useForm();

    const calcLocalTime = (tz:number) => {
        const lcoaleTime = moment().utcOffset(tz/60);
        return lcoaleTime.format('h:mma') ?? '';
    }

    return (
        <div className='locale-validator'>
            {
                formData && formData.locale &&
                <>
                    <div className='lv-time'>
                    <div className='lv-tz'>
                        <img src={ `${imgUrl}/timezone.png` } />
                        <h3>{ calcLocalTime(formData.locale.tz) }</h3>
                    </div>
                </div>
                <div className='lv-rows'>
                    <div className='lv-row'>
                        <img className='city' src={ `${imgUrl}/city.png`} />
                        <h4>{ formData.locale.city }, { formData.locale.state }</h4>
                    </div>
                </div>
                <div className='lv-weather'>
                    <div className='lv-icon lv-cond'>
                        <img src={ `https://openweathermap.org/img/wn/${formData.locale.icon}@2x.png` } />
                        <h4>{ formData.locale.conditions }</h4>
                    </div>
                    <div className='lv-temp-hum'>
                        <div className='lv-rdg' style={ {borderBottomRightRadius: '0px'} }>
                            <img src={ `${imgUrl}/temp.png` } />
                            <h4>{sessionData.imperial ? cToF(parseInt(formData.locale.temp)) : parseInt(formData.locale.temp)}˚{sessionData.imperial ? 'F' : 'C'}</h4>
                        </div>
                        <div className='lv-rdg' style={ {borderTopRightRadius: '0px'} }>
                            <img src={ `${imgUrl}/hum.png` } />
                            <h4>{ formData.locale.hum }%</h4>
                        </div>
                    </div>
                </div>
                </>
            }
        </div>
    )

}
 
export default LocaleValidator;