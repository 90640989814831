import { useState, useEffect, useRef } from 'react';

import DropSelector from '../selectors/DropSelector';

import { useForm } from '../../hooks/useFormContext';

import { Input } from '../../tsDeclarations/form';
import { imgUrl } from '../../templates/consts';
import { useSelector } from '../../hooks/useSelector';
import { parseInputContent, validatePhoneNumber } from '../../util/inputParser';
import Tooltip from '../util/tooltip/Tooltip';

interface Props {
    input: Input;
    invalidProps: any;
    img: string;
    signup: boolean;
}

interface PhoneNumber {
    countryCode: string;
    areaCode?: string;
    phoneNo: string;
}

const TextInput: React.FC<Props> = ({ input, invalidProps, img, signup }) => {

    const ref = useRef(null);

    const [content, setContent] = useState('');
    const [blur, setBlur] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({ countryCode: '', phoneNo: '' });
    const [formData, updateFormData] = useForm();
    const [showPw, setShowPw] = useState<boolean>(false);
    const [inputErr, setInputErr] = useState<string>('');
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [missingPwReqs, setMissingPwReqs] = useState<string[]>([])

    const [, , ,] = useSelector('');

    const handleBlur = () => {
        setShowPw(false);
        setShowTooltip(false);
        if (input.prop == 'phone_number') {
            try {
                validatePhoneNumber(`+${formData.countryCodes}${content}`);
            } catch (validPhoneNumberErr) {
                setInputErr(validPhoneNumberErr as string);
            }
        }
    }
    const primePhoneNumber = () => {
        // setShowDropSel(input.prop == 'phone_number' && !phoneNumber.countryCode);
        // if (!showDropSel && !phoneNumber.countryCode) setContent('');
    }
    const updateContent = (val: string) => {
        setContent(val);
    }
    const updateCountryCode = (countryCode: string) => {
        setPhoneNumber({ countryCode, phoneNo: '' });
    }

    useEffect(() => {
        try {
            if (showTooltip) filterPwReqs();
            const parsed = parseInputContent(input, content);
            if (img === 'login' && input.prop === 'password') {
                setTimeout(() => updateFormData({ password: content }), 50)
            } else {
                updateFormData({ [input.prop]: content });
            }
        } catch (updateContentErr) {
            setInputErr(updateContentErr as string);
            setTimeout(() => {
                setInputErr('');
            }, 1500);
        }
    }, [content]);

    // Handles Password Validator Tooltip in Form Component
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const handleFocus = () => {
        if (signup && input.prop == 'password') {
            setShowTooltip(true);
            filterPwReqs(true);
        } else if (img === 'login') {
            // var ipt = document.getElementById(`#${input.prop}`) as any;
            // setTimeout(() => {
            //     if (ipt && ipt.matches(':-internal-autofill-selected')) {
            //         /* do something */
            //     }
            // }, 500);
            // if (ref.current) {
            //     const cur = ref.current as any;
            //     if (cur.value) setContent(cur.value);
            // }
        }
    }

    const filterPwReqs = (reset?: boolean) => {
        const len = { cond: content.length >= 8 && content.length <= 16, msg: 'Must be 8 - 16 characters' };
        const special = { cond: '!@#$%^&*+}|{~`<>?/'.split('').some((char: string) => content.includes(char)), msg: 'Must include special character' };
        const capital = { cond: content.split('').some((char: string) => content.includes(char.toUpperCase()) && !'!@#$%^&*+}|{~`<>?/'.includes(char)), msg: 'Must include uppercase letter' };
        const lower = { cond: content.split('').some((char: string) => content.includes(char.toLowerCase()) && !'!@#$%^&*+}|{~`<>?/'.includes(char)), msg: 'Must include lowercase letter' };
        const num = { cond: content.split('').some((char: string) => parseInt(char)), msg: 'Must include a number' };
        if (reset) {
            setMissingPwReqs([len, special, capital, lower, num].map((req: any) => req.msg));
        } else {
            if (content.length) {
                const reqs = [len, special, capital, lower, num];
                let newReqs = reqs.reduce((updatedReqs: string[], req: any) => {
                    if (!req.cond) updatedReqs.push(req.msg);
                    return updatedReqs;
                }, []);
                setMissingPwReqs(newReqs);
            } else {
                setMissingPwReqs([len, special, capital, lower, num].map((req: any) => req.msg));
            }
        }
    }

    const handleOnInput = (e: any) => {
        // e.preventDefault();
        const newVal = e.target.value;
        if (newVal.length) {
            updateContent(newVal);
            updateFormData({ [input.prop]: newVal });
        } else {
            updateContent('');
            updateFormData({ [input.prop]: '' });
        }
    }

    useEffect(() => {
        if (!inputErr.length) setContent('');
    }, [inputErr]);

    // useEffect(() => {
    //     if (img === 'login') {
    //         if (ref.current) {
    //             let interval = setInterval(() => {
    //                 const cur = ref.current as any;
    //                 if (cur && cur.value) {
    //                     updateContent(cur.value);
    //                     updateFormData({ [input.prop]: cur.value })
    //                     clearInterval(interval);
    //                 }
    //             }, 100);
    //         }
    //     }
    // }, []);

    // useEffect(() => {

    //     // TODO: AUTOFILL HERE

    //     // Check if email and password inputs are populated by the browser autofill
    //     if (document.activeElement instanceof HTMLInputElement) {
    //         const activeElement = document.activeElement as HTMLInputElement;

    //         if (activeElement.name === 'email' && activeElement.value) {
    //             setContent(activeElement.value);
    //         }

    //         if (activeElement.name === 'password' && activeElement.value) {
    //             setContent(activeElement.value);
    //         }
    //     }
    // }, []);

    return (
        <>
            {
                input &&
                <label
                    className='text-input'>
                    <div className='input-box'>
                        {
                            ['phone_number'].includes(input.prop) &&
                            <div className='input-pre'>
                                <h5>+{formData.countryCodes}</h5>
                            </div>
                        }
                        <input
                            onClick={() => primePhoneNumber()}
                            className='form-input'
                            value={content}
                            // onChange={e => updateContent(e.target.value)}
                            onInput={e => handleOnInput(e)}
                            onFocus={handleFocus}
                            onBlur={e => handleBlur()}
                            type={input.textType == 'password' && showPw ? 'text' : input.textType}
                            autoComplete={img == 'login' ? (input.prop === 'email' ? 'email' : 'password') : ''}
                            id={input.prop}
                            name={input.prop}
                            ref={ref} />
                        {
                            input.textType && ['password'].includes(input.textType) &&
                            <div className='input-pre input-aft' onClick={() => setShowPw(!showPw)}>
                                <img src={`${imgUrl}/${showPw ? 'hide' : 'show'}.png`} />
                            </div>
                        }
                    </div>
                    {input.label + (input.optional && input.label[input.label.length - 1] != '*' ? '*' : '')}
                    {
                        inputErr.length > 0 &&
                        <div className='input-err' onClick={() => setInputErr('')}>
                            <h6>{inputErr}</h6>
                        </div>
                    }
                    {
                        showTooltip && missingPwReqs.length > 0 &&
                        <div className='faux-tt pw-tt'>
                            {
                                missingPwReqs.map((req: any) => (
                                    <small>{req}</small>
                                ))
                            }
                        </div>
                    }
                </label>
            }
        </>
    )
}

export default TextInput;