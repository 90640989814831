import { useEffect, useState } from "react";
import { capitalize, imgUrl, measSet as measKey } from "../../../templates/consts";

interface Props {
    zoneDp:any;
}

const EquipDp:React.FC<Props> = ({ zoneDp }) => {

    const [datapoint, setDatapoint] = useState<any>(zoneDp.datapoint);
    const [zone, setZone] = useState<any>(zoneDp.zone);
    const [measSet, setMeasSet] = useState<any>([]);
    
    const listMeas = () => {
        if (datapoint.meas && datapoint.alerts) {
            let mSet = ['htp', 'hhm', 'iaq', 'co2', 'voc']
            if (zone.sections?.some((sec: any) => sec.diffSensor && sec.diffSensor == datapoint.apsn)) mSet.push('dpr', 'days');
            setMeasSet(mSet);
        }
    }

    useEffect(() => {
        listMeas();
    }, []);


    const getEnvIndex = () => {
        const secDps = zone.datapoints.filter((dp: any) => dp.sectionIndex == datapoint.sectionIndex);
        return secDps.map((dp:any) => dp.id).indexOf(datapoint.id) + 1;
    }

    const formatName = () => {
        if (zone.sections?.some((sec: any) => sec.diffSensor && sec.diffSensor == datapoint.apsn)) {
            const likeConfig = zone.datapoints.filter((dp: any) => dp.config == datapoint.config);
            return `${capitalize(datapoint.config)} ${likeConfig.indexOf(datapoint) + 1}`;
        } else {
            return `${capitalize(datapoint.config)} Env Sensor ${getEnvIndex()}`
        }
    }

    const rdgStatus = (rdg: string) => {
        if (!datapoint.meas) return 'gray';
        if (datapoint.alerts[rdg].bad) return 'var(--cancel)';
        if (datapoint.alerts[rdg].crit) return 'var(--crit)';
        return 'var(--prim-transparent)';
    }

    const dpStatus = () => {
        let color = 'gray';
        if (datapoint.meas && datapoint.alerts) {
            if (measSet.some((m: string) => datapoint.alerts[m].bad)) {
                color = 'cancel';
            } else if (measSet.some((m: string) => datapoint.alerts[m].crit)) {
                color = 'crit';
            } else {
                color = 'prim-transparent';
            }
        }
        return `var(--${color})`;
    }

    return (
        <div className='tooltip-msg'>
            {
                zone && datapoint &&
                <div className='equip-tooltip'>
                    <div className='equip-tooltip-header' style={ {borderBottom: `1px solid ${dpStatus()}`} }>
                        <p style={ {color: dpStatus()} }>{ formatName() }</p>
                    </div>
                        <div className='equip-tooltip-rdgs'>
                        {
                            measSet.map((rdg: string) => (
                                <div className='equip-tooltip-rdg' style={ { border: `2px solid ${rdgStatus(rdg)}` } }>
                                    <img src={`${imgUrl}/${rdg}.png`} />
                                    <small style={{ color: rdgStatus(rdg) }}>
                                        {datapoint.meas[rdg] ?? '-'}
                                        {datapoint.meas[rdg] && (measKey.find((p:any) => p.prop == rdg)?.units ?? '')}
                                    </small>
                                </div>
                            ))
                        }
                        </div>
                </div>
            }
        </div>
    )

}

export default EquipDp;