import { imgUrl, setStatus } from "../../templates/consts"
import '../../stylesheets/Card.css'

interface Props {
    datapoint:any;
}

const Load:React.FC<Props> = ({ datapoint }) => {
    return (
        <div className='card-content' style={ {justifyContent: 'center'} }>
            <div 
                className='card-load'>
                <div className='ap-flash' 
                    style={ {
                        // animation: `flashes${datapoint.alerts?.overall ? '-' + datapoint.alerts.overall : ''} 1s forwards`,
                        background: setStatus('', datapoint.alerts?.overall ?? ''),
                        border: '1px solid var(--white)'
                    } }>
                    <div className='ap-translucent'>
                        <img src={ `${imgUrl}/datapoint.png` } />
                    </div>
                </div>
                <img className='card-logo' src={ `${imgUrl}/pro-box.png` } />
            </div>
        </div>
    )
}

export default Load;