import { useState, useEffect } from "react"
import { imgUrl } from "../../../templates/consts";

interface Props {
    setParentTog: (newIndex:number) => void;
}

const SettingsToggle:React.FC<Props> = ({ setParentTog }) => {

    const [activeTog, setActiveTog] = useState<number>(0);

    const togs = [
        {title: 'Org Details', img: 'enterprise'},
        {title: 'Team', img: 'team'},
        {title: 'Preferences', img: 'gearsWhite'}];

    useEffect(() => {
        setParentTog(activeTog);
    }, [activeTog]);

    return (
        <div className='settings-toggle'>
            
            <div className='settings-tri'>
                {/* <div
                    className={`s-node${activeTog == 0 ? ' active-s-node' : ''}`}
                    onClick={ () => setActiveTog(0) }>
                        <img src={`${imgUrl}/enterprise.png`} />
                </div>
                <div
                    className={`s-node${activeTog == 1 ? ' active-s-node' : ''}`}
                    onClick={ () => setActiveTog(1) }>
                        <img style={ {width: '90%'} } src={`${imgUrl}/team.png`} />
                </div> */}
                {/* <div
                    className='s-node disabled-s-node'>
                        <img src={`${imgUrl}/team.png`} />
                </div> */}
                {/* <div
                    className='s-node disabled-s-node'>
                        <img src={`${imgUrl}/gearsWhite.png`} />
                </div> */}
                {
                    togs.map((tog: any, togIndex: number) => (
                        <div
                            className={`s-node${activeTog == togIndex ? ' active-s-node' : ''}`}
                            onClick={ () => setActiveTog(togIndex) }>
                                <img style={ {
                                    width: tog.img == 'team' ? '90%' : ''
                                } } src={`${imgUrl}/${tog.img}.png`} />
                        </div>
                    ))
                }
            </div>
            <h3>{ togs[activeTog].title }</h3>
        </div>
    )

}

export default SettingsToggle;