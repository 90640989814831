import { useEffect, useState } from "react";
import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl } from "../../templates/consts";
import { useSession } from "../../hooks/useSessionDev";

interface Props {
    sec: any;
    bankState: string;
}

const EquipDiff: React.FC<Props> = ({ sec, bankState }) => {

    const [equip] = useEquipment();
    const [sessionData] = useSession();

    const [diffDp, setDiffDp] = useState<any>(null);

    useEffect(() => {
        setDiffDp(equip.datapoints.find((dp: any) => dp.apsn == sec.diffSensor));
    }, []);

    const alertColor = (rdg?: string) => {
        let color = 'breezi-blue'
        if (diffDp && diffDp.meas && diffDp.alerts) {
            const diffMeas = rdg ? [rdg] : ['days', 'dpr'];
            if (diffMeas.some((prop: string) => diffDp.alerts[prop].crit)) {
                color = 'crit';
            } else if (diffMeas.some((prop: string) => diffDp.alerts[prop].bad)) {
                color = 'cancel';
            } else {
                color = 'prim';
            }
        }
        return `var(--${color})`;
    }

    const alertClass = (rdg: string) => {
        let alert = '';
        if (rdg === 'days') {
            return ' good';
        } else if (diffDp && diffDp.meas && diffDp.alerts) {
            if (diffDp.alerts[rdg].crit || diffDp.meas.dpr > 120 || (diffDp.meas.days < 15 && rdg === 'days')) {
                return ' crit';
            } else if (diffDp.alerts[rdg].bad) {
                return ' bad'
            } else {
                return ' good';
            }
        }
        return alert;
    }

    const skewByApElem = () => {
        const secDps = equip.datapoints.filter((dp: any) => dp.sectionIndex == sec.index);
        if (secDps.length && bankState == '') {
            const upstream = secDps.find((sDp: any) => sDp.loc == 'up');
            const downstream = secDps.find((sDp: any) => sDp.loc == 'down');
            if (upstream && !downstream) return '40px';
            if (downstream && !upstream) return '-40px';
        }
        return '0';
    }

    const calcVal = (rdg: string) => {
        const calced = rdg === 'dpr' && sessionData.iwg ? (diffDp.meas[rdg] * .0040147).toFixed(3) : diffDp.meas[rdg]
        if (diffDp?.config === 'coil' && rdg === 'days') return '>270';
        return calced;
    }

    const calcUnits = (rdg: string) => {
        return rdg === 'dpr' && sessionData.iwg ? 'iwg' : (rdg == 'days' ? 'days' : 'Pa');
    }

    return (
        <div
            className='hvac-diff'
            style={{
                borderTop: `2px solid ${alertColor()}`,
                marginLeft: skewByApElem(),
                maxWidth: '200px'
            }}>
            <div
                className='hvac-diff-nodes'
                style={{
                    flexDirection: bankState != '' || (window.innerHeight >= 400 && window.innerHeight <= 880) ? 'row' : 'column'
                }}>
                {/* {
                    diffDp && diffDp.calibrating && ['days', 'dpr'].map((rdg: string) => (
                        <div className='ap-equip-rdg'>
                            <div className='ap-equip-head'>
                                <img src={`${imgUrl}/${sec.elems[0].type === 'Coil' && rdg === 'days' ? 'coilFlow' : rdg}.png`} />
                            </div>
                            <h6 style={{ lineHeight: window.innerWidth >= 1200 ? '' : '11px', fontSize: '.6em' }}>
                                Learning
                            </h6>
                        </div>
                    ))
                } */}
                {
                    diffDp && ['days', 'dpr'].map((rdg: string) => (
                    <div className={`ap-equip-rdg${alertClass(rdg)}${diffDp.alerts ? '-equip-rdg' : ''}`}>
                        <div className='ap-equip-head'>
                            <img src={`${imgUrl}/${sec.elems[0].type === 'Coil' && rdg === 'days' ? 'coilFlow' : rdg}.png`} />
                        </div>
                        {
                            diffDp.meas && diffDp.meas.hasOwnProperty(rdg) &&
                            <h6 style={{ lineHeight: window.innerWidth >= 1200 ? '' : '11px', height: '100%', paddingTop: '10px' }}>
                                {calcVal(rdg)}<br />
                                <span style={{ fontSize: '.65em' }}>{calcUnits(rdg)}</span>
                            </h6>
                        }
                        {
                            (!diffDp.meas || !diffDp.meas.hasOwnProperty(rdg)) &&
                            <h6 style={{ lineHeight: '11px', height: '100%', paddingTop: '10px' }}>
                                -<br />
                                <span style={{ fontSize: '.65em' }}>{calcUnits(rdg)}</span>
                            </h6>
                        }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default EquipDiff;