// import { Regex } from "aws-sdk/clients/iot";
import { Input } from "../tsDeclarations/form"

import { isPossibleNumber, isValidNumber } from "libphonenumber-js";

type CharMap = {
    char:string;
    index:number;
}

const transformProperNoun = (str:string) => {
    str = str.trim().toLowerCase();
    let strArr:string[] = str.split('');
    const spaceMaps:CharMap[] = 
        strArr.map((char, chIndex) => ({ char, index:chIndex }))
            .filter(chObj => chObj.char == ' ');
    spaceMaps.forEach((sm:CharMap) => {
        strArr[sm.index + 1] = str[sm.index + 1].toUpperCase();
    });
    let retStr:string = strArr.join('');
    return `${retStr[0].toUpperCase()}${retStr.substring(1, retStr.length)}`;
}

const validatePw = (pw:string) => {
    let errMsg:string = '';
    if (pw.length < 8 || pw.length > 16) {
        errMsg = 'Must be 8-16 characters in length';
    } else if (pw.split('').filter((char:string) => char == ' ').length) {
        errMsg = 'Can not include spaces'
    } else {
        const lowerRegex:RegExp = /[a-z]/;
        const upperRegex:RegExp = /[A-Z]/;
        const numberRegex:RegExp = /[0-9]/;
        const specialCharRegex:RegExp = /[!@#$%^&*(),.?":{}|<>]/;
        const regexNomenclatures = [
            'lowercase letter',
            'uppercase letter',
            'number',
            'special character'
        ];
        [lowerRegex, upperRegex, numberRegex, specialCharRegex].forEach((re, reIndex) => {
            let valid = re.test(pw);
            if (!valid && !errMsg.length) errMsg = `Must include at least one ${regexNomenclatures[reIndex]}`
        });
    }
    return errMsg.length ? {invalid: true, msg: errMsg} : pw;
}

const validateEmail = (email:string) => {
    const emailRegex:RegExp = /^(?!.*(?:\.com|\.net)$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
    return emailRegex.test(email)
        ? email
        : {invalid: true, msg: `Must be a valid company email (no .com, .net, etc)`}
}

const validatePhoneNumberByCountry = (data:any) => {
    const fullPhoneNumber = data.countryCodes + data.phone_number;
    const validPhoneNumber = isValidNumber(fullPhoneNumber, data.country);
    return validPhoneNumber ? data.phone_number : {invalid: true, msg: `${fullPhoneNumber} is not a valid number for country ${data.country}`}
}

export const validateInputs = (formData:any, inputs:Input[]) => {
    const toValidate = inputs.filter((input:Input) => input.textType && ['properNoun', 'phoneNumber', 'email', 'password'].includes(input.textType));
    if (toValidate.length) {
        return toValidate.reduce((invalidInputs:any, input:Input) => {
            try {
                switch (input.prop) {
                    case 'given_name':
                        invalidInputs[input.prop] = transformProperNoun(formData[input.prop]);
                        break;
                    case 'family_name':
                        invalidInputs[input.prop] = transformProperNoun(formData[input.prop]);
                        break;
                    case 'password':
                        invalidInputs[input.prop] = validatePw(formData[input.prop]);
                        break;
                    case 'confPw':
                        invalidInputs[input.prop] = validatePw(formData[input.prop]);
                        break;
                    case 'email':
                        invalidInputs[input.prop] = validateEmail(formData[input.prop]);
                        break;
                    case 'phone_number':
                        invalidInputs[input.prop] = validatePhoneNumberByCountry(formData);
                        break;
                    default:
                        invalidInputs[input.prop] = formData[input.prop];
                }
                if (input.match && !invalidInputs[input.prop]?.invalid) {
                    const inputMatch = toValidate.find(validateInput => validateInput.prop == input.match);
                    const valMatch = invalidInputs[input.match];
                    if (typeof valMatch == 'string' && invalidInputs[input.prop] != valMatch) {
                        invalidInputs[input.prop] = {invalid: true, msg: `Entered ${input.label} must match ${inputMatch?.label} exctly`}
                    }
                }
                return invalidInputs;
            } catch(validateInputsErr) {
                console.error({validateInputsErr})
            }
        }, {})
    }
}
