import CardHeader from "./CardHeader";
import CRPChart from '../chart/CRP';

interface Props {
    datapoint: any;
    dpIndex: number;
    setActiveIndex: (index: number) => void;
    zoneRefresh: () => void;
}

const CRP: React.FC<Props> = ({ datapoint, dpIndex, setActiveIndex, zoneRefresh }) => {
    return (
        <div className='card-content'>
            <CardHeader datapoint={datapoint} dpIndex={dpIndex} subtitle='Condition Risk Profile' setActiveIndex={setActiveIndex} zoneRefresh={zoneRefresh} />
            <div className='card-rdg-content'>
                <div className='dp-card-body crp-card-body'>
                    <CRPChart meas={datapoint.meas ?? false} ht={100} alert={datapoint.alerts?.overall ?? false} />
                </div>
            </div>
        </div>
    )
}

export default CRP;