import { useContext, useEffect, useState } from "react";
import { imgUrl } from "../../templates/consts";
import { AppContext } from "../../context/AppContext";
import { useLogin } from "../../hooks/useLogin";

interface Props {
    closeForm: (attemptLogin:boolean) => void;
    initForgot: () => void;
}

const titles = [
    'Enter Credentials'
];

const loginProps = [
    ['email', 'password'],
    ['newPassword', 'confPassword']
]

const propTitles = [
    ['Email', 'Password'],
    ['New Password', 'Confirm New Password']
]

const LoginForm: React.FC<Props> = ({ closeForm, initForgot }) => {

    const { appData, setAppData } = useContext(AppContext);
    
    const [authenticate] = useLogin();

    const [step, setStep] = useState<number>(0);
    const [formData, setFormData] = useState<any>({});
    const [showPw, setShowPw] = useState<boolean>(false);
    const [stepFulfilled, setStepFulfilled] = useState<boolean>(false);
    const [missingPwReqs, setMissingPwReqs] = useState<any>([]);
    const [showReqs, setShowReqs] = useState<boolean>(false);
    
    const handleKeyDown = (e:any) => {
        if (e.key == 'Enter') {
            if (stepFulfilled) {
                // currentStep == template.steps.length - 1
                //     ? submitForm()
                //     : updateCurrentStep(currentStep + 1);
                stepSubmit();
            }
        }
        if (e.key == 'Escape') {
            closeForm(false)
        }
    }

    const updateFormData = (prop:string, val:string) => {
        setFormData({ ...formData, [prop]: val });
    }

    useEffect(() => {
        let props = loginProps[step];
        setStepFulfilled(props.every((p:string) => formData[p] && formData[p].length));
    }, [formData]);

    const forgotModal = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Contact Breezi Support',
                content: "During the Pilot Program, please contact support@breezi.io if you have forgotten your password."
            }
        });
    }

    const filterPwReqs = (reset: boolean) => {
        let content = formData.newPassword ?? '';
        const len = { cond: content.length >= 8 && content.length <= 16, msg: 'Must be 8 - 16 characters' };
        const special = { cond: '!@#$%^&*+}|{~`<>?/'.split('').some((char: string) => content.includes(char)), msg: 'Must include special character' };
        const capital = { cond: content.split('').some((char: string) => content.includes(char.toUpperCase()) && !'!@#$%^&*+}|{~`<>?/1234567890'.includes(char)), msg: 'Must include uppercase letter' };
        const lower = { cond: content.split('').some((char: string) => content.includes(char.toLowerCase()) && !'!@#$%^&*+}|{~`<>?/1234567890'.includes(char)), msg: 'Must include lowercase letter' };
        const num = { cond: content.split('').some((char: string) => parseInt(char) || char === '0'), msg: 'Must include a number' };
        const match = { cond: content === loginProps[step][1], msg: 'Passwords must match' }
        if (reset) {
            setMissingPwReqs([len, special, capital, lower, num, match].map((req: any) => req.msg));
        } else {
            if (content.length) {
                const reqs = [len, special, capital, lower, num, match];
                let newReqs = reqs.reduce((updatedReqs: string[], req: any) => {
                    if (!req.cond) updatedReqs.push(req.msg);
                    return updatedReqs;
                }, []);
                setMissingPwReqs(newReqs);
            } else {
                setMissingPwReqs([len, special, capital, lower, num, match].map((req: any) => req.msg));
            }
        }
    }

    const handleFocus = (prop:string) => {
        if (prop === 'newPassword') {
            setShowReqs(true);
            filterPwReqs(true);
        } else {
            // setShowReqs(false);
        }
    }

    const stepSubmit = async () => {
        setShowPw(false);
        try {
            let data = formData;
            data.email = data.email.toLowerCase();
            await authenticate(formData);
            closeForm(true);
        } catch (stepSubmitErr) {
            console.error(stepSubmitErr);
            if (stepSubmitErr === 'Password Update Required') {
                setStep(1);
                setAppData({
                    ...appData,
                    helper: {
                        type: 'info',
                        title: 'Temporary Password Accepted',
                        content: `Click “Set My Password” below to select the permanent password you will use to access BreeziPro going forward.`
                    }
                });
            } else {
                const err = stepSubmitErr as any;
                console.error({ err })
                setAppData({
                    ...appData,
                    helper: {
                        type: 'error',
                        title: 'Authentication Error',
                        content: err.message ?? err.toString()
                    }
                });
            }
        }
    }

    useEffect(() => {
        if (formData.newPassword?.length) {
            filterPwReqs(false);
        } else {
            filterPwReqs(true);
        }
    }, [formData.newPassword]);

    useEffect(() => {
        if (formData.newPassword?.length && formData.confPassword === formData.newPassword) {
            setMissingPwReqs(missingPwReqs.filter((req:any) => req.cond === 'match'));
        }
    }, [formData.confPassword]);

    return (
        <main
            className='form-main'
            onKeyDown={e => handleKeyDown(e)}>
            <div className='panel popup-panel form-panel' style={{overflow: 'hidden', height: '460px'}}>
                <header className='panel-header form-header'>
                    <div className='header-icon'>
                        <img src={`${imgUrl}/login.png`} />
                    </div>
                    <div className='header-title'>
                        <h2 style={{ fontWeight: 500, textDecoration: 'none', fontSize: '1.3em' }}>Login</h2>
                        <h4 style={{ fontSize: '1em', fontWeight: 400 }}>{ titles[step] }</h4>
                    </div>
                    <div className='panel-close' onClick={() => closeForm(false)}>
                        <img src={`${imgUrl}/closeToo.png`} />
                    </div>
                </header>
                <form className='panel-body form-body' style={{ flexDirection: 'column', justifyContent: 'center', marginBottom: '30px' }}>
                    {
                        loginProps.map((lStep: any, lStepIndex: number) => (
                            <>
                                {
                                    lStepIndex === step &&
                                    <>
                                    {
                                        lStep.map((prop: string, propIndex: number) => (
                                            <label className='text-input' style={{margin: '30px', marginTop: '30px'}}>
                                                <div className="input-box">
                                                    <input
                                                        className='form-input'
                                                        type={prop.toLowerCase().includes('password') && !showPw ? 'password' : 'text'}
                                                        value={formData[prop]}
                                                        onChange={e => updateFormData(prop, e.target.value)}
                                                        autoComplete={prop === 'email' ? 'email' : 'password'}
                                                        onFocus={() => handleFocus(prop)} />
                                                    {
                                                        prop.toLowerCase().includes('password') &&
                                                        <div className='input-pre input-aft' onClick={() => setShowPw(!showPw)}>
                                                            <img src={`${imgUrl}/${showPw ? 'hide' : 'show'}.png`} />
                                                        </div>
                                                    }
                                                </div>
                                                {propTitles[step][propIndex]}
                                                {
                                                    showReqs && missingPwReqs.length > 0 && prop === 'newPassword' &&
                                                    <div className='faux-tt pw-tt'>
                                                        {
                                                            missingPwReqs.map((req: any) => (
                                                                <small>{req}</small>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </label>
                                        ))
                                    }
                                    </>
                                }
                            </>       
                        ))
                    }
                    {
                        step === 0 && !stepFulfilled &&
                         <small
                            style={{
                                color: 'gray',
                                cursor: 'pointer',
                                marginTop: '10px'
                                }}
                            // onClick={() => initForgot ? initForgot() : {}}>
                            onClick={() => forgotModal()}>
                            Forgot Password    
                        </small>
                    }
                    {
                        stepFulfilled && (step !== 1 || !missingPwReqs.length) &&
                        <div className='btns' style={{ width: '90%', height: '60px', position: 'absolute', bottom: '0px', border: 'none'}}>
                            <a className='btn prim-btn' onClick={() => stepSubmit()}>Submit</a> 
                        </div>
                    }
                </form>
            </div>
        </main>
    );

}

export default LoginForm;