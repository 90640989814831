import { useEffect } from 'react';

import Header from './Header';
import Nav from './Nav';

import '../../stylesheets/Util.css';

export default () => {

    useEffect(() => {
    }, []);

    return (
        <>
            <Header />
            <Nav />
        </>
    )

}