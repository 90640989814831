import { useEffect } from "react";

interface Props {
    val:string
}

const PWValidator: React.FC<Props> = ({ val }) => {
    useEffect(() => {

    }, []);
    return (
        <h1>VALIDATING PW</h1>
    )
}

export default PWValidator;