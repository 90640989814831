import { useContext, useEffect, useState } from "react";
// import { useSession } from "../../hooks/useSession";
import { useSession } from "../../hooks/useSessionDev";

import '../../stylesheets/Building.css';
import { imgUrl } from "../../templates/consts";
import { AppContext } from "../../context/AppContext";
import { useRest } from "../../hooks/useRest";

interface Props {
    zone:any;
    zoneRefresh:() => void;
    closeSettings:() => void;
}

const AssignEquip:React.FC<Props> = ({ zone, zoneRefresh, closeSettings }) => {

    const { appData, setAppData } = useContext(AppContext);
    const [ sessionData, , , getSessionEntity, setSessionEntity ] = useSession();
    const [ request ] = useRest();

    const [ step, setStep ] = useState<number>(0);
    const [ bldg, setBldg ] = useState<any>(null);
    const [ floor, setFloor ] = useState<any>(null);

    const constructFloors = (bldg:any) => {
        let above = Array.from(Array(parseInt(bldg.above)), (floor:any, index:number) => ({ index: index + 1 })).reverse() || [];
        let below = Array.from(Array(parseInt(bldg.below)), (floor:any, index:number) => ({ index: 0 - (index + 1) })) || [];
        above = above.map(aboveFloor => {
            const foundFloor = bldg.floors.find((bldgFloor:any) => bldgFloor.index == aboveFloor.index);
            return foundFloor ?? aboveFloor;
        });
        below = below.map(belowFloor => {
            const foundFloor = bldg.floors.find((bldgFloor:any) => bldgFloor.index == belowFloor.index);
            return foundFloor ?? belowFloor;
        });
        const foundRoof = bldg.floors.find((bldgFloor: any) => bldgFloor.type == 'rooftop');
        let roof = foundRoof ?? {};
        return { roof, above, below };
    };

    const hasEquip = (floor:any) => {
        return floor.zones && floor.zones.some((z:any) => z.equipType && z.sections && z.sections.length);
    }

    const floorEquip = () => {
        return floor.zones.filter((z:any) => z.equipType);
    }

    const handleFloorClick = (bldgFloor:any) => {
        if (hasEquip(bldgFloor)) setFloor(bldgFloor);
    }

    const submitAssignment = async (equip:any) => {
        const body = ['name', 'id', 'equipType'].reduce((bodyObj:any, prop:string) => {
            bodyObj[prop] = equip[prop];
            return bodyObj;
        }, {});
        try {
            const servedBy = await request(`assignEquip/${zone.id}`, 'put', '', body);
            // updateSessionEntity('zone', zone.id, { ...zone, servedBy: assignedEquip }, sessionData)
            setSessionEntity('zone', { ...zone, servedBy });
            zoneRefresh();
            closeSettings();
            setAppData({ ...appData, helper :{
                type: 'tutorial',
                title: 'HVAC Equipment Assigned',
                content: `${zone.name} will now be served by ${servedBy.name ?? ' this Air Handler'}`
            }, loading: { visible: false }
            });
            closeSettings();
        } catch(submitAssignmentErr) {
            const err = submitAssignmentErr as any;
            setAppData({ ...appData, helper :{
                type: 'error',
                title: 'Equipment Assignment Error',
                content: err.message
            }, loading: { visible: false }});
            closeSettings();
        }
    }

    useEffect(() => {
        (async () => {
            if (zone && !bldg) {
                const parentFloor = await getSessionEntity('floor', zone.fid) as any;
                const parentBldg = await getSessionEntity('building', parentFloor.bid) as any;
                setBldg({ ...parentBldg, floors: constructFloors(parentBldg) });
                setAppData({ ...appData, loading: {visible: false} })
            }
        })()
    }, [zone]);

    // TODO: Add extra step if equipment already exists to confirm user wants to continue...

    return (
        <div className='assign-equip'>
            <div className='grid-tog zone-grid-tog'>
                <div className='sleeve-node zone-sleeve-node' onClick={() => closeSettings()}>
                    <img src={ `${imgUrl}/closeToo.png` } />
                </div>
            </div>
            <h5>{ !floor ? 'Select Floor on which HVAC is located' : 'Select Equipment from ' + `Floor ${floor.index}` }</h5>
            {
                bldg && !floor &&
                <div className='zone-bldg'>
                        {
                            bldg.floors.roof &&
                            <div
                                className='zone-bldg-floor zone-roof'
                                style={ {
                                    background: hasEquip(bldg.floors.roof) ? 'var(--breezi-blue)' : '',
                                    cursor: hasEquip(bldg.floors.roof) ? 'pointer' : 'not-allowed'
                            } } onClick={ () => handleFloorClick(bldg.floors.roof) }>
                                {
                                    hasEquip(bldg.floors.roof) &&
                                    <h6>Rooftop</h6>
                                }
                            </div>
                        }
                    {
                        bldg.floors.above.map((bldgFloor:any, bldgFloorIndex:number) => (
                            <>
                            {
                                <div 
                                    className='zone-bldg-floor'
                                    style={ {
                                        background: hasEquip(bldgFloor) ? 'var(--breezi-blue)' : '',
                                        cursor: hasEquip(bldgFloor) ? 'pointer' : 'not-allowed'
                                    } }
                                    onClick={ () => handleFloorClick(bldgFloor) }> 
                                    {
                                        hasEquip(bldgFloor) &&
                                        <h6>{ bldgFloor.name ?? `Floor ${bldgFloor.index}` }</h6>
                                    }
                                </div>
                            }
                            </>
                        ))
                    }
                    <div className='zone-bldg-floor ground-level'></div>
                    {
                        bldg.floors.below.map((bldgFloor:any, bldgFloorIndex:number) => (
                            <div className='zone-bldg-floor'style={ {
                                background: hasEquip(bldgFloor) ? 'var(--breezi-blue)' : '',
                                cursor: hasEquip(bldgFloor) ? 'cursor' : 'not-allowed'
                            } }
                            onClick={ () => handleFloorClick(bldgFloor) }>
                                {
                                    hasEquip(bldgFloor) &&
                                    <h6>{ bldgFloor.name ?? `Floor ${bldgFloor.index}` }</h6>
                                }
                            </div>
                        ))
                    }
                </div>
            }
            {
                floor &&
                <div className='zone-equips'>
                    {
                        floorEquip().map((equip:any, equipIndex:number) => (
                            <div className='zone-equip-node' onClick={ () => submitAssignment(equip) }>
                                <div className="zone-equip-node-head">
                                    <img src={ `${imgUrl}/equip.png` } />
                                </div>
                                <div className='zone-equip-node-body'>
                                    <small>Name: <span style={ {color: 'var(--prim)'} }>{ equip.name }</span></small>
                                    <small>Type: <span style={ {color: 'var(--prim)'} }>{ equip.equipType.toUpperCase() }</span></small>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default AssignEquip;