import { useContext, useEffect, useState } from "react";
// import { useSession } from "../../hooks/useSession"
import { useSession } from "../../hooks/useSessionDev"

import Form from '../util/Form';
import BuildingCard from '../organization/BuildingCard';

import obOrgTemp from '../../templates/formTemplates/onboardOrganization.json';
import obBldgTmp from '../../templates/formTemplates/onboardBuilding.json';

import '../../stylesheets/Organization.css';
import { imgUrl, secStatus } from "../../templates/consts";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../../context/AppContext";
import OrgCard from "../organization/OrgCard";
import { getServers } from "dns";

export default () => {

    const { id } = useParams();
    const nav = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const initRefresh = queryParams.get('refreshSession');

    const { appData, setAppData } = useContext(AppContext);
    const [sessionData, setInitialSession, , getSessionEntity, setSessionEntity] = useSession();

    const [onboardForm, setOnboardForm] = useState<boolean | string>(false);
    const [organization, setOrganization] = useState<any>(null);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [fauxReport, setFauxReport] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setAppData({ ...appData, loading: { visible: true }, helper: false })
            setOrganization(null);
            setLoaded(false);
            setInitialSession();
            // const restOrg = await getSessionEntity('organization', '');
            // if (restOrg) {
            //     setSessionEntity('', restOrg);
            // }
        })()
    }, []);

    const intiFormClose = async (ent?: string) => {
        if (ent == 'organization') {
            const org = await getSessionEntity('organization', '');
            if (org) {
                setSessionEntity('organization', org);
            }
        }
        setOnboardForm(false);
    }

    const initFauxReport = (e: any, open:boolean) => {
        e.stopPropagation();
        setFauxReport(open);
    }

    const blockOb = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Disabled',
                content: "This feature has been restricted for the duration of the Pilot Program"
            }
        });
    }

    useEffect(() => {
        if (sessionData.util && sessionData.email) {
            if (sessionData.organization) setOrganization(sessionData.organization);
            if (localStorage.getItem('breeziProDeeplink')) localStorage.clearItem('breeziProDeeplink');
            // if (!sessionData.organization || !sessionData.organization.id) {
            //     // if (sessionData.organization);
            //     // setOnboardForm('organization');
            // } else {
            //     setOrganization(sessionData.organization);
            // }
        }
    }, [sessionData]);

    useEffect(() => {
        if (organization) {

            setLoaded(true);
            // setTimeout(() => setLoaded(true), 2000);
        }
    }, [organization]);

    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setAppData({ ...appData, loading: { visible: false } });
                setLoaded(false);
            }, 2500);
        }
    }, [loaded]);

    useEffect(() => {

    }, []);

    return (
        <main className='organization-main'>
            {
                organization && organization.id &&
                <div className='organization-bldgs'>
                    {
                        organization.buildings.map((bldg: any) => (
                            // <BuildingCard bldg={ bldg } />
                            <OrgCard initFauxReport={initFauxReport} bldg={bldg} />
                        ))
                    }
                    {
                        window.innerWidth >= 767
                            // ? <section className='card bldg-card ob-card' onClick={() => setOnboardForm('building')}>
                            ? <section className='card bldg-card ob-card' onClick={() => blockOb()}>
                                <img src={`${imgUrl}/bldgType.png`} />
                                <h1 style={{ marginTop: '10px' }}>Add Building</h1>
                                <h1 style={{ fontSize: '3em' }}> + </h1>
                            </section>
                            // : <section className='mobile-ob-bldg-card' onClick={() => setOnboardForm('building')}>
                            : <section className='mobile-ob-bldg-card' onClick={() => blockOb()}>
                                <img src={`${imgUrl}/bldgType.png`} />
                                <h1>Add New Building...</h1>
                            </section>
                    }
                </div>
            }
            {
                onboardForm == 'organization' &&
                <Form tmp={obOrgTemp} closeForm={() => intiFormClose('organization')} />
            }
            {
                onboardForm == 'building' &&
                <Form tmp={obBldgTmp} closeForm={() => setOnboardForm(false)} parentId={organization.id} />
            }
            {
                fauxReport &&
                <img className='faux-report' src={`${imgUrl}/faux-report-2.png`} onClick={e => initFauxReport(e, false)} />
            }
        </main>
    )
}