import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import cToF from 'celsius-to-fahrenheit';
import moment from 'moment';

import { imgUrl, setStatus } from "../../templates/consts";
import FloorEquip from "../floor/FloorEquip";
import '../../stylesheets/Snapshot.css';
import { useSession } from "../../hooks/useSessionDev";
import { useRest } from "../../hooks/useRest";

interface Props {
    equip: any;
    graphicOnly?:boolean;
}

const defaultBars = [
    {
        title: 'Next Filter Maintenance',
        img: 'coilFlow'
    },
    {
        title: 'Return Temperature',
        img: 'temp'
    },
    {
        title: 'Return Humidity',
        img: 'hum'
    },
    {
        title: 'Delta Temperature',
        img: 'delta'
    },
    {
        title: 'Supply Humidity',
        img: 'hum'
    },
    {
        title: 'Supply Temperature',
        img: 'temp'
    },
    {
        title: 'Next Coil Maintenance',
        img: 'filterOfficial'
    },
    
]

const EquipSnapshot: React.FC<Props> = ({ equip, graphicOnly }) => {

    const nav = useNavigate();

    const [sessionData] = useSession();
    const [req] = useRest();
    
    const [bars, setBars] = useState<any[]>([]);
    const [hoverEquip, setHoverEquip] = useState<boolean>(false);
    const [hoverBar, setHoverBar] = useState<number>(-1);
    const [showNameplate, setShowNameplate] = useState<boolean>(false);
    const [onOff, setOnOff] = useState<boolean>(false);

    const stageByType = (stages: any, type: string) => stages.filter((s: any) => s.elems.length && s.elems[0].type === type);

    
    const setProps = (alerts: any, meas: any, prop: string) => {
        let height:number|string;
        let color:string;
        let val:number|string;
        let width:number|string = !graphicOnly ? 30 : 15;
        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;
        const isMobile = clientWidth < clientHeight;
        const isLaptop = !isMobile && clientHeight >= 400 && window.innerHeight <= 880;
        const isHiRes = !isMobile && clientHeight >= 1200;
        if (!meas.hasOwnProperty(prop)) {
            return {height: '', color: 'none', val: '-', width: '', border: '2px solid gray'}
        } else {
            val = parseInt(meas[prop]);
            if (alerts[prop].crit) {
                height = 140;
                color = 'var(--crit)';
            } else if (alerts[prop].bad) {
                height = 100;
                color = 'var(--cancel)';
            } else {
                height = 80;
                color = 'var(--prim)';
            }
        }
        if (isLaptop || graphicOnly) height -= 45;
        if (isHiRes) {
            height += 30;
            width += 15;
        }
        height = height + 'px';
        width = width + 'px';
        return { height, width, color, val };

        // if (!meas.hasOwnProperty(prop)) {
        //     return {height: !isLaptop() ? '70px' : '50px', color: 'gray', val: '-'}
        // } else {
        //     let val = meas[prop].toString();
        //     val = parseInt(val);
        //     if (alerts[prop].crit) {
        //         return {height: !isLaptop() ? '140px' : '95px', color: 'var(--crit)', val }
        //     }
        //     if (alerts[prop].bad) {
        //         return {height: !isLaptop() ? '100px' : '75px', color: 'var(--cancel)', val }
        //     }
        //     return {height: !isLaptop() ? '70px' : '50px', color: 'var(--prim)', val: meas[prop] ?? '-'} 
        // }
    }

    const setStages = (stages: any[], filter?:boolean) => {
        const stage = stages[0];
        const dp = equip.datapoints.find((dp: any) => dp.sectionIndex == equip.sections.indexOf(stage));
        if (dp && dp.meas && dp.alerts) {
            const daysProps = setProps(dp.alerts, dp.meas, 'days');
            const tmpProps = setProps(dp.alerts, dp.meas, 'htp');
            const humProps = setProps(dp.alerts, dp.meas, 'hhm');
            let props = [daysProps, tmpProps, humProps];
            if (filter) props = props.reverse();
            return props;
        }
        return false;
    }

    const setDeltaBar = (stage: any, delta: number) => {
        let height: string;
        let color: string;
        const fahr = Math.round(delta * 9 / 5);
        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;
        const isMobile = clientWidth < clientHeight;
        const isLaptop = !isMobile && clientHeight >= 400 && window.innerHeight <= 880;
        const isHiRes = window.innerHeight >= 1200;
        if (fahr >= 15 || fahr < 6) {
            height = !isLaptop ? (!isHiRes ? '80px' : '110px') : '35px';
            color = 'var(--prim)';
        } else if (fahr >= 10 && fahr <= 14) {
            height = !isLaptop ? (!isHiRes ? '100px' : '130px') : '60px';
            color = 'var(--cancel)';
        } else {
            height = !isLaptop ? (!isHiRes ? '140px' : '170px') : '80px';
            color = 'var(--crit)';
        }
        if (graphicOnly) {
            const heightNum = parseInt(height);
            height = `${heightNum - 10}px`;
        }
        return { ...stage[3], height, color, val: delta, img: 'delta', width: graphicOnly ? '15px' : (isHiRes ? '45px' : '30px')};
    }

    const bifurcate = () => {
        const stages = equip.sections;
        setBars(defaultBars);
        if (stages.length) {
            const fanStages = stageByType(stages, 'Fan');
            const coilStages = stageByType(stages, 'Coil');
            const filterStages = stageByType(stages, 'Filter');
            let newStages = [ ...defaultBars ];
            // TODO: This will need to be refactored to allow for more than 1 stage of each type, and more than one datapoint on each stage
            if (coilStages.length) {
                const coilBars = setStages(coilStages)
                const filterBars = setStages(filterStages, true);
                if (coilBars) {
                    [0, 1, 2].forEach((index:number) => newStages[index] = {...newStages[index], ...coilBars[index]});
                }
                if (filterBars) {
                    [4, 5, 6].forEach((index:number) => newStages[index] = { ...newStages[index], ...filterBars[index - 4]});
                }
                if (coilBars && filterBars) {
                    let deltaStage = newStages[3] as any;
                    const greater = coilBars[1].val > filterBars[1].val ? coilBars : filterBars;
                    const lesser = greater === coilBars ? filterBars : coilBars;
                    const greaterVal = greater[1].val && typeof greater[1].val === 'number' ? greater[1].val : false;
                    const lesserVal = lesser[1].val && typeof lesser[1].val === 'number' ? lesser[1].val : false;
                    const delta = greaterVal && lesserVal ? Math.round(greaterVal - lesserVal) : 0;
                    // const delta = [greater, lesser].every((bars:any) => bars[1]?.val && typeof bars[1].val !== 'string') ? Math.round(greater[1].val - lesser[1].val) : 0;
                    deltaStage = setDeltaBar(deltaStage, delta);
                    newStages[3] = deltaStage;
                }
            }
            setBars(newStages.reverse());
        }
    }

    const setVal = (val:any, index:number) => {
        val = parseInt(val);
        if (index === 6) return '>270';
        if ([1, 5].includes(index) && sessionData.imperial) return Math.round(cToF(val));
        if (index === 3 && sessionData.imperial) return Math.round(val * 9 / 5);
        return val;
    }

    const lastUpdate = () => {
        const updates = equip.datapoints.map((dp: any) => dp.updatedTms);
        const max = Math.max(...updates);
        if (!max) return false;
        return (window.innerHeight >= 880 ? 'Updated: ' : '') + moment(max * 1000).format('MMM-DD @ h:mma');
    }

    const assessOnOff = async () => {
        if (!equip.meas || !equip.meas.dpr) {
            return 'powerOff';
        } else {
            const dids = equip.datapoints.map((dp: any) => dp.did);
            const onOffs = await Promise.all(dids.map(async (did: number) => {
                return (await req('onOff/' + did, 'get')).rawDpr;
            }));
            setOnOff(onOffs.some((oo:any) => typeof oo === 'number' && oo >= 10));
        }
    }

    const nameplates:any = {
        'Bryant Unit 1': [
            {
                prop: 'Name',
                val: 'Bryant Unit 1'
            },
            {
                prop: 'Model',
                val: '508J'
            },
            {
                prop: 'Serial Number',
                val: '1915C64494'
            },
            {
                prop: 'Type',
                val: 'Package AHU'
            },
            {
                prop: 'Capacity',
                val: '5-ton'
            },
            {
                prop: 'MESP',
                val: '1 iwg'
            }
        ],
        'Bryant Unit 2': [
            {
                prop: 'Name',
                val: 'Bryant Unit 2'
            },
            {
                prop: 'Model',
                val: '508J'
            },
            {
                prop: 'Serial Number',
                val: '1915C64492'
            },
            {
                prop: 'Type',
                val: 'Package AHU'
            },
            {
                prop: 'Capacity',
                val: '5-ton'
            },
            {
                prop: 'MESP',
                val: '1 iwg'
            }
        ]
    }

    useEffect(() => {
        bifurcate();
        assessOnOff();
    }, []);

    return !showNameplate ? (!graphicOnly
        ? <div className='floor-zone floor-equip' onMouseOver={() => setHoverEquip(true)} onMouseLeave={() => setHoverEquip(false)}>
            <header className='floor-zone-status' style={{ background: setStatus('all', equip.alerts.overall) }}></header>
                <header className='floor-zone-header' onClick={() => nav('/equipment/' + equip.id)}>
                    <div className='floor-zone-icon'>
                        <img style={{padding: '2%', height: '60%'}} src={`${imgUrl}/equip.png`} />
                    </div>
                    <div className='floor-zone-title' style={{marginLeft: window.innerHeight >= 400 && window.innerHeight <= 700 && window.innerWidth >= 1100 ? '10px' : ''}}>
                    <h3>{equip.name}</h3>
                    {
                        !hoverEquip
                            ? <small style={{ color: 'var(--breezi-blue)', fontSize: '.85em' }}>{equip.datapoints.length ?? 0} AirPulse{equip.datapoints.length === 1 ? '' : 's'}</small>   
                            : <small style={{ color: 'var(--breezi-blue)', fontSize: '.85em' }}>{lastUpdate()}</small>
                    }
                    </div>
            </header>
            {
                bars.length > 0 &&
                <div className='snapshot-bars' style={{marginBottom: window.innerHeight >= 440 && window.innerHeight <= 880 ? '5px' : ''}}>
                    {
                            bars.map((b: any, bIndex: number) => (
                            <>
                                    <div
                                        className='snp-bar'
                                        onMouseOver={() => setHoverBar(bIndex)}
                                        onMouseLeave={() => setHoverBar(-1)}>
                                        {
                                            hoverBar === bIndex &&
                                            <div className='snp-node' style={{border: b.height ? `2px solid ${b.color}` : ''}}>
                                                <img src={`${imgUrl}/${b.img}.png`} />
                                            </div>
                                        }
                                        <div
                                            className='snp-bar-rdg'
                                            style={{
                                                height: b.height ?? '',
                                                background: b.color ?? '',
                                                borderRadius: b.height ? '22px' : '',
                                                border: b.border ?? (b.height ? 'none' : ''),
                                                width: b.width
                                            }}></div>
                                        {
                                            hoverBar === bIndex &&
                                            <div className='snp-node' style={{border: b.height ? `2px solid ${b.color}` : ''}}>
                                                    <h5>{b.val || b.val == 0 ? setVal(b.val, bIndex) : '-'}</h5>
                                                    {/* <h5>{b.val ?? '-'}</h5> */}
                                            </div>
                                        }
                                    </div>
                            </>
                        ))
                        }
                </div>
            }
            {
                hoverBar > -1 &&
                <small className='snp-title'>
                    <>
                        {defaultBars[hoverBar].title}
                        {[6].includes(hoverBar) && <span style={{color: 'var(--breezi-blue)'}}>: Learning</span>}
                    </>
                </small>
            }
            <img className='snp-onoff' style={{width: window.innerHeight >= 440 && window.innerHeight <= 880 ? '20px' : '', height: 'auto'}} src={`${imgUrl}/${onOff ? 'power' : 'powerOff'}.png`} />
            <img 
                className='snp-onoff' 
                style={{top: '10px', width: '20px', height: 'auto', cursor: 'pointer'}} 
                src={`${imgUrl}/infoIcon.png`}
                onClick={() => setShowNameplate(true)} />
        </div>
        :
        <div className='mini-snap'>
            {
                bars.length > 0 &&
                <div className='snapshot-bars' style={{paddingBottom: 0}}>
                    {
                            bars.map((b: any, bIndex: number) => (
                            <>
                                {
                                    !['coilFlow'].includes(b.img) &&
                                    <div
                                        className='snp-bar'
                                        onMouseOver={() => setHoverBar(bIndex)}
                                        onMouseLeave={() => setHoverBar(-1)}
                                        style={{ width: '20px' }}>
                                        <div
                                            className='snp-bar-rdg'
                                            style={{
                                                height: (bIndex === 3 ? parseInt(b.height) - 35 : parseInt(b.height)) + 'px',
                                                background: b.color ?? '',
                                                borderRadius: b.height ? '22px' : '',
                                                border: b.height ? 'none' : '',
                                                width: '15px'
                                            }}></div>
                                    </div>
                                }
                                {
                                    ['coilFlow'].includes(b.img) &&
                                    <div 
                                        className='snp-bar' 
                                        onMouseOver={() => setHoverBar(bIndex)} 
                                        onMouseLeave={() => setHoverBar(-1)}
                                        style={{width: '20px'}}>
                                        <div className='snp-bar-rdg' style={{width: '14px', height: '14px'}}>
                                            
                                        </div>
                                    </div>
                                }
                            </>
                        ))
                    }
                </div>
            }
        </div>) :
            <div className='floor-zone floor-equip'>
                <img 
                    className='snp-onoff' 
                    style={{top: '10px', width: '20px', height: 'auto', cursor: 'pointer'}} 
                    src={`${imgUrl}/closeToo.png`}
                        onClick={() => setShowNameplate(false)} />
                <header className='floor-zone-status' style={{ background: setStatus('all', equip.alerts.overall) }}></header>
                    <header className='floor-zone-header' onClick={() => nav('/equipment/' + equip.id)}>
                        <div className='floor-zone-icon'>
                            <img style={{padding: '2%', height: '60%'}} src={`${imgUrl}/equip.png`} />
                        </div>
                        <div className='floor-zone-title'>
                            <h3>{equip.name}</h3>
                            <small style={{ color: 'var(--breezi-blue)', fontSize: '.85em' }}>Nameplate Info</small>
                        </div>
            </header>
                <div className='snp-nameplate-body'>
                    <div className='snp-nameplate-info'>
                    {
                        nameplates && nameplates[equip.name] && nameplates[equip.name].map((detail: any, detailIndex: number) => (
                                <div className='snp-nameplate-item'>
                                    <h4 className='snp-nameplate-head'>{detail.prop}</h4>
                                    <p>{detail.val}</p>
                                </div>
                            ))    
                        }
                    </div>    
                </div>
            </div>
}

export default EquipSnapshot;