import { useEffect, useState } from "react";
import { Input } from "../../tsDeclarations/form";
import { useForm } from "../../hooks/useFormContext";

interface Props {
    input:Input;
    def:boolean;
}

const BinaryInput:React.FC<Props> = ({ input, def }) => {

    // NOTE: this Component is a misnomer: it is no longer a binary but allows a list of any length to be passed as params
    // I didn't want to change the name in a bunch of places, not to mention file names, so I will update it in the next release
    // TODO: change Binary vars, imports, files

    const [ formData, updateFormData ] = useForm();

    const [ active, setActive ] = useState(0);

    useEffect(() => {
        updateFormData({[input.prop]: input.options[active]});
    }, [active]);

    return (
        <div className='util-tog-outer'>
            <h3 className='toggle-title'>{ input.label }</h3>
            <div className='util-tog-contianer'>
                {
                    input.options.map((option, optionIndex) => (
                        <small
                            style={ {
                                background: active == optionIndex ? 'rgba(0, 153, 191, 0.6)' : '',
                                color: active != optionIndex ? 'darkgray' : ''
                            } }
                            onClick={ () => setActive(optionIndex) }>
                                { input.alts ? input.alts[optionIndex] : option }
                        </small>
                    ))
                }
            </div>
        </div>
    )

}

export default BinaryInput;