import { imgUrl } from "../../../templates/consts";

interface Props {
    img:string;
    title:string;
    content:string;
}

const Info:React.FC<Props> = ({ img, title, content }) => {
  return (
        <div className='tooltip-info'>
            <header className='tooltip-info-header'>
                <img src={ `${imgUrl}/${img}.png` } />
                <h4>{ title }</h4>
            </header>
            <div className='tooltip-info-content'>
                <p>{ content }</p>
            </div>
        </div>
  )
}

export default Info
