import { useState, useEffect, useContext } from 'react';
import '../../../stylesheets/Settings.css';
import { BreeziProVersionNumber, imgUrl } from '../../../templates/consts';
import { AppContext } from '../../../context/AppContext';
import SettingsToggle from './SettingsToggle';
import OrgDetails from './OrgDetails';
import Summary from './Summary';

import Preferences from './Preferences';
import Team from './Team';

export default () => {

    const { appData, setAppData } = useContext(AppContext);

    const [panelClass, setPanelClass] = useState<string>('');
    const [ activeTogIndex, setActiveTogIndex ] = useState<number>(0);

    const handlePanelClick = (e: any, closes:boolean=false) => {
        e.stopPropagation();
        if (closes) setAppData({ ...appData, settings: false });
    }

    return (
        <div className='overlay dark-overlay settings-overlay' onClick={ e => handlePanelClick(e, true) }>
            <div className={`settings${' ' + panelClass}`} onClick={ e => handlePanelClick(e) } style={{maxHeight: '87.5vh'}}>
                <div style={{left: window.innerWidth <= 767 ? '12px' : ''}} className='close-settings' onClick={ e => handlePanelClick(e, true) }>
                    <img src={ `${imgUrl}/closeBlack.png` } />
                </div>
                <SettingsToggle setParentTog={ setActiveTogIndex } />
                {
                    activeTogIndex == 0 && <OrgDetails />
                }
                {
                    activeTogIndex == 1 && <Team />
                }
                {
                    activeTogIndex == 2 && <Preferences /> 
                }
                {
                    activeTogIndex === 0 &&
                    <div className='version-number' style={{bottom: '10px'}}>
                        <small>2023 Breezi Version { BreeziProVersionNumber }</small>
                    </div>
                }
            </div>
        </div>
    )

}