import { useContext, useEffect, useState } from "react";
// import { useSession } from "../../../hooks/useSessionContext"
// import { useSession } from "../../../hooks/useSession"
import { useSession } from '../../../hooks/useSessionDev';
import { useRest } from "../../../hooks/useRest";
import { AppContext } from "../../../context/AppContext";
import { imgUrl } from "../../../templates/consts";

import Form from '../Form';
import teamTmp from '../../../templates/formTemplates/onboardTeamMember.json';
import '../../../stylesheets/Card.css';

export default () => {

    const [sessionData, , , , , , , , , , , , updateSessionNotifUsers] = useSession();
    const [request] = useRest();
    const { appData, setAppData } = useContext(AppContext);
    const [notifUsers, setNotifUsers] = useState<any>([]);

    const [team, setTeam] = useState<any[]>([]);
    const [obTeamMember, setObTeamMember] = useState<boolean>(false);

    const closeOb = (auth: boolean, newTeamMember: any) => {
        setObTeamMember(false);
        setAppData({
            ...appData,
            loading: { visible: false }
        });
        if (newTeamMember) {
            setTimeout(() => {
                setAppData({
                    ...appData,
                    helper: {
                        type: 'tutorial',
                        title: 'User added to your Organization',
                        content: `Your new Team Member has been added to your Organization. An email with login instructions has been sent to the requested address and the new Team Member may begin using BreeziPro immediately.`
                    }
                });
            }, 1000);
        }
        return
    }

    const togNotifUser = async (e: any, email: string) => {
        try {
            const newNotifUsers = await request('team', 'put', undefined, email);
            updateSessionNotifUsers(newNotifUsers);
        } catch (togNotifUserErr) {
            console.error({ togNotifUserErr });
            const err = togNotifUser as any;
            setAppData({
                ...appData,
                helper: {
                    type: 'error',
                    title: 'Toggle User Notifications Error',
                    content: err.message
                }
            })
        }
    }

    useEffect(() => {
        (async () => {
            if (!team.length) {
                try {
                    const team = await request('team', 'get');
                    setTeam(team);
                } catch (getTeamErr) {
                    const err = getTeamErr as any;
                    setAppData({ helper: { type: 'error', title: 'Get Team Error', content: err.message ?? '' } });
                }
            }
            setAppData({ ...appData, loading: { visible: false } });
        })();
    }, []);

    useEffect(() => {
        if (sessionData.organization?.notifUsers) {
            setNotifUsers(sessionData.organization.notifUsers);
        }
    }, [sessionData]);

    return (
        <div className='settings-body' style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            {
                team.map((user: any, userIndex: number) => (
                    <div className='s-section team-section'>
                        <div className='s-section-head' style={{ width: '30%' }}>
                            <img src={`${imgUrl}/user.png`} />
                            <h5>{user.given_name} {user.family_name}</h5>
                            <h5
                                style={{ margin: '-5px', paddingTop: '5px', color: 'gray', borderTop: '1px solid var(--gray)' }}>
                                {user.email != sessionData.email ? user.jobTitle : 'Me'
                                }</h5> 
                        </div>
                        <div className='s-section-content'>
                            <h5>{user.email}</h5>
                            {/* <h5>{user.phoneNumber}</h5> */}
                            <h5><img src={`https://catamphetamine.github.io/country-flag-icons/3x2/${user.country}.svg`} /></h5>
                            <div className='team-notif-checkbox'>
                                <label className='checkbox-label'>
                                    <input type='checkbox' onChange={e => togNotifUser(e, user.email)} checked={notifUsers.includes(user.email)} />
                                    Receive Alert Notifications
                                </label>
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                sessionData.email === 'support@breezi.io' &&
                <div className='s-section' style={{ justifyContent: 'center' }}>
                    <a className='sec-btn settings-card-btn' onClick={() => setObTeamMember(true)}>Add New Team Member</a>
                </div>
            }
            {
                obTeamMember &&
                <div className='overlay settings-form-overlay'>
                    <Form tmp={teamTmp} closeForm={closeOb} />
                </div>
            }
        </div>
    )
}