import { useState, useEffect } from 'react';

// import { useSession } from '../../../hooks/useSession';
import { useSession } from '../../../hooks/useSessionDev';
import { imgUrl } from '../../../templates/consts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default () => {

	const nav = useNavigate();
	// const { id } = useParams();
	const location = useLocation();
	const id = location.pathname.split('/')[2]

	// const [ sessionData, , , , , , , setSessionData, , updateUnitPrefs ] = useSession();
	// const [ sessionData, , , , , , , , , updateUnitPrefs ] = useSession();
	const [ sessionData, , setUnitPref ] = useSession();

	const [ imperial, setImperial ] = useState<boolean>(sessionData.imperial ?? false);
	const [ iwg, setIwg ] = useState<boolean>(sessionData.iwg ?? false);
	const [loc, setLoc] = useState<boolean>(false);
	
	const togUnit = (unit: string) => {
		const togged = !(unit == 'iwg' ? iwg : imperial);
		if (unit == 'iwg') {
			setIwg(togged);
		} else {
			setImperial(togged);
		}
		setUnitPref(unit, togged, true);
	}


	// useEffect(() => {
	// 	if (sessionData.imperial) setImperial(true);
	// 	if (sessionData.iwg) setIwg(true);
	// }, []);

	// useEffect(() => {
	// 	if (imperial != sessionData.imperial) {
			
	// 		setUnitPref('imperial', imperial, false);
	// 		// nav('/organization?refreshSession=true');
	// 	}
	// }, [imperial]);

	// useEffect(() => {
	// 	if (iwg != sessionData.iwg) {
	// 		setUnitPref('iwg', iwg, false);
	// 		// nav('/organization?refreshSession=true');
	// 	}
	// }, [iwg]);

	return (
		<div className='settings-body' style={ {marginTop: '10%'} }> 
			{
				sessionData.organization &&
				<>
					<div className='s-section s-tog-section'>
						<div className='s-section-head'>
							<img src={ `${imgUrl}/units.png` } />
							<h5>Units</h5>
						</div>
						<div className='s-section-content s-tog-content'>
							<div className='s-toggle-container'>
								<h5 style={ {color: imperial ? '' : '#2869B9'} }>METRIC</h5>
								<div className='s-toggle' onClick={ () => togUnit('imperial') }>
									<div className={ `s-tog-node${imperial ? ' active-s-tog' : ''}`}></div>
								</div>
								<h5 style={ {color: !imperial ? '' : '#2869B9'} }>IMPERIAL</h5>
							</div>
						</div>
					</div>
					<div className='s-section'>
						<div className='s-section-head'>
							<img src={ `${imgUrl}/pressureBlue.png` } />
							<h5>Pressure Units</h5>
						</div>
						<div className='s-section-content s-tog-content'>
							<div className='s-toggle-container'>
								<h5 style={ {color: iwg ? '' : '#2869B9'} }>PASCALS</h5>
								<div className='s-toggle' onClick={ () => togUnit('iwg') }>
									<div className={ `s-tog-node${iwg ? ' active-s-tog' : ''}`}></div>
								</div>
								<h5 style={ {color: !iwg ? '' : '#2869B9'} }>IWG</h5>
							</div>
						</div>
					</div>
					<div className='s-section'>
						<div className='s-section-head'>
							<img src={ `${imgUrl}/locale.png`} />
							<h5>Reference Location</h5>
						</div>
						<div className='s-section-content s-tog-content inactive-tog'>
							<div className='s-toggle-container'>
								<h5 style={ {color: 'gray'} }>CURRENT</h5>
								<div className='s-toggle'>
									<div className='s-tog-node'></div>
								</div>
								<h5 style={ {color: 'gray'} }>IWG</h5>
							</div>
						</div>
					</div>
					<div className='s-section'>
						<div className='s-section-head'>
							<img src={ `${imgUrl}/lang.png`} />
							<h5>Language</h5>
						</div>
						<div className='s-section-content s-tog-content inactive-tog'>
							<div className='s-toggle-container'>
								<h5 style={ {color: 'gray'} }>ENGLISH</h5>
								<div className='s-toggle'>
									<div className='s-tog-node'></div>
								</div>
								<h5 style={ {color: 'gray'} }>日本語</h5>
							</div>
						</div>
					</div>
				</>
			}
		</div>
	)
}