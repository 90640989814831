import { useSession } from "../../hooks/useSessionDev";
import cToF from 'celsius-to-fahrenheit';

import { imgUrl } from "../../templates/consts";
import { useEffect, useState } from "react";

import filterImg from '../../assets/images/filterOfficial.png';
import coilImg from '../../assets/images/coilFlow.png';
import tempImg from '../../assets/images/temp.png';
import humImg from '../../assets/images/hhm.png';
import moment from "moment";

interface Props {
    dp: any;
    supply: boolean;
    days: number | null;
    tms:number;
}

const PdfHiLo: React.FC<Props> = ({ dp, supply, days, tms }) => {

    const [sessionData] = useSession();

    const [d, setD] = useState<any>(null);
    
    const parseVal = (rdg: string, val: number) => {
        if (rdg === 'hhm') return val;
        return sessionData.imperial ? Math.round(cToF(val)) : val;
    }

    const parseUnit = (rdg:string) => {
        if (rdg === 'hhm') return '%';
        return `˚${sessionData.imperial ? 'F' : 'C'}`;
    }

    const assessAlert = () => {
        if (d !== null) {
            const frmt = moment(tms * 1000).format('MMM')
            if (['Feb', 'Mar', 'Apr'].some((month:string) => frmt.includes(month))) return 'prim'
            // if ((moment(tms * 1000).format('MMM').includes('Feb') || moment(tms * 1000).format('MMM').includes('Mar')) && supply) return 'prim'
            if (d <= 10) {
                return 'crit';
            } else if (d <= 25) {
                return 'cancel';
            } else if (d > 25) {
                return 'prim';
            } else {
                return 'inactive';
            }
        }
    }

    useEffect(() => {
        let newD = days as any;
        if (newD === false) newD = 0;
        setD(newD);
    }, [days]);

    return (
        <div className='pdf-equip-half'>
            <h3>{supply ? 'Supply' : 'Return'} Air</h3>
            <div className='pdf-hla-label'>
                <h6 className='hla-hi'>HIGH</h6>
                <h6 className='hla-avg'>AVG</h6>
                <h6 className='hla-lo'>LOW</h6>
            </div>
            <div className={`pdf-days ${assessAlert()}-pdf-days`}>
                <div className='pdf-days-head'>
                    <img src={supply ? coilImg : filterImg} />
                    <h5>{supply ? 'Evap Coil' : 'HVAC Filter'}</h5>
                </div>
                <div className='pdf-days-body'>
                    <h4>{moment(tms * 1000).format('MMM').includes('Feb') || moment(tms * 1000).format('MMM').includes('Mar') || moment(tms * 1000).format('MMM').includes('Apr') && supply ? '>270' : d}</h4>
                    <small>days</small>
                </div>
            </div>
            <div className='pdf-hi-los'>
            {
                ['htp', 'hhm'].map((rdg: string) => (
                    <div 
                        className='pdf-equip-range'
                        style={{
                            alignItems: rdg === 'htp' ? 'flex-end' : 'flex-start'
                        }}
                    >
                        <div
                            className='pdf-range-icon'
                            style={{
                                // left: rdg === 'htp' ? '-1px' : '',
                                // right: rdg === 'hhm' ? '-7px' : '',
                            }}
                            >
                            <img src={rdg === 'htp' ? tempImg : humImg} />
                        </div>
                        <div className='pdf-equip-span'>
                            <div
                                className='pdf-hi-lo-avg pdf-hi'
                                style={{
                                    left: rdg === 'htp' ? '-1px' : '',
                                    right: rdg === 'hhm' ? '-1px' : '',
                                }}
                                >
                                <h5>
                                    {
                                        parseVal(rdg, parseInt(dp.meas.workingHoursAvg[rdg].hi))
                                    }
                                    {
                                        parseUnit(rdg)
                                    }
                                </h5>
                            </div>
                            <div
                                className='pdf-hi-lo-avg pdf-avg'
                                style={{
                                    left: rdg === 'htp' ? '-1px' : '',
                                    right: rdg === 'hhm' ? '-1px' : '',
                                }}
                                >
                                <h5>
                                    {
                                        parseVal(rdg, parseInt(dp.meas.workingHoursAvg[rdg].avg))
                                    }
                                    {
                                        parseUnit(rdg)
                                    }
                                </h5>
                            </div>
                            <div
                                className='pdf-hi-lo-avg pdf-lo'
                                style={{
                                    left: rdg === 'htp' ? '-1px' : '',
                                    right: rdg === 'hhm' ? '-1px' : '',
                                }}
                                >
                                <h5>
                                    {
                                        parseVal(rdg, parseInt(dp.meas.workingHoursAvg[rdg].lo))
                                    }
                                    {
                                        parseUnit(rdg)
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default PdfHiLo;