import { useContext, useEffect, useState } from 'react';

import '../../stylesheets/Datapoint.css';
import TopRow from '../datapoint/TopRow';
import BottomRow from '../datapoint/BottomRow'
// import { useSession } from '../../hooks/useSessionContext';
// import { useSession } from '../../hooks/useSession';
import { useSession } from '../../hooks/useSessionDev';
import { useParams } from 'react-router-dom';
import Trend from '../datapoint/Trend';
import { useRest } from '../../hooks/useRest';
import { AppContext } from '../../context/AppContext';


export default () => {

    const { id } = useParams();

    const { appData, setAppData } = useContext(AppContext);

    // const [ sessionData, , , updateUtilData, , entityUpdate ] = useSession();
    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession()
    const [request] = useRest();

    const [hier, setHier] = useState<any>(null);
    const [dp, setDp] = useState<any>(null);

    const initializeDatapoint = async () => {
        const newDp = await getSessionEntity('datapoint', id ?? '');
        setSessionEntity('datapoint', newDp);
        setAppData({ ...appData, loading: {visible: true} });
        // if (newDp) setSessionEntity('datapoint', newDp);
    }

    const constructHier = async () => {
        const zone = sessionData.zone ?? await getSessionEntity('zone', dp.fid)
        const floor = sessionData.floor ?? await getSessionEntity('floor', zone.fid);
        const bldg = sessionData.building ?? await getSessionEntity('building', floor.bid);
        setHier([bldg.name, floor.name]);
    }

    useEffect(() => {
        (async () => {
            // if (localStorage.getItem('breeziProDeeplink')) localStorage.clearItem('breeziProDeeplink');
            await initializeDatapoint();
        })();
    }, []);

    useEffect(() => {
        if (sessionData.util && sessionData.util.tier == 'datapoint' && sessionData.datapoint) {
            setDp(sessionData.datapoint);
            if (localStorage.getItem('breeziProDeeplink')) localStorage.clearItem('breeziProDeeplink');
        } else {
            initializeDatapoint();
        }
    }, [sessionData.util]);

    useEffect(() => {
        if (dp) constructHier();
    }, [dp]);

    return (
        <>
            {dp &&
                <main className='datapoint-main'>
                    <TopRow datapoint={dp} hier={hier} />
                    <div className='datapoint-row lg-dp-row'>
                        <Trend datapoint={dp} />
                    </div>
                    <BottomRow datapoint={dp} />
                </main>
            }
        </>
    )

}