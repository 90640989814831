import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

import { useEffect, useRef, useState } from 'react';

import { imgUrl, measSet } from '../../templates/consts';

import '../../stylesheets/Chart.css';

HighchartsMore(Highcharts);

interface Props {
    componentOptions: any;
}

export const dummyTrend = () => {
    const start = Math.floor(Math.random() * 500);
    let trend = [start];
    Array.from(Array(50)).forEach(i => {
        const prev = trend[trend.length - 1];
        const offset = Math.round(Math.random() * 30);
        const step = Math.round(Math.random());
        if (step) {
            trend.push(prev + offset);
        } else {
            trend.push(prev - offset);
        }
    })
    return trend;
}

const Trend: React.FC<Props> = ({ componentOptions }) => {

    const [series, setSeries] = useState<any>([]);
    const [options, setOptions] = useState<any>({});

    useEffect(() => {
        setOptions(componentOptions);
    }, []);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: '97.5%', width: '100%', marginTop: '2.5%' } }}
            options={options} />
    )

}

export default Trend;