import ReactTooltip, { PlacesType } from 'react-tooltip';
import { Tooltip as DefaultTooltip } from 'react-tooltip';

import '../../../stylesheets/Tooltip.css';
import Message from './Message';
import Placard from './Placard';
import Info from './Info';
import EquipDp from './EquipDp';
import { useEffect, useState } from 'react';
import PWValidator from './PWValidator';

interface Props {
    id:string;
    anchor:string;
    title?:string;
    type:string;
    content?:any;
    imgs?: any[];
    ent?: any;
    mouseAnchor?: boolean;
    inputVal?:string;
}

const Tooltip: React.FC<Props> = ({ id, anchor, title, type, content, imgs, ent, mouseAnchor, inputVal }) => {
    const [equipEnt, setEquipEnt] = useState<any>(null);
    useEffect(() => {

    }, [anchor]);
    useEffect(() => {
        if (ent?.datapoints?.length) setEquipEnt({ zone: ent, datapoint: ent.datapoints[ent.dpIndex] })
    }, [mouseAnchor]);
    return (
        <DefaultTooltip id={ id } style={ {backdropFilter: 'blur(4px)', background: 'rgba(0, 0, 0, .9)'} }>
            <div className='custom-tooltip'>
                {
                    type == 'message' && <Message content={ content } />
                }
                {
                    type == 'placard' && imgs && <Placard img={ imgs[0] } />
                }
                {
                    type == 'info' && imgs && title && <Info img={ imgs[0] } title={ title } content={ content } />
                }
                {
                    type == 'equipDp' && mouseAnchor && equipEnt && <EquipDp zoneDp={ equipEnt } />
                }
                {
                    type == 'pwTT' && inputVal && <PWValidator val={ inputVal } />
                }
            </div>
        </DefaultTooltip>
    ) 
}

export default Tooltip;