import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl, measSet } from "../../templates/consts";
import MonthlyCRP from "./MonthlyCRP";
import MonthlyEnviroGauge from "./MonthlyEnviroGauge";

interface Props {
  zone: any;
  screenHeight: number;
  activeZone: any;
  setActiveZone:(newActiveZone:any) => void;
}

const MonthlyEnviro: React.FC<Props> = ({ zone, screenHeight, activeZone, setActiveZone }) => {

  const [sessionData, , , getSessionEntity] = useSession();
  const [active, setActive] = useState<boolean>(false);

  const [sessionZone, setSessionZone] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const sZone = await getSessionEntity('zone', zone.zid);
      setSessionZone(sZone);
    })();
  }, []);

  useEffect(() => {
    setActive(activeZone?.zid === zone.zid);
  }, [activeZone]);
  

  return sessionZone && (
    <div className='monthly-zone monthly-enviro' onClick={() => setActiveZone(zone)}>
      <header className='floor-zone-header'>
          <div className='floor-zone-icon' style={{background: 'none', border: '2px solid var(--breezi-blue)', borderBottom: 'none'}}>
              <img src={ `${imgUrl}/envZone.png` } />
          </div>
          <div className='floor-zone-title' style={{marginLeft: window.innerHeight >= 400 && window.innerHeight <= 700 && window.innerWidth >= 1100 ? '10px' : ''}}>
              <h3 style={{border: 'none'}}>{sessionZone.name}</h3>
              <h5>{zone.datapoints.length ?? 0} Datapoint{!zone.datapoints || zone.datapoints.length !== 1 ? 's' : ''}</h5>
          </div>
      </header>
      <div className='monthly-equip-body' style={{ width: '100%', marginTop: '10px' }}>
        {
          measSet.filter((rdg:any) => rdg.prop !== 'days').map((rdg: any, rdgIndex: number) => (
            <MonthlyEnviroGauge rdg={rdg.prop} img={rdg.img} units={rdg.units} val={zone['meas']['workingHoursAvg'][rdg.prop]} screenHeight={ screenHeight } />
          ))
        }
      </div>
      {
          !active &&
          <div className='report-zone-overlay'></div>
      }
    </div>
  )

}

export default MonthlyEnviro;