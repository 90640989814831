import { useEffect, useState } from "react";
import { rangeGroups } from "../templates/defaultSetpoints";
import { useRest } from "./useRest";
import { reduceEachLeadingCommentRange } from "typescript";

interface Props {
    entType:string;
    entId:string;
}

interface RdgType {
    prop:string;
    title:string;
    units:string;
    min:number;
    max:number;
    order:string;
    firstTier:string;
    tiers:string[];
    thresholds:{bad:number, bad2?:number, crit:number, crit2?:number}
}

interface RangeGroupType {
    title:string;
    imgs:string[];
    rdgs:RdgType[];
}

type GroupsType = {
    [prop:string]:any;
}

export const defThresh = () => {
    return {
        htp: {
            crit: 0,
            bad: 13,
            bad2: 26,
            crit2: 31
        },
        hhm: {
            crit: 20,
            bad: 40,
            bad2: 60,
            crit2: 82
        },
        days: {
            crit: 10,
            bad: 25
        },
        coilFlow: {
            bad: 70,
            crit: 90
        },
        dpr: {
            bad: 70,
            crit: 101
        },
        iaq: {
            bad: 150,
            crit: 250
        },
        co2: {
            bad: 500,
            crit: 1000
        },
        voc: {
            bad: 323,
            crit: 761
        }
    }
}

const constructRanges = (item:any) => {
    return Object.keys(item).reduce((rangeObj:any, itemKey) => {
        if (Array.isArray(item[itemKey])) {
            rangeObj[itemKey] = item[itemKey];
        } else {
            rangeObj[itemKey] = Object.keys(item[itemKey]).map(keyItem => item[itemKey][keyItem]);
        }
        return rangeObj;
    }, {});
    // if (Array.isArray(item)) return item;
    // return Object.keys(item).map((itemKey:string) => item[itemKey]);
}

const convertToDbRange = (rg:any, prop:string) => {
    const rGrps = { ...rangeGroups } as any;
    const rdgRanges = Object.keys(rGrps).reduce((allRdgs:any, rgProp:string) => {
        rGrps[rgProp].rdgs.forEach((rdg:any, rdgIndex:number) => {
            let udRdgs = {} as any;
            Object.keys(rdg.thresholds).forEach((thresh:string, tIndex:number) => udRdgs[thresh] = rg[tIndex]);
            allRdgs[rdg.prop] = udRdgs;
        });
        return allRdgs;
    }, {})[prop];
    
    return rdgRanges;
}

export const useSetpoints = ():[ 
    any, 
    any, 
    (newGroup:any) => void, 
    (existingSetpoints?:any) => void, 
    any, 
    (prop:string, rng:any) => void, 
    any, 
    (entId: string, entType: string) => Promise<any>,
    (entId:string, entType:string) => void
] => {

    const [ request ] = useRest();

    // group = groups info/props
    const [ group, setGroup ] = useState<any>({});
    // setpoints = group ranges
    const [ initSetpoints, setInitSetpoints ] = useState<any>(null);
    const [ setpoints, setSetpoints ] = useState<any>(null);
    const [ hasChanges, setHasChanges ] = useState<boolean>(false);
    const [ updatedSp, setUpdatedSp ] = useState<any>({});

    const initEntitySetpoints = (existingSetpoints:any=false) => {
        const thresholds:{[key:string]: any} = {};
        const def:{[key:string]: any} = defThresh();
        Object.keys(def).forEach((rdgProp:string) => {
            thresholds[rdgProp] = existingSetpoints[rdgProp] ?? def[rdgProp];
        });
        setInitSetpoints(thresholds);
        setSetpoints(thresholds);
    }

    const updateGroup = (grpIndex:number) => {
        const grpProp = ['env', 'pressure', 'iaq'][grpIndex];
        const rgs = rangeGroups as GroupsType;
        setGroup(rgs[grpProp]);
    }

    const updateEntitySetpoints = (prop:string, rng:any) => {
        setSetpoints({ ...setpoints, [prop]: rng});
    }

    const submitUpdatedSetpoints = async (entId:string, entType:string) => {
        try {
            return await request(`setpoints/${entId}?entity=${entType}`, 'post', 'setpoints', updatedSp);
        } catch(submitUpdatedSetpointsErr) {
            console.error({ submitUpdatedSetpointsErr })
        }
    }

    const resetDefaultSp = async (entId:string, entType:string) => {
        try {
            return await request(`setpoints/${entId}?entity=${entType}&reset=true`, 'post', 'setpoints');
        } catch(submitUpdatedSetpointsErr) {
            console.error({ submitUpdatedSetpointsErr })
        }
        // setUpdatedSp(defThresh());
    }

    useEffect(() => {
        if (setpoints) {
            const spRanges = constructRanges(setpoints);
            const spInitRanges = constructRanges(initSetpoints);
            let newSp:{[key:string]:any} = {};
            Object.keys(spRanges).forEach(spProp => {
                const propMatch = spRanges[spProp].every((spVal:number, spIndex:number) => spVal == spInitRanges[spProp][spIndex]);
                if (!propMatch) newSp[spProp] = convertToDbRange(setpoints[spProp], spProp);
            });
            setUpdatedSp(newSp);
        }
    }, [setpoints]);

    return [ rangeGroups, group, updateGroup, initEntitySetpoints, setpoints, updateEntitySetpoints, updatedSp, submitUpdatedSetpoints, resetDefaultSp ];

}