import moment from 'moment';

import TrendChart, { dummyTrend } from '../chart/Trend';
import { useTrend } from '../../hooks/useTrend';
import '../../stylesheets/Chart.css';
import '../../stylesheets/Datapoint.css';

import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import TrendControls from './TrendControls';

interface Props {
    equip:any;
}

const logReadable = (tms: any) => {
    if (tms !== null) {
        if (typeof tms === 'string') {
            tms = parseInt(tms);
        }
    }
}

export const intervals = [86400, 3600, 600];

const EquipTrend: React.FC<Props> = ({ equip }) => {

    const { appData, setAppData } = useContext(AppContext);

    const [trendData, setTrendData, getDeviceTrends, genChartOptions] = useTrend('equipment', equip);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [interval, setInterval] = useState<number>(86400);
    const [endTms, setEndTms] = useState<number | null>(null);
    const [breadcrumbs, setBreadcrumbs] = useState<any[]>([0]);
    const [tmsRange, setTmsRange] = useState<any[]>([]);
    const [showControls, setShowControls] = useState<boolean>(false);

    const loadTrendData = async (endTms: number|null, dir?:string|null) => {
        const dps = equip.datapoints;
        if (!dps || !dps.length) {
            return;
        }
        const trendsByDp = await Promise.all(dps.map(async (dp: any) => {
            try {
                let dpTrends = await getDeviceTrends(interval, endTms, dir ?? null, dp.did);
                dpTrends = dpTrends.filter((t: any) => !t.apsn);
                const dpSec = equip.sections[dp.sectionIndex];
                let stage = dpSec.elems[0].type;
                if (stage === 'Coil') stage = 'Supply';
                if (stage === 'Filter') stage = 'Return';
                return {stage: stage, data: dpTrends, apsn: dp.apsn};
            } catch (trendsByDpErr) {
                console.error({ trendsByDpErr, apsn: dp.apsn });
            }
        }))
        return trendsByDp;
    }

    const initGranularUpdate = (index: number, data: any) => {
        if (interval !== 600) {
            const tmsSet = data.map((item: any) => item.tms);
            const ts = tmsSet[index];
            setEndTms(ts);
            logReadable(endTms);
            setLoaded(false);
            setInterval(intervals[intervals.indexOf(interval) + 1]);
        }
      }

    const formatLabels = (data:any) => {
        return data.map((d: any) => moment(d.tms * 1000).format(interval === 86400 ? 'MM/DD' : 'hh:mma:M/DD'));
    }

    const zipTrends = (datae: any[]) => {
        let opts;
        if (datae.length === 1) {
            const data = datae[0];
            const cats = data.map((d: any) => moment(d.tms * 1000).format(interval === 86400 ? 'MM/DD' : 'hh:mma : M/D'));
            setTmsRange(data[0].data.map((d: any) => d.tms));
            opts = genChartOptions(formatLabels(data), 'datapoint', interval, initGranularUpdate, data);
            if (interval === 86400) delete opts.xAxis.plotBands;
        } else {
            const data = datae;
            let primary = data[data[0].data.length >= data[1].data.length ? 0 : 1];
            const secondary = data.find((set: any) => set.apsn !== primary.apsn);
            primary.data.splice(1, primary.data.length - secondary.data.length);
            // primary.data = primary.data.splice(0, secondary.data.length);
            const cats = primary.data.map((d: any) => moment(d.tms * 1000).format(interval === 86400 ? 'MM/DD' : 'M/D | h:mma'));
            setTmsRange(data[0].data.map((d: any) => d.tms));
            const returnStage = [primary, secondary].find((d: any) => d.stage === 'Return');
            const supplyStage = [primary, secondary].find((d:any) => d.stage === 'Supply');
            opts = genChartOptions(cats, 'datapoint', interval, initGranularUpdate, returnStage.data, supplyStage.data, ['Return', 'Supply'])
            if (interval === 86400) delete opts.xAxis.plotBands;
        }
        const options = opts as any;
        [1, 3, 5].forEach((x:number) => options.series[x].visible = false);
        return options;
    }

    const handleTrendNav = async (dir: string) => {
        setLoaded(false);
        const rng = [...tmsRange];
        const newTms = dir === 'forward'
            ? parseInt(rng[rng.length - 1])
            : parseInt(rng[0]);
        let bcs = breadcrumbs;
        bcs[intervals.indexOf(interval)] = newTms;
        setBreadcrumbs(bcs);
        const newData = await loadTrendData(newTms, dir);
        if (newData) {
            const chartOptions = zipTrends(newData);
            setTrendData(chartOptions);
            setLoaded(true);
        }
    }

    useEffect(() => {
        (async () => {
            setLoaded(false);
            if (interval !== 86400) {
                const intervalIndex = intervals.indexOf(interval);
                let newData;
                if (breadcrumbs[intervalIndex]) {
                    newData = await loadTrendData(parseInt(breadcrumbs[intervalIndex]));
                    setBreadcrumbs(breadcrumbs.splice(0, intervalIndex + 1));
                } else {
                    setBreadcrumbs([...breadcrumbs, endTms]);
                    newData = await loadTrendData(endTms);
                }
                if (newData && newData.length) {
                    const chartOptions = zipTrends(newData);
                    if (chartOptions) setTrendData(chartOptions);
                    setLoaded(true);
                }
            } else {
                const datasets = await loadTrendData(null);
                setEndTms(null);
                if (datasets?.length) {
                    const chartOptions = zipTrends(datasets);
                    if (chartOptions) setTrendData(chartOptions);
                    setLoaded(true);
                    const tmsStart = parseInt(tmsRange[0]);
                    setBreadcrumbs([tmsStart]);
                }
            }
            setAppData({ ...appData, loading: { visible: false } });
        })();
    }, [interval]);

    useEffect(() => {
        if (trendData) setLoaded(true);
    }, [trendData]);

    useEffect(() => {
        if (trendData) {
            setLoaded(false);
            setTimeout(() => setLoaded(true), 1000);
        }
    }, [window.innerWidth, window.innerHeight]);

    useEffect(() => {
        if (breadcrumbs[0] === 0) setBreadcrumbs([tmsRange[0]])
        // if (breadcrumbs[0] === 0) setBreadcrumbs([ tmsRange[0], ...breadcrumbs])
    }, [tmsRange]);
 
    return (
        <div className='dp-panel equip-trend-panel' style={{overflow: 'visible'}}>
            {
                loaded && trendData &&
                <>
                    <TrendControls
                        interval={interval}
                        setInterval={setInterval}
                        breadcrumbs={breadcrumbs}
                        handleTrendNav={handleTrendNav}
                        tmsRange={tmsRange} />
                    <TrendChart componentOptions={trendData} />
                </>
            }
        </div>
    )
}

export default EquipTrend;