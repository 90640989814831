import { useContext, useEffect, useState } from "react";
import { TooltipContext } from "../../hooks/useTooltip";
import Tooltip from "../util/tooltip/Tooltip";
import { AppContext } from "../../context/AppContext";


export default () => {

    const { appData, setAppData } = useContext(AppContext);
    
    const [tooltip, setTooltip] = useState<any>(null);

    useEffect(() => {
        setTooltip(appData.tooltip ?? null);
    }, [appData]);

    return tooltip &&
    <Tooltip
        id='master-tooltip'
        anchor={`#${tooltip.anchor}`}
        content={tooltip.content}
        type={tooltip.type} />
}