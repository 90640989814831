import { useContext, useEffect, useRef, useState } from "react";
import { useSession } from '../../hooks/useSessionDev'
import { useNavigate, useParams } from "react-router-dom";

import obZoneTemp from '../../templates/formTemplates/onboardZone.json';
import obEquipTemp from '../../templates/formTemplates/onboardEquipment.json';

import UtilPanel from '../floor/UtilPanel';
import ZonesPanel from '../floor/ZonesPanel';

import { FloorContext } from "../../hooks/useFloorContext";

import '../../stylesheets/Floor.css';
import { AppContext } from "../../context/AppContext";
import FloorZone from "../floor/FloorZone";
import { imgUrl } from "../../templates/consts";
import FloorEquip from "../floor/FloorEquip";
import Form from '../util/Form';
import ModifyEntity from "../util/modifyEntity/ModifyEntity";
import Tooltip from "../util/tooltip/Tooltip";
import Snapshot from "../equipment/EquipSnapshot";

export default () => {

    const { id } = useParams();
    const nav = useNavigate();

    const equipsRef = useRef(null);
    const zonesRef = useRef(null);

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession()

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [floor, setFloor] = useState<any>(null);
    const [zones, setZones] = useState<any[]>([]);
    const [ob, setOb] = useState<string>('');
    const [settings, setSettings] = useState<boolean>(false);
    const [showEquip, setShowEquip] = useState<boolean>(true);
    // const [tooltipProps, setTooltipProps] = useState<any>({ index: -1, equip: {}, mouseAnchor: false, anchor: '' });

    const initializeFloor = async () => {
        const newFloor = await getSessionEntity('floor', id ?? '', true);
        if (newFloor) setSessionEntity('floor', newFloor);
    }

    useEffect(() => {
        (async () => {
            setAppData({ ...appData, loading: { visible: true } });
            await initializeFloor();
        })();
    }, []);

    useEffect(() => {
        if (sessionData.floor) {
            setFloor(sessionData.floor);
            if (localStorage.getItem('breeziProDeeplink')) localStorage.clearItem('breeziProDeeplink');
            setAppData({ ...appData, loading: { visible: false } });
            setZones(sessionData.floor.zones);
        }
    }, [sessionData]);

    const clearOb = async () => {
        const newFloor = await getSessionEntity('floor', id ?? '') as any;
        const newZones = newFloor.zones;
        setZones(newZones);
        setOb('');
    }

    const togFloorSettings = (tog: boolean) => {
        setSettings(tog);
    }

    const handleScroll = (e: any, group: string) => {
        const ref = group === 'equips' ? equipsRef : zonesRef;
        if (ref.current && window.innerWidth > 767) {
            const r = ref.current as any;
            r.scrollLeft += e.deltaY;
        }
    }

    const handleRefresh = (e: any) => {
        e.stopPropagation();
        setFloor(null);
        initializeFloor();
    }

    const initBlocker = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Disabled',
                content: "This feature has been restricted for the duration of the Pilot Program"
            }
        });
    }

    useEffect(() => {
        if ((ob && ob.length || settings)) {
            // if (sessionData.email !== 'support@breezi.io') {
            initBlocker();
            setOb('');
            //     setOb('');
            // }
            // setSettings(false);
        }
    }, [ob, settings]);

    return (
        <main
            className='floor-main'
            style={{
                // height: screenWidth >= 851 ? '' : '90%',
                // padding: screenWidth >= 851 ? '' : 0,
                // marginTop: screenWidth >= 851 ? '' : '50px'
                // marginTop: window.innerHeight >= 400 && window.innerHeight <= 880 ? '4vh' : ''
            }}>
            {
                floor &&
                <section
                    className='panel floor-zones-panel'
                    style={{
                        // width: screenWidth >= 851 ? '' : '90vw',
                        // marginLeft: screenWidth >= 767 ? '' : '0',
                        background: 'none',
                        overflow: 'visible',
                        border: window.innerWidth <= 767 ? 'none' : '',
                        width: window.innerWidth <= 767 ? '95vw' : ''
                        // borderTopRightRadius: 0
                    }}>
                    {
                        window.innerWidth <= 767 &&
                        <div className='mobile-floor-toggle'>
                            <div
                                className={`mobile-floor-tog${showEquip ? ' active-floor-tog' : ''}`}
                                style={{
                                    background: showEquip ? 'var(--equip)' : '',
                                    border: '2px solid var(--equip)'
                                }}
                                onClick={() => setShowEquip(true)}>
                                <img src={`${imgUrl}/equip.png`} />
                            </div>
                            <div
                                className={`mobile-floor-tog${!showEquip ? ' active-floor-tog' : ''}`}
                                style={{
                                    background: !showEquip ? 'var(--breezi-blue)' : '',
                                    border: '2px solid var(--breezi-blue)'
                                }}
                                onClick={() => setShowEquip(false)}>
                                <img src={`${imgUrl}/envZone.png`} />
                            </div>
                        </div>
                    }
                    {
                        (window.innerWidth > 767 || showEquip) &&
                        <div className='floor-zones floor-equips'>
                            <div className='zone-row' ref={equipsRef} onWheel={e => handleScroll(e, 'equips')}>
                                {
                                    !settings &&
                                    <div className='floor-zones-header'>
                                        <img src={`${imgUrl}/equip.png`} />
                                        {/* <h4>HVAC Equipment</h4> */}
                                    </div>
                                }
                                {
                                    zones.filter(zn => zn.equipType).map(z => (
                                        <>
                                            {/* <FloorEquip equip={ z } updateTT={ setTooltipProps } /> */}
                                            <Snapshot equip={z} />
                                        </>
                                    ))
                                }
                                <div className='floor-zone ob-floor-zone' onClick={() => setOb('equip')}>
                                    <img src={`${imgUrl}/equip.png`} />
                                    <h4>Add HVAC Equipment</h4>
                                    <h1>+</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (window.innerWidth > 767 || !showEquip) &&
                        <div className='floor-zones' style={{ marginTop: window.innerWidth > 767 ? '10px' : '' }}>
                            <div className='zone-row' ref={zonesRef} onWheel={e => handleScroll(e, 'zones')}>
                                {
                                    !settings &&
                                    <div className='floor-zones-header'>
                                        {/* <div className='footer-settings-node' onClick={ () => togFloorSettings(true) }>
                                                    <img src={ `${imgUrl}/gearsWhite.png` } />
                                                </div> */}
                                        <img src={`${imgUrl}/envZone.png`} />
                                        {/* <h4>Measurement Zones</h4> */}
                                    </div>
                                }
                                {
                                    zones.filter(zn => !zn.equipType).map(z => (
                                        <>
                                            <FloorZone z={z} clearOb={clearOb} refresh={initializeFloor} />
                                        </>
                                    ))
                                }
                                <div className='floor-zone ob-floor-zone' onClick={() => setOb('zone')}>
                                    <img src={`${imgUrl}/envZone.png`} />
                                    <h4>Add Environmental Zone</h4>
                                    <h1>+</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        ob == 'zone' &&
                        <Form tmp={obZoneTemp} closeForm={() => clearOb()} parentId={id} tier={'floorZone'} />
                    }
                    {
                        ob == 'equip' &&
                        <Form tmp={obEquipTemp} closeForm={() => clearOb()} parentId={id} tier={'floorZone'} />
                    }
                    {
                        settings &&
                        <ModifyEntity
                            entType='floor'
                            ent={floor}
                            dest={'building'}
                            modifyableProps={[]}
                            floor={true}
                            closeModify={() => togFloorSettings(false)} />
                    }
                    {
                        !settings && window.innerWidth > 767 &&
                        <div className='floor-settings'>
                            <div className='floor-settings-node' style={{ marginBottom: window.innerHeight >= 1200 ? '85px' : '55px' }} onClick={() => setSettings(true)}>
                                <img src={`${imgUrl}/gearsWhite.png`} />
                            </div>
                            <div className='floor-settings-node' style={{ marginTop: window.innerHeight >= 1200 ? '85px' : '55px' }} onClick={e => handleRefresh(e)}>
                                <img src={`${imgUrl}/reload.png`} />
                            </div>
                        </div>
                    }
                </section>

            }
            {/* <Tooltip
                id='equip-dp-tooltip'
                anchor={tooltipProps.anchor}
                type='equipDp'
                ent={{ ...tooltipProps.zone, dpIndex: tooltipProps.index }}
                mouseAnchor={tooltipProps.mouseAnchor} /> */}

        </main>
    )

}