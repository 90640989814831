import { useEffect, useState } from "react";
import CardHeader from "./CardHeader";
import { imgUrl, measSet } from "../../templates/consts";

interface Props {
    datapoint: any;
    dpIndex: number;
    setActiveIndex: (index: number) => void;
    zoneRefresh: () => void;
}

type alert = { bad: number, crit: number };

const Alerts: React.FC<Props> = ({ datapoint, dpIndex, setActiveIndex, zoneRefresh }) => {

    const [alertList, setAlertList] = useState<alert[]>([]);

    const constructAlertList = () => {
        if (!datapoint.alerts) {
            setAlertList([]);
        } else {
            const alertProps = Object.keys(datapoint.alerts).filter(a => !['dpr', 'bat'].includes(a));
            const critList = alertProps.reduce((critAlerts: any[], alertProp: string) => {
                const meas = measSet.find((ms: any) => ms.prop == alertProp);
                if (datapoint.alerts[alertProp].crit) critAlerts.push({
                    prop: alertProp,
                    tier: 'crit',
                    img: meas?.img,
                    title: meas?.title
                });
                return critAlerts;
            }, []);
            const warnList = alertProps.reduce((warnAlerts: any[], alertProp: string) => {
                const meas = measSet.find((ms: any) => ms.prop == alertProp);
                if (datapoint.alerts[alertProp].bad
                    && !critList.find(cAlert => cAlert.prop == alertProp)) warnAlerts.push({
                        prop: alertProp,
                        tier: 'warn',
                        img: meas?.img,
                        title: meas?.title
                    });
                return warnAlerts;
            }, []);
            setAlertList(critList.concat(warnList));
        }
    }

    useEffect(() => {
        constructAlertList();
    }, []);

    const mProp = (rdg: string): any => {
        return measSet.find(m => m.prop == rdg);
    }

    return (
        <div className='card-content'>
            <CardHeader datapoint={datapoint} dpIndex={dpIndex} subtitle={'Alerts '} setActiveIndex={setActiveIndex} zoneRefresh={zoneRefresh} />
            <div className='dp-card-body card-rdg-content card-alert-content'>
                {
                    alertList.length > 0 && alertList.map((alert: any, alertIndex) => (
                        <div
                            className='dp-card-alert'
                            style={{
                                background: `var(--${alert?.tier == 'crit' ? 'crit' : 'cancel'}-transparent)`,
                                border: `2px solid var(--${alert?.tier == 'crit' ? 'crit' : 'cancel'})`
                            }}>
                            <div
                                className="dp-alert-icon"
                                style={{ background: `var(--${alert?.tier == 'crit' ? 'crit' : 'cancel'}` }}>
                                <img src={`${imgUrl}/${alert.img}.png`} />
                            </div>
                            {
                                alert.tier == 'warn' &&
                                <h5>{alert.title} Warning</h5>
                            }
                            {
                                alert.tier == 'crit' &&
                                <h5>Critical {alert.title} Alert</h5>
                            }
                        </div>
                    ))
                }
                {
                    datapoint.meas && !alertList.length &&
                    <div
                        className='dp-card-alert'
                        style={{
                            background: 'var(--prim-transparent)',
                            border: '2px solid var(--prim)'
                        }}>
                        <div
                            className="dp-alert-icon"
                            style={{ background: 'var(--prim)' }}>
                            <img src={`${imgUrl}/checked.png`} />
                        </div>
                        <h5>No Active Alerts</h5>
                    </div>
                }
                {
                    !datapoint.meas &&
                    <div
                        className='dp-card-alert'
                        style={{
                            background: 'var(--breezi-blue-transparent)',
                            border: '2px solid var(--breezi-blue)'
                        }}>
                        <div
                            className="dp-alert-icon"
                            style={{ background: 'var(--breezi-blue)' }}>
                            <img src={`${imgUrl}/calibrating.png`} />
                        </div>
                        <h5>Calibrating Sensors</h5>
                    </div>
                }
            </div>
        </div>
    )
}

export default Alerts;