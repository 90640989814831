import { defaultAlertThresholds, threshMap, cleanAvg, listProps, cleanAlerts } from "../templates/consts";

const convertDbSetpoints = sp => {
    return Object.keys(sp).reduce((formattedThresholds, spProp, spPropIndex) => {
        const spVal = sp[spProp];
        if (['htp', 'hhm'].includes(spProp)) {
            formattedThresholds[spProp] = [
                [defaultAlertThresholds[spProp][0][0], spVal.crit],
                [spVal.crit + 1, spVal.bad],
                [spVal.bad + 1, spVal.bad2],
                [spVal.bad2 + 1, spVal.crit2],
                [spVal.crit2 + 1, defaultAlertThresholds[spProp][4][1]]
            ]
        } else if (spProp == 'days') {
            formattedThresholds.days = [
                [defaultAlertThresholds.days[0][0], spVal.crit],
                [spVal.crit + 1, spVal.bad],
                [spVal.bad + 1, defaultAlertThresholds.days[2][1]]
            ]
        } else {
            formattedThresholds[spProp] = [
                [defaultAlertThresholds[spProp][0][0], spVal.bad],
                [spVal.bad + 1, spVal.crit],
                [spVal.crit + 1, defaultAlertThresholds[spProp][2][1]]
            ]
        }
        return formattedThresholds;
    }, {});
}

const justTierAlerts = alerts => {
    return Object.keys(alerts).reduce((tier, alertProp) => {
        if (tier == 'crit' || alerts[alertProp].crit) {
            return 'crit'
        } else if (tier == 'bad' || alerts[alertProp].bad) {
            return 'bad';
        } else {
            return 'good';
        }
    }, '');
}

export const assignAlerts = (ent, parentSp) => {
    let alerts = {};
    let thresholds = defaultAlertThresholds;
    if (parentSp) {
        parentSp = convertDbSetpoints(parentSp);
        Object.keys(parentSp).forEach(spProp => thresholds[spProp] = parentSp[spProp]);
    }
    if (ent.setpoints) {
        const entSp = convertDbSetpoints(ent.setpoints);
        Object.keys(entSp).forEach(spProp => thresholds[spProp] = entSp[spProp]);
    }
    if (!ent.meas) {
        alerts = false;
    } else {
        if (ent.meas.tms) ent.updatedTms = ent.meas.tms;
        if (ent.meas.alerts) delete ent.meas.alerts;
        let alertSet = ['htp', 'hhm'];
        if (ent.config) {
            if (ent.config === 'env') {
                alertSet = alertSet.concat(['iaq', 'co2', 'voc']);
            } else {
                alertSet = alertSet.concat(['dpr', 'days']);
            }
        }
        alerts = alertSet.reduce((aggAlerts, rdg, alertIndex, alertArr) => {

            // if (thresholds[rdg] && rdg != 'bat') {
            if (thresholds[rdg] && !['bat', 'days', 'dpr95'].includes(rdg)) {
                let val = ent.meas[rdg];
                let thresh = thresholds[rdg];
                if (thresh) {
                    let thRange;
                    if (rdg !== 'voc') {
                        thRange = thresh.find(threshTier => val >= threshTier[0] && val <= threshTier[1]);
                    } else {
                        val = parseFloat(val);
                        thRange = thresh.find(threshTier => val >= threshTier[0] && val <= threshTier[1]);
                    }
                    const thIndex = thresh.indexOf(thRange);
                    if (thIndex > -1) {
                        const alertTier = threshMap[rdg][thIndex].replace('2', '');
                        if (alertTier != 'good') aggAlerts[rdg][alertTier] += 1;
                        if (alertIndex == alertArr.length - 1) {
                            aggAlerts.overall = justTierAlerts(aggAlerts);
                        }
                    }
                }
            }
            return aggAlerts
        }, cleanAlerts());
    }
    ent.alerts = alerts;
    return ent;
}

export const getChildAlerts = children => {
    let alerts = {};
    const hasAlerts = children.filter(c => c.alerts);
    if (!hasAlerts.length) {
        alerts = false;
    } else {
        alerts = children.map(c => c.alerts).reduce((entAlerts, childAlerts, caIndex, cAlertArr) => {
            Object.keys(childAlerts).forEach(rdg => {
                if (rdg != 'overall') {
                    entAlerts[rdg].bad += childAlerts[rdg].bad;
                    entAlerts[rdg].crit += childAlerts[rdg].crit;
                }
            })
            if (caIndex == cAlertArr.length - 1) {
                entAlerts.overall = justTierAlerts(entAlerts);
            }
            return entAlerts;
        }, cleanAlerts());
    }
    return alerts;
}

const avgChildren = (children, sp, childProp) => {
    const avged = children.reduce((avg, child, cIndex, cArr) => {
        delete child.alerts;
        Object.keys(child).forEach(rdg => {
            let val = child[rdg];
            if (rdg == 'voc' && typeof val == 'string') val = parseFloat(val);
            avg[rdg] += val;
            if (cIndex == cArr.length - 1) {
                avg[rdg] = rdg == 'voc' ? parseFloat((avg[rdg]/children.length).toFixed(3)) : parseInt(avg[rdg]/children.length);
            }
        });
        return avg;
    }, cleanAvg());
    return Object.keys(avged).every(aProp => avged[aProp] == 0) ? false : avged;
}

export const avgAlert = (ent, childProp, sp = defaultAlertThresholds) => {
    const cPropIndex = listProps.indexOf(childProp);
    if (['datapoints', 'sections'].includes(childProp)) {
        if (!ent.datapoints?.length) {
            ent.alerts = false;
        } else {
            ent.datapoints = ent.datapoints.map(dp => dp = assignAlerts(dp, ent.setpoints ?? false));
            ent.alerts = getChildAlerts(ent.datapoints);
        }
    } else {
        let children = ent[childProp];
        if (!children || !children.length) {
            ent.alerts = false;
        } else {
            try {
                if (children.length) {
                    children = children.map(child => avgAlert(child, listProps[cPropIndex + 1], ent.setpoints ?? false));
                    ent[childProp] = children;
                    ent.alerts = getChildAlerts(ent[childProp]);
                }
            } catch (err) {
                console.error({ err })
            }
        }
    }
    return ent;
}

// export const avgAlert = (ent, childProp, sp = defaultAlertThresholds) => {
//     if (ent && ent.sections) childProp = 'datapoints';
//     const childIndex = listProps.indexOf(childProp);
//     // if (!ent[childProp]) ent[childProp] = [];
//     try {
//         if (!ent[childProp] || !ent[childProp].length) {
//             ent.alerts = false;
//         } else {
//             if (childProp == 'datapoints') {
//                 ent[childProp] = ent.meas ? ent[childProp].map(dp => assignAlerts(dp), ent.setpoints ?? false) : false;
//                 return ent;
//             } else {
                
//             }
//         }
        // ent.alerts = getChildAlerts(ent[childProp]);
    //     if (!ent[childProp] || !ent[childProp.length]) {
    //         ent.alerts = false
    //     } else {
    //         const children = ent[childProp];
    //         if (childProp == 'datapoints') {
    //             const noAlerts = children.filter(child => !child.hasOwnProperty('alerts'));
    //             noAlerts.forEach(naChild => {
    //                 const naIndex = children.indexOf(naChild);
    //                 if (!naChild.meas) {
    //                     naChild.alerts = false;
    //                 } else {
    //                     naChild = assignAlerts(naChild, ent.setpoints ?? false);
    //                 }
    //                 ent[childProp][naIndex] = naChild;
    //             })
    //         } else {
    //             ent[childProp] = ent[childProp].map(child => {
    //                 if (childProp === 'sections') {
    //                     child.alerts = false;
    //                     return child;
    //                 } else {
    //                     child.alerts = getChildAlerts(ent[childProp])
    //                     return avgAlert(child, listProps[childIndex + 1]);
    //                 }
    //             })
    //         }
    //         ent.alerts = getChildAlerts(ent[childProp]) ?? false;
    //     }
        // let hasAlerts = ent[childProp].filter(child => child.hasOwnProperty('alerts'));
        // while (hasAlerts.length != ent[childProp].length) {
            
        // let hasMeas = ent[childProp].filter(child => child.hasOwnProperty('meas'));
        // while (hasMeas.length != ent[childProp].length) {
        //     if (childProp != 'datapoints') {
        //         ent[childProp] = ent[childProp].map(child => {
        //             if (childProp != 'sections') {
        //                 return avgAlert(child, listProps[childIndex + 1])
        //             } else {
        //                 child.meas = false;
        //                 return child;
        //             }
        //         });
        //         hasMeas = ent[childProp].filter(child => child.hasOwnProperty('meas'));
        //     } else {
        //         const noMeas = ent[childProp].filter(child => !child.hasOwnProperty('meas'));
        //         noMeas.forEach(nmChild => {
        //             const nmIndex = ent[childProp].indexOf(nmChild);
        //             nmChild.meas = false;
        //             ent[childProp][nmIndex] = nmChild;
        //         });
        //     }
        // }
        // if (childProp == 'datapoints') ent[childProp] = ent[childProp].map(child => assignAlerts(child, ent.setpoints ?? false));
        // ent.meas = avgChildren(ent[childProp].map(c => c.meas), sp, childProp);
        
        // const meas = ent.meas;
//         return ent;
//     } catch(avgAlertErr) {
//         console.error({ childProp, avgAlertErr })
//     }
// }