
import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { imgUrl } from "../../templates/consts";

export default () => {

    const { appData, setAppData } = useContext(AppContext);

    const styles:{[key:string]:any} = {
        info: {
            primary: 'var(--breezi-blue)',
            secondary: 'var(--breezi-recessive)',
            img: 'info'
        },
        warning: {
            primary: 'var(--cancel)',
            secondary: '#947141',
            img: 'alert'
        },
        error: {
            primary: 'var(--crit)', 
            secondary: '#874A4E',
            img: 'errWhite'
        },
        tutorial: {
            primary: 'var(--prim)',
            secondary: '#4F8D51',
            img: 'checked'
        },
        blocker: {
            primary: 'var(--cancel)',
            secondary: '#8F6834',
            img: 'closeToo'
        }
    }

    type helperType = {type:string, title:string, body:string}|null;

    const [helper, setHelper] = useState<helperType>(null);
    const [prim, setPrim] = useState<any>(null);
    const [sec, setSec] = useState<any>(null);
    const [img, setImg] = useState<any>(null);

    useEffect(() => {
        if (appData.helper && appData.helper.type) {
            setHelper(appData.helper);
            setPrim(styles[appData.helper.type].primary ?? 'var(--breezi-blue)');
            setSec(styles[appData.helper.type].secondary ?? 'var(--breezi-recessive)');
            setImg(styles[appData.helper.type].img ?? 'tutorial');
        }
    }, []);

    // const prim = () => styles[appData.helper.type].primary;
    // const sec = () => styles[appData.helper.type].secondary;
    // const img = () => styles[appData.helper.type].img;

    return (
        <div className='overlay helper-overlay' onClick={() => setAppData({ ...appData, helper: false })}>
            {
                helper && appData.helper.type &&
                    <section className='panel helper-panel'>
                    <header className='helper-header' style={ {background: prim} }>
                        <div 
                            className='helper-icon'
                            style={ {
                                background: sec,
                                border: `10px solid ${prim}`
                            } }>
                            <img src={ `${imgUrl}/${img}.png`} />
                        </div>
                    </header>
                    <div className='helper-body'>
                            <h2 style={{
                                color: prim,
                                fontSize: appData.helper.title === 'Temporary Password Accepted' ? '1.2em' : ''
                            }}>{appData.helper.title}</h2>
                        <p>
                            { appData.helper.content }
                        </p>
                        <p>
                            { appData.helper.footnote ?? '' }
                        </p>
                            <small
                                onClick={() => setAppData({ ...appData, helper: false })}
                                style={{
                                    width: appData.helper.title === 'Temporary Password Accepted' ? '100%' : '',
                                    left: appData.helper.title === 'Temporary Password Accepted' ? '-20px' : ''
                                }}>{appData.helper.title !== 'Temporary Password Accepted' ? 'Dismiss' : 'Set My Password'}</small>
                    </div>
                </section>
            }
        </div>
    )

}