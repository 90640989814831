import { useState, useEffect } from 'react';
import moment from "moment";
import cToF from 'celsius-to-fahrenheit';

// import { useSession } from '../../hooks/useSessionContext';
import { useSession } from '../../hooks/useSessionDev';
import { imgUrl, measSet, profiles, setStatus } from "../../templates/consts";
import CardHeader from "./CardHeader";
import Env from './Env';
import Filter from './Filter';
import Tooltip from '../util/tooltip/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useRest } from '../../hooks/useRest';

interface Props {
    datapoint: any;
    zName: string;
    dpIndex: number;
    setActiveIndex: (index: number) => void;
    diff?: boolean;
    zoneRefresh: () => void;
    setPlacard?:false | ((newPlacard:string) => void);
}

const CardMeas: React.FC<Props> = ({ datapoint, zName, dpIndex, setActiveIndex, diff, zoneRefresh, setPlacard }) => {

    const nav = useNavigate();

    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession();
    const [req] = useRest();

    const [htp, setHtp] = useState<{ val: number | string, units: string }>({ val: '-', units: '˚C' });
    const [onOff, setOnOff] = useState<boolean>(false);

    const oneAlert = (prop: string) => {
        let alert = 'good'
        if (datapoint.alerts && datapoint.alerts[prop]) {
            const rdg = datapoint.alerts[prop];
            if (rdg.bad) alert = 'bad'
            if (rdg.crit) alert = 'crit';
        }
        return alert;
    }

    const getUnits = (rdg: string) => {
        const meas = measSet.find(m => m.prop == rdg);
        return meas?.units ?? '';
    }

    // const assessOnOff = () => {
    //     if (!sessionData.zone.servedBy) {
    //         return 'powerOff';
    //     }
    //     const zones = sessionData.building.floors.reduce((allZones: any[], floor: any) => allZones.concat(floor.zones), []);
    //     const equip = zones.find((z: any) => z.id === sessionData.zone.servedBy.id);
    //     return !equip?.meas || equip.meas.dpr < 20 ? 'powerOff' : 'power';
    // }

    const handleEquipNav = async () => {
        const servedZone = await getSessionEntity('zone', sessionData.zone.servedBy.id);
        setSessionEntity('zone', servedZone);
        nav(`/equipment/${sessionData.zone.servedBy.id}`);
    }

    const assessOnOff = async () => {
        const zone = await getSessionEntity('zone', datapoint.zid);
        if (zone.servedBy) {
            const equip = await getSessionEntity('zone', zone.servedBy.id);
            if (equip.datapoints) {
                const dids = equip.datapoints.map((dp: any) => dp.did);
                const onOffs = await Promise.all(dids.map(async (did: number) => {
                    return (await req('onOff/' + did, 'get')).rawDpr;
                }));
                setOnOff(onOffs.some((oo:any) => typeof oo === 'number' && oo >= 10))
            }
        }
    }

    useEffect(() => {
        assessOnOff();
    }, []);

    return (
        <div className='card-content'>
            <CardHeader datapoint={datapoint} dpIndex={dpIndex} subtitle={'Current Readings'} setActiveIndex={setActiveIndex} zoneRefresh={zoneRefresh} />
            {
                (datapoint.config && ['env', 'fan'].includes(datapoint.config) || !diff) && <Env dp={datapoint} setPlacard={setPlacard ?? false} />
            }
            {
                datapoint.config && datapoint.config == 'filter' && diff && <Filter dp={datapoint} coil={false} />
            }
            {
                datapoint.config && datapoint.config == 'coil' && diff && <Filter dp={datapoint} coil={true} />
            }
            {
                sessionData.zone?.servedBy && sessionData.building &&
                // <div className='card-served-by' onClick={() => nav(`/equipment/${sessionData.zone.servedBy.id}`)}>
                <div className='card-served-by'>
                    <div
                        className='card-hvac-ref'
                            data-tooltip-id={`${datapoint.id}-supplied-by-tt`}
                            onClick={() => handleEquipNav()}
                        id={`${datapoint.id}-supplied-by`}
                        style={{cursor: 'pointer', border: `2px solid ${onOff ? 'var(--equip)' : 'gray'}`}}>
                        <small>{sessionData.zone.servedBy.name}</small>
                        <img src={`${imgUrl}/${onOff ? 'power' : 'powerOff'}.png`} />
                    </div>
                </div>
            }
            {
                !sessionData.zone?.servedBy && datapoint.config == 'env' &&
                <div className='card-served-by'>
                    <div
                        className='card-hvac-ref'
                        style={{ width: '160px', border: `2px solid gray` }}
                        id={`${datapoint.id}-supplied-by`}>
                        <small>No HVAC Assigned</small>
                        <img src={`${imgUrl}/powerOff.png`} />
                    </div>
                </div>
            }
            <Tooltip
                type='message'
                id={`${datapoint.id}-supplied-by-tt`}
                anchor={`#${datapoint.id}-supplied-by`}
                content='Supplied by:' />
            {
                datapoint.config === 'env' &&
                <div className='card-tms'>
                    <small>Last Updated: {moment(datapoint.updatedTms * 1000).format('MMM DD @ h:mma')}</small>
                </div>
            }
        </div>
    )
}

export default CardMeas;