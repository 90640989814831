import { useNavigate, useParams } from "react-router-dom";
import { capitalize, imgUrl, profiles, setStatus } from "../../templates/consts";
import { useContext, useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import Tooltip from "../util/tooltip/Tooltip";
import { defThresh } from "../../hooks/useSetpoints";
import { useRest } from "../../hooks/useRest";
import { assignAlerts } from "../../util/alertAvg";
import { AppContext } from "../../context/AppContext";

interface Props {
    datapoint: any;
    dpIndex: number;
    subtitle?: string;
    setActiveIndex: (index: number) => void;
    zoneRefresh: () => void;
}

const CardHeader: React.FC<Props> = ({ datapoint, dpIndex, subtitle, setActiveIndex, zoneRefresh }) => {

    const nav = useNavigate();
    const { id } = useParams();

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData, , , getSessionEntity, setSessionEntity] = useSession();
    const [req] = useRest();
    const [dpName, setDpName] = useState<string|null>(null);

    const prof = profiles.find(p => p.prop == datapoint.config);

    const formatName = async () => {
        if (sessionData.zone) {
            const zone = sessionData.zone;
            if (datapoint.config == 'env') {
                const name = datapoint.name;
                if (name != '+|dynamic|+') return name;
                return `${prof?.title} ${dpIndex + 1}`;
            } else {
                let formatZone = sessionData.zone ?? false;
                if (!formatZone) formatZone = await getSessionEntity('zone', datapoint.zid);
                const likeConfig = formatZone.datapoints.filter((dp: any) => dp.config == datapoint.config);
                return `${capitalize(datapoint.config)} ${likeConfig.map((dp:any) => dp.apsn).indexOf(datapoint.apsn) + 1}`;
            }
        }
    }

    const oneAlert = (prop: string) => {
        let alert = 'good'
        if (datapoint.alerts && datapoint.alerts[prop]) {
            const rdg = datapoint.alerts[prop];
            if (rdg.bad) alert = 'bad'
            if (rdg.crit) alert = 'crit';
        }
        return alert;
    }

    const getBatImg = () => {
        if (!datapoint.alerts || !datapoint.alerts.bat) return 'bat';
        const bat = oneAlert('bat');
        switch (bat) {
            case 'bad': return 'averageBattery';
            case 'crit': return 'badBattery';
            default: return 'goodBattery';
        }
    }

    const statusLen = () => {
        switch (subtitle) {
            case 'Current Readings':
                return 'full';
            case 'Equipment':
                return 'left';
            case 'Condition Risk Profile':
                return 'left';
            default: return 'right';
        }
    }

    const updateActiveIndex = (e: any, index: number) => {
        e.stopPropagation();
        setActiveIndex(index);
    }

    const cardStatus = () => {
        // if (['env', 'fan'].includes(datapoint.config)) {} return setStatus('', datapoint.alerts?.overall ?? '');
        if (!datapoint.alerts || !datapoint.meas) return 'var(--breezi-blue)';
        let color;
        if (Object.keys(datapoint.alerts).some((alertRdg: string) => datapoint.alerts[alertRdg].bad)) {
            return 'var(--cancel)';
        } else if (Object.keys(datapoint.alerts).some((alertRdg: string) => datapoint.alerts[alertRdg].crit)) {
            return 'var(--crit)';
        } else {
            return 'var(--prim)';
        }
    }

    const handleNavDp = () => {
        if (!sessionData.zone.equip && window.innerHeight >= 880) nav(`/datapoint/${datapoint.id}`);
    }

    const clickSp = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex(6)
    }

    const handleRefresh = async (e: any) => {
        e.stopPropagation();
        try {
            let updated = await req(`datapoint/${datapoint.id}`, 'get');
            const z = { ...sessionData.zone };
            const zApsns = z.datapoints.map((dp: any) => dp.apsn);
            const dpIndex = zApsns.indexOf(datapoint.apsn);
            updated = assignAlerts(updated, z.setpoints ?? false);
            z.datapoints[dpIndex] = updated;
            setSessionEntity('zone', z);
            zoneRefresh();
        } catch (refreshErr) {
            console.error({ refreshErr });
            const err = refreshErr as any;
            setAppData({
                ...appData, helper: {
                    type: 'error',
                    title: 'Error refreshing Datapoint readings',
                    content: err.message
                }
            });
        }
        setAppData({ ...appData, loading: { visible: false } });
    }

    useEffect(() => {
        (async () => {
            const datapointName = await formatName();
            setDpName(datapointName);
        })();
    }, []);

    return dpName ?
        <>
            <header
                className={`card-status ${statusLen()}-card-status`}
                style={{ background: cardStatus() }}></header>
            <header
                className='dp-card-header' onClick={() => handleNavDp()}
                style={{ cursor: !sessionData.zone?.equip ? 'pointer' : '' }}>
                <div className='dp-card-title'>
                    <h4
                        data-tooltip-id={`${datapoint.apsn}-${dpIndex}-tooltip`}
                        id={`${datapoint.apsn}-header`}>
                        {dpName}
                    </h4>
                    <small style={{ color: setStatus('bat', oneAlert('bat')) }}>
                        <img src={`${imgUrl}/${getBatImg()}.png`} />
                        {datapoint.meas.bat ?? '-'} {datapoint.meas.bat && '%'}
                        {
                            !datapoint.maintenanceHistory &&
                            <img onClick={e => handleRefresh(e)} style={{ cursor: 'pointer', marginLeft: '10px' }} src={`${imgUrl}/reload.png`} />
                        }
                    </small>
                    {
                        datapoint.setpoints && datapoint.setpoints != defThresh() &&
                        <img
                            className='dp-sp-indicator'
                            src={`${imgUrl}/setpoints.png`}
                            data-tooltip-id={`${datapoint.id}-header-tooltip`}
                            onClick={e => clickSp(e)} />
                    }
                </div>
                {/* <div className='card-icon card-settings-icon' onClick={ e => handleRefresh(e) }>
                    <img src={ `${imgUrl}/reload.png` } />
                </div> */}
                <div className='card-icon card-settings-icon' onClick={e => updateActiveIndex(e, 5)}>
                    <img src={`${imgUrl}/gearsWhite.png`} />
                </div>
                <Tooltip type='message' id={`${datapoint.apsn}-${dpIndex}-tooltip`} anchor={`${datapoint.apsn}-header`} content={datapoint.apsn} />
            </header>
            <div className='card-header-subtitle'>
                {/* <small>{subtitle}</small> */}
            </div>
            <Tooltip
                id={`${datapoint.id}-header-tooltip`}
                anchor={`#${datapoint.id}-header`}
                type='message'
                content='Custom Setpoints Present' />
        </> :
        <>
            <header
                className={`card-status ${statusLen()}-card-status`}
                style={{ background: cardStatus() }}></header>
            <header
                className='dp-card-header' onClick={() => handleNavDp()}
                style={{ cursor: !sessionData.zone?.equip ? 'pointer' : '' }}>
                <div className='dp-card-title'>
                    <h4>
                        {/* {dpName} */}
                    </h4>
                    <small></small>
                </div>
                <div className='card-icon card-settings-icon' onClick={e => updateActiveIndex(e, 5)}>
                    <img src={`${imgUrl}/gearsWhite.png`} />
                </div>
            </header>
        </>

}

export default CardHeader;