import { useContext, useRef } from "react";
import { useEquipment } from "../../hooks/useEquipmentContext";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl } from "../../templates/consts";
import { equipBank } from "../../templates/equipmentComponentTemplate";
import { AppContext } from "../../context/AppContext";

interface Props {
    bankState: string;
}

const EquipmentBank: React.FC<Props> = ({ bankState }) => {

    const bankRef = useRef(null);

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData] = useSession();
    const [equip, setHoldElem, , , setHoldAp] = useEquipment();

    const handleElemDrag = (e: any, elem: any) => {
        if (sessionData.email === 'support@breezi.io') setHoldElem(true, elem);
    }

    const handleAPDrag = (e: any, ap: any) => {
        setHoldAp(true, ap);
    }

    const assessBorderColor = (type: string) => {
        let color;
        switch (type) {
            case 'Filter': color = 'white';
                break;
            case 'Coil': color = 'breezi-blue';
                break;
            default: color = 'equip';
        }
        return `var(--${color})`;
    }

    const handleScroll = (e: any) => {
        if (bankRef.current) {
            const bank = bankRef.current as any;
            bank.scrollLeft += e.deltaY
        }
    }

    const initBlocker = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Disabled',
                content: "This feature has been restricted for the duration of the Pilot Program"
            }
        });
    }

    return (
        <div
            className='equip-bank'
            style={{
                animation: 'none', justifyContent: bankState == 'config' ? '' : (!sessionData.apBank || !sessionData.apBank.length ? 'center' : 'flex-start')
            }}
            ref={bankRef}
            onWheel={e => handleScroll(e)}>
            {
                bankState == 'config'
                    ? equipBank.map((bankItem: any, bankItemIndex: number) => (
                        <div
                            className='hvac-bank-node'
                            style={{ border: `3px solid ${assessBorderColor(bankItem.type)}` }}
                            draggable={false}
                            // onDragStart={e => handleElemDrag(e, bankItem)}>
                            onDragStart={() => initBlocker()}
                            onClick={() => initBlocker()}>         
                            <img src={`${imgUrl}/equip-${bankItem.img}.png`} />
                            <small style={{ color: assessBorderColor(bankItem.type) }}>{bankItem.title}</small>
                        </div>
                    ))
                    : (!sessionData.apBank?.length
                        ? <h3>Your AirPulse Bank is Empty.</h3>
                        : sessionData.apBank.map((ap: any, apIndex: number) => (
                            <div
                                className='hvac-bank-node'
                                style={{ border: '3px solid var(--breezi-blue)' }}
                                draggable={false}
                                // onDragStart={e => handleElemDrag(e, ap)}>
                                onDragStart={() => initBlocker()}
                                onClick={() => initBlocker()}>
                                <img src={`${imgUrl}/envAp.png`} />
                                <small style={{ color: 'var(--breezi-blue)' }}>{ap.apsn}</small>
                            </div>
                        ))
                    )
            }
        </div>
    )
};

export default EquipmentBank;