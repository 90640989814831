import { useState, useEffect } from "react";

import { useAuth } from '../../../hooks/useAuth';
// import { useSession } from "../../../hooks/useSessionContext"
import { useSession } from "../../../hooks/useSessionDev"
import { imgUrl } from "../../../templates/consts";
import Summary from "./Summary";
import changePwTmp from '../../../templates/formTemplates/changePassword.json';
import Form from '../Form';

export default () => {

    const [, , , , , logout] = useAuth();

    const [sessionData] = useSession();
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [changePw, setChangePw] = useState<boolean>(false);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [window.innerWidth]);

    return (
        <div className='settings-body'>
            {
                sessionData.organization &&
                <div className='s-section'>
                    <div className='s-section-head'>
                        <img src={`${imgUrl}/locale.png`} />
                        <h5>Location</h5>
                    </div>
                    <div className='s-section-content'>
                        <h5>{sessionData.organization.name}</h5>
                        <h5>{sessionData.organization.street}</h5>
                        <h5>{sessionData.organization.locale.city}{sessionData.organization.locale.state ? `, ${sessionData.organization.locale.state}` : ''}</h5>
                        <h5>{sessionData.organization.country}, {sessionData.organization.zipCode}</h5>
                    </div>
                </div>
            }
            <Summary />
            {
                sessionData.organization &&
                <div className='s-section'>
                    <div className='s-section-head'>
                        <img src={`${imgUrl}/orders.png`} />
                        <h5>Work Orders</h5>
                    </div>
                    <div className='s-section-content'>
                        <h5>Coming Soon</h5>
                    </div>
                </div>
            }
            {
                screenWidth <= 767 &&
                <div className='s-section' style={{ justifyContent: 'center' }}>
                    <a
                        className='btn cancel-btn'
                        style={{ background: 'var(--cancel)', height: '25px', marginBottom: '20px' }}
                        onClick={() => logout()}>
                        Logout
                    </a>
                </div>
            }
            {/* {
                window.innerHeight > 880 &&
                <div className='s-section' style={{ justifyContent: 'center' }}>
                    <a className='cancel-btn settings-card-btn' onClick={() => { setChangePw(true) }}>Change Password</a>
                </div>
            }
            {
                changePw &&
                <div className='overlay settings-form-overlay'>
                    <Form tmp={changePwTmp} closeForm={() => setChangePw(false)} />
                </div>
            } */}
        </div>
    );

}