import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl } from "../../templates/consts";

interface Props {
    stageTog: number;
    setStageTog: (newStageTog: number) => void;
}

const MobToggle: React.FC<Props> = ({ stageTog, setStageTog }) => {

    const [equip] = useEquipment();

    const secImg = (sec: any) => {
        const type = sec.elems && sec.elems.length > 0
            ? sec.elems[0].type.toLowerCase()
            : '';
        let img = `${imgUrl}/`
        switch (type) {
            case 'fan':
                return img + 'hvac.png';
            case 'coil':
                return img + 'coilFlow.png';
            case 'filter':
                return img + 'filterOfficial.png';
        }
    }

    return (
        <div className='mob-equip-toggle'>
            {
                equip && equip.sections?.sort((a: any, b: any) => a.index - b.index).map((sec: any, secIndex: number) => (
                    <div
                        className={`mob-equip-tog${secIndex === stageTog ? ' mob-active-tog' : ''}`}
                        onClick={() => setStageTog(secIndex)}>
                        <img src={secImg(sec)} />
                    </div>
                ))
            }
        </div>
    )
}

export default MobToggle;