interface Props {
    content:string;
}

const Message:React.FC<Props> = ({ content }) => {
  return (
    <div className='tooltip-msg'>
        <p>{ content }</p>
    </div>
  )
}

export default Message
