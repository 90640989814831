import { useContext, useEffect, useState } from "react";

import '../../stylesheets/Setpoints.css';
import { compileSessionIds, findInHierarchy, imgUrl, threshMap } from "../../templates/consts";
import Range from "./Range";
import { defThresh, useSetpoints } from "../../hooks/useSetpoints";
// import { useSession } from "../../hooks/useSession";
import { useSession } from "../../hooks/useSessionDev";
import { AppContext } from "../../context/AppContext";

interface Props {
    entType:string;
    entId:string;
    defaultSp?:any;
    initCancel:() => void;
    currentVals?:any;
    initResetAlerts: (newSp: any) => Promise<void>;
    name?: string;
    watchVal?: boolean;
    config?:string;
}
 
interface RdgType {
    prop:string;
    title:string;
    units:string;
    min:number;
    max:number;
    order:string;
    firstTier:string;
    tiers:string[];
    thresholds:{bad:number, bad2?:number, crit:number, crit2?:number}
}

interface RangeGroupType {
    title:string;
    imgs:string[];
    rdgs:RdgType[];
}

type GroupsType = {
    [prop:string]:any;
}

const threshOrder = (prop:keyof typeof threshMap, rngs:any) => {
    const orderedThresh = threshMap[prop].filter(t => t != 'good');
    return orderedThresh.reduce((newThresh:any, tier:string) => {
        newThresh[tier] = rngs[tier];
        return newThresh;
    }, {});
}

const Setpoints: React.FC<Props> = ({ entType, entId, defaultSp, initCancel, currentVals, initResetAlerts, name, watchVal, config }) => {
    
    const { appData, setAppData } = useContext(AppContext)

    const [ groups, group, updateGroup, initEntitySetpoints, setpoints, updateSetpoints, updatedSp, submitUpdatedSetpoints, resetDefaultSp ] = useSetpoints();
    const [sessionData, , , getSessionEntity, setSessionEntity, getLocalBundle, , , updateParentEnts] = useSession();

    const [ activeGroup, setActiveGroup ] = useState<number>(0);
    const [showRanges, setShowRanges] = useState<boolean>(false);
    const [configGroups, setConfigGroups] = useState<string[]>(['env', 'pressure', 'iaq']);
    const [resetConf, setResetConf] = useState<boolean>(false);

    const filterConfigSp = (config: string) => {
        
    }

    useEffect(() => {
        'HERE'
        initEntitySetpoints(defaultSp);
        setShowRanges(true);
    }, []);

    useEffect(() => {
        if (entType != 'zone') {
            const dp = sessionData.zone.datapoints.find((dp: any) => dp.id == entId);
            if (dp.config && dp.config == 'env') {
                setConfigGroups(['env', 'iaq']);
            } else {
                setConfigGroups(['env', 'pressure'])
            }
        } else {
            setConfigGroups(['env', 'iaq']);
        }
    }, [watchVal]);

    useEffect(() => {
        setShowRanges(false);
        setTimeout(() => {
            updateGroup(activeGroup);
            setShowRanges(true);
        }, 100);
    }, [activeGroup]);

    const submitSp = async (reset:boolean) => {
        try {
            const newSp = !reset ? await submitUpdatedSetpoints(entId, entType) : await resetDefaultSp(entId, entType);
            if (newSp.restTms) delete newSp.restTms;
            const ent = entType == 'datapoint' ? sessionData.zone.datapoints.find((dp: any) => dp.id == entId) : { ...sessionData[entType] };
            // const ent = await getSessionEntity(entType, entId, false) as any;
            // const ent = await getSessionEntity(entType, entId);
            ent.setpoints = newSp.reset ? false : newSp;
            setSessionEntity(entType, ent);
            initResetAlerts(newSp);
            updateParentEnts(entType, ent)
            setAppData({ ...appData, loading: { visible: false } })
            initCancel();
        } catch(submitSpErr) {
            console.error({ submitSpErr });
            setAppData({ ...appData, loading: {visible: false} })
        }
    }

    const setSpHeight = () => {
        switch (entType) {
            case 'building': return '95%';
            case 'zone': return '80%';
            case 'floor': return '86.5%';
            case 'datapoint': return '';
        }
    }

    const parseSetActive = (index: number) => {
        const dp = sessionData.zone.datapoints.find((dp: any) => dp.id == entId);
        const config = dp.config;
        setActiveGroup(config && config == 'env' && index == 1 ? 2 : index);
    }

    const confirmReset = async () => {
        submitSp(true);
    }


    return (
        <>
            {
                setpoints &&
                <div className='sp'>
                    <header className='sp-header'>
                        <div className='sp-icon'>
                            <img src={ `${imgUrl}/setpoints.png` } />
                        </div>
                        <div className='header-title sp-title'>
                            <h2>{ group.title }</h2>
                                <small>
                                    Alert Setpoints
                                    <span style={{
                                        width: '100%',
                                        color: 'gray',
                                        margin: '5px'
                                    }}>{name ? `| ${name}` : ''}</span>
                                </small>
                        </div>
                    </header>
                    <div className='sp-tabs'>
                        {
                            configGroups.map((gProp, gPropIndex) => (
                                <div
                                    className={ `sp-tab${group.prop == gProp ? ' active-sp-tab' : ''}` }
                                    onClick={() => { parseSetActive(gPropIndex) }}>
                                    {
                                        groups[gProp].imgs[0] === 'days' &&
                                        <img src={ `${imgUrl}/${config === 'coil' ? 'coilFlow' : 'days'}.png`} />
                                    }
                                    {
                                        groups[gProp].imgs[0] !== 'days' &&
                                        <img src={ `${imgUrl}/${groups[gProp].imgs[0]}.png` } />
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className='sp-body'>
                        {  
                            showRanges && group.rdgs.map((rdg:any, rdgIndex:number) => (
                                <Range 
                                    key={`rdg${rdg.prop}`}
                                    rdgRange={setpoints[rdg.prop]}
                                    img={group.imgs[rdgIndex]}
                                    udSetpoints={updateSetpoints}
                                    currentVal={currentVals[rdg.prop]}
                                    rdgProps={groups[group.prop].rdgs[rdgIndex]}
                                    config={config ?? false} />
                            ))
                        }
                    </div>
                </div>
            }
            <div className='sp-btns'>
                {
                    entType == 'datapoint' && setpoints != defThresh() && <a className='sp-btn' style={ {color: 'lightblue'} } onClick={ () => setResetConf(true) }>Factory Defaults</a>
                }
                <a className='sp-btn' style={ {color: 'var(--orange-util)'} } onClick={ () => initCancel() }>Cancel</a>
                {
                    Object.keys(updatedSp).length > 0 && <a className='sp-btn' style={ {color: 'var(--prim)'} } onClick={ () => submitSp(false) }>Submit</a>
                }
            </div>
            {
                resetConf &&
                <div className='overlay dark-overlay reset-overlay' style={ {background: 'rgba(0, 0, 0, 0.9)'}}>
                        <h5>Are you sure you would like to reset Alert Thresholds to their factory default settings?<br /> <span style={{ color: 'var(--crit)' } }> This can not be undone.</span></h5>
                    <a className='settings-card-btn sec-btn' onClick={() => { submitSp(true) }}>Confirm</a>
                    <a className='settings-card-btn cancel-btn' onClick={ () => setResetConf(false) }>Cancel</a>
                </div>
            }
        </>
    )

}

export default Setpoints;