import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import Landing from './components/views/Landing';
import Loading from './components/util/LoadingMessage';
import Org from './components/views/Organization';
import Util from './components/util/UtilOverlay';
import Building from './components/views/Building';
import Floor from './components/views/Floor';
import Zone from './components/views/Zone';
import MobileZone from './components/zone/MobileZone';
import MobileDatapoint from './components/datapoint/MobileDatapoint';

import { AppContextType, AppContext } from './context/AppContext';
import { FormContext } from './hooks/useFormContext';

import './App.css';
import 'react-tooltip/dist/react-tooltip.css'
// import { SessionContext } from './hooks/useSessionContext';
// import { SessionContext } from './hooks/useSession';
import { SessionContext } from './hooks/useSessionDev';
import Datapoint from './components/views/Datapoint';
import Helper from './components/util/Helper';
import Equipment from './components/views/Equipment';
import Settings from './components/util/settings/Settings';
import Tooltip from './components/tooltip/Tooltip';
import MobileEquipment from './components/equipment/MobileEquipment';
import NavBetween from './components/util/NavBetween';
import ViewNavigator from './components/util/ViewNavigator';
import OrientationOverlay from './components/util/OrientationOverlay';
import MonthlyReport from './components/report/MonthlyReport';

interface ContextType { [key: string]: any };

function App() {
  const [appData, setAppData] = useState<any>({})
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [sessionData, setSessionData] = useState<{ [key: string]: any }>({});
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [showMobileHeader, setShowMobileHeader] = useState<boolean>(false);

  const [initialDims, setInitialDims] = useState<any>({w: window.innerWidth, h: window.innerHeight});
  const [windowDims, setwindowDims] = useState<any>({ w: window.innerWidth, h: window.innerHeight });
  const [screenDims, setScreenDims] = useState<any>({ w: window.screen.width, h: window.screen.height });
  const [showOrientationOverlay, setShowOrientationOverlay] = useState<boolean>(false);

  const ref = useRef(null) as any;

  const isFullScreen = () => {
    return (
      window.innerHeight > window.screen.height ||
      window.matchMedia('(display-mode: standalone)').matches
    );
  };

  const handleSwipeStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (ref.current) {
      ref.current = e.touches[0].clientY;
    }
  }

  const handleSwipeEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (ref.current && e.changedTouches[0].clientY < ref.current) {
      setShowMobileHeader(true);
    }
    ref.current = null;
  }

  const handleResize = () => {
    setScreenDims({w: window.screen.width, h: window.screen.height})
    setwindowDims({w: window.innerWidth, h: window.innerHeight})
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const orientation = (w: number, h: number) => w > h ? 'landscape' : 'portrait'

  useEffect(() => {
    const initialOrientation = orientation(initialDims.w, initialDims.h);
    const newOrientation = orientation(windowDims.w, windowDims.h);
    setShowOrientationOverlay(initialOrientation !== newOrientation);
    setAppData({ ...appData, windowDims, screenDims })
  }, [windowDims, screenDims]);

  return (
    <SessionContext.Provider value={{ sessionData, setSessionData }}>
      <FormContext.Provider value={{ formData, setFormData }}>
        <AppContext.Provider value={{ appData, setAppData }}>
          <div className="App"
            style={{
              height: window.innerWidth > 767 || window.matchMedia('(display-mode: standalone)').matches ? '100vh' : 'calc(100vh - 75px)'
              // height: window.innerWidth > 767 || isFullScreen() ? '100vh' : 'calc(100vh - 75px)',
              // marginTop: !isFullScreen() && window.innerWidth <= 767 ? '60px' : ''
            }}
            ref={ref}>
            <Router>
              <Routes>
                <Route index element={<Landing />} />
                <Route path={'/secondarySignup'} element={<Landing />} />
                <Route path={'/organization/:id'} element={<Org />} />
                <Route path={'/organization'} element={<Org />} />
                <Route path={'/building/:id'} element={<Building />} />
                <Route path={'/floor/:id'} element={<Floor />} />
                <Route path={'/navigator'} element={<ViewNavigator />} />
                <Route path={'/monthlyReport/:id'} element={<MonthlyReport />} />
                {
                  screenWidth > 767
                    ? <Route path={'/zone/:id'} element={<Zone />} />
                    : <Route path={'/zone/:id'} element={<MobileZone />} />
                }
                {
                  screenWidth > 767
                    ? <Route path={'/equipment/:id'} element={<Equipment />} />
                    : <Route path={'/equipment/:id'} element={<MobileEquipment />} />
                }
                {
                  screenWidth > 767
                    ? <Route path={'/datapoint/:id'} element={<Datapoint />} />
                    : <Route path={'/datapoint/:id'} element={<MobileDatapoint />} />
                }
              </Routes>
              {
                appData?.loading?.visible &&
                <Loading />
              }
              {
                ['organization', 'building', 'floor', 'zone', 'datapoint'].some(ent => sessionData.hasOwnProperty(ent)) &&
                <Util />
              }
              {
                appData?.settings &&
                <Settings />
              }
              {
                appData?.helper &&
                <Helper />
              }
              {/* {
                showMobileHeader && <h2 style={{position: 'absolute', zIndex: 1000, width: '100vw', height: '40px', background: 'red'}}>HI</h2>
              } */}
            </Router>
            <Tooltip />
            {
              showOrientationOverlay &&
              <OrientationOverlay orientation={orientation(initialDims.w, initialDims.h)} />
            }
          </div>
        </AppContext.Provider>
      </FormContext.Provider>
    </SessionContext.Provider>
  );
}

export default App;
