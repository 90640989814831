import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev"
import { EquipContext } from "../../hooks/useEquipmentContext";
import MobToggle from "./MobToggle";
import MobHVAC from "./MobHVAC";

export default () => {

    const [sessionData] = useSession();
    const [equip, setEquip] = useState<any>({});
    const [stageTog, setStageTog] = useState<number>(0);
    const [showContent, setShowContent] = useState<boolean>(true);

    useEffect(() => {
        setShowContent(false);
        setTimeout(() => setShowContent(true), 20)
    }, [stageTog]);


    return (
        <main className='mob-equip-main'>
            <EquipContext.Provider value={{ equip, setEquip }}>
                <MobToggle stageTog={stageTog} setStageTog={setStageTog} />
                {
                    showContent &&
                    <MobHVAC stageTog={stageTog} setStageTog={setStageTog} />
                }
            </EquipContext.Provider>
        </main>
    )

}