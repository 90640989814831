import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import { defaultAlertThresholds, imgUrl, measSet } from "../../templates/consts";
import DiffReading from "../reading/DiffRdg";
import ReadingGauge from "../reading/ReadingGauge";
import ReadingNode from "../reading/ReadingNode";
import { useRest } from "../../hooks/useRest";

interface Props {
    dp: any;
    coil: boolean;
}

const Filter: React.FC<Props> = ({ dp, coil }) => {

    const [sessionData, , , getSessionEntity] = useSession();
    const [req] = useRest();

    const [showBody, setShowBody] = useState<boolean>(false);
    const [onOff, setOnOff] = useState<boolean>(false);

    const getMin = (rdg: string) => {
        const defaultThresh = defaultAlertThresholds as any;
        const thresh = defaultThresh[rdg];
        return thresh[0][0];
    }

    const getMax = (rdg: string) => {
        const defaultThresh = defaultAlertThresholds as any;
        const thresh = defaultThresh[rdg];
        const maxThresh = thresh[thresh.length - 1];
        return maxThresh[1];
    }

    const assessOnOff = async () => {
        const equip = await getSessionEntity('zone', dp.zid);
        const dids = equip.datapoints.map((dp: any) => dp.did);
        const onOffs = await Promise.all(dids.map(async (did: number) => {
            return (await req('onOff/' + did, 'get')).rawDpr;
        }));
        setOnOff(onOffs.some((oo:any) => typeof oo === 'number' && oo >= 10));
    }

    useEffect(() => {
        if (dp) {
            setShowBody(false);
            setTimeout(() => setShowBody(true), 100);
            assessOnOff();
        }
    }, [dp]);

    return (
        <div className='card-body' style={{ marginTop: 0 }}>
            <div className='card-rdg-content lg-rdg-content'>
                <div className='card-rdg-rdgs'>
                    {
                        dp && dp.meas &&
                        <DiffReading
                            val={dp.meas ?? false}
                            alert={dp.alerts ?? false}
                            coil={coil} />
                    }
                    <div
                        className='card-rdg-rdgs'
                        style={{
                            width: '100%',
                            justifyContent: 'center',
                            marginTop: window.innerHeight >= 400 && window.innerHeight <= 880 && window.innerWidth > 767 ? '-10px' : '10px'
                        }}>
                        <div className='card-rdg-row lg-rdg-row' style={{ justifyContent: 'center' }}>
                            {
                                ['htp', 'hhm'].map((prop: string) => (
                                    <ReadingGauge
                                        rdg={prop}
                                        val={dp.meas[prop] ?? false}
                                        min={getMin(prop)}
                                        max={getMax(prop)}
                                        alert={dp.alerts[prop] ?? false}
                                        diff={false} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='diff-on-off'>
                {
                    onOff
                        ? <img src={`${imgUrl}/power.png`} />
                        : <img src={`${imgUrl}/powerOff.png`} />
                }
                <h5 style={{ color: onOff ? 'var(--equip)' : 'gray' }}>Unit {onOff ? 'ON' : 'OFF'}</h5>
            </div>
        </div >
    )
}

export default Filter;