import React, { useEffect, useState } from 'react';

import '../../stylesheets/Selectors.css';
import { useSelector } from '../../hooks/useSelector';
import { useForm } from '../../hooks/useFormContext';

interface Props {
    collectionProps: string[];
    initList:string;
    updateStep:() => void;
    entity:string|null;
}

const truncateCountryName = (countryName:string) => {
    return countryName.length > 18 ? `${countryName.substring(0, 3)}...` : countryName;
}

const Selector:React.FC<Props> = ({ collectionProps, initList, updateStep, entity }) => {

    const [ list, filterList, resetList ] = useSelector(initList);
    const [ content, setContent ] = useState<string>('');
    const [ formData, updateFormData ] = useForm();

    useEffect(() => {
        filterList(content, collectionProps);
    }, [content]);

    const updateProps = (item: any) => {
        const udProps = {
            country: item.isoCode2,
            isoCode2: item.isoCode2,
            countryCodes: item.countryCodes
        }
        updateFormData(udProps);
        updateStep();
    }

    return (
        <div className='lg-selector ltd-selector'>
            <div className='full-width-selector'>
                <label className='text-input'>
                    <input 
                    value={ content } 
                    onChange={ e => setContent(e.target.value) }
                    placeholder='Search...' />
                </label>
            </div>
            {
                list && list.length > 1 &&
                <>
                    {
                        list.map((li, liIndex) => (
                            <div className='selector-node' onClick={ () => updateProps(li) }>
                                <div className='selector-tab'></div>
                                {
                                    initList.includes('country') &&
                                    <img src={ `https://catamphetamine.github.io/country-flag-icons/3x2/${li.isoCode2}.svg` } />
                                }
                                {
                                    li.country &&
                                    <small>{ truncateCountryName(li.country) }</small>
                                }
                            </div>
                        ))
                    }
                </>
            }
        </div>
    )
}

export default Selector;