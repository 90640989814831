import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

import { useForm } from '../../hooks/useFormContext';

interface Props {
    initSubmitDrawer: (code: string) => void;
    resendCode: () => void;
}

const SegmentedNumber: React.FC<Props> = ({ initSubmitDrawer, resendCode }) => {

    const [otp, setOtp] = useState<string>('');
    const [formData, updateFormData, , authRequest] = useForm();

    useEffect(() => {
        updateFormData({ confCode: otp });
    }, [otp]);

    useEffect(() => {
        if (otp.length == 6) {
            initSubmitDrawer(otp);
        }
    }, [otp]);

    return (
        <>
            <h4 style={{ margin: 0 }}>Please enter the confirmation code sent to your email</h4>
            <OtpInput
                value={otp}
                onChange={setOtp}
                renderInput={props => <input {...props} />}
                numInputs={6}
                inputStyle={{
                    fontSize: '2em',
                    background: 'none',
                    border: '2px solid var(--breezi-blue)',
                    margin: '10px',
                    color: 'var(--white)',
                    fontFamily: 'Comfortaa, sans-serif',
                    marginTop: '-100px',
                    borderRadius: '5px'
                }} />
            <a className='seg-link' onClick={() => resendCode()}>
                <small style={{ fontFamily: 'Comfortaa, sans-serif' }}>Resend Code</small>
            </a>
        </>
    )
}

export default SegmentedNumber;