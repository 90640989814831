export const rangeGroups = {
    env: {
        title: 'Environment',
        prop: 'env',
        imgs: ['temp', 'hum'],
        rdgs: [
            {
                prop: 'htp',
                title: 'Temperature',
                units: '˚F',
                min: -10,
                max: 40,
                order: 'both',
                firstTier: 'crit',
                tiers: ['crit', 'cancel', 'prim', 'cancel', 'crit'],
                thresholds: {
                    crit: 32,
                    bad: 55,
                    bad2: 78,
                    crit2: 87
                }
            },
            {
                prop: 'hhm',
                title: 'Humidity',
                units: '%',
                min: 0,
                max: 100,
                order: 'both',
                firstTier: 'crit',
                tiers: ['crit', 'cancel', 'prim', 'cancel', 'crit'],
                thresholds: {
                    crit: 20,
                    bad: 40,
                    bad2: 60,
                    crit2: 82
                }
            }
        ]
    },
    pressure: {
        title: 'Pressure/Condition',
        prop: 'pressure',
        imgs: ['days', 'coilFlow', 'dpr'],
        rdgs: [
            {
                prop: 'days',
                title: 'Filter Health',
                units: ' d',
                min: 0,
                max: 180,
                order: 'desc',
                firstTier: 'crit',
                tiers: ['crit', 'cancel', 'prim'],
                thresholds: {
                    crit: 10,
                    bad: 25
                }
            },
            {
                prop: 'dpr',
                title: 'Pressure Differential',
                units: 'Pa',
                min: 0,
                max: 120,
                order: 'asc',
                firstTier: 'good',
                tiers: ['prim', 'cancel', 'crit'],
                thresholds: {
                    bad: 70,
                    crit: 101
                }
            }
        ]
    },
    iaq: {
        title: 'Air Quality',
        prop: 'iaq',
        imgs: ['voc', 'eCO2Text', 'tvocWithText'],
        rdgs: [
            {
                prop: 'iaq',
                title: 'Air Quality Index',
                units: '',
                min: 0,
                max: 500,
                order: 'asc',
                firstTier: 'good',
                tiers: ['prim', 'cancel', 'crit'],
                thresholds: {
                    bad: 150,
                    crit: 250
                }
            },
            {
                prop: 'co2',
                title: 'eCO2',
                units: 'ppm',
                min: 0,
                max: 1500,
                order: 'asc',
                firstTier: 'good',
                tiers: ['prim', 'cancel', 'crit'],
                thresholds: {
                    bad: 500,
                    crit: 1000
                }
            },
            {
                prop: 'voc',
                title: 'tVOC',
                units: 'ppm',
                min: 0,
                max: 1000,
                order: 'asc',
                firstTier: 'good',
                tiers: ['prim', 'cancel', 'crit'],
                thresholds: {
                    bad: 323,
                    crit: 761
                }
            }
        ]
    }
}