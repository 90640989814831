import cToF from 'celsius-to-fahrenheit';

import { imgUrl, measSet, setStatus } from "../../templates/consts";
import '../../stylesheets/Reading.css';
import { useSession } from "../../hooks/useSessionDev";

interface Props {
    rdg: string;
    alert: any;
    val: number | boolean;
}

const VertReading: React.FC<Props> = ({ rdg, alert, val }) => {

    const [sessionData] = useSession();

    const setUnits = () => {
        let m = measSet.find((m: any) => m.prop == rdg);
        if (m) {
            return rdg === 'htp' && sessionData.imperial ? '˚F' : m.units;
        } else {
            return '-';
        }
    }

    const setVal = (v: any) => {
        if (typeof v !== 'number') {
            v = parseInt(v);
        }
        return Math.round(rdg === 'htp' && sessionData.imperial ? cToF(v) : v)
    }

    return <div
        className='vert-reading'
        style={{
            border: `2px solid ${setStatus(rdg, alert)}`
        }}>
        <div
            className='vert-reading-header'
            style={{
                background: setStatus(rdg, alert, true),
                borderBottom: `2px solid ${setStatus(rdg, alert)}`
            }}>
            <img src={`${imgUrl}/${rdg == 'htp' ? 'temp' : rdg}.png`} />
        </div>
        {
            rdg !== 'voc' &&
            <div className='vert-reading-body' style={{ flexDirection: ['htp', 'hhm'].includes(rdg) ? 'row' : 'column' }}>
                {
                    val &&
                    <>
                        <h1 style={{ color: setStatus(rdg, alert) }}>{setVal(val)}</h1>
                        <h4 style={{ color: setStatus(rdg, alert), marginBottom: ['htp', 'hhm'].includes(rdg) ? '13%' : '' }}>{setUnits()}</h4>
                    </>
                }
                {
                    !val && <h5 style={{ color: 'gray' }}>Calibrating</h5>
                }
            </div>
        }
        {
            rdg === 'voc' &&
            <div className='vert-reading-body' style={{ flexDirection: ['htp', 'hhm'].includes(rdg) ? 'row' : 'column' }}>
                {
                    val &&
                    <>
                        <h1 style={{ color: setStatus(rdg, alert) }}>{val}</h1>
                        <h4 style={{ color: setStatus(rdg, alert) }}>{setUnits()}</h4>
                    </>
                }
                {
                    !val && <h5 style={{ color: 'gray' }}>Calibrating</h5>
                }
            </div>
        }
    </div>

}

export default VertReading;