import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { measSet, imgUrl, profiles, setStatus } from '../../templates/consts';

// import { useSession } from '../../hooks/useSessionContext';
// import { useSession } from '../../hooks/useSession';
import { useSession } from '../../hooks/useSessionDev';

import '../../stylesheets/Zone.css';

export default () => {

	const { id } = useParams();
	const nav = useNavigate();

	const [sessionData, , , getSessionEntity, setSessionEntity] = useSession();

	const [zone, setZone] = useState<any>(null);

	const getProfile = (dp: any) => profiles.find(p => p.prop == dp.config);

	const setDpName = (dp: any, dpIndex: number) => {
		if (dp.name != '+|dynamic|+') return dp.name;
		const hasProfile = zone?.datapoints.filter((zdp: any) => zdp.config == dp.config) ?? false;
		const prof = getProfile(dp);
		return `Environmental Sensor ${hasProfile.indexOf(dp) + 1}`;
	}

	const isOdd = (index: number) => index % 2 === 0;

	const statusNodes = (alert: any) => {
		if (['boolean', 'string'].includes(typeof alert)) return [];
		let nodes = 1;
		if (alert.bad) {
			nodes = 2;
		} else if (alert.crit) {
			nodes = 3;
		} else {
			nodes = 1;
		}
		return Array.from(Array(nodes), (x, y) => y);
	}

	const initializeZone = async () => {
		const newZone = await getSessionEntity('zone', id ?? '');
		if (newZone) setSessionEntity('zone', newZone);
	}

	useEffect(() => {
		(async () => {
			await initializeZone();
		})();
	}, []);

	useEffect(() => {
		if (sessionData.zone) {
			setZone(sessionData.zone);
		}
	}, [sessionData.util]);

	return (
		<main className='mobile-zone-main'>
			<div className='mobile-zone-panel'>
				{
					zone && zone.datapoints.map((dp: any, dpIndex: number) => (
						<div
							className='mob-zone-dp'
							onClick={() => nav(`/datapoint/${dp.id}`)}
							style={{
								background: setStatus('', dp.alerts.overall ?? false, true),
								border: `2px solid ${setStatus('', dp.alerts.overall ?? false)}`,
								width: '100px',
								height: '100px'
							}}>
							<img src={`${imgUrl}/apIcon.png`} />
							<h5>{setDpName(dp, dpIndex)}</h5>
						</div>
					))
				}
				{/* {
				zone && zone.datapoints.map((dp:any, dpIndex:number) => (
					<div className='mob-zone-dp' onClick={ () => nav(`/datapoint/${dp.id}`) }>
						<div 
							className='mob-dp-header'
							style={ {borderBottom: `1px solid ${setStatus('', dp.alerts.overall ?? false)}`} }>
							<img 
								src={ `${imgUrl}/${getProfile(dp)?.img ?? ''}.png` }
								style={ {background: setStatus('', dp.alerts.overall ?? false)} } />
							<h2
								style={ {color: setStatus('', dp.alerts.overall ?? false)} }>
								{ setDpName(dp, dpIndex) }
							</h2>
						</div>
						<div className='mob-dp-body'>
							{
								measSet.map((rdg:any, rdgIndex:number) => (
									<div 
										className='mob-dp-meas'
										style={ {
											justifyContent: 'flex-start'} }>
										<div 
											className='mob-dp-icon'
											style={ {background: !dp.alerts[rdg.prop] ? 'var(--breezi-blue-transparent)' : setStatus(rdg.prop, dp.alerts[rdg.prop] ?? false, true)} }>
											<img src={ `${imgUrl}/${rdg.img}.png` } />
										</div>	
										<div 
											className='mob-dp-status'
											style={ {
												// background: !dp.alerts[rdg.prop] ? 'var(--breezi-blue)' : setStatus(rdg.prop, dp.alerts[rdg.prop] ?? false)
											} }>
											{
												statusNodes(dp.alerts[rdg.prop] ?? false).map((node:any) => (
													<div 
														className='mob-dp-status-node'
														style={ {background: !dp.alerts[rdg.prop] ? 'var(--breezi-blue-transparent)' : setStatus(rdg.prop, dp.alerts[rdg.prop] ?? false, true)
													} }>
													</div>
												))
											}
										</div>
									</div>
								))
							}
						</div>
							{
								dp.meas && dp.updatedTms
								?	<small className='mob-dp-updated'>
										Updated: { moment(dp.updatedTms * 1000).format('MM/DD @ h:mma') }
									</small>
								:	<small className='mob-dp-updated'>
										Calibrating...
									</small>
							}
					</div>
				))
			} */}
			</div>
		</main>
	)

}