import React, { useEffect, useState, useContext } from 'react';

import { FormContext, useForm } from '../../hooks/useFormContext';
import { imgUrl } from '../../templates/consts';

import Steps from '../stepProgress/Steps';
import Selector from '../selectors/Selector';
import TextInput from '../form/TextInput';
import SegmentedNumber from '../form/SegmentedNumber';
import Drawer from './Drawer';
import Prefill from '../form/Prefill';
import LocaleValidator from '../form/LocaleValidator';
import { useSession } from '../../hooks/useSessionDev';
import { useRest } from '../../hooks/useRest';

import { FormRequest, Input, Step, FormTemplate } from '../../tsDeclarations/form';

import '../../stylesheets/Form.css';
import LtdSelector from '../form/LtdSelector';
import BinaryInput from './BinaryToggle';
import Notes from '../form/Notes';
import { AppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

import forgotPwTemplate from '../../templates/formTemplates/forgotPw.json';
import ForgotPassword from '../form/ForgotPassword';

interface Props {
    tmp: object;
    closeForm: (attemptLogin: boolean, newTeamMember?: any) => void;
    parentId?: string;
    tier?: string;
    initForgot?:() => void;
}

const FormComponent: React.FC<Props> = ({ tmp, closeForm, parentId, tier, initForgot }) => {

    const nav = useNavigate();

    const { appData, setAppData } = useContext(AppContext)
    const [request] = useRest();
    const [, , , , , , postObUpdate, , , , addTeamMember] = useSession();
    const [formData, updateFormData, clearInputs, authRequest, validateStep, invalidProps, addRestResProps] = useForm();

    const [template, setTemplate] = useState<FormTemplate>(tmp as FormTemplate);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [stepComplete, setStepComplete] = useState<boolean>(false);
    const [showForgotConf, setShowForgotConf] = useState<boolean>(false);


    const [sessionData] = useSession();

    const updateCurrentStep = async (newStep: number) => {
        const cStep = template?.steps[currentStep];
        try {
            if (['text', 'prefill'].includes(cStep?.inputs[0].inputType)) {
                const invalids = validateStep(cStep.inputs);
                if (invalids && !Object.keys(invalidProps).some((ip: string) => invalidProps[ip].inactive)) {
                    if (cStep.stepRequest?.auth) {
                        const authRes = await authRequest(cStep.stepRequest.route);
                        setCurrentStep(newStep);
                    } else if (cStep.stepRequest) {
                        const req = cStep.stepRequest;
                        let route = req.route;
                        if (req.queryStringParameters) {
                            route += '?';
                            const queryStrings = req.queryStringParameters.map(qp => `${qp}=${formData[qp]}`);
                            route += queryStrings.join('&');
                        }
                        const restRes = await request(route, req.method);
                        if (cStep.stepRequest.setProps) {
                            addRestResProps(['locale'], restRes);
                        }
                        setCurrentStep(newStep);
                        setTimeout(() => setAppData({ ...appData, loading: { visible: false }, helper: false }), 500);
                    }
                } else {
                    setCurrentStep(newStep);
                }
            } else {
                setCurrentStep(newStep);
            }
        } catch (stepErr) {
            console.error({ stepErr });
            setTimeout(() => closeForm(false), 2000)
        }
        // TODO: write contingencies here!
    }

    useEffect(() => {
        const step = template.steps[currentStep];
        const iProps = !step.inputs[0].props
            ? step.inputs.filter(i => !i.optional).map(i => i.prop)
            : step.inputs[0].props;
        const streetExtended = iProps.find((iProp: string) => iProp == 'streetExtended');
        if (streetExtended) {
            setStepComplete(formData.street ? true : false);
        } else {
            let isComplete = true;
            const matched = step.inputs.find((i: any) => i.match)
            if (matched) {
                const matchee = step.inputs.find((i: any) => i.prop === matched.match);
                if (matchee) {
                    if ([matched.prop, matchee?.prop].some((m: any) => !formData[m])) isComplete = false;
                    const dProp = formData[matched.prop] ?? 1;
                    const eProp = formData[matchee.prop] ?? 2;
                    if (dProp !== eProp) isComplete = false;
                } else {
                    isComplete = false;
                }
            }
            if (!iProps.every(ip => formData[ip] && formData[ip].toString().trim().length)) isComplete = false;
            setStepComplete(isComplete);
        }
    }, [formData]);

    const updateFromSelector = (selProps: any[]) => {
        updateFormData({ ...selProps });
        setCurrentStep(currentStep + 1);
    }

    useEffect(() => {
        let invalid = invalidProps ?? {};
        if (Object.keys(invalid).some(ip => typeof invalid[ip] != 'string')) setStepComplete(false);
    }, [invalidProps]);

    const incrementStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const decrementStep = () => {
        setCurrentStep(currentStep - 1);
    }

    const initSubmitDrawer = () => {
        setStepComplete(false);
        setStepComplete(true);
    }

    const submitForm = async () => {
        if (template.onComplete?.auth) {
            await authRequest(template.onComplete?.route);
            closeForm(true);
        } else {
            let data = formData;
            if (data.email) data.email = data.email.toLowerCase();
            if (parentId) data.parent = parentId;
            if (template.onComplete?.removeProps) {
                template.onComplete.removeProps.forEach(p => {
                    delete data[p]
                    delete data.locale[p];
                });
            }
            if (template.onComplete?.route && template.onComplete?.method) {
                try {
                    const posted = await request(template.onComplete?.route, template.onComplete?.method, tier ? tier : 'initial', data);
                    if (!template.onComplete.auth && template.img != 'team') postObUpdate(posted, template.entity == 'equip' ? 'zone' : template.entity ?? '');
                    // update team
                    if (template.onComplete.route == 'team') {
                        addTeamMember(posted);
                        clearInputs();
                        setTimeout(() => closeForm(false, posted), 1000)
                    }
                    clearInputs();
                    if (template.onComplete?.nav) nav(`/${template.onComplete.route}/${posted.id}`);
                    closeForm(template.onComplete?.auth == true);
                } catch (submitFormErr) {
                    const err = submitFormErr as any
                    console.error({ err });
                    setAppData({ ...appData, helper: { type: 'error', title: 'Onboarding Error', content: err.message } })
                }
            }
        }
    }

    const assessClose = (e: any) => {
        if (e.key == 'Escape') closeForm(false);
    }

    const handleKeyDown = (e: any) => {
        // TODO: fix erroneous submission here!
        if (e.key == 'Enter') {
            if (stepComplete) {
                currentStep == template.steps.length - 1
                    ? submitForm()
                    : updateCurrentStep(currentStep + 1);
            }
        }
        if (e.key == 'Escape') {
            closeForm(false)
        }
    }

    const initializeForgotPw = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Contact Breezi Support',
                content: "During the Pilot Program, please contact support@breezi.io if you have forgotten your password."
            }
        })
        // let forgotTemplate = forgotPwTemplate as unknown;
        // setShowForgotConf(true);
        // setTemplate(forgotTemplate as FormTemplate);
    }

    const forgotModal = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Contact Breezi Support',
                content: "During the Pilot Program, please contact support@breezi.io if you have forgotten your password."
            }
        });
    }

    useEffect(() => {
        if (template.steps[currentStep].inputs[0].textType == 'binary') setStepComplete(true);
    }, [currentStep]);

    useEffect(() => {
        clearInputs();
        if (!Object.keys(formData).length) {
            if (sessionData.organizations) {
                updateFormData({ country: sessionData.country });

            }
        }
    }, []);

    useEffect(() => {
        if (sessionData.organizations && formData.country) {
            const step = template.steps[currentStep];
            const firstInput = step.inputs[0];
            if (firstInput.inputType == 'selector' && firstInput.props) {
                updateFormData({ 'country': sessionData.country });
                setCurrentStep(currentStep + 1);
            }
        }
    }, [formData]);

    return (
        <main
            className={`form-main${template.entity == 'zone' ? ' zone-form' : ''}`}
            onKeyDown={e => handleKeyDown(e)}>
            <div className='panel popup-panel form-panel'>
                {
                    stepComplete &&
                    <Drawer
                        stepCount={template?.steps.length}
                        cur={currentStep}
                        setStep={updateCurrentStep}
                        submitFinal={submitForm} />
                }
                {
                    template?.steps.map((step, stepIndex) => (
                        <>
                            {
                                stepIndex == currentStep &&
                                <>
                                    <header className='panel-header form-header'>
                                        <div className='header-icon'>
                                            <img src={`${imgUrl}/${template.img}.png`} />
                                        </div>
                                        <div className='header-title'>
                                            <h2 style={{ fontWeight: 500, textDecoration: 'none', fontSize: '1.3em' }}>{template.title}</h2>
                                            <h4 style={{ fontSize: '1em', fontWeight: 400 }}>{step.title}</h4>
                                        </div>
                                        <div className='panel-close' onClick={() => closeForm(false)}>
                                            <img src={`${imgUrl}/closeToo.png`} />
                                        </div>
                                    </header>
                                    <Steps currentStep={currentStep} template={template.steps} setCurrentStep={setCurrentStep} />
                                    <form
                                        className='panel-body form-body'
                                        style={{
                                            flexDirection: step.inputs.length > 4 ? 'row' : 'column',
                                            justifyContent: step.inputs.length > 4 ? 'flex-start' : 'space-around'
                                        }}>
                                        {
                                            step.inputs.map((input, inputIndex) => (
                                                <>
                                                    {
                                                        input.inputType && input.inputType == 'prefill' && !input.filledBy &&
                                                        <Prefill input={input} prop={input.prop} decrement={decrementStep} />
                                                    }
                                                    {
                                                        input.inputType && input.inputType == 'localeValidator' &&
                                                        <LocaleValidator initProceed={initSubmitDrawer} />
                                                    }
                                                    {
                                                        input.inputType && input.inputType == 'ltdSelector' &&
                                                        <LtdSelector list={input.options} updateStep={incrementStep} label={input.label ?? ''} prop={input.prop ?? 'type'} />
                                                    }
                                                    {
                                                        input.inputType && input.inputType == 'text' &&
                                                        <TextInput input={input} invalidProps={invalidProps} img={template.img ?? ''} signup={['admin', 'profile'].includes(template.img)} />
                                                    }
                                                    {
                                                        input.inputType && input.inputType == 'segmentedNum' &&
                                                        <SegmentedNumber initSubmitDrawer={initSubmitDrawer} resendCode={() => authRequest('resendCode')} />
                                                    }
                                                    {
                                                        input.inputType && input.inputType == 'selector' &&
                                                        <Selector entity={template.entity ?? null} collectionProps={['isoCode2', 'country', 'countryCodes']} initList={'countryCode'} updateStep={incrementStep} />
                                                    }
                                                    {
                                                        input.inputType && input.inputType == 'binary' &&
                                                        <BinaryInput input={input} def={false} />
                                                    }
                                                    { 
                                                        input.inputType && input.inputType == 'textarea' &&
                                                        <Notes input={input} />
                                                    }
                                                </>
                                            ))
                                        }
                                        {
                                            template.img === 'login' &&
                                            <small
                                                style={{
                                                    color: 'gray',
                                                    cursor: 'pointer'
                                                    }}
                                                // onClick={() => initForgot ? initForgot() : {}}>
                                                onClick={() => forgotModal()}>
                                                Forgot Password    
                                            </small>
                                        }
                                        {/* {
                                            showForgotConf &&
                                            <ForgotPassword setShowForgotConf={setShowForgotConf} />
                                        } */}
                                    </form>
                                </>
                            }
                        </>
                    ))
                }
            </div>
        </main>
    )
}

export default FormComponent; 