import { imgUrl } from "../../templates/consts";

interface Props {
    orientation:string;
}

const OrientationOverlay:React.FC<Props> = ({ orientation }) => {
    return <div style={{
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--breezi-blue)'
    }}>
        <img style={{width: '120px'}} src={`${imgUrl}/rotate.png`} />
        <h2 style={{margin: 0, marginTop: '20px', textDecoration: 'underline', maxWidth: '390px'}}>Screen Orientation Misaligned</h2>
        <p style={{margin: 0, maxWidth: '390px'}}>Please rotate or resize screen to {orientation} Orientation</p>
    </div>
}

export default OrientationOverlay;