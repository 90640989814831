import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl } from "../../templates/consts";
import '../../stylesheets/Zone.css';

interface Props {
    bankState: string;
    hier: any;
}

const EquipmentHeader: React.FC<Props> = ({ bankState, hier }) => {
    const [equip] = useEquipment();
    const assessOnOff = () => {
        return !equip.meas || equip.meas.dpr < 20 ? 'powerOff' : 'power';
    }
    return (
        <header
            className='panel-header zone-header'
            style={{
                position: 'relative',
                minHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : '',
                maxHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : ''
            }}>
            <div
                className='header-icon'
                style={{
                    background: 'var(--equip)',
                    minHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : '',
                    maxHeight: window.innerHeight >= 400 && window.innerHeight <= 880 ? '40px' : ''
                }}>
                <img
                    style={{
                        height: window.innerHeight >= 400 && window.innerHeight <= 880 ? '30px' : ''
                    }}
                    src={`${imgUrl}/${bankState != '' ? 'hvacConfig' : 'hvac'}.png`} />
            </div>
            {/* <div className='equip-title'>
                <h3>
                    {equip.name}
                    <span style={{ color: 'var(--breezi-blue)' }}>|</span>
                    |<span style={{ color: 'var(--breezi-blue)' }}> {equip.equipType.toUpperCase()}</span>
                </h3>
            </div> */}
            {
                equip && equip.name && hier && window.innerWidth > 767 &&
                <div className='zone-hier'>
                    {
                        hier.map((hString: string, hIndex: number) => (
                            <h4 style={{marginLeft: '0px'}}>
                                <span style={{ color: 'var(--breezi-blue)', fontWeight: 'bold' }}></span>
                                {' ' + hString} <span style={{ color: 'var(--breezi-blue)', fontWeight: 'bold', fontSize: '1.25em' }}>{hIndex !== 2 && '|'}</span></h4>
                        ))
                    }
                </div>
            }
        </header>
    )
}

export default EquipmentHeader;