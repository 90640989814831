import { useEffect, useState } from "react";
import { Input } from "../../tsDeclarations/form"
import { useForm } from "../../hooks/useFormContext";

interface Props {
    input:Input;
}

const Notes:React.FC<Props> = ({ input }) => {

    const [ formData, updateFormData ] = useForm();

    const [ content, setContent ] = useState('');

    const trackContent = (newContent:string) => {
        setContent(newContent.length <= input.maxChar ? newContent : newContent.substring(0, 249));
    }

    useEffect(() => {
        updateFormData({[input.prop]: content});
    }, [content]);

    return (
        <div className='notes-container'>
            <h3>{ input.label }</h3>
            <textarea value={ content } onChange={ e => trackContent(e.target.value) } />
        </div>
    )
}

export default Notes;