import moment from "moment";
import { imgUrl } from "../../templates/consts";

import maintImg from '../../assets/images/maint.png';
import hvacImg from '../../assets/images/hvac.png';
import coilImg from '../../assets/images/coilFlow.png';
import filterImg from '../../assets/images/filterOfficial.png';

interface Props {
    sessionZone: any;
    zEvents:any[];
}

const PdfMaint: React.FC<Props> = ({ sessionZone, zEvents }) => {

    const assessMaintImg = (evt: any) => {
        let img = '';
        const sec = parseInt(evt.sectionIndex);
        const sessionSec = sessionZone.sections[sec];
        const elem = sessionSec.elems[0];
        switch (elem.type) {
            case 'Fan': img = hvacImg;
                break;
            case 'Coil': img = coilImg;
                break;
            case 'Filter': img = filterImg;
                break;
            default: img = maintImg;
        }
        return img;
    }

    return (
        <div className={`pdf-zone-container pdf-maint${sessionZone.equip ? ' pdf-equip' : '' }`}>
            <header>
                <h2>Maintenance Events</h2>
            </header>
            {
                zEvents && zEvents.length > 1
                ? zEvents.map((ze: any, zeIndex: number) => (
                    <div className='pdf-maint-evt'>
                        <div className='pdf-maint-index'>
                            <div className='pdf-maint-index-container'>
                                <h1>{zeIndex + 1}</h1>
                            </div>
                        </div>
                        <div className='pdf-maint-header'>
                            <img src={assessMaintImg(ze)} />
                        </div>
                        <div className='pdf-maint-body'>
                            <h3>{ze.title} : <br /> {moment(parseInt(ze.tms) * 1000).format('MMM-DD, YYYY')}</h3>
                            <p>{ze.body}</p>
                        </div>
                    </div>
                ))
                : <div className='pdf-no-maint'>
                    <div className='pdf-no-maint-icon'>
                        <img src={maintImg} />
                    </div>
                    <h2>No Maintenance this Month</h2>
                </div>
            }
        </div>
    )

}

export default PdfMaint;