import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSessionDev";
import MonthlyEquip from "./MonthlyEquip";
import MonthlyEnviro from "./MonthlyEnviro";
import { imgUrl } from "../../templates/consts";
import moment from "moment";
import MonthlyTrend from "./MonthlyTrend";
import PdfTrend from "./PdfTrend";
import PdfEquip from "./PdfEquip";
import PdfEnviro from "./PdfEnviro";
import PdfMaint from "./PdfMaint";
import PdfNameplate from "./PdfNameplate";

import hvacImg from '../../assets/images/hvacUnit.png';
import envZoneImg from '../../assets/images/envZone.png';

interface Props {
    zone: any;
    report: any;
    dt: number | null;
    bldg: any;
    zoneDps: any;
    displayDate: number;
    eIndex?: number;
    chartsLoaded?:boolean[];
    setChartsLoaded?:(chartLoad:boolean[]) => void;
}

const PdfZone: React.FC<Props> = ({ zone, report, dt, bldg, zoneDps, displayDate, eIndex, chartsLoaded, setChartsLoaded }) => {

    const [sessionData, , , getSessionEntity] = useSession();

    const [zEvents, setZEvents] = useState<any[]>([]);
    const [envEvents, setEnvEvents] = useState<any[]>([]);
    const [sessionZone, setSessionZone] = useState<any>(null);
    const [sessionZones, setSessionZones] = useState<any>(null);

    const updateZoneEvents = () => {
        const zoneEvents = report.events.filter((evt: any) => evt.zid === zone.zid).reverse();
        if (zoneEvents.length) setZEvents(zoneEvents);
    }

    useEffect(() => {
        (async () => {
            if (!sessionZone) {
                if (!Array.isArray(zone)) {
                    const sZone = await getSessionEntity('zone', zone.zid);
                    setSessionZone(sZone);
                    if (report.events) updateZoneEvents();
                } else {
                    const sZones = await Promise.all(zone.map(async (sz: any) => {
                        return await getSessionEntity('zone', sz.zid);
                    }));
                    setSessionZones(sZones);
                }
            }
        })();
    }, []);

    return (<>
            {
                sessionZone &&
                <div className={`pdf-zone pdf-${sessionZone.equip ? 'equip' : 'enviro'}-zone`} style={{marginTop: eIndex === 0 ? 0 : ''}}>
                    <header className='report-header'>
                            <div className='report-title'>
                                <h2>
                                    {bldg.name} |
                                    <span style={{ color: 'var(--breezi-recessive)', fontSize: '.8em' }}> {bldg.street} | {bldg.locale.city}, {bldg.locale.state} {bldg.zipCode}</span>
                            </h2>
                            <div className='pdf-title-icon'>
                                <div className='report-header-icon' style={{borderRadius: 0}}>
                                    <img style={{height: sessionZone.equip ? '75%' : '', width: sessionZone.equip ? '75%' : ''}} src={sessionZone.equip ? hvacImg : envZoneImg} />
                                    {
                                        !sessionZone.equip && <small>UNIT</small>
                                    }
                                </div>
                                <h4 style={{color: 'var(--breezi-recessive)'}}>
                                    {sessionZone.name} ||
                                    <span style={{ color: 'var(--breezi-blue)' }}> {sessionZone.equipType.toUpperCase()} </span>
                                    ||
                                    <span style={{ color: 'var(--equip)', margin: '5px' }}>
                                        {sessionZone.datapoints.length} Datapoint{sessionZone.datapoints.length === 1 ? '' : 's'}
                                    </span>
                                </h4>
                            </div>
                            </div>
                        <div className='report-month pdf-month'>
                            <h2>{displayDate}</h2>
                            <small>Monthly Report</small>
                        </div>
                    </header>
                    {
                        sessionZone.equip
                            ? <PdfEquip equip={zone} sessionEquip={sessionZone} />
                            : <PdfEnviro zone={zone} sessionZone={sessionZone} />
                    }
                    {
                        sessionZone.equip && <PdfNameplate sessionZone={sessionZone} />
                    }
                    <div className='pdf-zone-container pdf-trend' style={{height: '34%'}}>
                        <header style={{position: !sessionZone.equip ? 'absolute' : 'relative', zIndex: 10}}>
                            <h2>Monthly Trend</h2>
                        </header>
                        <MonthlyTrend zone={sessionZone} trendTms={dt} report={report} pdf={true} equip={sessionZone.equip ?? false} chartsLoaded={chartsLoaded} setChartsLoaded={setChartsLoaded} />
                    </div>
                    {
                        sessionZone.equip &&
                        <>
                            <PdfMaint sessionZone={sessionZone} zEvents={zEvents} /> 
                        </>
                    }
                    <div className='pdf-zone-container pdf-maint pdf-notes'>
                        <header>
                            <h2>Notes / Recommendations</h2>
                        </header>
                    </div>
                </div>
            }
            {
                sessionZones &&
                <div className='pdf-zone pdf-split-zone'>
                    {
                        zone.map((z: any, zIndex:number) => (
                            <>
                                {
                                    zIndex === 0 &&
                                    <header className='report-header'>
                                        <div className='report-title'>
                                            <h2>
                                                {bldg.name} |
                                                <span style={{ color: 'var(--breezi-recessive)', fontSize: '.8em' }}> {bldg.street} | {bldg.locale.city}, {bldg.locale.state} {bldg.zipCode}</span>
                                            </h2>
                                            <div className='pdf-title-icon'>
                                                <div className='report-header-icon' style={{borderRadius: 0}}>
                                                    <img src={sessionZones[zIndex].equip ? hvacImg : envZoneImg} />
                                                    <small>{sessionZones[zIndex].equip ? 'UNIT' : 'ZONE'}</small>
                                                </div>
                                                <h4 style={{color: 'var(--breezi-recessive)'}}>
                                                        {sessionZones[zIndex].name} ||
                                                    <span style={{ color: 'var(--equip)', margin: '5px' }}>
                                                        {sessionZones[zIndex].datapoints.length} Datapoint{sessionZones[zIndex].datapoints.length === 1 ? '' : 's'}
                                                    </span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className='report-month pdf-month'>
                                            <h2>{displayDate}</h2>
                                            <small>Monthly Report</small>
                                        </div>
                                    </header>
                                }
                                {
                                    zIndex !== 0 &&
                                    <div className='pdf-title-icon-free'>
                                        <div className='pdf-title-icon'>
                                            <div className='report-header-icon' style={{borderRadius: 0}}>
                                                <img src={sessionZones[zIndex].equip ? hvacImg : envZoneImg} />
                                                <small>{sessionZones[zIndex].equip ? 'UNIT' : 'ZONE'}</small>
                                            </div>
                                            <h4 style={{color: 'var(--breezi-recessive)'}}>
                                                    {sessionZones[zIndex].name} ||
                                                <span style={{ color: 'var(--equip)', margin: '5px' }}>
                                                    {sessionZones[zIndex].datapoints.length} Datapoint{sessionZones[zIndex].datapoints.length === 1 ? '' : 's'}
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                }
                                <PdfEnviro zone={z} sessionZone={sessionZone} />
                                {
                                    zIndex === 0 && <div className='pdf-enviro-sep'></div>
                                }
                            </>
                        ))
                    }
                </div> 
            }
        </>)
}

export default PdfZone; 