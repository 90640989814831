import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MonthlyTrend, { getCurrentMonthStart } from "./MonthlyTrend";
import { useSession } from "../../hooks/useSessionDev";
import { imgUrl } from "../../templates/consts";

import { useRest } from "../../hooks/useRest";
import { assignAlerts, avgAlert } from "../../util/alertAvg";

import '../../stylesheets/Report.css';
import '../../stylesheets/Floor.css';
import MonthlyEquip from "./MonthlyEquip";
import MonthlyEnviro from "./MonthlyEnviro";
import MonthlyMaint from "./MonthlyMaint";
import { AppContext } from "../../context/AppContext";
import ExportButton from "./ExportButton";
import moment from "moment";
import MonthDropdown from "./MonthDropdown";
import MonthlyMaintDetail from "./MonthlyMaintDetail";
import MonthlyPdf from "./MonthlyPdf";

const returnTitleDate = (tms: number) => {
    return moment(tms).format('MMMM, YYYY');
}

const headerTitles = ['Equipment Conditions', 'Environmental Conditions'];

export default () => {

    const equipRef = useRef(null);
    const envRef = useRef(null);
    const { id } = useParams();
    const nav = useNavigate();

    const { appData, setAppData } = useContext(AppContext);

    const [req] = useRest();
    const [sessionData, , , getSessionEntity, setSessionData] = useSession();

    const [bldg, setBldg] = useState<any>(null);
    const [trendZone, setTrendZone] = useState<any>(null);
    const [reportData, setReportData] = useState<any>(null);
    const [equips, setEquips] = useState<any[]>([]);
    const [envZones, setEnvZones] = useState<any[]>([]);
    const [displayMonthly, setDisplayMonthly] = useState<boolean>(false);
    const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
    const [zoneTog, setZoneTog] = useState<'equip' | 'envZone'>('equip');
    const [activeZone, setActiveZone] = useState<any>(null);
    const [displayDate, setDisplayDate] = useState<any>(returnTitleDate(getCurrentMonthStart() * 1000));
    const [utcDate, setUtcDate] = useState<number>(getCurrentMonthStart())
    const [showMonths, setShowMonths] = useState<boolean>(false);
    const [formatPdf, setFormatPdf] = useState<boolean>(false);
    const [exportSlide, setExportSlide] = useState<boolean>(false);
    const [hoverHeader, setHoverHeader] = useState<number>(-1);

    const getBldgZones = (b: any) => {
        return b.floors.reduce((zones: any[], floor: any) => {
            zones = zones.concat(floor.zones);
            return zones;
        }, []);
    }

    const zoneDps = (zT: any) => {
        let equip = getBldgZones(bldg).filter((z: any) => z.equip);
        let enviro = getBldgZones(bldg).filter((z:any) => !equip.includes(z));
        let dps = 0;
        let zoneMatch = zT === 'equip' ? equip : enviro;
        if (zoneMatch.every((e: any) => e.datapoints && e.datapoints.length)) {
            dps = zoneMatch.reduce((dpCount: number, z: any) => {
                return dpCount + z.datapoints.length;
            }, 0);
        }
        const zones = zoneMatch.length;
        return { zones, dps }
    }

    const updateTrendZone = async (zid:string|number) => {
        if (id && bldg?.id) {
            const zones = getBldgZones(bldg);
            if (typeof zid === 'number') {
                setTrendZone(zones[zid]);
            } else {
                const tZone = zones.find((z: any) => z.id === zid);
                setTrendZone(tZone);
            }
        }
    }

    const updateMonth = async (tms: number) => {
        if (bldg) {
            setActiveZone(null);
            setReportData(null);
            setDisplayMonthly(false);
            setUtcDate(tms);
            setDisplayDate(returnTitleDate(tms * 1000));
            setTimeout(async () => {
                const report = await req(`/monthlyReport/${id}?tms=${tms}`, 'get', '');
                setReportData(report);
                setActiveZone(report.data.zones[0]);
            }, 500);
        }
    }

    const exitPdf = () => {
        setFormatPdf(false);
    }

    const updateActiveZone = async (zone: any) => {
        setActiveZone(zone);
        let sessionZone:any;
        try {
            sessionZone = await getSessionEntity('zone', zone.zid);
        } catch (sessionZoneErr) {
            console.error({ sessionZoneErr });
            sessionZone = zone;
        }
        let ref = equipRef.current as any;
        if (!sessionZone.equip) ref = envRef.current as any;
        const otro = ref === equipRef.current ? envRef.current as any : equipRef as any;
        const parent = !sessionZone.equip ? envZones : equips;
        const zIndex = parent.indexOf(zone);
        const scrollFocusElem = ref.children[zIndex] as HTMLElement;
        let toLeft = scrollFocusElem.offsetLeft - ref.offsetLeft;
        if (zIndex === 0) toLeft = 0;
        ref.scrollTo({
            left: toLeft,
            behavior: 'smooth'
        });
        otro.scrollTo({
            left: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        (async () => {
            if (id) {
                let b: any = {};
                try {
                    b = await getSessionEntity('building', id);
                } catch (sessionBldgErr) {
                    console.error({ sessionBldgErr });
                }
                setSessionData('building', b);
                setBldg(b);
                const report = await req(`/monthlyReport/${id}`, 'get', '');
                setActiveZone(report.data.zones[0])
                setReportData(report);
            }
        })();
    }, []);

    useEffect(() => {
        updateTrendZone(0);
    }, [bldg]);

    useEffect(() => {
        (async () => {
            setAppData({ ...appData, loading: {visible: true} })
            if (reportData && reportData.data) {
                let reportEquips = reportData.data.zones.filter((z: any) => z.meas.dapAvg);
                let reportEnvZones = reportData.data.zones.filter((z: any) => !reportEquips.includes(z));
                setEquips(reportEquips);
                setEnvZones(reportEnvZones);
                setDisplayMonthly(true);
                setTimeout(() =>  setAppData({ ...appData, loading: {visible: false} }), 1000)
            }
        })();
    }, [reportData]);
    
    useEffect(() => {
        if (screenHeight !== window.innerHeight) setScreenHeight(window.innerHeight);
    }, [window.innerHeight]);

    useEffect(() => {
        (async () => {
            if ((equips || envZones) && activeZone) {
                setTrendZone(null);
                setTimeout(async () => {
                    updateTrendZone(activeZone.zid);
                }, 500);
            }
        })();
    }, [activeZone]);

    useEffect(() => {
        if (formatPdf) setExportSlide(false);
    }, [formatPdf]);

    return displayMonthly && 
        !formatPdf ? (
            <main
                className='report-main'
                // style={{
                //     alignItems: formatPdf ? 'flex-start' : '',
                //     overflowY: formatPdf ? 'scroll' : 'hidden'
                // }}
            >
            <div
                    // className={`report-content${formatPdf ? ' report-content-pdf' : ''}`}
                className='report-content'
                id='monthly-report'
                    style={{ backgroundColor: 'var(--bg)' }}>
                    {
                        !formatPdf &&
                        <header className='report-header main-report-header'>
                            <div className='report-header-icon'>
                                <img src={`${imgUrl}/calendar.png`} />
                            </div>
                            <div className='report-title'>
                                <h2 style={{'fontWeight': 'lighter'}}>
                                    {bldg.name} |
                                    <span style={{ color: 'lightblue', fontSize: '.8em' }}> {bldg.street} | {bldg.locale.city}, {bldg.locale.state} {bldg.zipCode}</span>
                                </h2>
                                <h4>
                                    {bldg.floors.length} / {parseInt(bldg.above) + parseInt(bldg.below)} Floor{parseInt(bldg.above) + parseInt(bldg.below) === 1 ? '' : 's'} Monitored ||
                                    <span style={{ color: 'var(--equip)', margin: '10px' }}>
                                        {zoneDps('equip').zones} HVAC Unit{zoneDps('equip').zones === 1 ? '' : 's'}
                                        <span style={{ fontWeight: 'lighter' }}> | {zoneDps('equip').dps} AirPulse{zoneDps('equip').dps === 1 ? '' : 's'}</span>
                                    </span>
                                    ||
                                    <span style={{ color: 'var(--breezi-blue)', margin: '10px' }}>
                                        {zoneDps('enviro').zones} Enviro Zone{ zoneDps('enviro').zones === 1 ? '' : 's' }
                                        <span style={{ fontWeight: 'lighter' }}> | {zoneDps('enviro').dps} AirPulse{zoneDps('enviro').dps === 1 ? '' : 's'}</span>
                                    </span>
                                </h4>
                                {/* <ExportButton /> */}
                            </div>
                            <div className='report-month-outer' style={{opacity: !exportSlide ? 1 : 0}}>
                                <h2 className='report-header-month' onClick={() => setShowMonths(true)}>{displayDate}</h2>
                                {
                                    showMonths &&
                                    <MonthDropdown
                                        displayDate={displayDate} 
                                        setDisplayDate={setDisplayDate}
                                        showMonths={showMonths}
                                        setShowMonths={setShowMonths}
                                        updateMonth={updateMonth} />
                                }
                            </div>                                
                            <div
                                className='export-btn'
                                onMouseOver={() => setExportSlide(true)}
                                onMouseLeave={() => setExportSlide(false)}
                                onClick={() => setFormatPdf(true)}
                                >
                                <img src={`${imgUrl}/export.png`} />
                                {
                                    exportSlide && 
                                    <div className='export-slide'>
                                        <h3>Export as PDF</h3>
                                    </div>
                                }
                            </div>
                        </header>
                    }
                <div className='report-row'>
                    <div className='report-panel report-top'>
                        <div className='floor-zones floor-equips' style={{ height: '100%', width: '50%' }}>
                                <div className='zone-row'>
                                    <div className='floor-zones-header' onMouseOver={() => setHoverHeader(0)} onMouseLeave={() => setHoverHeader(-1)}>
                                        {
                                            hoverHeader !== 0 && <img src={`${imgUrl}/equip.png`} />    
                                        }
                                        {
                                            hoverHeader === 0 && <h2>{headerTitles[0]}</h2>
                                        }
                                    </div>
                                    <div className='inner-report-row' ref={equipRef}>
                                        {
                                            equips.map((e: any, eIndex: number) => (
                                                <>
                                                    {
                                                        activeZone?.zid === e.zid &&
                                                        <div className='monthly-active'>
                                                            <MonthlyEquip equip={ e } screenHeight={screenHeight} activeZone={activeZone} setActiveZone={updateActiveZone} month={displayDate} />
                                                        </div>
                                                    }
                                                    {
                                                        activeZone.zid !== e.zid &&
                                                        <div className='monthly-inactive'>
                                                            <MonthlyEquip equip={ e } screenHeight={screenHeight} activeZone={activeZone} setActiveZone={updateActiveZone} month={displayDate} />
                                                        </div>
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='floor-zones' style={{marginLeft: '10px', height: '100%', width: '50%'}}>
                                <div className='zone-row' style={
                                    screenHeight < 400 || screenHeight > 880
                                        ? { marginRight: '2%', border: '3px solid var(--breezi-blue)'}
                                        : { marginRight: '2%', border: '1px solid var(--breezi-blue)'}
                                    }>
                                        <div className='floor-zones-header' style={{background: 'var(--breezi-blue)'}} onMouseOver={() => setHoverHeader(1)} onMouseLeave={() => setHoverHeader(-1)}>
                                            {
                                                hoverHeader !== 1 && <img src={`${imgUrl}/envZone.png`} />    
                                            }
                                            {
                                                hoverHeader === 1 && <h2>{headerTitles[1]}</h2>
                                            }
                                        </div>
                                        <div className='inner-report-row' ref={envRef}>
                                            {
                                                    envZones.map((ez: any, eIndex: number) => (
                                                        <>
                                                            {
                                                                activeZone?.zid === ez.zid &&
                                                                <div className='monthly-active'>
                                                                    <MonthlyEnviro zone={ez} screenHeight={screenHeight} activeZone={activeZone} setActiveZone={updateActiveZone} />
                                                                </div>
                                                            }
                                                            {
                                                                activeZone.zid !== ez.zid &&
                                                                <div className='monthly-inactive'>
                                                                    <MonthlyEnviro zone={ez} screenHeight={screenHeight} activeZone={activeZone} setActiveZone={updateActiveZone} />
                                                                </div>
                                                            }
                                                        </>
                                                    ))
                                            }   
                                        </div>
                                </div>
                            </div>
                        </div>
                    {
                        formatPdf && <MonthlyMaintDetail events={reportData.events ?? []} />
                    }
                </div>
                <div className='report-row'>
                    {
                        trendZone && reportData &&
                        <>
                            <div className='report-panel report-trends'>
                                <MonthlyTrend zone={trendZone} report={reportData} trendTms={utcDate} pdf={false} />
                            </div>
                        </>
                    }
                    {
                        (!trendZone || !reportData) &&
                        <div className='report-panel report-trends'>
                                <div className='dp-panel monthly-trend-panel'></div>
                        </div>
                    }
                    {
                        !formatPdf &&
                        <div className='report-panel report-maint'>
                            <MonthlyMaint events={reportData.events ?? []} screenHeight={screenHeight} />
                        </div>
                    }
                </div>
            </div>
        </main>
    )
    : bldg && reportData && zoneDps && formatPdf &&
    (<MonthlyPdf report={reportData ?? null} bldg={bldg ?? null} zoneDps={zoneDps ?? null} dt={utcDate} displayDate={displayDate} closePdf={() => exitPdf()} />)

}