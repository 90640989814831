import { useEffect, useState } from "react";
import Setpoints from "../setpoints/Setpoints";

interface Props {
    datapoint:any;
    flipBack:(index:number) => void;
    lastIndex:number;
    currentVals?:any;
    initResetAlerts: (newSp: any) => Promise<void>;
    config:string;
}

const DatapointSetpoints: React.FC<Props> = ({ datapoint, flipBack, lastIndex, currentVals, initResetAlerts, config }) => {

    const [ grpWatch, setGrpWatch ] = useState<boolean>(false);
    
    useEffect(() => {
        setGrpWatch(!grpWatch);
    }, []);

    return (
        <div className='card-content'>
            <Setpoints 
                entType='datapoint'
                entId={datapoint.id}
                initCancel={() => flipBack(1)}
                currentVals={datapoint.meas ?? '-'}
                initResetAlerts={initResetAlerts}
                defaultSp={datapoint.setpoints ?? false}
                name={datapoint.apsn}
                watchVal={grpWatch}
                config={config} />
        </div>
    )

}

export default DatapointSetpoints;