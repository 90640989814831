import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../context/AppContext';

import '../../stylesheets/Loading.css';



const createLetteringSet = (strArr: string[]) => {
    return strArr.reduce((sets: [string, string][], ltr: string, lIndex: number) => {
        if (lIndex <= 4) sets.push([ltr, strArr[strArr.length - 1]]);
        strArr.pop();
        return sets;
    }, []);
}

export default () => {

    const { appData } = useContext(AppContext);
    const [msg, setMsg] = useState<string>('');
    const lettering: string[] = 'breezi-pro'.split('');
    const alt: string[] = 'loading...'.split('');
    const letteringSets: any[] = createLetteringSet(lettering);
    const altSets: any[] = createLetteringSet(alt);

    const sets: string[] = []

    return (
        <main className='landing-main loading-main'>
            <div className='overlay dark-overlay'>
                <section className='loading'>
                    {
                        letteringSets.map((l, lx: number) => (
                            <>
                                <div
                                    className='letter-node'
                                    style={{
                                        width: `${200 - (lx * 48)}px`,
                                        height: `${200 - (lx * 48)}px`,
                                        animation: `orbit-${lx % 2 == 0 ? 'left' : 'right'} 5.5s linear infinite`,
                                        animationDelay: `${lx * .25}s`
                                    }}>
                                    <div
                                        className='loading-letter'
                                        style={{
                                            left: '-17.5px',
                                        }}>
                                        <h5 className='loading-let'>{l[0]}</h5>
                                        <h5 className='alt-let '>{altSets[lx][1]}</h5>
                                    </div>
                                    <div className='loading-letter' style={{ right: '-17.5px' }}>
                                        <h5 className='loading-let'>{l[1]}</h5>
                                        <h5 className='alt-let'>{altSets[lx][0]}</h5>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </section>
            </div>
        </main>
    )

} 