import { useContext, useState } from "react";
import { useEquipment } from "../../hooks/useEquipmentContext";
import { imgUrl } from "../../templates/consts";
import { useRest } from "../../hooks/useRest";
import { AppContext } from "../../context/AppContext";
import { useSession } from "../../hooks/useSessionDev";

interface Props {
    sectionIndex: number;
    elemIndex: number|undefined;
    closeForm: () => void;
}

const MaintenanceEventForm: React.FC<Props> = ({ sectionIndex, elemIndex, closeForm }) => {

    const { appData, setAppData } = useContext(AppContext);

    const [equip] = useEquipment();
    const [req] = useRest();
    const [sessionData] = useSession();
    
    const [content, setContent] = useState<any>({});
    const [flashLen, setFlashLen] = useState<boolean>(false);

    const updateContent = (val: string) => {
        if (content.content && val.length >= 350) {
            setFlashLen(true);
            setTimeout(() => {
                setFlashLen(false);
            }, 2000);
        } else {
            setContent({ ...content, content: val });
        }
    }

    const submitMaintenanceEvent = async () => {
        try {
            const body = { sectionIndex, elemIndex, title: content.title, body: content.content, assigner: sessionData.email };
            const res = await req('maintenanceEvent/' + equip.id, 'post', '', body);
            closeForm();
            setAppData({
                ...appData,
                helper: {
                    type: 'info',
                    title: 'Successfully Added Maintenance Event',
                    content: `A maintenance event with the title ${content.title} has been added to the database for this equipment. It will be accessible through the equipment's maintenance record, and in this building's monthly report.`
                }
            })
        } catch (submitMaintenanceEventErr) {
            console.error({ submitMaintenanceEventErr });
            const err = submitMaintenanceEventErr as any;
            setAppData({
                ...appData,
                helper: {
                    type: 'error',
                    title: 'Unable to Add Maintenance Event',
                    content: err.message
                }
            })
        }
    }
    
    return (
        <>
            <header className="expand-header">
                <img src={`${imgUrl}/maintenance.png`} />
                <div className='expand-title'>
                    <h5>Other Maintenance</h5>
                </div>
            </header>
            <label className='expand-input'>
                <input value={content.title} onChange={e => setContent({ ...content, title: e.target.value })} />
                Event Title
            </label>
            <label className='expand-textarea'>
                <textarea
                    value={content.content}
                    onChange={e => updateContent(e.target.value)}
                    style={{animation: flashLen ? 'len-flash-box 1s forwards' : ''}}/>
                <h5 style={{margin: 0, color: 'gray'}}>Event Details (<span style={{animation: flashLen ? 'len-flash-text 1s forwards' : ''}}>max 350 characters</span>)</h5>
            </label>
            <div className='btns expand-btns' style={{marginTop: '10px', border: 'none'}}>
                <a className='btn cancel-btn' onClick={() => closeForm()}>Cancel</a>
                {
                    ['title', 'content'].every((prop: string) => content[prop] && content[prop].length) &&
                    <a className='btn prim-btn' onClick={ () => submitMaintenanceEvent() }>Submit</a>
                }
            </div>
        </>
    )

}

export default MaintenanceEventForm;