import { useContext, useEffect, useState } from "react";
import { Tooltip as DefaultTooltip } from 'react-tooltip';

// import { useSession } from "../../hooks/useSessionContext"
import { useSession } from "../../hooks/useSessionDev"
import { findInHierarchy, imgUrl } from "../../templates/consts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

export default () => {

    const nav = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData, setInitialSession, , getSessionEntity, , getLocalBundle] = useSession();

    const [activeNode, setActiveNode] = useState<number>(0);
    const [hoverNode, setHoverNode] = useState<number>(-1);
    const [path, setPath] = useState<string>('');

    const navTemplates = [
        {
            title: 'Organization',
            img: 'organization',
            color: '#013c4b',
            ent: 'organization'
        },
        {
            title: 'Building',
            img: 'bldgType',
            color: 'var(--breezi-recessive)',
            ent: 'building'
        },
        {
            title: 'Floor',
            img: 'tilesWhite',
            color: '#047f9e',
            ent: 'floor'
        },
        {
            title: sessionData.zone && sessionData.zone.equip ? 'Equipment' : 'Enviro Zone',
            img: 'envZone',
            color: 'var(--breezi-blue)',
            ent: 'zone'
        },
        {
            title: 'Datapoint',
            img: 'datapoint',
            color: '#00b9e8',
            ent: 'datapoint'
        }
    ];

    useEffect(() => {
        const splitPath = location.pathname.split('/')[1]
        let searchPath = splitPath == 'equipment' ? 'zone' : splitPath;
        let currentTemp = navTemplates.find(nt => nt.ent == searchPath);
        if (location.pathname.includes('monthlyReport')) currentTemp = navTemplates[1];
        if (currentTemp) setActiveNode(navTemplates.indexOf(currentTemp));
        setPath(splitPath);
    }, [location]);

    const navigateToEntity = async (ent: string, index: number) => {
        const loc = getLocalBundle('organization', '', true);
        if (loc) {
            const sessionEnt = loc[ent];
            if (ent === 'zone') {
                const zid = sessionData.zone.id;
                const route = sessionData.zone.equip ? 'equipment' : 'zone';
                nav(`/${route}/${zid}`);
            } else {
                nav(sessionEnt ? `${ent}/${ent == 'organization' ? '' : sessionEnt.id}` : '/organization');
            }
        }
    }

    return (
        <nav className='util-nav'>
            {
                sessionData.organization && path.length > 0 &&
                navTemplates.map((navNode, nodeIndex) => (
                    <div
                        className={`nav-node${nodeIndex > activeNode ? ' inactive-nav-node' : ''}`}
                        style={{ background: navNode.color }}
                        onMouseOver={() => setHoverNode(nodeIndex)}
                        onMouseLeave={() => setHoverNode(-1)}
                        onClick={() => navigateToEntity(navNode.ent, nodeIndex)}>
                        {
                            nodeIndex <= activeNode &&
                            <>
                                <img src={`${imgUrl}/${path == 'equipment' && nodeIndex == 3 ? 'equip' : navNode.img}.png`} />
                                {
                                    hoverNode == nodeIndex &&
                                    <h5 style={{ background: navNode.color }}>{navNode.title}</h5>
                                }
                            </>
                        }
                    </div>
                ))
            }

        </nav>
    )

}