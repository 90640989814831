import { useContext, useEffect, useState } from "react";
import { capitalize } from "../../../templates/consts";

import '../../../stylesheets/Card.css';
import { useModifyEntity } from "../../../hooks/useModifyEntity";
import Setpoints from "../../setpoints/Setpoints";
import { AppContext } from "../../../context/AppContext";

interface Props {
    entType: string;
    ent: any;
    dest:string;
    modifyableProps: any[];
    floor?:boolean;
    refresh?:() => void;
    closeModify?:() => void;
}

const ModifyEntity: React.FC<Props> = ({ entType, ent, dest, modifyableProps, floor=false, refresh, closeModify }) => {

    const { appData, setAppData } = useContext(AppContext);

    const [deleteEntity] = useModifyEntity(entType, ent.id)


    const [view, setView] = useState<string>('');
    const [delVal, setDelVal] = useState<string>('')
    const [utilStyle, setUtilStyle] = useState<any>({});

    const handleDeleteEnt = async () => {
        await deleteEntity(dest);
        if (refresh) refresh();
    }

    const handleSetpointRefresh = async (newSp:any) => {
        // console.log('refreshing setpoints...');
    }
    
    const initBlocker = () => {
        setAppData({
            ...appData,
            helper: {
                type: 'blocker',
                title: 'Disabled',
                content: "This feature has been restricted for the duration of the Pilot Program"
            }
        });
    }
    
    useEffect(() => {
        type nos = number|string
        type styleType = {position:string, top:nos, left:nos, bottom:nos, right:nos, height?:nos, width?:nos, zIndex?:nos}
        const newStyle:styleType = {
            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
        }
        if (floor) {
            newStyle.zIndex = 2;
            newStyle.width = '100%';
            newStyle.height = '100%';
        }
        if (entType == 'building') {
            newStyle.top = '80px';
            newStyle.height = 'calc(100% - 7px)';
        }
        if (['zone', 'datapoint'].includes(entType)) newStyle.height = '100%';
        setUtilStyle(newStyle);
    }, []);
    
    return (
        <div className='del-overlay' style={utilStyle}>
            {
                !view.length &&
                <>
                    <a className='settings-card-btn inactive-btn' style={ {width: entType == 'datapoint' ? '90%' : '350px', cursor: 'not-allowed'} }>
                        Modify { capitalize(entType) }
                    </a>
                    {/* <a className='settings-card-btn sec-btn' style={{ width: '350px' }} onClick={ () => setView('setpoints') }>
                        Alarm Setpoints
                    </a> */}
                    {/* <a className='settings-card-btn crit-btn' onClick={() => setView('confDel')} style={{ width: entType == 'datapoint' ? '90%' : '350px' }}> */}
                    <a className='settings-card-btn crit-btn' onClick={ () => initBlocker() } style={ {width: entType == 'datapoint' ? '90%' : '350px'}}>
                        { floor ? 'Clear' : 'Delete' } { capitalize(entType) }
                    </a>
                    {
                        closeModify &&
                        <a className='settings-card-btn cancel-btn' onClick={ () => closeModify() } style={ {width: entType == 'datapoint' ? '90%' : '350px' } }>
                            Cancel
                        </a>
                    }
                </>
            }
            {
                view == 'confDel' &&
                <div className='del-overlay'>
                        <label className='text-input'>
                            <input value={ delVal } onChange={ e => setDelVal(e.target.value) } />
                        </label>
                        <small style={ {margin: '4%', marginTop: '1%'} }>
                            Confirm delete by typing 'delete' <br />
                            <span style={ {color: 'red', fontFamily: 'Roboto', fontWeight: 'bolder', textDecoration: 'underline'} }>WARNING:</span>
                            This can not be undone!
                        </small>
                        <a className='settings-card-btn tri-btn cancel-btn' onClick={ () => setView('') } style={ {width: entType == 'datapoint' ? '90%' : '350px'}}>
                            Cancel
                        </a>
                        {
                            delVal.toLowerCase() == 'delete' &&
                            <a 
                                className='settings-card-btn crit-btn'
                                onClick={ () => handleDeleteEnt() }
                                style={ {width: entType == 'datapoint' ? '90%' : '350px'}}>
                                Permanently Delete</a>
                        }
                </div>
            }
                {
                    view == 'setpoints' &&
                        <div className='modify-setpoints'>
                            <Setpoints 
                                entType={entType}
                                entId={ent.id}
                                defaultSp={ent.setpoints ?? false}
                                initCancel={() => setView('')}
                                currentVals={ent.meas ?? false}
                                    initResetAlerts={handleSetpointRefresh} />
                        </div>
                }
        </div>
        
    )
} 

export default ModifyEntity;