import { useEffect, useState } from "react";
import { useSession } from '../../hooks/useSessionDev';
import { imgUrl, profiles, setStatus } from "../../templates/consts";
import { useParams } from "react-router-dom";
import moment from "moment";
import MeasPanel from "./MeasPanel";
import { useRest } from "../../hooks/useRest";
import VertReading from "../reading/VertReading";

const panels = [
    {
        prop: 'pressure',
        color: 'tri',
        rdgs: ['days', 'maint', 'dpr'],
        tmp: 1
    },
    {
        prop: 'env',
        color: 'prim-transparent',
        rdgs: ['htp', 'hhm'],
        tmp: 2
    },
    {
        prop: 'iaq',
        color: 'gray',
        rdgs: ['iaq', 'co2', 'voc'],
        tmp: 3
    }
]

interface Props {
    datapoint: any;
    hier: any;
}


const TopRow: React.FC<Props> = ({ datapoint, hier }) => {

    const { id } = useParams();

    const [sessionData, , , getSessionEntity] = useSession();

    const [zoneData, setZoneData] = useState<any>(null);

    const prof = profiles.find(p => p.prop == datapoint?.config);

    const formatName = () => {
        const dpIndex = zoneData.datapoints.map((dp: any) => dp.id ?? dp).indexOf(datapoint.id) + 1;
        const name = datapoint.name;
        if (name != '+|dynamic|+') return name;
        return `${zoneData.name} ${prof?.title} ${dpIndex + 1}`;
    }

    const oneAlert = (prop: string) => {
        let alert = 'good'
        if (datapoint?.alerts && datapoint.alerts[prop]) {
            const rdg = datapoint.alerts[prop];
            if (rdg.bad) alert = 'bad'
            if (rdg.crit) alert = 'crit';
        }
        return alert;
    }

    const getBatImg = () => {
        if (!datapoint || !datapoint.alerts || !datapoint.alerts.bat) return 'bat';
        const bat = oneAlert('bat');
        switch (bat) {
            case 'bad': return 'averageBattery';
            case 'crit': return 'badBattery';
            default: return 'goodBattery';
        }
    }

    useEffect(() => {
        (async () => {
            if (!zoneData) setZoneData(sessionData.zone);
        })();
    }, [sessionData]);

    return (
        <>
            {
                datapoint && zoneData &&
                <div className='datapoint-row'>
                    <div className='dp-panel dp-quart dp-info-quart'>
                        <header className='dp-panel-header'>
                            <div className='dp-info' style={{ marginLeft: '10px' }}>
                                {
                                    hier && window.innerWidth > 767 &&
                                    <div className='zone-hier'>
                                        {
                                            hier.map((hString: string, hIndex: number) => (
                                                <h4>
                                                    <span style={{ color: 'var(--breezi-blue)', fontWeight: 'bold' }}></span>
                                                    {' ' + hString} <span style={{ color: 'var(--breezi-blue)', fontWeight: 'bold' }}>{hIndex !== 1 && '||'}</span></h4>
                                            ))
                                        }
                                    </div>
                                }
                                <h3>{formatName()}</h3>
                                {/* <h3>{ sessionData.zone.name }</h3> */}
                                {/* <small><span style={ {color: 'var(--breezi-blue)'} }>{ sessionData.building.name } |</span>| { sessionData.floor.name }</small> */}
                            </div>
                            <div className='dp-icon'>
                                <img src={`${imgUrl}/${prof?.img}.png`} />
                            </div>
                        </header>
                        <div className='dp-panel-body dp-info-body'>
                            {/* <div className='dp-info-node'>
                                <img src={ `${imgUrl}/${prof?.img}.png` } />
                            </div> */}
                            <div className='dp-info-node'>
                                <img src={`${imgUrl}/${getBatImg()}.png`} />
                                <h4 style={{ color: setStatus('bat', oneAlert('bat')) }}>{datapoint.meas.bat}%</h4>
                            </div>
                            {
                                window.innerHeight > 880 &&
                                <small>Last Updated: {moment((datapoint.updatedTms ?? datapoint.assignedTms) * 1000).format('MMM DD @ h:mma')}</small>
                            }
                        </div>
                    </div>
                    {/* {
                        prof &&
                        panels.map((panel, panelIndex) => (
                            <MeasPanel rdgs={ panel.rdgs } meas={ datapoint.meas } color={ panel.color } tmp={ panel.tmp } />
                        ))
                    } */}
                    <div className='dp-panel dp-env-panel'>
                        <header className='dp-util-header' style={{ background: 'var(--tri)', position: 'absolute', left: 0 }}>
                            <h5>Environmental</h5>
                        </header>
                        {
                            ['htp', 'hhm', 'iaq', 'co2', 'voc'].map((prop: string) => (
                                <VertReading
                                    rdg={prop}
                                    alert={datapoint.alerts ? datapoint.alerts[prop] : false}
                                    val={datapoint.meas ? datapoint.meas[prop] : false} />
                            ))
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default TopRow; 