import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { useContext, useEffect, useState } from 'react';
import cToF from 'celsius-to-fahrenheit';

import { useTrend } from '../../hooks/useTrend';
import TrendChart, { dummyTrend } from '../chart/Trend';
import { AppContext } from '../../context/AppContext';
import { useRest } from '../../hooks/useRest';
import '../../stylesheets/Chart.css';
import moment from 'moment';
import { measSet } from '../../templates/consts';
import TrendControls from './TrendControls';
import { useSession } from '../../hooks/useSessionDev';

HighchartsMore(Highcharts);

interface Props {
  datapoint: any;
}

export const intervals = [86400, 3600, 600];

const Trend: React.FC<Props> = ({ datapoint }) => {

  const { appData, setAppData } = useContext(AppContext);

  const [req] = useRest();
  const [sessionData] = useSession();
  const [trendData, setTrendData, getDeviceTrends, genChartOptions] = useTrend('datapoint', datapoint);

  // const [trendData, setTrendData] = useState<any>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [interval, setInterval] = useState<number>(86400);
  const [endTms, setEndTms] = useState<number | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([0]);
  const [tmsRange, setTmsRange] = useState<any[]>([]);

  const initTrend = async (endTms: number | false, dir?: string | null) => {
    setAppData({ ...appData, loading: { visible: true } })
    let trend = false;

    // TODO: add cahceing logic for trend data
    // let loc = localStorage.getItem('breeziProTrendData');
    // const localTrends = loc ? JSON.parse(loc) : {};'

    let data = await getDeviceTrends(interval, endTms, dir ?? null);
    data = data.map((item: any) => {
      if (item?.data?.dpr) delete item.data.dpr;
      return item;
    });
    trend = data;
    
    setTimeout(() => setAppData({ ...appData, loading: { visible: false } }), 300)
    return trend;
  }

  const initGranularUpdate = async (index: number, data: any) => {
    if (interval !== 600) {
      const tmsSet = data.map((item: any) => item.tms);
      const ts = tmsSet[index];
      setEndTms(ts);
      setLoaded(false);
      setInterval(intervals[intervals.indexOf(interval) + 1]);
    }
  }

  const handleTrendNav = async (dir: string) => {
    setLoaded(false);
    const rng = [...tmsRange];
    const newTms = dir === 'forward'
      ? parseInt(rng[rng.length - 1])
      : parseInt(rng[0])
    let bcs = breadcrumbs;
    bcs[intervals.indexOf(interval)] = newTms;
    setBreadcrumbs(bcs);
    const newData = await options(newTms, dir);
    setTrendData(newData);
    setLoaded(true)
  }

  useEffect(() => {
    (async () => {
      setLoaded(false);
      if (interval !== 86400) {
        const intervalIndex = intervals.indexOf(interval);
        let newData;
        if (breadcrumbs[intervalIndex]) {
          newData = await options(parseInt(breadcrumbs[intervalIndex]));
          setBreadcrumbs(breadcrumbs.splice(0, intervalIndex + 1));
        } else {
          setBreadcrumbs([...breadcrumbs, endTms])
          newData = await options(endTms);
        }
        setTrendData(newData);
        setAppData({ ...appData, loading: { visible: false } });
      } else {
        const data = await options(null);
        setTrendData(data);
        const tmsStart = parseInt(tmsRange[0]);
        setBreadcrumbs([tmsStart]);
      }
    })();
  }, [interval]);

  const options = async (tms: number | null, dir?: string) => {
    const data = await initTrend(tms ?? false, dir ?? null) as any;
    const formattedLabels = data.map((d: any) => moment(d.tms * 1000).format(interval === 86400 ? 'MM/DD' : 'hh:mma : M/D'));
    setTmsRange(data.map((d: any) => d.tms));
    const opts = genChartOptions(formattedLabels, 'datapoint', interval, initGranularUpdate, data);
    return opts;
  }

  useEffect(() => {
    if (trendData) {
      setLoaded(true)
    }
  }, [trendData]);

  useEffect(() => {
    setAppData({ ...appData, loading: { visible: true } });
  }, []);

  useEffect(() => {
    if (trendData) {
      setLoaded(false);
      setTimeout(() => setLoaded(true), 1000);
    }
  }, [window.innerWidth, window.innerHeight]);

  return (
    <div className='dp-panel dp-trend-panel' style={{ overflow: 'visible' }}>
      <TrendControls
        interval={interval}
        setInterval={setInterval}
        breadcrumbs={breadcrumbs}
        handleTrendNav={handleTrendNav}
        tmsRange={tmsRange}/>
      {
        loaded && trendData && <TrendChart componentOptions={trendData} />
      }
    </div>
  )

}

export default Trend;